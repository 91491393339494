import { Box, Grid, Typography } from '@mui/material'
import { PagePaper } from 'components'
import { uniqBy, orderBy, map } from 'lodash'

import { GeneralInformationsType } from './GeneralInformations.types'

import { formatters } from 'helpers'

const GeneralInformations = ({ dataProcess }: GeneralInformationsType) => {
  const dataCollectedsNames = dataProcess?.dataCollecteds
    ?.map((dataCollected) => {
      return dataCollected?.collectedFields
        ?.filter(Boolean)
        ?.map((collectedField) => collectedField.dataCollectedOption.name)
    })
    .flat()

  const titularAgeRangeNames = () => {
    const arrayTitularAgeRanges = dataProcess?.dataCollecteds?.flatMap(
      (dataCollected) => {
        return dataCollected?.titularAgeRange
      }
    )

    const arrayTitularAgeRangeNames = orderBy(
      uniqBy(arrayTitularAgeRanges, 'id'),
      'id'
    )

    return map(arrayTitularAgeRangeNames, 'name').join(' - ')
  }

  return (
    <PagePaper marginBottom={6}>
      <Grid container spacing={3} p={3}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={700} color="primary">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography fontWeight={500}>Identificador</Typography>
            <Typography>{dataProcess?.id}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography fontWeight={500}>Nome do Processo</Typography>
            <Typography>{dataProcess?.name}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography fontWeight={500}>Finalidade</Typography>
            <Typography>{dataProcess?.finality}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography fontWeight={500}>Dados Tratados</Typography>
            <Typography>{dataCollectedsNames?.join(', ')}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography fontWeight={500}>Faixa Etária</Typography>
            <Typography>{titularAgeRangeNames()}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography fontWeight={500}>Data</Typography>
            <Typography>
              {formatters.date.ptBrFormatDate(dataProcess?.createdAt)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </PagePaper>
  )
}

export default GeneralInformations
