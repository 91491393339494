import api from 'service/api'

const dponetAPI = api.create('dponet')
const dponetAPIWithoutRedirect = api.create('dponet', false)

const changeStatus = async ({
  outsourcedDpoId,
  outsourcedDpo,
}: {
  outsourcedDpoId?: string | number
  outsourcedDpo?: object
}) => {
  return dponetAPI.put(
    `/advisors/outsourced_dpos/${outsourcedDpoId}/change_status`,
    {
      outsourcedDpo,
    }
  )
}

const transparentLogin = async ({
  companyId,
}: {
  companyId?: string | number
}) => {
  return dponetAPIWithoutRedirect.post(
    `/advisors/user_companies/${companyId}/select`
  )
}

export default {
  changeStatus,
  transparentLogin,
}
