const ACTIVE_STATUS = 'ACTIVE'
const CANCELED_STATUS = 'CANCELED'
const PENDING_STATUS = 'PENDING'
const REFUSED_STATUS = 'REFUSED'

export default {
  ACTIVE_STATUS,
  CANCELED_STATUS,
  PENDING_STATUS,
  REFUSED_STATUS,
}
