import api from 'service/api'

const dponetAPI = api.create('dponet')
const get = async ({
  profileId = '',
  params = {},
}: {
  profileId?: string
  params: object
}) => {
  return dponetAPI.get(`/advisors/profiles/${profileId}`, { params })
}

const create = async (data: object) => {
  return dponetAPI.post('/advisors/profiles', data)
}

const update = async (profileId: string | number, data: object) => {
  return dponetAPI.put(`/advisors/profiles/${profileId}`, data)
}

const changeStatus = async ({ profileId }: { profileId?: string | number }) => {
  return dponetAPI.put(`/advisors/profile/${profileId}/change_status`)
}

export default {
  create,
  get,
  update,
  changeStatus,
}
