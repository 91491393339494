import { useEffect, useState } from 'react'

import { PermissionContext } from 'contexts'

import { useOrganization } from 'hooks'

import { PermissionProviderType } from './permissionProvider.types'
import { ProfileFunctionalitiesType } from 'types/profile.types'

import service from 'service'

const AuthProvider = ({ children }: PermissionProviderType) => {
  const [permissions, setPermissions] = useState<ProfileFunctionalitiesType[]>(
    []
  )
  const [loaded, setLoaded] = useState<boolean>(false)

  const { currentOrganization } = useOrganization()

  const loadData = async () => {
    const response = await service.dponet.user.permissions()
    setPermissions(response.data.profilePermissions)
    setLoaded(true)
  }

  const isPermitted = (tag: string) => {
    if (!currentOrganization?.id) return false

    return permissions?.find((funct) => funct.tag === tag)?.status ?? true
  }

  const somePermitted = (someTags: string[]) => {
    return someTags.map((tag) => isPermitted(tag)).includes(true)
  }

  useEffect(() => {
    if (currentOrganization) loadData()
  }, [currentOrganization?.id])

  return (
    <PermissionContext.Provider
      value={{
        loadData,
        loaded,
        permissions,
        isPermitted,
        somePermitted,
      }}
    >
      {children}
    </PermissionContext.Provider>
  )
}

export default AuthProvider
