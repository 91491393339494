import { useState } from 'react'
import {
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material'

import {
  MenuButton,
  Permitted,
  Table as TableComponent,
  TableRow,
} from 'components'
import TagDeletionDialog from '../TagDeletionDialog'

import { TagTableType } from './tagTable.types'
import { TagType } from 'types/tag.types'

import { formatters } from 'helpers'
import permissionTagsConstants from 'constants/permissionTags'

const TagTable = ({ refresh, tags }: TagTableType) => {
  const [open, setOpen] = useState(false)
  const [selectedTag, setSelectedTag] = useState<TagType>()

  const handleOpenDeletionDialog = (tag: TagType) => {
    setOpen(true)
    setSelectedTag(tag)
  }

  return (
    <TableContainer>
      <TableComponent aria-label="listagem dos classificadores">
        <TableHead>
          <TableRow>
            <TableCell align="left">Nome</TableCell>
            <TableCell align="left">Criado por</TableCell>
            <TableCell align="left">Criado em</TableCell>
            <TableCell align="center">Empresas</TableCell>
            <Permitted tag={permissionTagsConstants.ORGANIZATION_TAG.MANAGE}>
              <TableCell align="right">Ação</TableCell>
            </Permitted>
          </TableRow>
        </TableHead>
        <TableBody>
          {tags?.map((tag) => (
            <TableRow key={tag.id}>
              <TableCell align="left">{tag.name}</TableCell>
              <TableCell align="left">{tag.author.name}</TableCell>
              <TableCell align="left">
                {formatters.date.ptBrFormatDate(tag.createdAt)}
              </TableCell>
              <TableCell align="center">{tag.companies}</TableCell>
              <Permitted tag={permissionTagsConstants.ORGANIZATION_TAG.MANAGE}>
                <TableCell align="right">
                  <MenuButton id={`organization-tag-${tag.id}`}>
                    <MenuItem onClick={() => handleOpenDeletionDialog(tag)}>
                      EXCLUIR
                    </MenuItem>
                  </MenuButton>
                </TableCell>
              </Permitted>
            </TableRow>
          ))}
        </TableBody>
      </TableComponent>
      <TagDeletionDialog
        refresh={refresh}
        open={open}
        onClose={() => setOpen(false)}
        tag={selectedTag}
      />
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tags?.length}
        rowsPerPage={5}
        page={0}
        onPageChange={() => {}}
        onRowsPerPageChange={() => {}}
        labelRowsPerPage="Linhas por página"
      /> */}
    </TableContainer>
  )
}

export default TagTable
