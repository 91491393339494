import { useEffect, useState } from 'react'

import { FilterInput, Filters } from 'components'

import { FilterHookType } from 'types/filters.types'

import { useFetch } from 'hooks'

import { OrganizationCompanyType } from 'types/organizationCompany'
import organizationCompaniesConstants from 'constants/organizationCompanies'

import { formatters } from 'helpers'
import service from 'service'

const TaskFilters = ({ filter }: { filter: FilterHookType }) => {
  const [shouldFetch, setShouldFetch] = useState<boolean>(false)

  useEffect(() => {
    if (filter.drawerOpen && !shouldFetch) setShouldFetch(true)
  }, [filter.drawerOpen])

  const [organizationCompanyFilter, setOrganizationCompanyFilter] = useState<
    string | null
  >()
  const validCompanyFilterInput =
    !!organizationCompanyFilter && organizationCompanyFilter?.length >= 3

  useEffect(() => {
    if (filter.drawerOpen && !shouldFetch) setShouldFetch(true)
  }, [filter.drawerOpen])

  const { response, loading } = useFetch(
    service.dponet.organizationCompany.get,
    {
      params: {
        companyName: organizationCompanyFilter,
        perPage: 999,
        minimal: true,
        status: organizationCompaniesConstants.ACTIVE_STATUS,
      },
    },
    [organizationCompanyFilter],
    validCompanyFilterInput
  )

  return (
    <Filters filter={filter}>
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Identificador', name: 'id' }}
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Título da atividade', name: 'name' }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        loading={loading && validCompanyFilterInput}
        autocompleteOnChange={(data) => {
          setOrganizationCompanyFilter(data)
        }}
        {...{
          noOptionsText: formatters.autoCompleteNoText(
            organizationCompanyFilter || ''
          ),
          label: 'Empresa',
          name: 'companyId',
          options: response?.data?.organizationCompanies?.map(
            (organizationCompany: OrganizationCompanyType) => ({
              id: organizationCompany?.company?.id,
              name: organizationCompany?.company?.name,
            })
          ),
        }}
      />
    </Filters>
  )
}

export default TaskFilters
