import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
  TextField,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { useSnackbar } from 'hooks'

import { formatters, validates } from 'helpers'

import { AnswerFormType } from './answerForm.types'

import schema, { AnswerFormSchemaType } from './schema'
import service from 'service'

const AnswerForm = ({
  setIsLoading,
  onClose,
  isEdit = false,
  questionId,
  answerId,
  title,
  status = 'inactive',
  refresh,
}: AnswerFormType) => {
  const { snackbar } = useSnackbar()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AnswerFormSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: title || '',
      status: validates.privacyPortal.statusTranslationToSwitch(status),
    },
  })

  const onSubmit = async (data: AnswerFormSchemaType) => {
    try {
      setIsLoading(true)

      isEdit
        ? await service.dponet.privacyPortal.updateAnswer({
            answerId,
            answerOptionTicket: {
              title: data?.title,
              status: validates.privacyPortal.switchTranslation(data?.status),
            },
          })
        : await service.dponet.privacyPortal.createAnswer({
            questionId,
            answerOptionTicket: {
              title: data?.title,
              status: validates.privacyPortal.switchTranslation(data?.status),
            },
          })

      snackbar.open({
        message: isEdit
          ? 'Resposta alterada com sucesso!'
          : 'Resposta criada com sucesso!',
        variant: 'success',
      })
      refresh()
      onClose()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      id="privacy-portal-answer-form"
      display="flex"
      flexDirection="column"
      gap={4}
    >
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors?.title}
            helperText={errors?.title?.message}
            label="Título da resposta"
            type="text"
            variant="outlined"
            fullWidth
          />
        )}
        control={control}
        name="title"
      />
      <Controller
        render={({ field }) => (
          <FormControl>
            <FormControlLabel
              control={<Switch {...field} checked={!!field.value} />}
              label="Ativar"
            />
            {!!errors?.status && (
              <FormHelperText error={!!errors?.status}>
                {errors?.status?.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
        control={control}
        name="status"
      />
    </Box>
  )
}

export default AnswerForm
