import { styled } from '@mui/material/styles'

const LogoDpoLia = styled('img')(() => ({
  height: '90%',
  width: '90%',
  objectFit: 'cover',
  objectPosition: '-3% 1%',
}))

export default LogoDpoLia
