import { useEffect, useState } from 'react'

import { AuthContext } from 'contexts'

import { AuthProviderType } from './authProvider.types'
import { UserType } from 'types/user.types'

import service from 'service'
import routes from 'constants/routes'
import constants from 'constants/index'

const AuthProvider = ({ children }: AuthProviderType) => {
  const [user, setUser] = useState<UserType>({})

  const [loaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (service.dponet.auth.isAuthenticated()) {
      loadData()
    }
  }, [])

  const loadData = async () => {
    await loadUserData()
  }

  const loadUserData = async () => {
    try {
      setLoaded(false)
      const response = await service.dponet.user.me({
        params: {
          outsourcedDposStatus: constants.outsourcedDpos.PENDING_STATUS,
        },
      })
      setUser(response?.data?.user)
    } catch (error) {
      console.error(error)
      loadOut()
    } finally {
      setLoaded(true)
    }
  }

  const loadOut = () => {
    service.dponet.auth.logout()
    service.dponet.auth.removeOrganization()
    location.replace(routes.auth.login)
    setUser({})
  }

  return (
    <AuthContext.Provider
      value={{
        loadData,
        loaded,
        loadOut,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
