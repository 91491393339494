import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'
import {
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material'
import { ArrowRight } from 'react-feather'

import { Table as TableComponent, TableRow } from 'components'

import { ProfileTableType } from './profileTable.types'

import routes from 'constants/routes'

const ProfileTable = ({ profiles }: ProfileTableType) => {
  const navigate = useNavigate()

  const handleClickActionButton = (
    event: React.MouseEvent<unknown>,
    id: number
  ) => {
    event.stopPropagation()

    handleCLickTableRow(id)
  }

  const handleCLickTableRow = (id: number) => {
    navigate(
      reverse(routes.app.profiles.update, {
        profileId: id,
      })
    )
  }

  return (
    <TableContainer>
      <TableComponent aria-label="listagem dos grupos advisors">
        <TableHead>
          <TableRow>
            <TableCell align="left">Identificador</TableCell>
            <TableCell align="left">Nome</TableCell>
            <TableCell align="left">Descrição</TableCell>
            <TableCell align="right">Ação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {profiles?.map((profile) => (
            <TableRow
              key={profile.id}
              onClick={() => handleCLickTableRow(profile.id)}
            >
              <TableCell align="left">{profile.id}</TableCell>
              <TableCell align="left">{profile.name}</TableCell>
              <TableCell align="left">{profile.description}</TableCell>
              <TableCell align="right">
                <Button
                  variant="outlined"
                  color="neutral"
                  endIcon={<ArrowRight size={20} />}
                  onClick={(event) =>
                    handleClickActionButton(event, profile.id)
                  }
                >
                  DETALHES
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableComponent>
    </TableContainer>
  )
}

export default ProfileTable
