import { Box, Typography } from '@mui/material'

import { LinearProgress } from 'components'

import { LinearProgressWithLabelAndTitleType } from './linearProgressWithTitleAndLabel.types'

const LinearProcessWithLabel = (props: LinearProgressWithLabelAndTitleType) => {
  const { title, value, valueLabel, thickness, borderRadius, ...rest } = props

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="subtitle2" color="text.primary">
          {title}
        </Typography>

        <Typography variant="subtitle2" color="text.primary">
          {valueLabel ?? `${Math.round(value)}%`}
        </Typography>
      </Box>

      <LinearProgress
        variant="determinate"
        $thickness={thickness}
        $borderRadius={borderRadius}
        value={value}
        {...rest}
      />
    </Box>
  )
}

export default LinearProcessWithLabel
