import { Hidden, TablePagination } from '@mui/material'

import {
  ListPageStructure,
  LoadingFeedback,
  NoData,
  PageHead,
  PagePaper,
  Permitted,
} from 'components'
import { ProfileCreate, ProfileTable } from './components'

import { useFetch, usePagination } from 'hooks'

import service from 'service'
import permissionTagsConstants from 'constants/permissionTags'

const ProfileMain = () => {
  const { handleChangePage, handleChangeRowsPerPage, page, perPage } =
    usePagination()

  const { response, loading } = useFetch(
    service.dponet.profiles.get,
    {
      params: { perPage, page },
    },
    [page, perPage]
  )

  return (
    <ListPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead
        name="Perfis de Usuários"
        actionButton={
          <Permitted tag={permissionTagsConstants.ADVISOR_PROFILE.MANAGE}>
            <ProfileCreate />
          </Permitted>
        }
      />
      {response?.data?.profiles.length === 0 ? (
        <NoData title="Nenhum perfil foi encontrado." />
      ) : (
        <PagePaper>
          <ProfileTable profiles={response?.data?.profiles} />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            page={page - 1}
            rowsPerPage={perPage}
            onPageChange={(_, page) => handleChangePage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
          />
        </PagePaper>
      )}
    </ListPageStructure>
  )
}

export default ProfileMain
