import * as yup from 'yup'

const schema = yup.object({
  visualIdentifySelection: yup.string().required(),
})

export type IdentifySelectionFormType = yup.InferType<typeof schema>

export type IdentifySelectionControlType = 'visualIdentifySelection'

export default schema
