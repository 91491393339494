import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import polygonOutlined from 'images/polygon_green_outlined.png'

const PolygonOutlined = styled(Box)(() => ({
  backgroundImage: `url(${polygonOutlined})`,
  height: 92,
  width: 83,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

export default PolygonOutlined
