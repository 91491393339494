import { Box, Paper, StepLabel, Stepper, useMediaQuery } from '@mui/material'

import { Step } from './components'

import { useIncidentManegement } from 'hooks'

import theme from 'theme'

const StepperComponent = () => {
  const { isCompleted, activeStep, handleStep, incident } =
    useIncidentManegement()
  const matches = useMediaQuery(theme.breakpoints.up('lg'))

  const steps = () => {
    const defaultSteps = [
      'Detecção',
      'Investigação',
      'Avaliação',
      'Lições Aprendidas',
    ]

    if (incident?.confirmed) {
      defaultSteps.splice(3, 0, 'Detalhamento', 'Tratamento', 'Comunicação')
    }

    return defaultSteps
  }

  return (
    <Paper component={Box} p={5}>
      <Stepper
        activeStep={activeStep}
        orientation={matches ? 'horizontal' : 'vertical'}
      >
        {steps().map((step, index) => (
          <Step
            key={step}
            onClick={() => handleStep(index)}
            completed={isCompleted(index)}
          >
            <StepLabel>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Paper>
  )
}

export default StepperComponent
