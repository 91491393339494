import {
  Loader as LoaderIcon,
  Eye as EyeIcon,
  AlertCircle as AlertCircleIcon,
  Check as CheckIcon,
  Moon as MoonIcon,
} from 'react-feather'

import dataProcessConstants from 'constants/dataProcess'

const statusIcon = (statusId: number, size = 18) => {
  switch (statusId) {
    case dataProcessConstants.PENDING_STATUS_ID:
      return <LoaderIcon color="white" size={size} />
    case dataProcessConstants.WAITING_REVIEW_STATUS_ID:
      return <EyeIcon color="white" size={size} />
    case dataProcessConstants.DISAPPROVED_STATUS_ID:
      return <AlertCircleIcon color="white" size={size} />
    case dataProcessConstants.APPROVED_STATUS_ID:
      return <CheckIcon color="white" size={size} />
    case dataProcessConstants.REVIEWING_STATUS_ID:
      return <EyeIcon color="white" size={size} />
    case dataProcessConstants.INACTIVE_STATUS_ID:
      return <MoonIcon color="white" size={size} />
    case dataProcessConstants.AUTO_REVIEW_STATUS_ID:
      return <EyeIcon color="white" size={size} />
    default:
      return <></>
  }
}

export default statusIcon
