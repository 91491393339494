import { Fragment, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { ExternalLink as ExternalLinkIcon } from 'react-feather'
import { useNavigate } from 'react-router-dom'

import { HelpDialog, Link } from './components'
import { LoadingFeedback } from 'components'

import { useAuth, useSnackbar } from 'hooks'

import { getTomTicketURL } from 'service/env'
import routes from 'constants/routes'
import service from 'service'
import { formatters } from 'helpers'

const HelpBox = () => {
  const [openHelpDialog, setOpenHelpDialog] = useState(false)
  const [dataDocument, setDataDocument] = useState<File[]>([])
  const [loading, setLoading] = useState(false)

  const { snackbar } = useSnackbar()
  const { user } = useAuth()
  const navigate = useNavigate()
  const url = getTomTicketURL()

  const goToFaqsDocumentations = () => {
    navigate(routes.app.faqs.all)
  }

  const goToMyTickets = () => {
    navigate(routes.app.myTickets.all)
  }

  const handleRedirectToTomticket = async () => {
    try {
      setLoading(true)

      const response = await service.dponet.tomticket.getTomticketToken()
      const tomticketToken = response?.data

      window.open(`${url}=${tomticketToken}`)
    } catch (error) {
      snackbar.open({
        message:
          formatters.errorMessage(error) ||
          'Ocorreu um erro, tente novamente mais tarde.',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleOpenHelpDialog = () => setOpenHelpDialog(true)
  const handleCloseHelpDialog = () => {
    setDataDocument([])
    setOpenHelpDialog(false)
  }

  return (
    <Fragment>
      <LoadingFeedback open={loading} />

      <Button
        variant="contained"
        color="tertiary"
        endIcon={<ExternalLinkIcon size="14" />}
        onClick={goToFaqsDocumentations}
      >
        Central de ajuda
      </Button>

      <Box mt={2} width="100%">
        {user?.tomticketToken && (
          <Link onClick={handleRedirectToTomticket}>
            <Typography variant="body2" gutterBottom>
              Ver meus tickets
            </Typography>
          </Link>
        )}
        <Link onClick={goToMyTickets}>
          <Typography variant="body2" gutterBottom>
            Tickets Jurídicos/SI
          </Typography>
        </Link>
        <Link onClick={handleOpenHelpDialog}>
          <Typography variant="body2">Entre em contato</Typography>
        </Link>
      </Box>
      <HelpDialog
        open={openHelpDialog}
        onClose={handleCloseHelpDialog}
        openHelpDialog={openHelpDialog}
        dataDocument={dataDocument}
        setDataDocument={setDataDocument}
      />
    </Fragment>
  )
}

export default HelpBox
