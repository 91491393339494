import { Button } from '@mui/material'

import { colors } from '@mui/material'
import { styled } from '@mui/material/styles'

const ButtonSubmit = styled(Button)(({ theme }) => ({
  backgroundColor: colors.grey[100],
  color: theme.palette.primary.main,
  border: 'none',
  fontWeight: 'normal',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    border: 'none',
    backgroundColor: colors.grey[300],
  },
  justifyContent: 'start',
}))

export default ButtonSubmit
