import { Drawer as DrawerMUI } from '@mui/material'
import { styled } from '@mui/material/styles'

const Drawer = styled(DrawerMUI)(({ theme }) => ({
  position: 'fixed',
  height: 'calc(100vh - 72px)',
  width: '256px',
  background: `linear-gradient(to bottom, ${theme.palette.primary.main} 80%, ${theme.palette.secondary.main} 100%)`,

  '& .MuiPaper-root': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
}))

export default Drawer
