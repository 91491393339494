import * as yup from 'yup'

const schema = yup.object({
  companyMatrixId: yup
    .object()
    .shape({
      label: yup.string().required(),
      document: yup.string().required(),
      companyId: yup.number().required(),
    })
    .nullable(),
  organizationTags: yup.array(),
})

export type CompanyUpdateFormType = yup.InferType<typeof schema>

export default schema
