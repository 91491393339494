import { createContext } from 'react'

import { PermissionContextType } from 'types/permission.types'

const contextValues: PermissionContextType = {
  loadData: async () => {},
  permissions: [],
  isPermitted: (tag: string) => !!tag,
  somePermitted: (someTags: string[]) => !!someTags[0],
}

const ProviderContext = createContext(contextValues)

export default ProviderContext
