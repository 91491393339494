import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack,
  TextField,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import BasicDialog from 'components/BasicDialog'

import { FeedbackDialogType } from './feedbackDialog.types'

import schema, { FeedbackDialogFormType } from './schema'

const FeedbackDialog = ({
  open,
  onClose,
  onSubmit: onSubmitFeedback,
}: FeedbackDialogType) => {
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      confuse: false,
      incomplete: false,
      outOfContext: false,
      notInCompliance: false,
      other: false,
      otherReason: '',
    },
  })

  const fields = [
    'confuse',
    'incomplete',
    'outOfContext',
    'notInCompliance',
    'other',
  ] as const

  const answers = {
    confuse: 'Resposta confusa',
    incomplete: 'Resposta incompleta',
    outOfContext: 'Resposta fora de contexto',
    notInCompliance: 'Resposta fora de conformidade com a lei',
    other: 'Outro',
  }

  const onSubmitForm = (data: FeedbackDialogFormType) => {
    let reason = ''

    fields.forEach((fieldName) => {
      if (fieldName === 'other') return

      if (data[fieldName]) reason += `${answers[fieldName]};//\n`
    })

    if (data.other) reason += `${data.otherReason};//\n`

    onSubmitFeedback(reason)
  }

  return (
    <BasicDialog
      title="Justifique"
      open={open}
      onClose={onClose}
      form="feedback-form"
    >
      <form id="feedback-form" onSubmit={handleSubmit(onSubmitForm)}>
        {fields.map((fieldName) => (
          <Stack key={fieldName}>
            <Controller
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox />}
                  label={answers[fieldName]}
                />
              )}
              name={fieldName}
              control={control}
            />
            <FormHelperText error={!!errors[fieldName]}>
              {errors[fieldName]?.message}
            </FormHelperText>
          </Stack>
        ))}

        {watch('other') && (
          <Stack>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors['otherReason']}
                  helperText={<>{errors['otherReason']?.message}</>}
                  label="Especifique qual é o motivo"
                  multiline
                  rows={2}
                  fullWidth
                />
              )}
              name="otherReason"
              control={control}
            />
          </Stack>
        )}
      </form>
    </BasicDialog>
  )
}

export default FeedbackDialog
