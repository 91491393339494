import moment from 'moment'
import { Grid, TextField } from '@mui/material'

import { Card, MUI } from 'components'
import IncidentTextArea from '../../../IncidentTextArea'
import IncidentCheckbox from '../IncidentCheckbox'

import { DescriptionIncidentType } from './descriptionIncident.types'

const DescriptionIncident = ({
  incident,
  options,
}: DescriptionIncidentType) => {
  const incidentDatetime = incident?.incidentDatetime
    ? moment(incident.incidentDatetime).toDate()
    : new Date()

  return (
    <Card title="Formulário de Comunicação de Incidente de Segurança com Dados Pessoais - Simplificado">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <MUI.DatePicker
            label="Data do incidente"
            defaultValue={incidentDatetime}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MUI.TimePicker
            label="Hora do incidente"
            defaultValue={incidentDatetime}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Quantidade estimada de titulares afetados"
            fullWidth
            defaultValue={incident?.affectedHolders}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <IncidentTextArea
            label="Descreva, resumidamente, como ocorreu o incidente:"
            value={incident?.description}
          />
        </Grid>
        <Grid item xs={12}>
          <IncidentTextArea
            label="Explicar resumidamente, por que o incidente ocorreu (identifique a causa raiz, se conhecida):"
            value={incident?.descriptionRootIncident}
          />
        </Grid>
        <Grid item xs={12}>
          <IncidentTextArea
            label="Quais medidas foram adotadas para corrigir as causas do incidente?"
            value={incident?.descriptionMeasures}
          />
        </Grid>
        <Grid item xs={12}>
          <IncidentCheckbox
            label="Antes do incidente, quais das seguites medidas de segurança eram adotadas"
            incidentOptions={options.whichSecurityMeasuresAdopted || []}
            incidentOptionsSelectedItems={
              incident?.dataOptionIncidents?.whichSecurityMeasuresAdopted || []
            }
            other
          />
        </Grid>
        <Grid item xs={12}>
          <IncidentTextArea
            label="Descrever as demais medidas de segurança técnicas e administrativas adotadas antes do incidente:"
            value={
              incident?.descriptionOtherTechnicalAdministrativeSecurityMeasure
            }
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default DescriptionIncident
