import { ChangeEvent, useRef, useState } from 'react'
import { ChromePicker, ColorResult } from 'react-color'
import { Controller } from 'react-hook-form'
import { Box, FormControl, FormHelperText, TextField } from '@mui/material'

import { useOutsideClick } from 'hooks'

import { ButtonColorPickerType } from './buttonColorPicker.types'
import { onlyLetters, removeSpaces } from 'helpers/regex'

const ButtonColorPicker = ({
  name,
  control,
  error,
  controlName,
  colorPicker,
  setColorPicker,
}: ButtonColorPickerType) => {
  const [showPicker, setShowPicker] = useState(false)

  const boxChromePickerRef = useRef<HTMLDivElement | null>(null)
  const textFieldRef = useRef<HTMLInputElement | null>(null)

  const handleTextFieldFocus = () => {
    setShowPicker(true)
  }

  useOutsideClick({
    ref: boxChromePickerRef,
    callback: (event: MouseEvent) => {
      if (
        textFieldRef.current &&
        textFieldRef.current.contains(event.target as Node)
      ) {
        return
      }
      setShowPicker(false)
    },
  })

  const commonLetters = (value: string) => {
    const onlyLettersData = onlyLetters(value)
    return removeSpaces(onlyLettersData).toUpperCase()
  }

  const handleColorChange = (
    newColor?: ColorResult,
    event?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let newColorValue = ''

    if (newColor) {
      newColorValue = '#' + commonLetters(newColor.hex)
    } else if (event) {
      newColorValue = '#' + commonLetters(event.target.value)
    }

    setColorPicker(newColorValue)
  }

  return (
    <Box>
      <Box ref={boxChromePickerRef}>
        <Controller
          render={({ field }) => (
            <FormControl fullWidth margin="dense">
              <TextField
                {...field}
                error={!!error}
                label={name}
                variant="outlined"
                onFocus={handleTextFieldFocus}
                inputRef={textFieldRef}
                fullWidth
                onChange={(event) => {
                  handleColorChange(undefined, event)
                }}
              />
              <FormHelperText error={!!error}>{error?.message}</FormHelperText>
            </FormControl>
          )}
          name={controlName}
          control={control}
          defaultValue={colorPicker}
        />
      </Box>
      <Box display="flex" justifyContent="end">
        <Box
          ref={boxChromePickerRef}
          width="fit-content"
          position="absolute"
          zIndex={1600}
        >
          {showPicker && (
            <ChromePicker
              color={colorPicker}
              onChange={handleColorChange}
              disableAlpha
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ButtonColorPicker
