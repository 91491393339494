import api from 'service/api'

const dponetAPI = api.create('dponet')

const create = async ({
  organizationId,
  organizationUser,
}: {
  organizationId?: string | number
  organizationUser: object
}) => {
  return dponetAPI.post(`/advisors/organizations/${organizationId}/users`, {
    organizationUser,
  })
}

const get = async ({
  organizationId = '',
  params = {},
}: {
  organizationId?: string | number
  params?: object
}) => {
  return dponetAPI.get(`/advisors/organizations/${organizationId}/users`, {
    params,
  })
}

const changeStatus = async ({
  organizationId,
  organizationUserId,
  status,
}: {
  organizationId?: string | number
  organizationUserId?: string | number
  status: string
}) => {
  return dponetAPI.patch(
    `/advisors/organizations/${organizationId}/users/${organizationUserId}/change_status`,
    {
      status,
    }
  )
}

const replyInvite = async ({
  organizationId,
  organizationUserId,
  status,
}: {
  organizationId?: string | number
  organizationUserId?: string | number
  status: string
}) => {
  return dponetAPI.patch(
    `/advisors/organizations/${organizationId}/users/${organizationUserId}/reply_invite`,
    {
      status,
    }
  )
}

export default {
  create,
  get,
  changeStatus,
  replyInvite,
}
