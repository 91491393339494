import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material'

import { BasicDialogType } from './basicDialog.types'

const BasicDialog = ({
  children,
  dialogActions,
  form = '',
  maxWidth = 'sm',
  fullScreen = false,
  onClose = () => {},
  onSave = () => {},
  open,
  title,
  isSaving = false,
  disableBackdropClick = false,
  saveButtonName = 'Salvar',
  closeButtonName = 'Fechar',
  justClose = false,
  saveButtonColor = 'primary',
}: BasicDialogType) => {
  return (
    <Dialog
      open={open}
      onClose={!disableBackdropClick ? onClose : () => {}}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
      <Divider />
      <DialogActions>
        {dialogActions ?? (
          <>
            <Button onClick={onClose}>{closeButtonName}</Button>
            {!justClose && (
              <Button
                onClick={onSave}
                variant="contained"
                type="submit"
                form={form}
                disabled={isSaving}
                color={saveButtonColor}
              >
                {isSaving ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  saveButtonName
                )}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default BasicDialog
