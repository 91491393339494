import { Fragment } from 'react'
import { Share2 as ShareIcon } from 'react-feather'
import { Box, Grid } from '@mui/material'

import { TitleSection, TopicSection, DataProcessInfoChip } from '..'

import { DataTreatmentsType } from './dataTreatments.types'
import theme from 'theme'

const DataTreatments = ({ dataTreatments = [] }: DataTreatmentsType) => {
  return (
    <Box m={4}>
      <TitleSection title="Dados compartilhados" icon={ShareIcon} />
      <Grid container mt={2}>
        <Grid item xs={6}>
          <Box mt={2}>
            <DataProcessInfoChip
              text="Com quem os dados são compartilhados"
              color={theme.palette.secondary.main}
              textColor="white"
            />
            <Grid container>
              {dataTreatments?.map((dataTreatment) => (
                <Grid item key={dataTreatment?.id} xs={12}>
                  <Fragment>
                    <TopicSection text={dataTreatment?.shareProcess?.name} />
                    <Box display="flex" flexDirection="row" mt={0.5}>
                      <DataProcessInfoChip
                        text={dataTreatment?.shareProcess?.name}
                      />
                    </Box>
                  </Fragment>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mt={2}>
            <DataProcessInfoChip
              text="Por que os dados são compartilhados"
              color={theme.palette.secondary.main}
              textColor="white"
            />
            <Grid container>
              {dataTreatments?.map((dataTreatment) => (
                <Grid item xs={12} key={dataTreatment?.id}>
                  <Fragment>
                    <TopicSection text={dataTreatment?.shareProcess?.name} />
                    <Box display="flex" mt={0.5}>
                      <DataProcessInfoChip text={dataTreatment?.description} />
                    </Box>
                  </Fragment>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mt={2}>
            <DataProcessInfoChip
              text="Como os dados são compartilhados"
              color={theme.palette.secondary.main}
              textColor="white"
            />
            {dataTreatments?.map((dataTreatment) => (
              <Fragment key={dataTreatment?.id}>
                <TopicSection text={dataTreatment?.shareProcess?.name} />
                <Box display="flex" mt={0.5}>
                  <DataProcessInfoChip
                    text={dataTreatment?.controllMechanism}
                  />
                </Box>
              </Fragment>
            ))}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mt={2}>
            <DataProcessInfoChip
              text="Tipo"
              color={theme.palette.secondary.main}
              textColor="white"
            />
            <Grid container>
              {dataTreatments?.map((dataTreatment) => (
                <Grid item xs={12} key={dataTreatment?.id}>
                  <Fragment key={dataTreatment?.id}>
                    <TopicSection text={dataTreatment?.shareProcess?.name} />
                    <Box display="flex" mt={0.5}>
                      <DataProcessInfoChip
                        text={dataTreatment?.shareType?.name}
                      />
                    </Box>
                  </Fragment>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DataTreatments
