import { useRef } from 'react'
import { Clock as ClockIcon, Paperclip as PaperclipIcon } from 'react-feather'
import { useFormContext } from 'react-hook-form'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { isNil } from 'lodash'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  Avatar,
  Box,
  Button,
  colors,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { ButtonGroup, TextEditor } from 'components'
import { StyledAttachmentButton, StyledButton } from './components'

import { usePermission } from 'hooks'

import { avatarLetters } from 'helpers'

import { TicketTextBoxType } from './ticketTextBox.types'

import theme from 'theme'
import constants from 'constants/index'

const TicketTextBox = ({
  activeButton,
  selectedFiles,
  setActiveButton,
  setSelectedFiles,
  setStatusButtonGroup,
  statusButtonGroup,
  ticket,
  user,
}: TicketTextBoxType) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const { isPermitted } = usePermission()

  const { register } = useFormContext()

  const permissionTag = constants.permissionTags.JURIDIC_TICKET.MANAGE
  const statusOptions = constants.tickets.TICKET_CHAT_STATUS_OPTIONS

  const isPermittedManageTicket = isPermitted(permissionTag)

  const isSwipeable = useMediaQuery(theme?.breakpoints?.down('sm'), {
    defaultMatches: true,
  })

  const handleButtonClick = (buttonName: number) => {
    if (setActiveButton) {
      setActiveButton(buttonName)
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setSelectedFiles && selectedFiles) {
      const newFiles: File[] = Array.from(event?.target?.files || [])
      setSelectedFiles([...selectedFiles, ...newFiles])

      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  const handleRemoveFile = (fileName: string) => {
    if (setSelectedFiles && selectedFiles) {
      const updatedFiles = selectedFiles?.filter(
        (file) => file?.name !== fileName
      )
      setSelectedFiles(updatedFiles)
    }
  }

  const deadlineTranslation = (deadline: number): string => {
    const pluralSuffix = deadline !== 1 ? 's' : ''

    if (deadline <= 0) {
      return 'A resolução dessa solicitação se encontra atrasada!'
    } else {
      return `${deadline} dia${pluralSuffix} restante${pluralSuffix}`
    }
  }

  return (
    <Box mt={6}>
      <Paper component={Box} elevation={1} mt={2} padding={2}>
        <Box display="flex" p={1} gap={2}>
          {constants.tickets.TICKET_CHAT_TABS.map((message) => (
            <StyledButton
              key={message.id}
              isActive={activeButton === message.id}
              onClick={() => handleButtonClick(message.id)}
              disabled={!isPermittedManageTicket}
            >
              <Typography variant="button" px={1}>
                {message.kind}
              </Typography>
            </StyledButton>
          ))}
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column" p={1.5} mt={3.5} gap={4}>
          <TextEditor />
          <PerfectScrollbar>
            <Box display="flex" flexDirection="row">
              {selectedFiles &&
                selectedFiles?.map((file) => (
                  <Box key={file?.name} mr={2} mb={2}>
                    <StyledAttachmentButton type="button">
                      {file?.name}
                      <Typography
                        color={colors.grey[400]}
                        onClick={() => handleRemoveFile(file?.name)}
                        display="flex"
                        pl={2}
                        noWrap
                      >
                        <CancelIcon />
                      </Typography>
                    </StyledAttachmentButton>
                  </Box>
                ))}
            </Box>
          </PerfectScrollbar>
        </Box>
        <Divider />
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={theme.spacing(3, 2, 1, 2)}
          spacing={4}
        >
          <Grid item xs={12} sm={4}>
            <Box
              display="flex"
              gap={4}
              justifyContent={isSwipeable ? 'center' : 'start'}
            >
              <Avatar alt={user?.name}>{avatarLetters(user?.name)}</Avatar>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Tooltip title={user?.name} placement="top">
                  <Typography
                    fontWeight={700}
                    color="primary"
                    maxWidth={180}
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {user?.name}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Box
              display="flex"
              justifyContent={isSwipeable ? 'center' : 'start'}
            >
              <Button
                variant="outlined"
                component="label"
                disabled={!isPermittedManageTicket}
              >
                <PaperclipIcon size={15} />
                ANEXAR
                <Box display="none">
                  {register && (
                    <input
                      {...register('attachment')}
                      ref={fileInputRef}
                      id="attachment"
                      name="attachment"
                      type="file"
                      multiple
                      accept={`${constants.validations?.FILE_TYPES_IMAGE},${constants.validations?.FILE_TYPES_DOCS}`}
                      onChange={handleFileChange}
                      disabled={!isPermittedManageTicket}
                    />
                  )}
                </Box>
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} minWidth={300}>
            {statusButtonGroup && (
              <ButtonGroup
                options={statusOptions.filter((item) => item.id !== null)}
                title="ENVIAR COMO"
                buttonGroup={statusButtonGroup}
                setButtonGroup={setStatusButtonGroup}
                type="submit"
                minTitleWidth={100}
                fullWidth
                disabled={
                  !isPermittedManageTicket ||
                  statusButtonGroup ===
                    constants.tickets.WAITING_CONFIRMATION_TICKET_ID
                }
              />
            )}
          </Grid>
        </Grid>
        {!isNil(ticket?.daysToDeadlineDate) && (
          <Box
            display="flex"
            bgcolor={colors.blueGrey[700]}
            padding={2}
            borderRadius={1}
            alignItems="start"
            gap={2}
            mt={2}
          >
            <ClockIcon color="white" size={16} />
            <Typography color="white" variant="body2">
              Prazo:&nbsp;
              <strong>{deadlineTranslation(ticket?.daysToDeadlineDate)}</strong>
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  )
}

export default TicketTextBox
