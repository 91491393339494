import { Drawer } from './components'
import Items from '../Items'

const PersistentDrawer = () => {
  return (
    <Drawer variant="permanent">
      <Items />
    </Drawer>
  )
}

export default PersistentDrawer
