import { colors } from '@mui/material'

import { FormFieldType } from 'types/questionnaire'

import theme from 'theme'

const ACTIVE_QUESTION = 1
const INACTIVE_QUESTION = 3

const ACTIVE_QUESTIONNAIRE_LABEL = 'Ativo'
const INACTIVE_QUESTIONNAIRE_LABEL = 'Inativo'

const ACTIVE_QUESTION_LABEL = 'Ativo'
const INACTIVE_QUESTION_LABEL = 'Inativo'

const ALL_CHIP_STATUS = [ACTIVE_QUESTION, INACTIVE_QUESTION] as const

const LEGAL_QUESTIONNAIRE = 'Jurídico'
const GOVERNANCE_AND_CULTURAL_QUESTIONNAIRE = 'Governança e Cultura'
const INFRASTRUCTURE_AND_TECHNOLOGY_QUESTIONNAIRE =
  'Infraestrutura e Tecnologia'
const FUNDAMENTAL_REQUIREMENTS_QUESTIONNAIRE = 'Requisitos Fundamentais'

const QUESTIONNAIRES_COLORS = {
  [LEGAL_QUESTIONNAIRE]: theme.palette.primary.main,
  [GOVERNANCE_AND_CULTURAL_QUESTIONNAIRE]: colors.lightBlue['A100'],
  [INFRASTRUCTURE_AND_TECHNOLOGY_QUESTIONNAIRE]: colors.red['A700'],
  [FUNDAMENTAL_REQUIREMENTS_QUESTIONNAIRE]: colors.green['A700'],
}

const QUESTION_CHIP_STATUS_TRANSLATION = {
  [ACTIVE_QUESTION]: ACTIVE_QUESTION_LABEL,
  [INACTIVE_QUESTION]: INACTIVE_QUESTION_LABEL,
}

const QUESTION_CHIP_STATUS_COLOR = {
  [ACTIVE_QUESTION]: 'success',
  [INACTIVE_QUESTION]: 'neutral',
}

const QUESTIONNAIRES = [
  LEGAL_QUESTIONNAIRE,
  GOVERNANCE_AND_CULTURAL_QUESTIONNAIRE,
  INFRASTRUCTURE_AND_TECHNOLOGY_QUESTIONNAIRE,
  FUNDAMENTAL_REQUIREMENTS_QUESTIONNAIRE,
] as const

export { QUESTIONNAIRES }

const QUESTION_FORM_FIELDS: FormFieldType[] = [
  { name: 'theme', label: 'Tema', multiline: false, rows: 1 },
  { name: 'question', label: 'Pergunta', multiline: true, rows: 4 },
  {
    name: 'affirmative',
    label: 'Medidas de controle adotadas',
    multiline: true,
    rows: 4,
  },
  {
    name: 'reason',
    label: 'Motivo da inclusão',
    multiline: true,
    rows: 4,
  },
  {
    name: 'recomendations',
    label: 'Recomendações',
    multiline: true,
    rows: 4,
  },
  {
    name: 'securityPolicy',
    label: 'Política de segurança',
    multiline: false,
    rows: 1,
  },
]

export type ChipStatusQuestionnairesType = (typeof ALL_CHIP_STATUS)[number]

export default {
  ACTIVE_QUESTIONNAIRE_LABEL,
  INACTIVE_QUESTIONNAIRE_LABEL,
  FUNDAMENTAL_REQUIREMENTS_QUESTIONNAIRE,
  GOVERNANCE_AND_CULTURAL_QUESTIONNAIRE,
  INFRASTRUCTURE_AND_TECHNOLOGY_QUESTIONNAIRE,
  LEGAL_QUESTIONNAIRE,
  QUESTIONNAIRES_COLORS,
  QUESTIONNAIRES,
  ACTIVE_QUESTION,
  INACTIVE_QUESTION,
  ACTIVE_QUESTION_LABEL,
  INACTIVE_QUESTION_LABEL,
  ALL_CHIP_STATUS,
  QUESTION_CHIP_STATUS_TRANSLATION,
  QUESTION_CHIP_STATUS_COLOR,
  QUESTION_FORM_FIELDS,
}
