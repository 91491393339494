import { formatters, incidents as incidentsHelpers } from 'helpers'
import constants from 'constants/index'
import theme from 'theme'

import components from 'utils/jsPDF/components'

import { SecurityMeasuresType } from './SecurityMeasures.types'

const SecurityMeasures = ({ pdf, date, incidents }: SecurityMeasuresType) => {
  const GAP = constants.jsPdf.GAP
  const incidentSecurityMeasures =
    incidents?.incidentTechnicalAdministrativeSecury

  pdf.addPage('a4', 'p')

  components.subtitle({ pdf, sub: 'Medidas de Segurança', positionY: GAP })
  components.lineWithDate({ pdf, date: date })

  components.paragraph({
    pdf,
    text: 'Medidas de Segurança, Técnicas e Administrativas, para a Proteção dos Dados Pessoais',
    positionY: GAP * 1.5,
    font: 'Roboto-Bold',
  })

  const incidentData = [
    [
      'Os dados violados estavam protegidos de forma a impossibilitar a identificação de seus titulares?',
      incidentsHelpers.dataProtectedNotPossibleIdentifyHolderStatus(
        incidentSecurityMeasures?.dataProtectedNotPossibleIdentifyHolder
      ) || '-',
    ],
    [
      'Descreva os meios utilizados para proteger a identidade dos titulares, e a quais tipos de dados foram aplicados:',
      incidentSecurityMeasures?.descriptionProtectDataHolderType || '-',
    ],
    [
      'Antes do incidente, quais das seguintes medidas de segurança eram adotadas?',
      incidents?.dataOptionIncidents?.whichSecurityMeasuresAdopted
        ? incidents?.dataOptionIncidents?.whichSecurityMeasuresAdopted
            .map(
              (securityMeasuresAdopted) => securityMeasuresAdopted.name + '\n'
            )
            .join('')
        : '-',
    ],
    [
      'Descreva as demais medidas de segurança técnicas e administrativas adotadas antes do incidente:',
      incidents?.descriptionOtherTechnicalAdministrativeSecurityMeasure || '-',
    ],
    [
      'Após o incidente, foi adotada alguma nova medida de segurança?',
      incidents?.dataOptionIncidents?.afterWhichSecurityMeasuresAdopted
        ? incidents?.dataOptionIncidents?.afterWhichSecurityMeasuresAdopted
            .map((securityMeasure) => securityMeasure.name + '\n')
            .join('')
        : '-',
    ],
    [
      'Se cabível, descreva as medidas de segurança adicionais adotadas após o incidente:',
      incidentSecurityMeasures?.descriptionSecurityMeasuresAdoptedAfterIncident ||
        '-',
    ],
    [
      'As atividades de tratamento de dados afetadas estão submetidas a regulamentações de segurança setoriais?',
      formatters.convertBoolToString(
        incidentSecurityMeasures?.affectedDataProcessingSectoralSecurityRegulation
      ) || '-',
    ],
    [
      'Se cabível, indique as regulamentações setoriais de segurança aplicáveis às atividades de tratamento de dados afetadas pelo incidente',
      incidentSecurityMeasures?.descriptionSectoralSecurityRegulationAffected ||
        '-',
    ],
  ]

  components.table({
    pdf,
    body: incidentData,
    startY: GAP * 1.6,
    cellWidth: 0.4,
  })

  components.numberPage({ pdf, color: theme.palette.primary.main })
}

export default SecurityMeasures
