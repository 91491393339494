import { TableRow as TableRowMUI } from '@mui/material'

import { TableRowType } from './tableRow.types'

const TableRow = ({ onClick = () => {}, children }: TableRowType) => {
  return (
    <TableRowMUI hover onClick={onClick}>
      {children}
    </TableRowMUI>
  )
}

export default TableRow
