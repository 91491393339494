import { createContext } from 'react'

import { SnackbarContentType } from 'providers/SnackbarProvider/snackbarProvider.types'

const SnackbarContext = createContext({
  snackbar: {
    open: (params: SnackbarContentType) => {
      if (!params) {
        return
      }
    },
  },
})

export default SnackbarContext
