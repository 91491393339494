import React, { useRef } from 'react'
import { Upload as UploadIcon, Download as DownloadIcon } from 'react-feather'
import {
  Box,
  Grid,
  Typography,
  Button,
  Link,
  FormHelperText,
} from '@mui/material'

import { MUI } from 'components'

import { OpinionAttachmentType } from './OpinionAttachment.types'

import theme from 'theme'

const OpinionAttachment = ({
  selectedFile,
  setSelectedFile,
  attachment,
  disabled,
  isNotObserverOrganization,
  name,
  description,
  error = false,
}: OpinionAttachmentType) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files

    if (setSelectedFile && files && files.length > 0) {
      setSelectedFile(files[0])
    }
  }

  const handleRemoveFile = () => {
    if (setSelectedFile && selectedFile) {
      setSelectedFile(null)

      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  return (
    <Grid item xs={12}>
      <Box
        mt={2}
        padding={5}
        border="2px dashed #546E7A"
        borderRadius={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        {attachment ? (
          <>
            <DownloadIcon color={theme.palette.neutral.main} />
            <Typography
              variant="body1"
              pt={2}
              color={theme.palette.neutral.main}
            >
              {attachment.name}
            </Typography>
            {isNotObserverOrganization && (
              <Link
                href={attachment.url}
                target="_blank"
                rel="noopener noreferrer"
                variant="body1"
              >
                Ver Documento
              </Link>
            )}
          </>
        ) : (
          <>
            {disabled ? (
              <Typography
                variant="body1"
                pt={2}
                color={theme.palette.neutral.main}
              >
                Sem Anexo
              </Typography>
            ) : (
              <>
                <MUI.LabelHtml
                  htmlFor={name}
                  $display="flex"
                  $flexDirection="column"
                  $alignItems="center"
                  $cursor="pointer"
                >
                  <UploadIcon color={theme.palette.neutral.main} />
                  <Typography
                    variant="body1"
                    pt={2}
                    color={theme.palette.neutral.main}
                  >
                    {selectedFile ? selectedFile.name : description}
                  </Typography>
                  <MUI.InputHtml
                    ref={fileInputRef}
                    id={name}
                    name={name}
                    type="file"
                    multiple
                    accept="application/pdf"
                    $display="none"
                    onChange={handleFileChange}
                  />
                </MUI.LabelHtml>
              </>
            )}
          </>
        )}
        {selectedFile && (
          <Box mt={2}>
            <Button variant="outlined" onClick={handleRemoveFile}>
              Remover
            </Button>
          </Box>
        )}
      </Box>
      {error && (
        <Box mt={1} ml={4}>
          <FormHelperText error id="component-error-text">
            Anexe um arquivo
          </FormHelperText>
        </Box>
      )}
    </Grid>
  )
}

export default OpinionAttachment
