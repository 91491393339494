import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { ColorSpecification, PreviewPage } from './components'
import { LoadingFeedback, MUI } from 'components'

import { formatters } from 'helpers'

import { useFetch, useSnackbar } from 'hooks'

import { PrivacyPortalThemeType } from './privacyPortalTheme.types'
import { GetPrivacyPortalThemeType } from 'types/privacyPortal.types'

import schema, {
  PrivacyPortalThemeControlType,
  PrivacyPortalThemeFormType,
} from './schema'
import constants from 'constants/index'
import service from 'service'
import theme from 'theme'

const PrivacyPortalTheme = ({
  companyDocument,
  isExtraLarge,
  companyId,
  triggerSettings,
  selectedTab,
  loadingCompany,
}: PrivacyPortalThemeType) => {
  const { snackbar } = useSnackbar()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<PrivacyPortalThemeFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      primaryColor: theme.palette.primary.main,
      primaryFontColor: '#FFFFFF',
      secondaryColor: theme.palette.secondary.main,
      secondaryFontColor: '#FFFFFF',
    },
  })

  const primaryColor = watch('primaryColor')
  const primaryFontColor = watch('primaryFontColor')
  const secondaryColor = watch('secondaryColor')
  const secondaryFontColor = watch('secondaryFontColor')

  const { response: responseTheme, loading: loadingTheme } = useFetch(
    service.dponet.privacyPortal.getTheme,
    {
      companyId: companyId,
    },
    [companyDocument],
    !!companyDocument &&
      selectedTab === constants.privacyPortal.THEME_CONFIGURATION_ID
  )

  const PortalTheme: GetPrivacyPortalThemeType =
    responseTheme?.data?.privacyPortalTheme

  const setColor = (name: PrivacyPortalThemeControlType) => (color: string) => {
    setValue(name, color)
  }

  const onSubmit = async (data: PrivacyPortalThemeFormType) => {
    try {
      if (companyId) {
        const formData = {
          companyId: Number(companyId),
          primaryColor: data?.primaryColor,
          primaryFontColor: data?.primaryFontColor,
          secondaryColor: data?.secondaryColor,
          secondaryFontColor: data?.secondaryFontColor,
          allRelatedCompanies: data?.allRelatedCompanies || false,
        }

        await service.dponet.privacyPortal.postTheme(formData)

        snackbar.open({
          message: 'Tema salvo com sucesso!',
          variant: 'success',
        })
      } else {
        triggerSettings('companyId')
      }
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (!!PortalTheme && companyDocument && !loadingTheme) {
      setColor('primaryColor')(PortalTheme?.primaryColor)
      setColor('primaryFontColor')(PortalTheme?.primaryFontColor)
      setColor('secondaryColor')(PortalTheme?.secondaryColor)
      setColor('secondaryFontColor')(PortalTheme?.secondaryFontColor)
    } else {
      setColor('primaryColor')(theme.palette.primary.main)
      setColor('primaryFontColor')('#FFFFFF')
      setColor('secondaryColor')(theme.palette.secondary.main)
      setColor('secondaryFontColor')('#FFFFFF')
    }
  }, [PortalTheme, loadingTheme])

  return (
    <form id="privacy-portal-theme-form" onSubmit={handleSubmit(onSubmit)}>
      <LoadingFeedback
        open={!!(companyDocument && (loadingCompany || loadingTheme))}
      />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <FormControl>
                <MUI.FormControlLabel
                  control={<MUI.Checkbox {...field} checked={!!field.value} />}
                  label={
                    <Typography fontWeight={500}>
                      Aplicar o tema para todas as empresas vinculadas
                    </Typography>
                  }
                />
                {!!errors?.allRelatedCompanies && (
                  <FormHelperText error={!!errors?.allRelatedCompanies}>
                    {errors?.allRelatedCompanies?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
            name="allRelatedCompanies"
            control={control}
          />
        </Grid>
        <Grid container spacing={isExtraLarge ? 16 : 6} py={6} pl={6}>
          <Grid item xs={12} lg={5}>
            <Box display="flex" flexDirection="column" gap={6}>
              <ColorSpecification
                colorPicker={primaryColor}
                setColorPicker={setColor('primaryColor')}
                fontColor={primaryFontColor}
                titleName="Especificação da primeira cor"
                subtitleName="(Cabeçalho e rodapé)"
                buttonMainName="Primeira Cor"
                buttonFontName="Primeira Fonte"
                control={control}
                errorMainName={errors?.primaryColor}
                errorFontName={errors?.primaryFontColor}
                controlMainName="primaryColor"
                controlFontName="primaryFontColor"
              />
              <ColorSpecification
                colorPicker={secondaryColor}
                setColorPicker={setColor('secondaryColor')}
                fontColor={secondaryFontColor}
                titleName="Especificação da segunda cor"
                subtitleName="(Cards, botões)"
                buttonMainName="Segunda Cor"
                buttonFontName="Segunda Fonte"
                control={control}
                errorMainName={errors?.secondaryColor}
                errorFontName={errors?.secondaryFontColor}
                controlMainName="secondaryColor"
                controlFontName="secondaryFontColor"
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={7}>
            <PreviewPage
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              primaryTextColor={primaryFontColor}
              secondaryTextColor={secondaryFontColor}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default PrivacyPortalTheme
