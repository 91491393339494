import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { Edit2 as Edit2Icon } from 'react-feather'
import {
  Button,
  TableBody,
  TableContainer,
  TableHead,
  Tooltip,
  Typography,
} from '@mui/material'
import { reverse } from 'named-urls'

import { MUI, Table as TableComponent, TableRow } from 'components'

import { formatters, validates } from 'helpers'

import {
  QuestionTableHandleClick,
  QuestionTableType,
} from './questionTable.types'

import constants from 'constants/index'
import routes from 'constants/routes'

const QuestionTable = ({
  kind,
  companyId,
  answerOptionsTickets,
  questionOptionTicketId,
  disableButton = false,
}: QuestionTableType) => {
  const navigate = useNavigate()

  const handleCLickTableRow = ({
    companyId = 'undefined',
    questionId,
    answerId,
  }: QuestionTableHandleClick) => {
    navigate(
      reverse(routes.app.privacyPortal.editAnswer, {
        companyId: companyId,
        questionId: questionId,
        answerId: answerId,
      })
    )
  }

  return (
    <TableContainer>
      <TableComponent aria-label="Listagem das respostas e suas configurações">
        <TableHead>
          <TableRow>
            <MUI.TableCell align="left" width={400} $maxWidth={400}>
              Resposta
            </MUI.TableCell>
            {(kind === constants.privacyPortalForm.REQUEST_KIND ||
              kind === constants.privacyPortalForm.RELATIONSHIP_SHELF_KIND) && (
              <Fragment>
                <MUI.TableCell align="center">Subopções</MUI.TableCell>
                <MUI.TableCell align="center">Especificações</MUI.TableCell>
              </Fragment>
            )}
            {kind === constants.privacyPortalForm.DEMAND_KIND && (
              <Fragment>
                <MUI.TableCell align="left">Responsável</MUI.TableCell>
                <MUI.TableCell align="center">
                  Resposta Automática
                </MUI.TableCell>
                <MUI.TableCell align="center">Prazo Empresa</MUI.TableCell>
                <MUI.TableCell align="center">Prazo Advisor</MUI.TableCell>
              </Fragment>
            )}
            {(kind === constants.privacyPortalForm.REQUEST_KIND ||
              kind === constants.privacyPortalForm.RELATIONSHIP_SHELF_KIND) && (
              <MUI.TableCell align="center">Atualizado em</MUI.TableCell>
            )}
            <MUI.TableCell align="center">Status</MUI.TableCell>
            <MUI.TableCell align="center" width={130}>
              Ação
            </MUI.TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {answerOptionsTickets?.map((answerOption) => (
            <TableRow
              key={answerOption?.id}
              onClick={() =>
                handleCLickTableRow({
                  companyId: companyId,
                  questionId: questionOptionTicketId,
                  answerId: answerOption?.id,
                })
              }
            >
              <MUI.TableCell align="left" width={400} $maxWidth={400}>
                <Tooltip title={answerOption?.title}>
                  <Typography
                    variant="inherit"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {answerOption?.title}
                  </Typography>
                </Tooltip>
              </MUI.TableCell>
              {(kind === constants.privacyPortalForm.REQUEST_KIND ||
                kind ===
                  constants.privacyPortalForm.RELATIONSHIP_SHELF_KIND) && (
                <Fragment>
                  <MUI.TableCell align="center">
                    {answerOption?.subOption ? 'Sim' : 'Não'}
                  </MUI.TableCell>
                  <MUI.TableCell align="center">
                    {answerOption?.specificField ? 'Sim' : 'Não'}
                  </MUI.TableCell>
                </Fragment>
              )}
              {kind === constants.privacyPortalForm.DEMAND_KIND && (
                <Fragment>
                  <MUI.TableCell align="left" $maxWidth={200} width={200}>
                    <Tooltip
                      title={answerOption?.responsibleProfile?.name || '-'}
                    >
                      <Typography
                        variant="inherit"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {answerOption?.responsibleProfile?.name || '-'}
                      </Typography>
                    </Tooltip>
                  </MUI.TableCell>
                  <MUI.TableCell align="center">
                    {answerOption.automaticAnswerEnabled ? 'Sim' : 'Não'}
                  </MUI.TableCell>
                  <MUI.TableCell align="center">
                    {answerOption?.companyDeadlineDay
                      ? `${answerOption?.companyDeadlineDay} dias`
                      : '-'}
                  </MUI.TableCell>
                  <MUI.TableCell align="center">
                    {answerOption?.deadlineDay
                      ? `${answerOption?.deadlineDay} dias`
                      : '-'}
                  </MUI.TableCell>
                </Fragment>
              )}
              {(kind === constants.privacyPortalForm.REQUEST_KIND ||
                kind ===
                  constants.privacyPortalForm.RELATIONSHIP_SHELF_KIND) && (
                <MUI.TableCell align="center">
                  {formatters.date.ptBrFormatDateTime(answerOption?.updatedAt)}
                </MUI.TableCell>
              )}
              <MUI.TableCell align="center">
                <MUI.Chip
                  label={validates.privacyPortal.labelTranslation(
                    answerOption?.status
                  )}
                  variant="filled"
                  size="small"
                  color={validates.privacyPortal.statusColor(
                    answerOption?.status
                  )}
                />
              </MUI.TableCell>
              <MUI.TableCell align="center">
                <Button
                  variant="outlined"
                  startIcon={<Edit2Icon size={15} />}
                  onClick={() =>
                    handleCLickTableRow({
                      companyId: companyId,
                      questionId: questionOptionTicketId,
                      answerId: answerOption?.id,
                    })
                  }
                  disabled={disableButton}
                >
                  Editar
                </Button>
              </MUI.TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableComponent>
    </TableContainer>
  )
}

export default QuestionTable
