import { useState } from 'react'
import { Box, Typography, Hidden } from '@mui/material'

import { AccountBox, Avatar, Menu } from './components'

import { useAuth } from 'hooks'
import { avatarLetters } from 'helpers'

const AccountInfo = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { user, loadOut } = useAuth()

  const openMenu = Boolean(anchorEl)

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <AccountBox
        id="account-box"
        onClick={handleOpenMenu}
        aria-controls={openMenu ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
      >
        <Avatar alt="Avatar">{avatarLetters(user?.name)}</Avatar>
        <Hidden only="xs">
          <Box minWidth={80} textAlign="start" px={1}>
            <Typography variant="body1" fontWeight="500" whiteSpace="nowrap">
              {user?.name}
            </Typography>
          </Box>
        </Hidden>
      </AccountBox>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        logout={loadOut}
      />
    </Box>
  )
}

export default AccountInfo
