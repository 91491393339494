import { Fragment, useEffect, useState } from 'react'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import {
  Button,
  Hidden,
  MenuItem,
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { isEmpty } from 'lodash'

import {
  BasicDialog,
  MenuButton,
  MUI,
  NoData,
  PaperHeader,
  Permitted,
  Table as TableComponent,
} from 'components'
import { QuestionForm, QuestionChangeStatusForm } from './components'

import { useFetch } from 'hooks'

import { validates } from 'helpers'

import { QuestionDataType, QuestionTableType } from './questionTable.types'
import { ChipStatusQuestionnairesType } from 'constants/questionnaire'
import { QuestionType } from 'types/questionnaire'

import constants from 'constants/index'
import service from 'service'

const QuestionTable = ({
  disableManagement = false,
  disableDefault,
  questionnaireId,
  setDisableQuestionnaire,
  setIsLoading,
}: QuestionTableType) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [openQuestionModal, setOpenQuestionModal] = useState(false)
  const [openQuestionStatusChangeModal, setOpenQuestionStatusChangeModal] =
    useState(false)
  const [isInactivationForm, setIsInactivationForm] = useState<
    boolean | undefined
  >()
  const [questionData, setQuestionData] = useState<QuestionDataType>()

  const questionFormName = 'question-form'
  const questionStatusChangeFormName = 'question-status-change-form'

  const { response, loading, refresh } = useFetch(
    service.dponet.questionnaires.getQuestions,
    {
      organizationQuestionnaireId: questionnaireId,
      params: {
        page,
        perPage,
      },
    },
    [questionnaireId, page, perPage]
  )

  const questions: QuestionType[] = response?.data?.questions

  const handleChangePage = (newPage: number) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPerPage = parseInt(event.target.value, 10)
    setPerPage(newPerPage)
  }

  const handleOpenQuestionModal = ({
    affirmative,
    isEdit,
    question,
    questionId,
    reason,
    recomendations,
    securityPolicy,
    theme,
  }: QuestionDataType) => {
    setQuestionData({
      questionId,
      theme,
      question,
      affirmative,
      reason,
      recomendations,
      securityPolicy,
      isEdit,
    })
    setOpenQuestionModal(true)
  }

  const handleCloseQuestionModal = () => setOpenQuestionModal(false)

  const handleOpenQuestionStatusChangeModal = (
    status: ChipStatusQuestionnairesType,
    questionId: number | string
  ) => {
    setIsInactivationForm(status !== constants.questionnaire.INACTIVE_QUESTION)
    setQuestionData({ questionId })
    setOpenQuestionStatusChangeModal(true)
  }

  const handleCloseQuestionStatusChangeModal = () =>
    setOpenQuestionStatusChangeModal(false)

  useEffect(() => {
    loading ? setIsLoading(true) : setIsLoading(false)
  }, [loading])

  useEffect(() => {
    if (isEmpty(questions)) setDisableQuestionnaire(true)
    else setDisableQuestionnaire(false)
  }, [response])

  return (
    <Fragment>
      <Paper variant="outlined">
        <PaperHeader
          title="Questões"
          px={3}
          actionButton={
            <Permitted tag={constants.permissionTags.QUESTIONNAIRES.MANAGE}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<PlusCircleIcon size={20} />}
                onClick={() => handleOpenQuestionModal({ isEdit: false })}
                disabled={disableManagement || disableDefault}
              >
                Criar Questões
              </Button>
            </Permitted>
          }
        />
        {loading || isEmpty(questions) ? (
          <NoData
            title="Nenhuma questão foi encontrada."
            subTitle={
              !disableManagement
                ? 'Por favor, crie uma nova questão utilizando o botão de criar questões.'
                : ''
            }
            withoutPaper
          />
        ) : (
          <Fragment>
            <TableContainer>
              <TableComponent aria-label="Listagem de questões">
                <TableHead>
                  <TableRow>
                    <MUI.TableCell align="left">ID</MUI.TableCell>
                    <MUI.TableCell
                      align="left"
                      width={!disableManagement ? 300 : 400}
                      $maxWidth={!disableManagement ? 300 : 400}
                    >
                      Tema
                    </MUI.TableCell>
                    <MUI.TableCell align="left" width={900} $maxWidth={900}>
                      Pergunta
                    </MUI.TableCell>
                    <MUI.TableCell align="center" width={200} $maxWidth={200}>
                      Status
                    </MUI.TableCell>
                    <Permitted
                      tag={constants.permissionTags.QUESTIONNAIRES.MANAGE}
                    >
                      <MUI.TableCell align="center">Ações</MUI.TableCell>
                    </Permitted>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questions?.map((question) => (
                    <TableRow key={question?.id}>
                      <MUI.TableCell align="left">{question?.id}</MUI.TableCell>
                      <MUI.TableCell
                        align="left"
                        width={!disableManagement ? 300 : 400}
                        $maxWidth={!disableManagement ? 300 : 400}
                        $whiteSpace="normal"
                        $textAlign="justify"
                      >
                        {question?.theme}
                      </MUI.TableCell>
                      <MUI.TableCell
                        align="left"
                        width={900}
                        $maxWidth={900}
                        $whiteSpace="normal"
                        $textAlign="justify"
                      >
                        {question?.question}
                      </MUI.TableCell>
                      <MUI.TableCell align="center" width={200} $maxWidth={200}>
                        <MUI.Chip
                          label={validates.questions.labelTranslation(
                            question?.status
                          )}
                          variant="filled"
                          size="small"
                          color={validates.questions.statusColor(
                            question?.status
                          )}
                          $fontWeight={500}
                          $width="100%"
                        />
                      </MUI.TableCell>
                      <Permitted
                        tag={constants.permissionTags.QUESTIONNAIRES.MANAGE}
                      >
                        <MUI.TableCell align="center">
                          <MenuButton id={`question-${question?.id}`}>
                            <MenuItem
                              onClick={() =>
                                handleOpenQuestionModal({
                                  questionId: question?.id,
                                  theme: question?.theme,
                                  question: question?.question,
                                  affirmative: question?.affirmative,
                                  reason: question?.reason,
                                  recomendations: question?.recomendations,
                                  securityPolicy: question?.securityPolicy,
                                  isEdit: true,
                                })
                              }
                              disabled={disableManagement || disableDefault}
                            >
                              Editar
                            </MenuItem>
                            {question?.status ===
                              constants.questionnaire.ACTIVE_QUESTION && (
                              <MenuItem
                                onClick={() =>
                                  handleOpenQuestionStatusChangeModal(
                                    question?.status,
                                    question?.id
                                  )
                                }
                                disabled={disableManagement || disableDefault}
                              >
                                Inativar
                              </MenuItem>
                            )}
                          </MenuButton>
                        </MUI.TableCell>
                      </Permitted>
                    </TableRow>
                  ))}
                </TableBody>
              </TableComponent>
            </TableContainer>
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={(_, page) => handleChangePage(page)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Fragment>
        )}
      </Paper>
      <BasicDialog
        title={questionData?.isEdit ? 'Editar questão' : 'Criar questão'}
        closeButtonName="Cancelar"
        form={questionFormName}
        open={openQuestionModal}
        onClose={handleCloseQuestionModal}
        maxWidth="lg"
        disableBackdropClick
      >
        <QuestionForm
          questionnaireId={questionnaireId}
          questionData={questionData}
          setIsLoading={setIsLoading}
          formName={questionFormName}
          onClose={handleCloseQuestionModal}
          refresh={refresh}
        />
      </BasicDialog>
      <BasicDialog
        title={`${isInactivationForm ? 'Inativar' : 'Ativar'} questão`}
        saveButtonName={`${isInactivationForm ? 'Inativar' : 'Ativar'}`}
        saveButtonColor={isInactivationForm ? 'error' : 'primary'}
        closeButtonName="Cancelar"
        form={questionStatusChangeFormName}
        open={openQuestionStatusChangeModal}
        onClose={handleCloseQuestionStatusChangeModal}
        maxWidth="md"
      >
        <QuestionChangeStatusForm
          formName={questionStatusChangeFormName}
          isInactive={isInactivationForm}
          questionId={questionData?.questionId}
          questionnaireId={questionnaireId}
          setIsLoading={setIsLoading}
          onClose={handleCloseQuestionStatusChangeModal}
          refresh={refresh}
        />
      </BasicDialog>
    </Fragment>
  )
}

export default QuestionTable
