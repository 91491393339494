import { Button as ButtonMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

type HoverType = {
  backgroundColor?: Property.BackgroundColor
}

const Button = styled(ButtonMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $alignItems?: Property.AlignItems
  $backgroundColor?: Property.BackgroundColor
  $border?: Property.Border<TLength>
  $borderRadius?: Property.BorderRadius<TLength>
  $color?: Property.Color
  $display?: Property.Display
  $height?: Property.Height<TLength>
  $hover?: HoverType
  $justifyContent?: Property.JustifyContent
  $paddingLeft?: Property.PaddingLeft<TLength>
  $textTransform?: Property.TextTransform
}>(
  ({
    $alignItems,
    $backgroundColor,
    $border,
    $borderRadius,
    $color,
    $display,
    $height,
    $hover,
    $justifyContent,
    $paddingLeft,
    $textTransform,
  }) => ({
    alignItems: $alignItems,
    backgroundColor: $backgroundColor,
    border: $border,
    borderRadius: $borderRadius,
    color: $color,
    display: $display,
    height: $height,
    justifyContent: $justifyContent,
    paddingLeft: $paddingLeft,
    textTransform: $textTransform,
    '&:hover': {
      backgroundColor: $hover?.backgroundColor,
    },
  })
)

export default Button
