import axios from 'axios'

import { getBaseURL } from 'service/env'
import service from 'service'
import api from 'service/api'

const dponetAPI = api.create('dponet')
const baseURL = getBaseURL('dponet')

const get = ({
  incidentId = '',
  params = {},
}: {
  incidentId?: string | number
  params?: object
}) => {
  return dponetAPI.get(`/advisors/incidents/${incidentId}`, {
    params,
  })
}

const put = async ({
  incidentId = '',
  legalOpinionData,
}: {
  incidentId?: string | number
  legalOpinionData: {
    legalOpinion?: string
    attachments?: File | null
    confirmed: string
    shouldNotify?: string
    notificationOpinion?: string
    notificationOpinionAttachment?: File | null
  }
}) => {
  const data = new FormData()

  data.append('incident[legal_opinion]', legalOpinionData?.legalOpinion || '')
  data.append('incident[confirmed]', legalOpinionData?.confirmed)

  if (legalOpinionData?.shouldNotify)
    data.append('incident[should_notify]', legalOpinionData?.shouldNotify)

  data.append(
    'incident[notification_opinion]',
    legalOpinionData?.notificationOpinion || ''
  )

  if (legalOpinionData?.attachments)
    data.append('incident[attachments]', legalOpinionData?.attachments)

  if (legalOpinionData?.notificationOpinionAttachment)
    data.append(
      'incident[notification_opinion_attachment]',
      legalOpinionData?.notificationOpinionAttachment
    )

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'PUT',
    url: `${baseURL}/advisors/incidents/${incidentId}`,
    data: data,
    ...config,
  })
}

const options = async ({ ...params }) => {
  return await dponetAPI.get('/advisors/incident_options', { params })
}

const allowObservers = async ({
  incidentId = '',
  allowedObserversIds = {},
}: {
  incidentId?: string | number
  allowedObserversIds?: object
}) => {
  return dponetAPI.put(`/advisors/incidents/${incidentId}/allow_observers`, {
    allowedObserversIds,
  })
}
const incidents = {
  allowObservers,
  get,
  put,
  options,
}

export default incidents
