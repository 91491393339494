import { isUndefined } from 'lodash'

import { QuestionsDpoFormType } from 'views/LegitimateInterest/components/QuestionsDpo/schema'
import { AnswersLiaReviewFormType } from 'views/LegitimateInterest/schemaAnswersAnalysis'

const mountAnalysisLiaAnswersData = (data: AnswersLiaReviewFormType) => {
  const mountedData = []

  for (const key in data) {
    if (key.startsWith('liaQuestionOrder')) {
      const liaQuestionOrder = key.replace('liaQuestionOrder', '')
      const answerDpoKey = `answerDpoQuestion${liaQuestionOrder}`
      const reasonRejection =
        data[answerDpoKey as keyof AnswersLiaReviewFormType]
      const approvedAnswerKey = `answerDpoQuestion${liaQuestionOrder}WasApproved`
      const approvedAnswer =
        data[approvedAnswerKey as keyof AnswersLiaReviewFormType]

      if (isUndefined(liaQuestionOrder)) continue

      const liaAnswerAnalysis = {
        liaQuestionOrder,
        approvedAnswer,
        reasonRejection,
      }

      mountedData.push(liaAnswerAnalysis)
    }
  }

  return mountedData
}

const mountDpoReplyLia = (data: QuestionsDpoFormType) => {
  const mountDpoReply = []
  for (const key in data) {
    if (key.startsWith('questionDpo')) {
      const liaQuestionOrder = key.replace('questionDpo', '')
      const answer = data[key as keyof QuestionsDpoFormType]

      const dpoReply = { liaQuestionOrder, answer }

      mountDpoReply.push(dpoReply)
    }
  }
  return mountDpoReply
}

export default {
  mountAnalysisLiaAnswersData,
  mountDpoReplyLia,
}
