import { Box, Paper } from '@mui/material'

import { Chart, PaperHeader } from 'components'

import { RisksPerSeverityType } from './risksPerSeverity.types'

import securityMeasureConstants from 'constants/securityMeasure'

const RisksPerSeverity = ({ fragilityStats }: RisksPerSeverityType) => {
  const colors = [
    securityMeasureConstants.ADOPTED_STATUS_COLOR,
    securityMeasureConstants.RISK_ASSUMED_STATUS_COLOR,
    securityMeasureConstants.PENDING_STATUS_COLOR,
    securityMeasureConstants.OVERDUE_STATUS_COLOR,
  ]

  const values = [
    fragilityStats?.fragility?.low || 0,
    fragilityStats?.fragility?.medium || 0,
    fragilityStats?.fragility?.high || 0,
    fragilityStats?.fragility?.severe || 0,
  ]

  const labels = ['Baixo', 'Médio', 'Alto', 'Severo']

  return (
    <Paper component={Box} p={2} width="100%" height="100%">
      <PaperHeader title="Distribuição de ameaças por grau de severidade" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
        height="100%"
        py={6}
      >
        <Chart.PieChart
          names={labels}
          values={values}
          colors={colors}
          title="Ameaças"
          legendShow
          heightValue={295}
          showName={false}
        />
      </Box>
    </Paper>
  )
}

export default RisksPerSeverity
