import { TypeButton, TypeButtonActive } from 'components/TypeButton'

import { StyledButtonType } from './styledButton.types'

const StyledButton = ({
  children,
  isActive,
  onClick,
  disabled = false,
}: StyledButtonType) => {
  return (
    <div>
      {!isActive ? (
        <TypeButton type="button" disabled={disabled} onClick={onClick}>
          {children}
        </TypeButton>
      ) : (
        <TypeButtonActive type="button" disabled={disabled} onClick={onClick}>
          {children}
        </TypeButtonActive>
      )}
    </div>
  )
}

export default StyledButton
