import { inRange, orderBy, size } from 'lodash'
import { CompanyStats } from 'types/organization.types'

const get = (
  companiesStats: CompanyStats[],
  healthScoreMin: number,
  healthScoreMax: number,
  order: 'desc' | 'asc' = 'desc'
) => {
  const companies = companiesStats?.filter((company) =>
    inRange(company.healthScore, healthScoreMin, healthScoreMax)
  )
  return orderBy(companies, ['healthScore'], [order])
}

const count = (
  companiesStats: CompanyStats[],
  healthScoreMin: number,
  healthScoreMax: number
) => {
  return size(get(companiesStats, healthScoreMin, healthScoreMax))
}

export default {
  get,
  count,
}
