const ALL_ORGANIZATIONS = 'TODAS'
const VINCULATED_ORGANIZATIONS = 'MEUS GRUPOS'
const INVITED_ORGANIZATIONS = 'CONVIDADO'

const KIND_DPO = 'DPO'
const KIND_OBSERVER = 'OBSERVER'

const KIND_DPO_LABEL = 'DPO'
const KIND_OBSERVER_LABEL = 'OBSERVADOR'

const KIND_LABELS = {
  [KIND_DPO]: KIND_DPO_LABEL,
  [KIND_OBSERVER]: KIND_OBSERVER_LABEL,
}

const TABS = [
  ALL_ORGANIZATIONS,
  // VINCULATED_ORGANIZATIONS,
  // INVITED_ORGANIZATIONS,
]

export default {
  ALL_ORGANIZATIONS,
  INVITED_ORGANIZATIONS,
  KIND_DPO,
  KIND_OBSERVER,
  KIND_LABELS,
  TABS,
  VINCULATED_ORGANIZATIONS,
}
