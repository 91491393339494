/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material'

import { BasicDialog, LoadingFeedback } from 'components'
import QuestionTable from '../QuestionTable'
import QuestionMenuTitle from '../QuestionMenuTitle'
import QuestionForm from '../QuestionForm'
import AnswerForm from '../AnswerForm'

import { formatters } from 'helpers'

import { useFetch, useSnackbar } from 'hooks'

import { PrivacyPortalFormType } from './privacyPortalForm.types'
import { GetQuestionOptionsInterface } from 'interfaces/privacyPortal.interfaces'
import { QuestionDataType } from '../QuestionMenuTitle/questionMenuTitle.types'

import constants from 'constants/index'
import service from 'service'
import schema, { PrivacyPortalFormSchemaType } from './schema'

const PrivacyPortalForm = ({
  companyId,
  companyDocument,
  loadingCompany,
  selectedTab,
  isPermittedManageForm,
}: PrivacyPortalFormType) => {
  const [openEditQuestionModal, setOpenEditQuestionModal] = useState(false)
  const [questionFormIsLoading, setQuestionFormIsLoading] = useState(false)
  const [openNewQuestionModal, setOpenNewQuestionModal] = useState(false)
  const [questionData, setQuestionData] = useState<QuestionDataType>()
  const [isLoading, setIsLoading] = useState(false)

  const { snackbar } = useSnackbar()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PrivacyPortalFormSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      allRelatedCompanies: false,
    },
  })

  const { response, loading, refresh } = useFetch(
    service.dponet.privacyPortal.getQuestions,
    {
      companyId: companyId,
    },
    [companyDocument],
    !!companyDocument &&
      selectedTab === constants.privacyPortal.FORM_CONFIGURATION_ID
  )

  const QuestionOptions: GetQuestionOptionsInterface[] =
    response?.data?.questionOptionTickets

  const handleCloseEditQuestionModal = () => {
    setOpenEditQuestionModal(false)
  }

  const handleCloseNewQuestionModal = () => {
    setOpenNewQuestionModal(false)
  }

  const onSubmit = async (data: PrivacyPortalFormSchemaType) => {
    try {
      setIsLoading(true)

      snackbar.open({
        message: 'Formulário salvo com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Grid container spacing={6}>
      <LoadingFeedback
        open={!!(companyDocument && (loadingCompany || loading)) || isLoading}
      />
      {/* Sera implementado na automação */}
      {/* <Grid
        component="form"
        id="privacy-portal-form-customization"
        onSubmit={handleSubmit(onSubmit)}
        item
        xs={12}
      >
        <Controller
          render={({ field }) => (
            <FormControl>
              <Box pl={4}>
                <FormControlLabel
                  {...field}
                  control={<Checkbox />}
                  label={
                    <Typography variant="body1" fontWeight={500}>
                      Aplicar as modificações para todas as empresas vinculadas
                    </Typography>
                  }
                />
                {!!errors?.allRelatedCompanies && (
                  <FormHelperText error={!!errors?.allRelatedCompanies}>
                    {errors?.allRelatedCompanies?.message}
                  </FormHelperText>
                )}
              </Box>
            </FormControl>
          )}
          name="allRelatedCompanies"
          control={control}
        />
      </Grid> */}
      {QuestionOptions?.map((question) => (
        <Grid key={question?.id} item xs={12}>
          <Box display="flex" flexDirection="column" gap={5}>
            <Box pl={4}>
              <QuestionMenuTitle
                optionsMenuType={'answer'}
                title={question?.title}
                questionId={question?.id}
                setOpenEditQuestionModal={setOpenEditQuestionModal}
                setOpenNewQuestionModal={setOpenNewQuestionModal}
                setQuestionData={setQuestionData}
                disableButton={!isPermittedManageForm}
              />
            </Box>
            <QuestionTable
              kind={question?.kind}
              companyId={companyId}
              answerOptionsTickets={question?.answerOptionTickets}
              questionOptionTicketId={question?.id}
              disableButton={!isPermittedManageForm}
            />
          </Box>
        </Grid>
      ))}
      <BasicDialog
        open={openEditQuestionModal}
        onClose={handleCloseEditQuestionModal}
        title="Editar pergunta"
        isSaving={questionFormIsLoading}
        form="privacy-portal-question-form"
      >
        <QuestionForm
          isEdit
          onClose={handleCloseEditQuestionModal}
          questionId={questionData?.questionId}
          refresh={refresh}
          setIsLoading={setQuestionFormIsLoading}
          title={questionData?.title}
        />
      </BasicDialog>
      <BasicDialog
        open={openNewQuestionModal}
        onClose={handleCloseNewQuestionModal}
        title="Nova resposta"
        isSaving={questionFormIsLoading}
        form="privacy-portal-answer-form"
      >
        <AnswerForm
          onClose={handleCloseNewQuestionModal}
          questionId={questionData?.questionId}
          refresh={refresh}
          setIsLoading={setQuestionFormIsLoading}
        />
      </BasicDialog>
    </Grid>
  )
}

export default PrivacyPortalForm
