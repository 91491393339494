import { Fragment, useState } from 'react'
import { IconButton, Box, Typography, Fade, colors } from '@mui/material'
import { Eye as EyeIcon } from 'react-feather'

import { LogoDai } from 'components'

import { AiTicketGenerativeBoxType } from './aiTicketGenerativeBox.types'

import daiLogo from 'images/dai_logo.png'
import theme from 'theme'
import AiOptions from 'components/AiOptions/AiOptions'

const AiTicketGenerativeBox = ({
  haveStartedGeneratedSuggestion,
  alreadyGeneratedSuggestion,
  daiFeedback,
  suggestion,
  handleFeedback,
  handleRegenerate,
}: AiTicketGenerativeBoxType) => {
  const [showSuggestion, setShowSuggestion] = useState(
    haveStartedGeneratedSuggestion
  )

  const handleChangeShowSuggestion = () =>
    setShowSuggestion((showSuggestion) => !showSuggestion)

  const suggestionSplited = suggestion?.split('\\n') || []

  return (
    <Fade in timeout={1000}>
      <Box mr={3} my={3}>
        <Box p={2} bgcolor={colors.blue[100]} borderRadius="4px 4px 0 0">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <LogoDai src={daiLogo} alt="logo dai" />
              <Typography
                variant="body2"
                color={theme.palette.secondary.main}
                fontWeight={500}
                align="justify"
              >
                RESPOSTA GERADA PARA O TICKET
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleChangeShowSuggestion}>
                <EyeIcon color={colors.blue[700]} />
              </IconButton>
            </Box>
          </Box>
        </Box>
        {showSuggestion && (
          <Box p={2} bgcolor={colors.lightBlue[50]} borderRadius="0 0 4px 4px">
            <Typography
              variant="body2"
              textAlign="justify"
              color="text.secondary"
            >
              {suggestionSplited.map((suggestion, index) => (
                <Fragment key={index}>
                  {suggestion}
                  <br />
                </Fragment>
              ))}
            </Typography>

            <AiOptions
              alreadyGeneratedSuggestion={alreadyGeneratedSuggestion}
              handleFeedback={handleFeedback}
              daiFeedback={daiFeedback}
              suggestion={suggestion}
              handleRegenerate={handleRegenerate}
            />
          </Box>
        )}
      </Box>
    </Fade>
  )
}

export default AiTicketGenerativeBox
