import { Editable as SlateEditable } from 'slate-react'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { InferType, TLength } from 'types/materialUI.types'

type EditableType = {
  $border?: Property.Border<TLength>
  $borderRadius?: Property.BorderRadius<TLength>
  $p?: Property.Padding<TLength>
  $minHeight?: Property.MinHeight<TLength>
  $bgcolor?: Property.BackgroundColor
  $outline?: Property.Outline<TLength>
  $borderColor?: Property.BorderColor
  $boxShadow?: Property.BoxShadow
  $height?: Property.Height<TLength>
  $fontFamily?: Property.FontFamily
  $fontSize?: Property.FontSize<TLength>
  $overflow?: Property.Overflow
  $color?: Property.Color
}

interface EditableWithValidationsType extends EditableType {
  $focus?: InferType<EditableType>
}

const Editable = styled(SlateEditable, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<EditableWithValidationsType>(
  ({
    theme,
    $border = '1px solid',
    $borderRadius,
    $p = 3,
    $minHeight,
    $bgcolor,
    $outline,
    $borderColor,
    $boxShadow,
    $height = 150,
    $fontFamily = theme.typography.body1.fontFamily,
    $fontSize = 16,
    $overflow = 'auto',
    $focus,
    $color,
  }) => ({
    border: $border,
    borderRadius: $borderRadius,
    padding: $p && theme.spacing($p),
    minHeight: $minHeight,
    backgroundColor: $bgcolor,
    outline: $outline,
    borderColor: $borderColor,
    boxShadow: $boxShadow,
    height: $height,
    fontFamily: $fontFamily,
    fontSize: $fontSize,
    overflow: $overflow,
    color: $color,
    '&:focus': {
      outline: $focus?.outline,
      borderColor: $focus?.borderColor,
      boxShadow: $focus?.boxShadow,
    },
  })
)

export default Editable
