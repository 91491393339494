import { Grid, useMediaQuery } from '@mui/material'

import { PageName } from 'components'

import { PageHeadType } from './pageHead.types'

import theme from 'theme'

const PageHead = ({ name, actionButton, returnedUrl }: PageHeadType) => {
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  return (
    <Grid container spacing={2} mb={7}>
      <Grid
        item
        xs={12}
        sm={actionButton ? 6 : 12}
        display="flex"
        justifyContent={isSmall ? 'center' : 'start'}
      >
        <PageName name={name} returnedUrl={returnedUrl} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        display="flex"
        alignItems="center"
        justifyContent={isSmall ? 'center' : 'end'}
      >
        {actionButton && actionButton}
      </Grid>
    </Grid>
  )
}

export default PageHead
