import * as yup from 'yup'

const schema = yup.object({
  action: yup.string().required(),
  organization: yup.string().when('action', {
    is: 'create',
    then: () => yup.string().required(),
  }),
  organizationId: yup.string().when('action', {
    is: 'link',
    then: () => yup.string().required(),
  }),
})

export type SelectOrganizationDialogSchemaType = yup.InferType<typeof schema>

export default schema
