import { IncidentOptionType } from 'types/incidents.types'

const displayedOther = (checkedOptions: IncidentOptionType[], name: string) => {
  const snakeToCamel = (str: string | null) => {
    if (!str) return ''

    return str
      ?.toLowerCase()
      ?.replace(
        /([-_][a-z])/g,
        (group) => group.toUpperCase()?.replace('-', '')?.replace('_', '')
      )
  }

  const isPresent = checkedOptions?.find(
    (option) =>
      option?.keyName === 'other' && snakeToCamel(option?.name) === name
  )

  if (isPresent) return true

  return false
}

export default displayedOther
