const env: string = process.env.REACT_APP_API || 'development'

export const getBaseURL = (project: string) => envsBaseUrl[project][env]
export const getTomTicketURL = () => tomTicketBaseUrl[env]
export const getGoogleRecaptchaToken = () => googleRecaptchaToken[env]
export const getEditorTyneMceToken = () => editorTyneMceToken[env]
export const getGoogleAuthenticationToken = () => googleAuthenticationToken[env]

type envBaseType = {
  [propKey: string]: string
}

type envsBaseUrlType = {
  [propKey: string]: envBaseType
}

const envsBaseUrl: envsBaseUrlType = {
  advisor: {
    development: 'http://localhost:3021',
  },
  dponetPortal: {
    development: 'http://localhost:3002',
    sandbox: 'https://app.sandbox.dponet.com.br',
    production: 'https://app.dponet.com.br',
  },
  dponet: {
    development: 'http://api.localhost:3001',
    swagger: 'https://virtserver.swaggerhub.com/LUIZBERGAMIN/advisor/1.0.0/',
    sandbox: 'https://api.sandbox.dponet.com.br',
    production: 'https://api.dponet.com.br',
  },
}

const googleRecaptchaToken: envBaseType = {
  development: '6LcUO9wlAAAAAFP4FAWvGoNeWOtbK-yCq40eKOvC',
  sandbox: '6LcUO9wlAAAAAFP4FAWvGoNeWOtbK-yCq40eKOvC',
  production: '6LfkOdwlAAAAAKtuo2MBKEEFkn9BRr9wvEPRu_6Y',
}

const googleAuthenticationToken: envBaseType = {
  development:
    '894570988547-5jalp3akf2ltlm8q1bo2vbtr9ej2925n.apps.googleusercontent.com',
  sandbox:
    '894570988547-84e31dr62mq5t9gnl9d0o5ubh06h4oh1.apps.googleusercontent.com',
  production:
    '894570988547-84e31dr62mq5t9gnl9d0o5ubh06h4oh1.apps.googleusercontent.com',
}

const tomTicketBaseUrl: envBaseType = {
  development: 'https://dponet.tomticket.com/helpdesk/login?jwt',
  sandbox: 'https://dponet.tomticket.com/helpdesk/login?jwt',
  production: 'https://dponet.tomticket.com/helpdesk/login?jwt',
}

const editorTyneMceToken: envBaseType = {
  development: 'mhfqqnu8h9sdp9poi354uh2bqva111r9vm65bhe462kyux5i',
  sandbox: '04cwdxlxaqi28ar132hvaii2e6v6lmmk8z11ccd3nms3bscj',
  production: '04cwdxlxaqi28ar132hvaii2e6v6lmmk8z11ccd3nms3bscj',
}
