import { useState } from 'react'
import { Button, Grid, Typography } from '@mui/material'

import { BigAvatar, DialogLogs } from 'components'
import { TicketExportDialog } from './components'

import { TicketHeadType } from './ticketHead.types'

import organizationsConstants from 'constants/organizations'

const TicketHead = ({
  ticket,
  ticketMessages,
  logs,
  loadingLogs,
}: TicketHeadType) => {
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false)
  const [openExportDialog, setOpenExportDialog] = useState(false)

  const handleChangeHistoryDialog = () => {
    setOpenHistoryDialog((openHistoryDialog) => !openHistoryDialog)
  }

  const handleChangeExportDialog = () => {
    setOpenExportDialog((openExportDialog) => !openExportDialog)
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={5} sm={2} lg={1.5}>
        <BigAvatar
          alt={ticket?.company?.name || ticket?.requesterOrganization?.name}
          src={ticket?.company?.logo || undefined}
        />
      </Grid>
      <Grid item xs={7} sm={10} lg={10.5}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={7} md={8} lg={9}>
            <Typography fontWeight={500} variant="h6">
              ID:&nbsp;
              {ticket?.id || '-'}
            </Typography>
            <Typography color="primary" variant="h6">
              {ticket?.company?.name || ticket?.requesterOrganization?.name}
            </Typography>
            <Typography color="text.secondary">
              {ticket?.company?.document ||
                (ticket?.requesterOrganization?.kind &&
                  organizationsConstants.KIND_LABELS[
                    ticket?.requesterOrganization?.kind
                  ])}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            md={4}
            lg={3}
            display="inline-flex"
            justifyContent="end"
            alignItems="start"
            gap={5}
          >
            <Button variant="outlined" onClick={handleChangeHistoryDialog}>
              HISTÓRICO
            </Button>
            {!loadingLogs && (
              <DialogLogs
                open={openHistoryDialog}
                onClose={handleChangeHistoryDialog}
                logs={logs}
                showRegisterSession
              />
            )}
            <Button variant="outlined" onClick={handleChangeExportDialog}>
              EXPORTAR
            </Button>
            <TicketExportDialog
              open={openExportDialog}
              onClose={handleChangeExportDialog}
              ticket={ticket}
              ticketMessages={ticketMessages}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TicketHead
