import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Navigation, GridStructure } from './components'

import { MainLayoutProps } from './mainLayout.types'

import routes from 'constants/routes'
import service from 'service'

const MainLayout = ({ children }: MainLayoutProps) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!service.dponet.auth.isAuthenticated()) {
      navigate(routes.auth.login)
    }
  }, [])

  return (
    <GridStructure>
      <Navigation>{children}</Navigation>
    </GridStructure>
  )
}

export default MainLayout
