import constants from 'constants/index'
import theme from 'theme'

import components from 'utils/jsPDF/components'

import { OccurrenceScienceType } from './OccurrenceScience.types'

const OccurrenceScience = ({ pdf, date, incidents }: OccurrenceScienceType) => {
  const GAP = constants.jsPdf.GAP
  const MARGIN_TABLE = constants.jsPdf.MARGIN_TABLE

  const BLANK = 'N/A'

  const operatorData = incidents?.incidentDataAgents?.operator?.[0]

  pdf.addPage('a4', 'p')

  components.subtitle({ pdf, sub: 'Ciência da Ocorrência', positionY: GAP })
  components.lineWithDate({ pdf, date: date })

  components.paragraph({
    pdf,
    text: 'Avaliação do risco',
    positionY: GAP * 1.5,
    font: 'Roboto-Bold',
  })

  const incidentData = [
    [
      'Justifique, se cabível, a avaliação do risco do incidente',
      incidents?.justificationRiskAssessment || BLANK,
    ],
  ]

  components.table({
    pdf,
    body: incidentData,
    startY: GAP * 1.6,
    cellWidth: 0.4,
  })

  components.paragraph({
    pdf,
    text: 'Da ciência da ocorrência do incidente',
    positionY: pdf.lastAutoTable.finalY + MARGIN_TABLE,
    font: 'Roboto-Bold',
  })

  const controllerDataTable = [
    [
      'Por qual meio se tomou conhecimento do incidente?',
      incidents?.dataOptionIncidents?.howDidIncidentBecomeKnown
        ? incidents?.dataOptionIncidents?.howDidIncidentBecomeKnown
            .map((incidentBecomeKnown) => incidentBecomeKnown.name + '\n')
            .join('')
        : BLANK,
    ],
    [
      'Descreva, resumidamente, de que forma a ocorrência do incidente foi conhecida',
      incidents?.decriptionOccurrenceIncidentWasKnown || BLANK,
    ],
  ]

  components.table({
    pdf,
    body: controllerDataTable,
    startY: pdf.lastAutoTable.finalY + MARGIN_TABLE + GAP * 0.1,
    cellWidth: 0.4,
  })

  components.paragraph({
    pdf,
    text: 'Dados do Operador',
    positionY: pdf.lastAutoTable.finalY + MARGIN_TABLE,
    font: 'Roboto-Bold',
  })

  const dpoData = [
    ['Razão social/Nome', operatorData?.name || BLANK],
    ['Documento', operatorData?.document || BLANK],
    ['E-mail', operatorData?.email || BLANK],
  ]

  components.table({
    pdf,
    body: dpoData,
    startY: pdf.lastAutoTable.finalY + MARGIN_TABLE + GAP * 0.1,
    cellWidth: 0.4,
  })

  components.numberPage({ pdf, color: theme.palette.primary.main })
}

export default OccurrenceScience
