import { Fragment } from 'react'
import { useFormContext } from 'react-hook-form'
import { MoreHorizontal as MoreHorizontalIcon } from 'react-feather'
import { useSlate } from 'slate-react'
import {
  Box,
  ListItemText,
  Menu,
  MenuList,
  Tooltip,
  Typography,
} from '@mui/material'

import { MUI } from 'components'

import { validates } from 'helpers'

import { MarksType } from 'types/slateJs.types'
import { MoreOptionsMenuType } from './moreOptionsMenu.types'

import palette from 'theme/palette'

const MoreOptionsMenu = ({
  hiddenGroups,
  handleClick,
  handleCloseMenu,
  anchorElState,
  controlName,
}: MoreOptionsMenuType) => {
  const editor = useSlate()
  const { anchorEl, setAnchorEl } = anchorElState

  const {
    formState: { errors },
  } = useFormContext()

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)

  return (
    <Box display="flex">
      <Tooltip title="Exibir ou ocultar itens adicionais da barra de ferramentas">
        <MUI.IconButton $borderRadius={0} onClick={handleOpenMenu}>
          <MoreHorizontalIcon />
        </MUI.IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {hiddenGroups?.map((group, index) => (
          <MenuList key={index}>
            {group?.map((element, elementIndex) => {
              const Logo = element?.Icon || Fragment
              const isActive =
                element.type === 'mark'
                  ? validates.slateJs.isMarkActive(
                      editor,
                      element.format as keyof MarksType
                    )
                  : validates.slateJs.isBlockActive(editor, element.format)

              return (
                <MUI.MenuItem
                  key={elementIndex}
                  $textTransform="none"
                  onMouseDown={(event) => {
                    event.preventDefault()
                    handleClick(
                      element?.format as keyof MarksType,
                      element?.type
                    )
                  }}
                >
                  <MUI.ListItemIcon
                    $color={
                      isActive
                        ? errors?.[controlName]
                          ? palette.error.main
                          : 'black'
                        : 'gray'
                    }
                  >
                    {validates.slateJs.toolbarTitleLabel(element?.format) ? (
                      <Typography>
                        {validates.slateJs.toolbarTitleLabel(element?.format)}
                      </Typography>
                    ) : (
                      <Logo />
                    )}
                  </MUI.ListItemIcon>
                  <ListItemText>
                    <Typography fontWeight={isActive ? 900 : 400}>
                      {element?.description}
                    </Typography>
                  </ListItemText>
                  <Box
                    display="flex"
                    justifyContent="start"
                    minWidth={42}
                    ml={4}
                  >
                    <Typography variant="caption" color="text.secondary">
                      {element?.shortcutKey}
                    </Typography>
                  </Box>
                </MUI.MenuItem>
              )
            })}
          </MenuList>
        ))}
      </Menu>
    </Box>
  )
}

export default MoreOptionsMenu
