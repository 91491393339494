import { useState } from 'react'
import { Eye as EyeIcon } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { isEmpty } from 'lodash'
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  colors,
} from '@mui/material'

import { AiGenerativeBox, LogoDai, TicketInnerMessageBox } from 'components'
import { TicketMessageAttachment } from './components'

import { useSnackbar } from 'hooks'

import { DefaultTicketMessageType } from './defaultTicketMessage.types'

import { avatarLetters, formatters, validates } from 'helpers'
import { getBaseURL } from 'service/env'
import service from 'service'
import theme from 'theme'

import daiLogo from 'images/dai_logo.png'
import DOMPurify from 'dompurify'

const DefaultTicketMessage = ({
  ticket,
  hiddenContent,
  message,
  handleClickChange,
  bgColor,
  iconColor,
}: DefaultTicketMessageType) => {
  const daiResponseQuantity = message?.daiResponses?.length || 0
  const daiResponse = message?.daiResponses?.[daiResponseQuantity - 1]

  const [interactions, setInteractions] = useState(daiResponseQuantity)
  const [generativeSuggestion, setGenerativeSuggestion] = useState(
    daiResponse?.response || ''
  )
  const [haveStartedGeneratedSuggestion, setHaveStartedGeneratedSuggestion] =
    useState(false)
  const [haveAlreadyGeneratedSuggestion, setHaveAlreadyGeneratedSuggestion] =
    useState(!!daiResponse?.response)

  const { snackbar } = useSnackbar()

  const messageSanitizer = DOMPurify.sanitize(
    message ? message?.content : ticket?.content || '-',
    {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
    }
  )

  const handleRequestGenerativeSuggestion = async () => {
    setHaveStartedGeneratedSuggestion(true)
    setInteractions((interactions) => interactions + 1)

    try {
      const url = getBaseURL('dponet')
      const uri = `${url}/advisors/tickets/${ticket?.id}/juridic/messages/${message?.id}/generative`

      const responseStream = await fetch(uri, {
        method: 'GET',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Authorization: service.dponet.auth.getToken() || '',
        },
      })

      if (!responseStream.ok || !responseStream.body) {
        throw responseStream.statusText
      }

      const reader = responseStream.body.getReader()
      const decoder = new TextDecoder()
      const loopRunner = true

      const dataRegex = /data: "/g
      const nRegex = /"\n\n/g

      while (loopRunner) {
        const { value, done } = await reader.read()

        if (done) break

        const decodedChunk = decoder.decode(value, { stream: true })

        const decodedChunkFormatted = decodedChunk
          .replaceAll(dataRegex, '')
          .replaceAll(nRegex, '')

        setGenerativeSuggestion(
          (generativeSuggestion) => generativeSuggestion + decodedChunkFormatted
        )
      }

      setHaveAlreadyGeneratedSuggestion(true)
    } catch (error) {
      snackbar.open({
        message:
          'Ocorreu um erro de comunicação com a DAI. Por favor, tente novamente mais tarde.',
        variant: 'error',
      })
    }
  }

  const handleRegenerate = async () => {
    setHaveAlreadyGeneratedSuggestion(false)
    setHaveStartedGeneratedSuggestion(false)
    setGenerativeSuggestion('')
    await handleRequestGenerativeSuggestion()
  }

  const handleFeedback = async (rate: string, reason: string = '') => {
    await service.dponet.daiFeedback.post({
      ticketId: ticket?.id,
      ticketMessageId: message?.id,
      params: {
        daiFeedback: {
          rate,
          reason,
        },
        daiResponseIndex: interactions - 1,
      },
    })
  }

  return (
    <Paper>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        bgcolor={
          message
            ? validates.tickets.colorKindMessage(message.kind, 'background')
            : bgColor || colors.orange[50]
        }
        p={3}
      >
        <Box display="flex" gap={4}>
          <Avatar alt={ticket?.user?.name}>
            {avatarLetters(ticket?.user?.name)}
          </Avatar>
          <Box display="flex" flexDirection="column">
            <Typography fontWeight={700} color="primary">
              {message
                ? `${message?.author
                    .name} - ${validates.tickets.discoveringKindMessage(
                    message?.kind
                  )}`
                : ticket?.user?.name}
            </Typography>

            {message ? (
              <Typography variant="caption" color="text.secondary">
                {formatters.date.ptBrFormatDateTime(message.createdAt)}
              </Typography>
            ) : (
              <Typography variant="caption" color="text.secondary">
                By: {ticket?.company?.name} | <strong>Representante</strong>
                &nbsp;|&nbsp;
                {ticket?.createdAt
                  ? formatters.date.ptBrFormatDateTime(ticket.createdAt)
                  : '-'}
              </Typography>
            )}
          </Box>
        </Box>

        <Box display="flex">
          <Box display="flex" justifyContent="flex-end" margin={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleRequestGenerativeSuggestion}
              disabled={
                haveAlreadyGeneratedSuggestion || haveStartedGeneratedSuggestion
              }
            >
              <LogoDai src={daiLogo} alt="logo dai" />
              <Typography
                variant="body2"
                color={theme.palette.secondary.main}
                fontWeight={500}
              >
                Gerar análise
              </Typography>
            </Button>
          </Box>

          <IconButton
            onClick={() => handleClickChange(message ? message?.id : 'content')}
          >
            <EyeIcon
              color={
                message
                  ? validates.tickets.colorKindMessage(message?.kind, 'icon')
                  : iconColor || colors.orange[700]
              }
            />
          </IconButton>
        </Box>
      </Box>

      {!hiddenContent[message ? message?.id : 'content'] && (
        <>
          <Box marginX={3} paddingY={5}>
            <PerfectScrollbar>
              <TicketInnerMessageBox
                dangerouslySetInnerHTML={{
                  __html: messageSanitizer,
                }}
              />
            </PerfectScrollbar>
          </Box>

          <AiGenerativeBox
            suggestion={generativeSuggestion as string}
            daiFeedback={daiResponse?.daiFeedback}
            handleFeedback={handleFeedback}
            alreadyGeneratedSuggestion={haveAlreadyGeneratedSuggestion}
            handleRegenerate={handleRegenerate}
          />

          <PerfectScrollbar>
            {!isEmpty(message?.attachments) && (
              <Box display="flex" pb={2} pl={3} gap={3}>
                {message?.attachments?.map((attachment) => (
                  <TicketMessageAttachment
                    key={attachment?.name}
                    name={attachment?.name}
                    url={attachment?.url}
                  />
                ))}
              </Box>
            )}
          </PerfectScrollbar>
        </>
      )}
    </Paper>
  )
}

export default DefaultTicketMessage
