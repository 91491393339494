import { Fragment, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Checkbox, FormControlLabel } from '@mui/material'

import { BasicDialog } from 'components'
import { TicketPDF } from './components'

import { TicketExportDialogType } from './ticketExportDialog.types'

import schema, { TicketExportDialogFormType } from './schema'

const TicketExportDialog = ({
  ticket,
  ticketMessages,
  open,
  onClose,
}: TicketExportDialogType) => {
  const { control, watch, reset } = useForm<TicketExportDialogFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      externalMessage: false,
      internalMessage: false,
    },
  })

  const externalMessage = watch('externalMessage')
  const internalMessage = watch('internalMessage')
  const formData = { externalMessage, internalMessage }

  const buttonValidation = !(externalMessage || internalMessage)

  useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open])

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title={`Exportar Ticket: ${ticket?.ticketRef || '-'}`}
      dialogActions={
        <Fragment>
          <Button color="primary" onClick={onClose}>
            Cancelar
          </Button>
          <TicketPDF
            ticket={ticket}
            ticketMessages={ticketMessages}
            disabled={buttonValidation}
            exportOptions={formData}
          />
        </Fragment>
      }
    >
      <form id="ticket-export-dialog-form">
        <Box display="flex" flexDirection="column">
          <Box>
            <Controller
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox />}
                  label="Interações públicas"
                />
              )}
              name="externalMessage"
              control={control}
            />
          </Box>
          <Box>
            <Controller
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox />}
                  label="Anotações internas"
                />
              )}
              name="internalMessage"
              control={control}
            />
          </Box>
        </Box>
      </form>
    </BasicDialog>
  )
}

export default TicketExportDialog
