import '@fontsource/raleway/400.css'
import '@fontsource/raleway/600.css'
import '@fontsource/raleway/700.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

export default {
  htmlFontSize: 10,
  fontFamily: ['Roboto', 'Raleway', 'Arial', 'sans-serif'].join(','),
  h1: {
    fontSize: '8.4rem',
  },
  h2: {
    fontSize: '6.4rem',
  },
  h3: {
    fontSize: '4.8rem',
  },
  h4: {
    fontSize: '4rem',
  },
  h5: {
    fontSize: '3.2rem',
  },
  h6: {
    fontSize: '2.4rem',
  },
}
