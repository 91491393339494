import { styled } from '@mui/material/styles'
import { LinearGradient } from 'components'

const RightBannerBox = styled(LinearGradient())(({ theme }) => ({
  display: 'grid',
  placeItems: 'center',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover, 50%',
  backgroundPosition: 'bottom',
  [theme.breakpoints.down('md')]: {
    height: '15vh',
  },
}))

export default RightBannerBox
