import calculatePercentage from './calculatePercentage'
import companyTrails from './companyTrails'
import dataProcesses from './dataProcesses'
import questionControlStatsByStatus from './questionControlStatsByStatus'
import questionnaires from './questionnaires'
import threatsByDepartment from './threatsByDepartment'

export default {
  calculatePercentage,
  companyTrails,
  dataProcesses,
  questionControlStatsByStatus,
  questionnaires,
  threatsByDepartment,
}
