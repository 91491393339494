import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow as TableRowMUI,
} from '@mui/material'

import { PaperHeader } from 'components'
import { CompanyComplianceRow } from './components'

import { usePermission } from 'hooks'

import { CompanyStats } from 'types/organization.types'

import { companies as companiesHelpers } from 'helpers'
import permissionTagsConstants from 'constants/permissionTags'

const CompanyTrailsCard = ({
  title,
  companiesStats,
  order,
  id,
}: {
  title: string
  companiesStats: CompanyStats[]
  order: 'desc' | 'asc'
  id: string
}) => {
  const { isPermitted } = usePermission()
  const orderedCompanies = companiesHelpers.companyByHealthScore.get(
    companiesStats,
    0,
    101,
    order
  )

  const isPermittedToAccessCompanies = isPermitted(
    permissionTagsConstants.ORGANIZATION_COMPANY.VIEW
  )

  return (
    <Paper component={Box} p={2} width="100%" height="100%" id={id}>
      <PaperHeader title={title} />
      <TableContainer>
        <Table aria-label={`listagem de ${title}`}>
          <TableHead>
            <TableRowMUI>
              <TableCell width="20%">Conformidade(%)</TableCell>
              <TableCell width={isPermittedToAccessCompanies ? '60%' : '80%'}>
                Empresa
              </TableCell>
              {isPermittedToAccessCompanies && (
                <TableCell width="20%">Ações</TableCell>
              )}
            </TableRowMUI>
          </TableHead>
          <TableBody>
            {orderedCompanies.slice(0, 4).map((company) => (
              <CompanyComplianceRow
                key={company.organizationCompanyId}
                company={company}
                isPermittedToAccessCompanies={isPermittedToAccessCompanies}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default CompanyTrailsCard
