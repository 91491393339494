import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Autocomplete, Button, TextField } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'

import {
  BasicDialog,
  CompanyAutocomplete,
  ConditionalLoading,
  LoadingFeedback,
} from 'components'

import { CompanyUpdateDialogType } from './companyUpdateDialog.types'
import { OrganizationCompanyType } from 'types/organizationCompany'
import { AutoselectType } from 'components/CompanyAutocomplete/companyAutocomplete.types'

import service from 'service'
import organizationCompaniesContants from 'constants/organizationCompanies'
import { useFetch, useSnackbar } from 'hooks'
import { formatters } from 'helpers'
import schema, { CompanyUpdateFormType } from './schema'

const CompanyUpdateDialog = ({
  organizationCompany,
  open,
  onClose,
  refresh,
}: CompanyUpdateDialogType) => {
  const [loading, setLoading] = useState(false)
  const [companiesArray, setCompaniesArray] = useState<AutoselectType[]>([])

  const { snackbar } = useSnackbar()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyMatrixId: null,
      organizationTags: organizationCompany?.organizationTags || [],
    },
  })

  const { response: responseTags, loading: loadingTags } = useFetch(
    service.dponet.tags.get,
    {
      params: {
        perPage: 999,
        minimal: true,
      },
    },
    [],
    open
  )

  const organizationOrganizationTags = responseTags?.data?.organizationTags
  const companyMatrix = organizationCompany?.companyMatrix

  const updateOrganizationCompany = async (data: CompanyUpdateFormType) => {
    try {
      setLoading(true)

      await service.dponet.organizationCompany.update({
        organizationCompanyId: organizationCompany.id,
        organizationCompany: {
          companyMatrixId: data.companyMatrixId?.companyId,
          organizationTagsIds:
            data?.organizationTags?.map(
              (organizationTag) => organizationTag.id
            ) ?? [],
        },
      })

      onClose()
      refresh()

      snackbar.open({
        message: 'Empresa atualizada com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const mountCompanyParams = (organizationCompany: OrganizationCompanyType) => {
    const document = organizationCompany.company.document

    return {
      label: `${organizationCompany.company.name} - ${document}`,
      document,
      companyId: organizationCompany.id,
    }
  }

  const getCompanyMatrix = async () => {
    const response = await service.dponet.organizationCompany.get({
      params: {
        id: companyMatrix?.id,
        status: organizationCompaniesContants.ACTIVE_STATUS,
      },
    })

    const organizationCompanies: OrganizationCompanyType[] =
      response?.data?.organizationCompanies

    return organizationCompanies?.map(mountCompanyParams) ?? []
  }

  useEffect(() => {
    if (companyMatrix && open)
      getCompanyMatrix().then((companies) => setCompaniesArray(companies))
  }, [companyMatrix, open])

  useEffect(() => {
    if (!isEmpty(companiesArray)) setValue('companyMatrixId', companiesArray[0])
  }, [companiesArray])

  useEffect(() => {
    if (!companyMatrix && !open) reset()
  }, [open])

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title="Empresa"
      dialogActions={
        <>
          <Button color="primary" onClick={onClose}>
            CANCELAR
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            form="organization-company-form"
          >
            SALVAR
          </Button>
        </>
      }
    >
      <LoadingFeedback open={loading} />

      <ConditionalLoading loading={loadingTags} centered>
        <form
          id="organization-company-form"
          onSubmit={handleSubmit(updateOrganizationCompany)}
        >
          {(!companyMatrix || (!isEmpty(companiesArray) && open)) && (
            <Controller
              render={({ field: { ref, ...rest } }) => (
                <CompanyAutocomplete
                  {...rest}
                  inputRef={ref}
                  error={!!errors?.companyMatrixId?.message}
                  helperText={errors?.companyMatrixId?.message}
                  label="Empresa Matriz"
                  isOrganizationCompany
                  defaultCompanies={companiesArray}
                />
              )}
              name="companyMatrixId"
              control={control}
            />
          )}
          <Controller
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                options={organizationOrganizationTags}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Classificadores"
                    margin="dense"
                    error={!!errors?.organizationTags?.message}
                    helperText={errors?.organizationTags?.message}
                    InputLabelProps={{
                      children: null,
                    }}
                  />
                )}
                onChange={(_, data) => field.onChange(data)}
              />
            )}
            name="organizationTags"
            control={control}
          />
        </form>
      </ConditionalLoading>
    </BasicDialog>
  )
}

export default CompanyUpdateDialog
