import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import { Button, Grid, TextField } from '@mui/material'

import { useSnackbar } from 'hooks'

import { FormTypes, PayloadData } from './form.types'

import { formatters } from 'helpers'
import routes from 'constants/routes'
import schema from './schema'
import service from 'service'

const Form = ({ isLoading, setLoading }: FormTypes) => {
  const navigate = useNavigate()
  const { snackbar } = useSnackbar()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = async (data: PayloadData) => {
    try {
      setLoading(true)
      const response = await service.dponet.auth.recoveryPassword(data)
      setLoading(false)
      snackbar.open({
        message:
          response?.data?.message ||
          'Um e-mail de recuperação foi enviado para você.',
        variant: 'success',
      })
      navigate(routes.auth.login)
    } catch (error) {
      console.error(error)
      setLoading(false)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.email}
                helperText={<>{errors.email?.message}</>}
                label="E-mail"
                type="email"
                placeholder="Digite seu e-mail"
                fullWidth
              />
            )}
            name="email"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={isLoading}
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default Form
