import { Fragment, useEffect, useRef, useState } from 'react'
import { Grid, Hidden, TablePagination } from '@mui/material'
import { isEmpty } from 'lodash'

import {
  FilterButton,
  ListPageStructure,
  LoadingFeedback,
  NoData,
  PageHead,
} from 'components'
import { LegalFrameworksFilters, RowLegalFramework } from './components'

import { useFetch, useFilter, usePagination } from 'hooks'

import { DataProcessWithIndexType } from 'types/dataProcess.types'
import { FormattedLegalFrameworkType } from 'types/legalFramework.types'

import { dataProcesses } from 'helpers'
import service from 'service'

const LegalFrameworksMain = () => {
  const filter = useFilter()
  const ref = useRef<null | HTMLDivElement>(null)

  const [isLoading, setIsLoading] = useState(false)

  const [legalFrameworks, setLegalFrameworks] =
    useState<FormattedLegalFrameworkType>()

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ initialPerPage: 4 })

  useEffect(() => {
    const loadLegalFrameworks = async () => {
      const response = await service.dponet.legalFrameworks.get()
      const orderedLegalFrameworks = dataProcesses.orderLegalFrameworks(
        response?.data?.legalFrameworks
      )
      setLegalFrameworks(orderedLegalFrameworks)
    }

    loadLegalFrameworks()
  }, [])

  const { response, loading } = useFetch(
    service.dponet.dataProcesses.getWithLegalFrameworks,
    {
      params: {
        ...filter.filters,
        page,
        perPage,
      },
    },
    [filter.filters, page, perPage]
  )

  const dataProcessWithIndex: DataProcessWithIndexType[] =
    response?.data?.dataProcesses

  useEffect(() => {
    const timer = setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [page, perPage])

  return (
    <ListPageStructure ref={ref}>
      <PageHead
        name="Enquadramento Legal"
        actionButton={<FilterButton setDrawerOpen={filter.setDrawerOpen} />}
      />
      <LoadingFeedback open={isLoading || loading} />
      {loading || isEmpty(dataProcessWithIndex) ? (
        <NoData title="Nenhum processamento de dados foi encontrado." />
      ) : (
        <Fragment>
          <Grid container spacing={4}>
            {dataProcessWithIndex?.map((dataProcess) => (
              <Grid item xs={12} key={dataProcess?.id}>
                <RowLegalFramework
                  data={dataProcess}
                  legalFrameworks={legalFrameworks}
                  setLoading={setIsLoading}
                />
              </Grid>
            ))}
          </Grid>
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onPageChange={(_, page) => handleChangePage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[2, 4, 6]}
            labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </Fragment>
      )}
      <LegalFrameworksFilters filter={filter} />
    </ListPageStructure>
  )
}

export default LegalFrameworksMain
