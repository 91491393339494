import { Box } from '@mui/material'

import { DetailBox } from './components'

import { DetailsStatusType } from './detailsStatus.types'

const DetailsStatus = ({ generalDataProcessStats }: DetailsStatusType) => {
  const activeDataProcess =
    generalDataProcessStats.statuses.reviewApproved +
    generalDataProcessStats.statuses.reviewDisapproved +
    generalDataProcessStats.statuses.pending +
    generalDataProcessStats.statuses.reviewing +
    generalDataProcessStats.statuses.reviewingLia

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      gridTemplateRows="1fr 1fr"
      gap={4}
      height="100%"
    >
      <Box>
        <DetailBox color="green" label="ATIVOS" value={activeDataProcess} />
      </Box>
      <Box>
        <DetailBox
          color="gray"
          label="INATIVOS"
          value={generalDataProcessStats.statuses.inactive}
        />
      </Box>
      <Box>
        <DetailBox
          color="green"
          label="APROVADOS"
          value={generalDataProcessStats.statuses.reviewApproved}
        />
      </Box>
      <Box>
        <DetailBox
          color="red"
          label="REPROVADOS"
          value={generalDataProcessStats.statuses.reviewDisapproved}
        />
      </Box>
    </Box>
  )
}

export default DetailsStatus
