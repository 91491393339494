import api from 'service/api'
import { AnalysisLiaAnswersMountedDataType } from 'types/liaAnswers.types'

const dponetAPI = api.create('dponet')
const dponetAPIWithNoTreatError = api.create('dponet', false)

const showWithLia = async ({
  dataProcessId = '',
}: {
  dataProcessId?: string | number
}) => {
  return await dponetAPI.get(
    `/advisors/data_processes/${dataProcessId}/legitimate_interest/show`
  )
}

const getWithLia = async ({ params = {} }: { params?: object }) => {
  return await dponetAPI.get(`/advisors/data_processes/legitimate_interest`, {
    params,
  })
}

const getWithLegalFrameworks = async ({ params = {} }: { params?: object }) => {
  return await dponetAPI.get(`/advisors/data_processes/legal_frameworks`, {
    params,
  })
}

const changeStatus = async ({
  dataProcessId,
  statusId,
  dataProcess,
}: {
  dataProcessId: number
  statusId: number
  dataProcess?: object
}) => {
  return await dponetAPI.put(
    `/advisors/data_processes/${dataProcessId}/change_status/${statusId}`,
    dataProcess
  )
}

const put = async ({
  dataProcessId,
  dataProcess,
}: {
  dataProcessId: number
  dataProcess: object
}) => {
  return await dponetAPI.put(`/advisors/data_processes/${dataProcessId}`, {
    dataProcess,
  })
}

const analysisAnswer = async ({
  dataProcessId,
  dataProcess,
}: {
  dataProcessId?: number | string
  dataProcess: {
    analysisAnswers: AnalysisLiaAnswersMountedDataType[]
  }
}) => {
  return await dponetAPIWithNoTreatError.put(
    `/advisors/data_processes/${dataProcessId}/analysis_lia_answers`,
    {
      dataProcess,
    }
  )
}

export default {
  analysisAnswer,
  changeStatus,
  showWithLia,
  getWithLegalFrameworks,
  getWithLia,
  put,
}
