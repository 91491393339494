import { useContext } from 'react'

import { DrawerContext } from 'contexts'

const useDrawer = () => {
  const context = useContext(DrawerContext)

  if (context === undefined) {
    throw new Error('useDrawer must be used within a DrawerContext.Provider')
  }

  return context
}

export default useDrawer
