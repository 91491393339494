import { Box, BoxProps, Card, CardContent, CardHeader } from '@mui/material'

import { InformativeBoxType } from './informativeBox.types'

import theme from 'theme'

const InformativeBox = ({
  title,
  action,
  children,
  ...rest
}: InformativeBoxType & BoxProps) => {
  return (
    <Card
      component={Box}
      height="100%"
      display="grid"
      gridTemplateRows="auto 1fr"
    >
      <CardHeader
        component={Box}
        bgcolor={theme.palette.primary.main}
        action={action}
        subheader={title}
        subheaderTypographyProps={{
          color: theme.palette.primary.contrastText,
        }}
      />
      <CardContent component={Box} paddingX={5} pt={3} m={0} {...rest}>
        {children}
      </CardContent>
    </Card>
  )
}

InformativeBox.defaultProps = {
  action: <></>,
}

export default InformativeBox
