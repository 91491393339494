import api from 'service/api'

const dponetAPI = api.create('dponet')

const organization = async ({
  organizationId,
}: {
  organizationId: number | string | undefined
}) => {
  return await dponetAPI.get(`/advisors/organizations/${organizationId}/stats`)
}

const company = async ({
  organizationId,
}: {
  organizationId: number | string | undefined
}) => {
  return await dponetAPI.get(
    `/advisors/organizations/${organizationId}/stats/company`
  )
}

const dataProcess = async ({
  organizationId,
}: {
  organizationId: number | string | undefined
}) => {
  return await dponetAPI.get(
    `/advisors/organizations/${organizationId}/stats/data_process`
  )
}

const questionnaire = async ({
  organizationId,
}: {
  organizationId: number | string | undefined
}) => {
  return await dponetAPI.get(
    `/advisors/organizations/${organizationId}/stats/questionnaire`
  )
}

const incident = async ({
  organizationId,
}: {
  organizationId: number | string | undefined
}) => {
  return await dponetAPI.get(
    `/advisors/organizations/${organizationId}/stats/incident`
  )
}

const companyTrail = async ({
  organizationId,
}: {
  organizationId: number | string | undefined
}) => {
  return await dponetAPI.get(
    `/advisors/organizations/${organizationId}/stats/company_trail`
  )
}

export default {
  organization,
  company,
  dataProcess,
  questionnaire,
  incident,
  companyTrail,
}
