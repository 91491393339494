import { useState } from 'react'
import { Check as CheckIcon } from 'react-feather'
import { Box, Button, Divider, Paper, Typography } from '@mui/material'
import { isEmpty } from 'lodash'

import { LegalFrameworksChecklist, DialogApproveRevision } from './components'

import { LegalFrameworksDetailsType } from './legalFrameworksDetails.types'

import service from 'service'
import theme from 'theme'
import constants from 'constants/index'

const LegalFrameworksDetails = ({
  changeDataProcessStatus,
  dataProcess,
  legalFrameworkByDataTypes,
  setLoading,
}: LegalFrameworksDetailsType) => {
  const [dialogApproveRevision, setDialogApproveRevision] = useState(false)
  const [selectedLegalFrameworks, setSelectedLegalFrameworks] = useState<
    number[]
  >([])
  const [selectedLegalFrameworksNames, setSelectedLegalFrameworksNames] =
    useState<string[]>([])

  const { APPROVED_STATUS_ID, REVIEWING_STATUS_ID } = constants.dataProcess

  const { LEGITIMATE_INTEREST, FRAUD_PREVENTION } =
    constants.legitimateInterest.LEGAL_FRAMEWORKS_LIA

  const changePendingLiaStatus =
    selectedLegalFrameworksNames.includes(LEGITIMATE_INTEREST) ||
    selectedLegalFrameworksNames.includes(FRAUD_PREVENTION)

  const updateLegalFrameworks = async () => {
    await service.dponet.dataProcesses.put({
      dataProcessId: dataProcess?.id,
      dataProcess: {
        legalFrameworks: selectedLegalFrameworks?.map((id) => ({ id })),
      },
    })
    changeDataProcessStatus(APPROVED_STATUS_ID)
  }

  return (
    <Paper square>
      <Box p={4}>
        <Typography variant="body1" fontWeight={500}>
          Detalhes do enquadramento legal
        </Typography>
      </Box>
      <Divider />
      <Box m={4}>
        <Box
          p={1}
          mb={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor={theme.palette.success.main}
        >
          <CheckIcon size={24} color="white" />
          <Box ml={1}>
            <Typography variant="button" color="white">
              SUGERIDO POR INTELIGÊNCIA ARTIFICIAL
            </Typography>
          </Box>
        </Box>
        <LegalFrameworksChecklist
          dataProcess={dataProcess}
          selectedLegalFrameworks={selectedLegalFrameworks}
          setSelectedLegalFrameworks={setSelectedLegalFrameworks}
          selectedLegalFrameworksNames={selectedLegalFrameworksNames}
          setSelectedLegalFrameworksNames={setSelectedLegalFrameworksNames}
          legalFrameworkByDataTypes={legalFrameworkByDataTypes}
        />
      </Box>
      <Divider />
      {dataProcess?.statusId === REVIEWING_STATUS_ID && (
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="success"
            onClick={() => setDialogApproveRevision(true)}
            disabled={isEmpty(selectedLegalFrameworks)}
          >
            Aprovar processo
          </Button>
        </Box>
      )}
      <DialogApproveRevision
        data={dataProcess}
        open={dialogApproveRevision}
        setOpen={setDialogApproveRevision}
        onEvent={updateLegalFrameworks}
        setLoading={setLoading}
        changePendingLiaStatus={changePendingLiaStatus}
      />
    </Paper>
  )
}

LegalFrameworksDetails.defaultProps = {
  changeDataProcessStatus: () => {},
  dataProcess: {},
  legalFrameworks: {},
  setLoading: () => {},
}

export default LegalFrameworksDetails
