import * as yup from 'yup'

const schema = yup.object({
  comment: yup.string(),
})

export type DialogConfirmationPossibilitiesFormType = yup.InferType<
  typeof schema
>

export default schema
