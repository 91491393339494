import { Fragment, useState, useEffect } from 'react'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { BasicDialog, LoadingFeedback, MUI } from 'components'
import { AttachedDocuments } from './components'

import { useSnackbar } from 'hooks'

import { HelpDialogType } from './helpDialog.types'

import schema, { HelpDialogFormType } from './schema'
import service from 'service'
import { formatters } from 'helpers'
import formatDataTomticket from 'helpers/formatters/formatDataTomtickets'

const HelpDialog = ({ open, onClose, openHelpDialog }: HelpDialogType) => {
  const [loading, setLoading] = useState(false)
  const [dataDocument, setDataDocument] = useState<File[]>([])

  const { snackbar } = useSnackbar()

  useEffect(() => {
    if (!openHelpDialog) {
      setDataDocument([])
    }
  }, [openHelpDialog])

  const defaultTicketTypes = [
    { id: 'support', name: 'Suporte' },
    { id: 'financial', name: 'Financeiro' },
    { id: 'legal', name: 'Jurídico' },
    { id: 'security', name: 'Segurança da Informação' },
    { id: 'others', name: 'Outros' },
  ]

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      description: '',
      ticketType: '',
    },
  })

  const createTicketOnTomTicket = async (data: HelpDialogFormType) => {
    await service.dponet.tomticket.createTicket(
      formatDataTomticket(data, dataDocument)
    )
  }

  const createLegalTicket = async (data: HelpDialogFormType) => {
    await service.dponet.ticket.createJuridic({
      tickets: { ...data, attachments: dataDocument },
    })
  }

  const createSecurityTicket = async (data: HelpDialogFormType) => {
    await service.dponet.ticket.createSecurity({
      tickets: { ...data, attachments: dataDocument },
    })
  }

  const onSubmit = async (data: HelpDialogFormType) => {
    setLoading(true)
    try {
      const ticketType = data.ticketType

      if (ticketType === 'legal') {
        await createLegalTicket(data)
      } else if (ticketType === 'security') {
        await createSecurityTicket(data)
      } else {
        await createTicketOnTomTicket(data)
      }

      snackbar.open({
        message: 'Sucesso ao solicitar contato',
        variant: 'success',
      })

      reset()
      onClose()
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <BasicDialog
        open={open}
        title="Atendimento"
        maxWidth="md"
        onClose={onClose}
        dialogActions={
          <Fragment>
            <Button onClick={onClose}>Fechar</Button>
            <Button variant="contained" type="submit" form="help-form">
              Enviar
            </Button>
          </Fragment>
        }
      >
        <LoadingFeedback open={loading} />
        <form id="help-form" onSubmit={handleSubmit(onSubmit)}>
          <Box display="grid" gap={5} mb={5}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth margin="dense">
                  <InputLabel id="ticket-type-label">
                    Selecione o tema
                  </InputLabel>
                  <Select
                    {...field}
                    error={false}
                    labelId="ticket-type-label"
                    id="ticket-type-select"
                    label="Selecione o tema"
                  >
                    {defaultTicketTypes.map((ticketType) => (
                      <MUI.MenuItem key={ticketType.id} value={ticketType.id}>
                        {ticketType.name}
                      </MUI.MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              name="ticketType"
              control={control}
            />

            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.title}
                  helperText={<>{errors?.title?.message}</>}
                  label="Título do ticket"
                  type="text"
                  fullWidth
                />
              )}
              name="title"
              control={control}
            />

            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.description}
                  helperText={<>{errors?.description?.message}</>}
                  label="Escreva sua solicitação"
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                />
              )}
              name="description"
              control={control}
            />
          </Box>
          <AttachedDocuments
            dataDocument={dataDocument}
            setDataDocument={setDataDocument}
          />
        </form>
      </BasicDialog>
    </Fragment>
  )
}

export default HelpDialog
