import { Box } from '@mui/material'

import { InformativeBox, LinearProgressWithTitleAndLabel } from 'components'

import { ByCategoryCardType } from './byCategoryCard.types'

import dashboardHelper from 'helpers/dashboard'

const ByCategoryCard = ({ questionControlStats }: ByCategoryCardType) => {
  const questionControlStatsByCategory = questionControlStats?.byCategory

  const totalCount = questionControlStatsByCategory?.reduce(
    (acc, category) => acc + category.count,
    0
  )

  return (
    <InformativeBox title="Medida de segurança por categoria">
      <Box display="flex" flexDirection="column" gap={3}>
        {questionControlStatsByCategory.map((questionControlStatsCategory) => (
          <Box key={questionControlStatsCategory.kind}>
            <LinearProgressWithTitleAndLabel
              thickness={2}
              borderRadius={1}
              color="info"
              title={questionControlStatsCategory.kind}
              value={dashboardHelper.calculatePercentage(
                totalCount,
                questionControlStatsCategory.count
              )}
              valueLabel={questionControlStatsCategory.count}
            />
          </Box>
        ))}
      </Box>
    </InformativeBox>
  )
}

export default ByCategoryCard
