import { isEmpty } from 'lodash'
import { Eye as EyeIcon } from 'react-feather'
import {
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material'

import { Table, TableRow } from 'components'

import { DocumentsSectionType } from './documentsSection.types'

import { getBaseURL } from 'service/env'

const documentsSection = ({ nonComplianceReport }: DocumentsSectionType) => {
  const documentName = (url: string) => {
    const urlSplited = url.split('/')
    return urlSplited[urlSplited.length - 1]
  }

  const openDocument = (url: string) => {
    const baseUrl = getBaseURL('dponet')
    window.open(baseUrl + url, '_blank')
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Documentos
      </Typography>
      {isEmpty(nonComplianceReport?.documents) ? (
        <Typography variant="body2">Não há documentos cadastrados.</Typography>
      ) : (
        <TableContainer>
          <Table aria-label="Documentos">
            <TableHead>
              <TableRow>
                <TableCell width="90%">Documentos</TableCell>
                <TableCell width="10%" align="right">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonComplianceReport?.documents.map((document) => (
                <TableRow key={document.url}>
                  <TableCell>{documentName(document.url)}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => openDocument(document.url)}>
                      <EyeIcon size={24} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default documentsSection
