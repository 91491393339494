import { rowTwoColumnsType } from './rowTwoColumns.types'

import { ratio } from '../../helpers'
import constants from 'constants/index'
import theme from 'theme'

const rowTwoColumns = ({
  pdf,
  positionY,
  column_one_text,
  column_two_text,
  bgColor = '#EEE',
  fontColor = theme.palette.background.default,
}: rowTwoColumnsType) => {
  const PADDING = constants.jsPdf.PADDING
  const WIDTH_PDF = constants.jsPdf.WIDTH

  const WIDTH = WIDTH_PDF(pdf)
  const RADIUS = ratio(30)
  const labelWIDTH = ratio(160)
  const labelHeight = ratio(80)

  pdf.setFontSize(ratio(48))
  pdf.setTextColor(theme.palette.primary.main)
  pdf.setFont('Raleway-Regular')

  pdf.text(column_one_text, PADDING, positionY)
  pdf.setFillColor(bgColor)
  pdf.roundedRect(
    WIDTH - PADDING - labelWIDTH / 2,
    positionY - labelHeight / 2 - 2,
    ratio(100),
    ratio(70),
    RADIUS,
    RADIUS,
    'F'
  )

  pdf.setTextColor(fontColor)
  pdf.setFont('Raleway-Bold')

  pdf.text(
    column_two_text,
    WIDTH - PADDING - labelWIDTH / 4,
    positionY + labelHeight / 16 - 2
  )
  pdf.setFillColor('#eee')

  pdf.rect(PADDING, positionY + 6, WIDTH - PADDING * 2, ratio(1), 'F')
}

export default rowTwoColumns
