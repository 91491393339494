import { useEffect, useState } from 'react'
import { Box, Button, Paper, TextField, Typography } from '@mui/material'
import { Check as Checkicon, X as XIcon, User as UserIcon } from 'react-feather'
import { Controller, useFormContext } from 'react-hook-form'

import { PaperHeader, MUI } from 'components'

import {
  AnswerDpoQuestionType,
  QuestionIsPresentType,
  LiaQuestionOrderType,
} from 'types/liaAnswers.types'
import { LiaAnswersAnalysisPendingType } from './LiaAnswersAnalysisPending.types'

import dponet_logo from 'images/dponet_logo.png'
import {
  AnswersLiaReviewFormType,
  AnswersLiaReviewKeyType,
} from '../../schemaAnswersAnalysis'
import { formatters } from 'helpers'
import constants from 'constants/index'

const LiaAnswersAnalysisPending = ({
  liaAnswer,
}: LiaAnswersAnalysisPendingType) => {
  const [approved, setApproved] = useState(false)
  const [disapproved, setDisapproved] = useState(false)

  const { COLORS } = constants.legitimateInterest

  const questionOrder = liaAnswer?.liaQuestionOrder

  const answerDpoQuestion =
    `answerDpoQuestion${questionOrder}` as AnswerDpoQuestionType

  const liaQuestionOrder =
    `liaQuestionOrder${questionOrder}` as LiaQuestionOrderType

  const questionIsPresent =
    `question${questionOrder}IsPresent` as QuestionIsPresentType

  const {
    control,
    formState: { errors },
    setValue,
    resetField,
    clearErrors,
  } = useFormContext<AnswersLiaReviewFormType>()

  const answerDpoQuestionWasApproved: AnswersLiaReviewKeyType = `${answerDpoQuestion}WasApproved`

  const handleApproved = () => {
    setApproved(true)
    setValue(liaQuestionOrder, liaAnswer?.id)
    setValue(answerDpoQuestionWasApproved, true)
    clearErrors(answerDpoQuestionWasApproved)
    setDisapproved(false)
  }

  const handleDisapproved = () => {
    setDisapproved(true)
    setValue(liaQuestionOrder, liaAnswer?.id)
    setValue(answerDpoQuestionWasApproved, false)
    clearErrors(answerDpoQuestionWasApproved)
    setApproved(false)
  }

  const cancelApproved = () => {
    setApproved(false)
    resetField(answerDpoQuestionWasApproved)
  }

  const cancelDisapproved = () => {
    setDisapproved(false)
    resetField(answerDpoQuestionWasApproved)
    resetField(answerDpoQuestion)
  }

  useEffect(() => {
    setValue(questionIsPresent, true)
  }, [liaAnswer])

  return (
    <Paper component={Box} mb={3}>
      <PaperHeader
        title={`${liaAnswer?.liaQuestionOrder} - ${liaAnswer?.liaQuestionText}`}
        px={3}
        actionButton={
          <Box display="flex" flexDirection="column">
            <Box display="flex" gap={2} justifyContent="center">
              {approved ? (
                <Button
                  variant="contained"
                  color="tertiary"
                  onClick={cancelApproved}
                >
                  Aprovado
                </Button>
              ) : (
                <Button variant="outlined" onClick={handleApproved}>
                  <Checkicon size={18} />
                </Button>
              )}
              {disapproved ? (
                <Button
                  variant="contained"
                  color="error"
                  onClick={cancelDisapproved}
                >
                  Reprovado
                </Button>
              ) : (
                <Button variant="outlined" onClick={handleDisapproved}>
                  <XIcon size={18} />
                </Button>
              )}
            </Box>
            {!!errors?.[answerDpoQuestionWasApproved] && (
              <Typography color="tomato" variant="body2">
                {errors?.[answerDpoQuestionWasApproved]?.message}
              </Typography>
            )}
          </Box>
        }
      />
      <Box p={4}>
        <Box
          display="flex"
          bgcolor={COLORS.lightBlue}
          justifyContent="space-between"
          p={2}
          alignItems="center"
        >
          <Box display="flex" alignItems="center" gap={2} minHeight={70}>
            <Box
              bgcolor="white"
              display="flex"
              justifyContent="center"
              width={50}
              height={50}
              borderRadius={50}
              alignItems="center"
            >
              <UserIcon size={25} />
            </Box>
            <Typography fontWeight={700} color="primary">
              Cliente
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography color="primary" fontWeight={700}>
              Última atualização
            </Typography>
            <Typography>
              {formatters.date.ptBrFormatDate(liaAnswer?.updatedAt)}
            </Typography>
          </Box>
        </Box>
        <Box border={2} borderColor="whitesmoke" minHeight={70} p={2}>
          <Typography variant="body2">{liaAnswer?.answer}</Typography>
        </Box>
        {disapproved && (
          <>
            <Box
              display="flex"
              bgcolor={COLORS.greenLight}
              justifyContent="space-between"
              mt={2}
              p={2}
              alignItems="center"
            >
              <Box display="flex" alignItems="center" gap={2} minHeight={70}>
                <Box
                  bgcolor="white"
                  display="flex"
                  justifyContent="center"
                  width={50}
                  height={50}
                  borderRadius={50}
                  alignItems="center"
                >
                  <MUI.Img
                    src={dponet_logo}
                    $height="90%"
                    $width="90%"
                    $objectFit="cover"
                    $objectPosition="-3% 1%"
                  />
                </Box>
                <Typography fontWeight={700} color="primary">
                  DPO
                </Typography>
              </Box>
            </Box>
            <Box>
              <Controller
                name={answerDpoQuestion}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors?.[answerDpoQuestion]}
                    helperText={<>{errors?.[answerDpoQuestion]?.message}</>}
                    fullWidth
                    variant="outlined"
                    multiline
                    minRows={2}
                  />
                )}
              />
            </Box>
          </>
        )}
      </Box>
    </Paper>
  )
}

export default LiaAnswersAnalysisPending
