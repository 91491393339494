import {
  Box,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { AttachmentTableType } from './attachmentTable.types'
import { MenuButton } from 'components'

const AttachmentTable = ({
  attachments,
  handleDetach,
}: AttachmentTableType) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Documentos</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attachments.map((attachment, index) => (
            <TableRow key={attachment?.name + index}>
              <TableCell>{attachment?.name}</TableCell>
              <TableCell align="right">
                <Grid>
                  <Box display="flex" justifyContent="flex-end">
                    <MenuButton id={`ticket-attachment-${index}`}>
                      <MenuItem onClick={() => handleDetach(index)}>
                        Remover
                      </MenuItem>
                    </MenuButton>
                  </Box>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AttachmentTable
