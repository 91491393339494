import { Box, Divider, Paper, Tooltip, Typography, colors } from '@mui/material'

import { TicketInfoTableType } from './tickeInfoTable.types'

import { formatters } from 'helpers'
import ticketConstants from 'constants/tickets'
import ticketFeedbackConstants from 'constants/ticketFeedbacks'

const TicketInfoTable = ({ ticket }: TicketInfoTableType) => {
  const responsibleCompanyInfo =
    ticket?.company?.responsibleUser?.name +
    '\n' +
    ticket?.company?.responsibleUser?.email +
    '\n' +
    formatters.phoneDynamicMask(ticket?.company?.responsibleUser?.phone || '')
  const userInfo = ticket?.user?.name + '\n' + ticket?.user?.email

  const optionsTable = [
    {
      title: 'Assunto',
      value: ticket?.title,
      show: true,
    },
    {
      title: 'Responsável pela Organização',
      value: responsibleCompanyInfo,
      show: !!ticket?.company,
    },
    {
      title: 'Solicitante',
      value: userInfo,
      show: true,
    },
    {
      title: 'Categoria',
      value: ticketConstants.TICKET_KIND_TRANSLATION[ticket?.ticketType],
      show: true,
    },
    {
      title: 'Avaliação',
      value:
        ticket?.ticketFeedback?.rating &&
        ticketFeedbackConstants.TICKET_FEEDBACK_RATING_LABELS[
          ticket?.ticketFeedback?.rating
        ],
      show: !!ticket?.ticketFeedback,
    },
  ]

  return (
    <Box>
      <Paper component={Box}>
        <Box px={5} py={3}>
          <Typography variant="subtitle1" fontWeight={500} color="primary">
            Informações
          </Typography>
        </Box>
        {optionsTable.map(
          (option) =>
            option.show && (
              <Box key={option.title}>
                <Divider />
                <Tooltip title={option.value}>
                  <Box
                    p={5}
                    bgcolor={
                      option.title === 'Assunto'
                        ? colors.lightGreen['A100']
                        : 'white'
                    }
                  >
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      fontWeight={500}
                    >
                      {option.title}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="pre-line"
                    >
                      {option.value ? option.value : '-'}
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>
            )
        )}
      </Paper>
    </Box>
  )
}

export default TicketInfoTable
