import moment from 'moment'
import { PtBrFormatDateWithAddDaysType } from 'types/helper.types'

type dateType = number | string | null

const ptBrFormatDateWithAddDays = ({
  date,
  numberAdd,
  dateFormatAdd,
}: PtBrFormatDateWithAddDaysType) => {
  return moment(date).add(numberAdd, dateFormatAdd).format('DD/MM/YYYY')
}

const ptBrFormatDate = (date?: dateType, response?: string) => {
  return date ? moment(date).format('DD/MM/YYYY') : response ?? '-'
}

const ptBrFormatHours = (date?: dateType, response?: string) => {
  return date ? moment(date).format('HH:mm') : response ?? '-'
}

const ptBrFormatDateTime = (date?: dateType, response?: string) => {
  return date ? moment(date).format('DD/MM/YYYY [às] HH:mm') : response ?? '-'
}

export default {
  ptBrFormatDate,
  ptBrFormatDateWithAddDays,
  ptBrFormatHours,
  ptBrFormatDateTime,
}
