import * as yup from 'yup'

const schema = yup.object({
  questionKind: yup.string(),
  title: yup.string().max(100).required(),
  status: yup.boolean().required(),
  suboptions: yup.boolean().required(),
  specifications: yup.boolean().required(),
  deadlineDay: yup
    .number()
    .nullable()
    .when('questionKind', {
      is: 'demand',
      then: () => yup.number().positive().required(),
    }),
  companyDeadlineDay: yup
    .number()
    .nullable()
    .when('questionKind', {
      is: 'demand',
      then: () => yup.number().positive().required(),
    }),
  automaticAnswerEnabled: yup.boolean().when('questionKind', {
    is: 'demand',
    then: () => yup.boolean().required(),
  }),
  automaticAnswer: yup.string().when('automaticAnswerEnabled', {
    is: true,
    then: () => yup.string().required(),
  }),
  responsibleProfileId: yup.string().when('questionKind', {
    is: 'demand',
    then: () => yup.string().required(),
  }),
  coResponsibleProfileEnabled: yup.boolean().when('questionKind', {
    is: 'demand',
    then: () => yup.boolean().required(),
  }),
  coResponsibleProfileId: yup.string().when('coResponsibleProfileEnabled', {
    is: true,
    then: () => yup.string().required(),
  }),
})

export type AnswerCardSchemaType = yup.InferType<typeof schema>

export default schema
