import * as yup from 'yup'

const schema = yup.object({
  primaryColor: yup.string().required().min(4).max(7),
  primaryFontColor: yup.string().required(),
  secondaryColor: yup.string().required().min(4).max(7),
  active: yup.boolean(),
})

export type PlatformThemeFormType = yup.InferType<typeof schema>

export type PayloadType = {
  primaryColor: string
  primaryFontColor: string
  secondaryColor: string
  active?: boolean | string
}

export type PlatformThemeControlType =
  | 'primaryColor'
  | 'primaryFontColor'
  | 'secondaryColor'
  | 'active'

export default schema
