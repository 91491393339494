import PerfectScrollbar from 'react-perfect-scrollbar'
import { TableProps } from '@mui/material'

import { Table as StyledTable } from './components'

import { TableType } from './table.types'

const Table = ({ children, ...rest }: TableType & TableProps) => {
  return (
    <PerfectScrollbar>
      <StyledTable {...rest}>{children}</StyledTable>
    </PerfectScrollbar>
  )
}

export default Table
