import { useState } from 'react'
import { Button, Typography } from '@mui/material'

import { BasicDialog, LoadingFeedback } from 'components'

import { useSnackbar } from 'hooks'

import { TagDeletionDialogType } from './tagDeletionDialog.types'

import service from 'service'
import { formatters } from 'helpers'

const TagDeletionDialog = ({
  refresh,
  open,
  onClose,
  tag,
}: TagDeletionDialogType) => {
  const [loading, setLoading] = useState(false)

  const { snackbar } = useSnackbar()
  const deleteOrganizationTag = async () => {
    try {
      setLoading(true)
      await service.dponet.tags.destroy({
        tagId: tag?.id,
      })

      refresh()

      snackbar.open({
        message: 'Classificador excluído com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
    onClose()
  }

  return (
    <BasicDialog
      open={open}
      title="Excluir Classificador"
      onClose={onClose}
      dialogActions={
        <>
          <Button variant="text" color="neutral" onClick={() => onClose()}>
            FECHAR
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={deleteOrganizationTag}
          >
            EXCLUIR
          </Button>
        </>
      }
    >
      <LoadingFeedback open={loading} />

      <Typography>
        Você tem certeza que deseja excluir o classificador{' '}
        <strong>{tag?.name}</strong>?
      </Typography>
    </BasicDialog>
  )
}

export default TagDeletionDialog
