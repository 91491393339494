import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import { matchPath } from 'react-router'

import { AuthLayout } from 'layouts'
import { Authentication, Error } from 'views'

import routes from 'constants/routes'

const AuthenticationRoute = () => {
  const location = useLocation()

  const removeAuthenticationPrefix = (route: string) => {
    return route.replace('/authentication', '')
  }

  const isRegisterPath = !!matchPath(
    {
      path: routes.auth.register,
    },
    location.pathname
  )

  return (
    <AuthLayout showDescription={isRegisterPath}>
      <Routes>
        <Route
          path={removeAuthenticationPrefix(routes.auth.login)}
          element={<Authentication.Login />}
        />
        <Route
          path={removeAuthenticationPrefix(routes.auth.register)}
          element={<Authentication.Register />}
        />
        <Route
          path={removeAuthenticationPrefix(routes.auth.twoFactorAuthentication)}
          element={<Authentication.TwoFactorAuthentication />}
        />

        <Route path="/forbidden" element={<Error.Forbidden />} />
        <Route path="/not-found" element={<Error.NotFound />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
        <Route
          path={routes.auth.recoveryPassword}
          element={<Authentication.Recovery />}
        />
        <Route
          path={routes.auth.resetPassword}
          element={<Authentication.NewPassword />}
        />
      </Routes>
    </AuthLayout>
  )
}

export default AuthenticationRoute
