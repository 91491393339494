import constants from 'constants/index'

const holderAffectedInformedStatus = (idToFind: string | undefined): string => {
  const { COMMUNICATION_HOLDER_OPTIONS } = constants.incidents
  const option = COMMUNICATION_HOLDER_OPTIONS.find(
    (option) => option.id === idToFind
  )

  return option ? option.name : '-'
}

export default holderAffectedInformedStatus
