import { useEffect, useState } from 'react'
import qs, { ParsedQuery } from 'query-string'

import { useNavigate } from 'react-router-dom'

import {
  FilterHookType,
  FiltersTypes,
  UpdatedFiltersReturn,
} from 'types/filters.types'

const useFilter = (): FilterHookType => {
  const navigate = useNavigate()
  const search = qs.parse(location.search)

  const [filters, setFilters] = useState<FiltersTypes | ParsedQuery>(search)
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  useEffect(() => {
    const updatedFilters = Object.keys(filters)
      .filter((key) => !!filters[key])
      .reduce(
        (obj: UpdatedFiltersReturn, key) => ((obj[key] = filters[key]), obj),
        {}
      )

    navigate(location.pathname + '?' + qs.stringify(updatedFilters))
  }, [filters])

  return { filters, setFilters, drawerOpen, setDrawerOpen }
}

export default useFilter
