import { colors } from '@mui/material'

import components from '../../components'

import { GeneralInformationsType } from './generalInformation.types'

import { objectToImage, ratio } from '../../helpers'
import constants from 'constants/index'
import templates from '..'
import theme from 'theme'
import { companies as companiesHelpers } from 'helpers'

const generalInformations = async ({
  pdf,
  date,
  incidentsStats,
  processStats,
  gapCompliance,
  companyStats,
}: GeneralInformationsType) => {
  const PADDING = constants.jsPdf.PADDING
  const WIDTH_PDF = constants.jsPdf.WIDTH
  const GAP = constants.jsPdf.GAP
  const RECTANGLE_SIZE = constants.jsPdf.RECTANGLE_SIZE

  const WIDTH = WIDTH_PDF(pdf)

  const finalizedRmcs = incidentsStats.finalized + incidentsStats.riskAccepted
  const openRmcs =
    incidentsStats.onTime + incidentsStats.overdue + incidentsStats.pending

  const activeDataProcess =
    processStats.statuses.reviewApproved +
    processStats.statuses.reviewDisapproved +
    processStats.statuses.pending +
    processStats.statuses.reviewing

  templates.chapterCover({
    pdf,
    title: 'VISÃO GERAL',
    subtitle:
      'Síntese das principais informações e métricas apresentadas no painel de controle.',
  })

  pdf.addPage('a4', 'p')
  components.lineWithDate({
    pdf,
    date: date,
    color: theme.palette.primary.main,
  })

  components.numberAndTitle({ pdf, number: '3', title: 'Visão geral' })

  components.paragraph({
    pdf,
    text: 'Essa seção traz os principais indicadores, como um resumo dos principais dados que estão contidos no relatório.',
    positionY: GAP * 3,
    textColor: theme.palette.primary.main,
  })

  components.subtitle({
    pdf,
    sub: 'Healthscore',
    positionY: GAP * 3.75,
    textColor: theme.palette.primary.main,
  })

  components.paragraph({
    pdf,
    text: 'Em uma organização, o HealthScore pode medir a saúde da empresa com base em métricas da metodologia de adequação à LGPD dentro da trilha da DPOnet, através de indicadores-chave.',
    positionY: GAP * 4,
    textColor: theme.palette.primary.main,
  })

  components.paragraph({
    pdf,
    text: 'Aviso importante: o HealthScore não é uma forma oficial de indicar a adequação da empresa e não possui quaisquer fins comprobatórios de adequação à lei perante a ANPD.',
    positionY: GAP * 4.7,
    textColor: theme.palette.primary.main,
  })

  components.paragraph({
    pdf,
    text: 'O HealthScore no DPO Advisor corresponde ao cruzamento de diversos indicadores: Processos, Diagnósticos (questionários, medidas de segurança e ameaças) e Incidentes.',
    positionY: GAP * 5.25,
    textColor: theme.palette.primary.main,
  })

  const graphImg = await objectToImage('healthScore')

  if (graphImg) pdf.addImage(graphImg, 'PNG', PADDING, GAP * 6.7, 240, 170)

  // pdf.addPage('a4', 'p')

  // components.lineWithDate({
  //   pdf,
  //   date: date,
  //   color: theme.palette.primary.main,
  // })

  // components.subtitle({
  //   pdf,
  //   sub: 'Status geral',
  //   positionY: GAP,
  //   textColor: theme.palette.primary.main,
  // })

  // components.paragraph({
  //   pdf,
  //   text: 'Os indicadores de status de processos desempenham um papel fundamental na adequação à LGPD, fornecendo insights cruciais sobre o progresso e a eficácia das medidas implementadas pelas organizações. Esses indicadores são vitais para acompanhar o nível de conformidade e identificar áreas que requerem atenção especial. Eles incluem métricas como a quantidade de melhorias pendentes, quantidade de departamentos, quantidade de usuários e fornecedores.',
  //   positionY: GAP * 1.25,
  //   textColor: theme.palette.primary.main,
  // })

  pdf.addPage('a4', 'p')

  components.lineWithDate({
    pdf,
    date: date,
    color: theme.palette.primary.main,
  })

  components.subtitle({
    pdf,
    sub: 'Incidentes',
    positionY: GAP,
    textColor: theme.palette.primary.main,
  })

  components.paragraph({
    pdf,
    text: 'Incidentes se referem a qualquer evento ou ocorrência que resulte na violação ou comprometimento da segurança de dados pessoais.',
    positionY: GAP * 1.25,
    textColor: theme.palette.primary.main,
  })

  components.rectangle({
    pdf,
    positionX: PADDING,
    positionY: GAP * 1.75,
    width: RECTANGLE_SIZE.HALF_WIDTH,
    color: theme.palette.primary.main,
    ratioHeight: 0,
    label: 'Em análise',
    data: `${openRmcs}`,
    labelY: GAP * 2.25,
    fontSize: 80,
    dataBold: true,
    fontColor: colors.grey[50],
    labelColor: colors.grey[50],
    splited: true,
  })

  components.rectangle({
    pdf,
    positionX: WIDTH / 2 + ratio(70),
    positionY: GAP * 1.75,
    width: RECTANGLE_SIZE.HALF_WIDTH,
    color: theme.palette.primary.main,
    ratioHeight: 0,
    label: 'Resolvidos',
    data: `${finalizedRmcs}`,
    labelY: GAP * 2.25,
    fontSize: 80,
    dataBold: true,
    fontColor: colors.grey[50],
    labelColor: colors.grey[50],
    splited: true,
  })

  components.subtitle({
    pdf,
    sub: 'Processos',
    positionY: GAP * 3.75,
    textColor: theme.palette.primary.main,
  })

  components.paragraph({
    pdf,
    text: 'Processos são sequências de etapas, procedimentos internos ou ações realizadas pelas organizações para o tratamento de dados pessoais.',
    positionY: GAP * 4,
    textColor: theme.palette.primary.main,
  })

  components.rectangle({
    pdf,
    positionX: PADDING,
    positionY: GAP * 4.5,
    width: RECTANGLE_SIZE.HALF_WIDTH,
    color: theme.palette.success.main,
    ratioHeight: 0,
    label: 'Ativos',
    data: `${activeDataProcess}`,
    labelY: GAP * 5,
    fontSize: 80,
    dataBold: true,
    fontColor: colors.grey[50],
    labelColor: colors.grey[50],
    splited: true,
  })

  components.rectangle({
    pdf,
    positionX: WIDTH / 2 + ratio(70),
    positionY: GAP * 4.5,
    width: RECTANGLE_SIZE.HALF_WIDTH,
    color: colors.grey[600],
    ratioHeight: 0,
    label: 'Inativos',
    data: `${processStats.statuses.inactive}`,
    labelY: GAP * 5,
    fontSize: 80,
    dataBold: true,
    fontColor: colors.grey[50],
    labelColor: colors.grey[50],
    splited: true,
  })

  components.subtitle({
    pdf,
    sub: 'Questionários',
    positionY: GAP * 6.5,
    textColor: theme.palette.primary.main,
  })

  components.paragraph({
    pdf,
    text: 'Formulários usados para coletar informações pessoais de indivíduos que mostram a conformidade com as diretrizes de proteção de dados estabelecidas pela legislação.',
    positionY: GAP * 6.75,
    textColor: theme.palette.primary.main,
  })

  // components.rectangle({
  //   pdf,
  //   positionX: PADDING,
  //   positionY: GAP * 7.25,
  //   width: RECTANGLE_SIZE.HALF_WIDTH,
  //   color: theme.palette.success.main,
  //   ratioHeight: 0,
  //   label: 'Respondidos',
  //   data: '5', //generalStats.incidentRmcs.onTime.toString(),
  //   labelY: GAP * 7.75,
  //   fontSize: 80,
  //   dataBold: true,
  //   fontColor: colors.grey[50],
  //   labelColor: colors.grey[50],
  //   splited: true,
  // })

  // components.rectangle({
  //   pdf,
  //   positionX: WIDTH / 2 + ratio(70),
  //   positionY: GAP * 7.25,
  //   width: RECTANGLE_SIZE.HALF_WIDTH,
  //   color: theme.palette.tertiary.main,
  //   ratioHeight: 0,
  //   label: 'Não respondidos',
  //   data: '10', //generalStats.incidentRmcs.finalized.toString(),
  //   labelY: GAP * 7.75,
  //   fontSize: 80,
  //   dataBold: true,
  //   fontColor: colors.grey[50],
  //   labelColor: colors.grey[50],
  //   splited: true,
  // })

  components.rectangle({
    pdf,
    positionX: PADDING,
    positionY: GAP * 7.25,
    width: RECTANGLE_SIZE.HALF_WIDTH,
    color: theme.palette.tertiary.main,
    ratioHeight: 0,
    label: 'Conformidade de questionários',
    data: `${gapCompliance.toFixed(0)}%`,
    labelY: GAP * 7.75,
    fontSize: 80,
    dataBold: true,
    fontColor: colors.grey[50],
    labelColor: colors.grey[50],
    splited: true,
  })

  pdf.addPage('a4', 'p')

  components.lineWithDate({
    pdf,
    date: date,
    color: theme.palette.primary.main,
  })

  components.subtitle({
    pdf,
    sub: 'Empresas',
    positionY: GAP,
    textColor: theme.palette.primary.main,
  })

  components.paragraph({
    pdf,
    text: 'O gráfico classifica empresas com base na conformidade com a LGPD e destaca líderes em privacidade e empresas com conformidade limitada, fornecendo insights cruciais para medidas de proteção de dados.',
    positionY: GAP * 1.25,
    textColor: theme.palette.primary.main,
  })

  const complianceImg = await objectToImage('complianceByPorcentage')

  if (complianceImg)
    pdf.addImage(complianceImg, 'PNG', PADDING, GAP * 2, 210, 200)

  const orderedCompanyStats = companiesHelpers.companyByHealthScore.get(
    companyStats,
    0,
    101,
    'asc'
  )

  components.table({
    pdf,
    head: [
      ['Empresa', 'Conformidade dos Questionários (%)', 'Health Score (%)'],
    ],
    body: orderedCompanyStats.map((company) => [
      company.name,
      company.gapCompliance,
      company.healthScore,
    ]),
    startY: GAP * 6,
    cellWidth: 0.62,
  })
}

export default generalInformations
