const FIRST_ACCESS = 'Primeiro Acesso'
const COOKIE_BANNER = 'Banner de Cookies'
const FUNDAMENTAL_CONCEPTS_TRAINING = 'Treinamento de Conceitos Fundamentais'
const RESPONSIBLE_TRAINING = 'Treinamento do Representante'
const BUILD_YOUR_TEAM = 'Monte sua Equipe'
const CREATE_YOUR_PRIVACY_CHANNEL = 'Estabeleça o canal de comunicação'
const GAP_ANALISYS = 'Faça seu Diagnóstico'
const DATA_PROCESS_MAPPING = 'Mapeamento de Processos'
const HELP_CHANNEL = 'Como solucionar suas dúvidas'
const RMC = 'Melhoria Contínua'

const DASHBOARD_TRAILS_LABELS = {
  [FIRST_ACCESS]: FIRST_ACCESS,
  [COOKIE_BANNER]: COOKIE_BANNER,
  [RESPONSIBLE_TRAINING]: RESPONSIBLE_TRAINING,
  [GAP_ANALISYS]: 'Resolução de Questionários',
  [DATA_PROCESS_MAPPING]: 'Mapeamento de Processos',
  [RMC]: RMC,
}

const ALL_TRAILS = {
  FIRST_ACCESS,
  COOKIE_BANNER,
  FUNDAMENTAL_CONCEPTS_TRAINING,
  RESPONSIBLE_TRAINING,
  BUILD_YOUR_TEAM,
  CREATE_YOUR_PRIVACY_CHANNEL,
  GAP_ANALISYS,
  DATA_PROCESS_MAPPING,
  HELP_CHANNEL,
  RMC,
}

const DASHBOARD_TRAILS = [
  FIRST_ACCESS,
  COOKIE_BANNER,
  RESPONSIBLE_TRAINING,
  GAP_ANALISYS,
  DATA_PROCESS_MAPPING,
  RMC,
]

export default {
  DASHBOARD_TRAILS,
  DASHBOARD_TRAILS_LABELS,
  ALL_TRAILS,
}
