import { Box, Typography } from '@mui/material'

import { CompanyStatusRowType } from './companyStatusRow.types'

import polygonLightBlueFilled from 'images/polygon_light_blue_filled.svg'
import theme from 'theme'
import { AbsoluteCenteredBox } from 'components'

const CompanyStatusRow = ({
  title,
  value,
  icon: Icon,
}: CompanyStatusRowType) => {
  return (
    <Box display="flex" gap={5}>
      <Box>
        <Box position="relative">
          <img
            width={theme.spacing(14)}
            height={theme.spacing(14)}
            src={polygonLightBlueFilled}
            alt="check"
          />

          <AbsoluteCenteredBox>
            <Icon color={theme.palette.primary.light} />
          </AbsoluteCenteredBox>
        </Box>
      </Box>
      <Box>
        <Typography variant="caption">{title.toUpperCase()}</Typography>
        <Typography variant="h6" fontWeight={400}>
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

export default CompanyStatusRow
