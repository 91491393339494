import { ListItemIcon as ListItemIconMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'

const ListItemIcon = styled(ListItemIconMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $color?: Property.Color
}>(({ $color }) => ({
  color: $color,
}))

export default ListItemIcon
