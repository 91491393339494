import { Box, BoxProps, CircularProgress } from '@mui/material'

import { ConditionalLoadingType } from './conditionalLoading.types'

const ConditionalLoading = ({
  loading,
  centered,
  children,
  ...rest
}: ConditionalLoadingType & BoxProps) => {
  if (loading) {
    if (centered) {
      return (
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          {...rest}
        >
          <CircularProgress color="primary" />
        </Box>
      )
    }

    return <CircularProgress color="primary" />
  }

  return <>{children}</>
}

export default ConditionalLoading
