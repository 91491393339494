import * as yup from 'yup'

const schema = yup.object({
  allRelatedCompanies: yup.bool(),
  primaryColor: yup.string().required().min(4).max(7),
  primaryFontColor: yup.string().required(),
  secondaryColor: yup.string().required().min(4).max(7),
  secondaryFontColor: yup.string().required(),
})

export type PrivacyPortalThemeFormType = yup.InferType<typeof schema>

export type PrivacyPortalThemeControlType =
  | 'allRelatedCompanies'
  | 'primaryColor'
  | 'primaryFontColor'
  | 'secondaryColor'
  | 'secondaryFontColor'

export default schema
