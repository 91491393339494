import dataProcessConstants from 'constants/dataProcess'

const statusColor = (statusId: number) => {
  switch (statusId) {
    case dataProcessConstants.PENDING_STATUS_ID:
      return '#087DF1'
    case dataProcessConstants.WAITING_REVIEW_STATUS_ID:
      return '#FF9800'
    case dataProcessConstants.DISAPPROVED_STATUS_ID:
      return '#B71C1C'
    case dataProcessConstants.APPROVED_STATUS_ID:
      return '#4CAF50'
    case dataProcessConstants.REVIEWING_STATUS_ID:
      return '#671684'
    case dataProcessConstants.INACTIVE_STATUS_ID:
      return '#999999'
    case dataProcessConstants.AUTO_REVIEW_STATUS_ID:
      return '#148585'
    default:
      return ''
  }
}

export default statusColor
