import * as yup from 'yup'

const schema = yup
  .object({
    observation: yup.string().required(),
  })
  .required()

export type DialogReproveRevisionSchemaType = yup.InferType<typeof schema>

export default schema
