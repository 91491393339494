import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material'

import { Table, TableRow } from 'components'

import { SpecificRisksSectionType } from './specificRisksSection.types'
import { isEmpty } from 'lodash'

const NonComplianceReportActionsSection = ({
  nonComplianceReport,
}: SpecificRisksSectionType) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Riscos específicos para a empresa
      </Typography>
      {isEmpty(nonComplianceReport?.specificRisks) ? (
        <Typography variant="body2">Não há riscos cadastrados.</Typography>
      ) : (
        <TableContainer>
          <Table aria-label="Riscos específicos para a empresa">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Risco</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonComplianceReport?.specificRisks.map((specificRisk) => (
                <TableRow key={specificRisk.id}>
                  <TableCell>{specificRisk.id}</TableCell>
                  <TableCell>{specificRisk.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default NonComplianceReportActionsSection
