import { useState } from 'react'
import { Button, Typography } from '@mui/material'

import { BasicDialog } from 'components'

const OrganizationRequest = () => {
  const [openRequestDialog, setOpenRequestDialog] = useState(false)

  const handleCloseCreateDialog = () => {
    setOpenRequestDialog(false)
  }

  return (
    <BasicDialog
      open={openRequestDialog}
      title="Você foi convidado para participar deste grupo advisor"
      onClose={handleCloseCreateDialog}
      dialogActions={
        <>
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseCreateDialog}
          >
            REJEITAR CONVITE
          </Button>
          <Button
            variant="contained"
            color="tertiary"
            onClick={handleCloseCreateDialog}
          >
            ACEITAR CONVITE
          </Button>
        </>
      }
    >
      <ul>
        <li>
          <Typography variant="body1">UNIMED BRASIL DPO</Typography>
        </li>
      </ul>
    </BasicDialog>
  )
}

export default OrganizationRequest
