import { useEffect, useState } from 'react'
import { Box, Typography, colors } from '@mui/material'

import { MUI } from 'components'

import { CheckboxItemType } from './checkboxItem.types'

import legalFrameworkConstants from 'constants/legalFramework'

const CheckboxItem = ({
  disabled,
  item,
  defaultChecked,
  handleCheck,
  prediction,
  titularMinor,
}: CheckboxItemType) => {
  const [checked, setChecked] = useState(defaultChecked)

  const handleCheckItem = () => {
    handleCheck(item?.id, item?.name)
    setChecked((value) => !value)
  }

  useEffect(() => {
    if (defaultChecked) setChecked(true)
  }, [defaultChecked])

  const formatedPrediction =
    !!prediction && !disabled
      ? (parseFloat(prediction) * 100).toFixed(0).toString().padStart(2, '0') +
        '%'
      : titularMinor && item?.name === legalFrameworkConstants.PARENTAL_CONSENT
      ? '100%'
      : '-'

  return (
    <Box display="flex">
      <Box
        mr={2}
        width="28px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body2" fontWeight={500}>
          {formatedPrediction}
        </Typography>
      </Box>
      <MUI.FormControlLabel
        $color={disabled && !checked ? colors.grey[400] : 'primary'}
        control={
          <MUI.Checkbox
            checked={checked}
            onChange={() => handleCheckItem()}
            name={item?.name}
            $color={disabled && !checked ? colors.grey[400] : 'primary'}
          />
        }
        label={
          <Typography variant="body2" fontWeight={500}>
            {item?.name}
          </Typography>
        }
      />
    </Box>
  )
}

CheckboxItem.defaultProps = {
  item: {},
  defaultChecked: false,
  handleCheck: () => {},
}

export default CheckboxItem
