import * as yup from 'yup'

const envProduction = process.env.REACT_APP_API === 'production'

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
    recaptcha: envProduction ? yup.string().required() : yup.string(),
  })
  .required()

export type LoginFormType = yup.InferType<typeof schema>

export default schema
