import { Grid } from '@mui/material'

import { Card, LabelDescription } from 'components'

import { useIncidentManegement } from 'hooks'

const IncidentInfo = () => {
  const { incident } = useIncidentManegement()
  return (
    <Card title="Informações Gerais">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <LabelDescription title="ID" description={incident?.id.toString()} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LabelDescription
            title="Nome da Organização"
            description={incident?.company.name}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LabelDescription
            title="DPO Nomeado"
            description={incident?.company?.dpoName}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LabelDescription
            title="Responsável pelo Cadastro"
            description={incident?.userResponsible?.name}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LabelDescription
            title="Email do Responsável"
            description={incident?.userResponsible?.email}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default IncidentInfo
