import constants from 'constants/index'

const dataProtectedNotPossibleIdentifyHolderStatus = (
  idToFind: string | undefined
): string => {
  const { SECURITY_OPTIONS } = constants.incidents
  const option = SECURITY_OPTIONS.find((option) => option.id === idToFind)

  return option ? option.status : '-'
}

export default dataProtectedNotPossibleIdentifyHolderStatus
