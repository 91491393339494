import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { usePermission } from 'hooks'

import { OrganizationFormType } from './organizationForm.types'

import schema from './schema'
import organizationConstants from 'constants/organizations'
import permissionTagsConstants from 'constants/permissionTags'
import { formatters, regex } from 'helpers'

const OrganizationForm = ({
  onSubmit,
  organization,
  canDisable,
}: OrganizationFormType) => {
  const { isPermitted } = usePermission()

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: organization?.name || '',
      kind: organization?.kind || '',
      kindPerson: organization?.kindPerson || '',
      responsible: organization?.responsible || '',
      substitute: organization?.substitute || '',
      dpo: organization?.dpo || '',
      cnpj: (organization?.cnpj && formatters.cnpj(organization.cnpj)) || '',
      cpf: (organization?.cpf && formatters.cpf(organization.cpf)) || '',
    },
  })

  const formId = organization
    ? 'organization-updation-form'
    : 'organization-creation-form'
  const disableFields =
    canDisable && !isPermitted(permissionTagsConstants.ORGANIZATION.MANAGE)

  return (
    <Box
      id={formId}
      pt={2}
      flex={1}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.name}
                helperText={errors?.name?.message ?? 'Máximo de 50 caracteres'}
                label="Nome do Grupo Advisor"
                type="text"
                variant="outlined"
                fullWidth
                disabled={disableFields}
              />
            )}
            name="name"
            control={control}
          />
        </Grid>
        {watch('kind') === organizationConstants.KIND_DPO && (
          <>
            <Grid item xs={12}>
              <Controller
                name="kindPerson"
                control={control}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    margin="dense"
                    error={!!errors.kindPerson?.message}
                    disabled={disableFields}
                  >
                    <InputLabel id="kind-person-label">
                      Selecione o tipo de pessoa
                    </InputLabel>
                    <Select
                      {...field}
                      labelId="kind-person-label"
                      label="Selecione o tipo de pessoa"
                    >
                      <MenuItem value="juridic">Pessoa Jurídica</MenuItem>
                      <MenuItem value="personal">Pessoa Física</MenuItem>
                    </Select>
                    {!!errors.kindPerson && (
                      <FormHelperText>
                        {errors.kindPerson?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors?.dpo}
                    helperText={errors?.dpo?.message}
                    label="Encarregado (DPO)"
                    type="text"
                    variant="outlined"
                    fullWidth
                    disabled={disableFields}
                  />
                )}
                name="dpo"
                control={control}
              />
            </Grid>
            {watch('kindPerson') === 'personal' && (
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(e) => {
                        const formattedValue = formatters.cpf(
                          regex.onlyNumbers(e.target.value)
                        )
                        field.onChange(formattedValue)
                      }}
                      error={!!errors?.cpf}
                      helperText={errors?.cpf?.message}
                      label="CPF"
                      type="text"
                      variant="outlined"
                      fullWidth
                      disabled={disableFields}
                      inputProps={{ maxLength: 14 }}
                    />
                  )}
                  name="cpf"
                  control={control}
                />
              </Grid>
            )}
            {watch('kindPerson') === 'juridic' && (
              <>
                <Grid item xs={12}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        onChange={(e) => {
                          const formattedValue = formatters.cnpj(
                            regex.onlyNumbers(e.target.value)
                          )
                          field.onChange(formattedValue)
                        }}
                        error={!!errors?.cnpj}
                        helperText={errors?.cnpj?.message}
                        label="CNPJ"
                        type="text"
                        variant="outlined"
                        fullWidth
                        disabled={disableFields}
                        inputProps={{ maxLength: 18 }}
                      />
                    )}
                    name="cnpj"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors?.responsible}
                        helperText={errors?.responsible?.message}
                        label="Nome do Responsável"
                        type="text"
                        variant="outlined"
                        fullWidth
                        disabled={disableFields}
                      />
                    )}
                    name="responsible"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors?.substitute}
                        helperText={errors?.substitute?.message}
                        label="Nome do Substituto"
                        type="text"
                        variant="outlined"
                        fullWidth
                        disabled={disableFields}
                      />
                    )}
                    name="substitute"
                    control={control}
                  />
                </Grid>
              </>
            )}
          </>
        )}
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <FormControl
                {...field}
                disabled={!!organization}
                error={!!errors.kind}
              >
                <FormLabel id="radio-organization-kind" error={!!errors.kind}>
                  Finalidade do Grupo Advisor
                </FormLabel>
                <RadioGroup row defaultValue={organization?.kind}>
                  <FormControlLabel
                    value={organizationConstants.KIND_DPO}
                    control={<Radio />}
                    label="DPO"
                  />
                  <FormControlLabel
                    value={organizationConstants.KIND_OBSERVER}
                    control={<Radio />}
                    label="Observador"
                  />
                </RadioGroup>
                {!!errors.kind && (
                  <FormHelperText>{errors?.kind?.message}</FormHelperText>
                )}
              </FormControl>
            )}
            name="kind"
            control={control}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default OrganizationForm
