import { Avatar as AvatarMUI } from '@mui/material'
import { styled } from '@mui/material/styles'

const BigAvatar = styled(AvatarMUI, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $background?: string
}>(({ $background }) => ({
  width: '100%',
  height: 'auto',
  aspectRatio: '1/1',
  maxWidth: 124,
  background: $background,
}))

export default BigAvatar
