import { Fragment, useState } from 'react'
import { Button, Typography, colors } from '@mui/material'

import { HealthScoreDialog } from './components'
import { Chart, LinearGradient as linear } from 'components'

import { HealthScorePieChartType } from './healthScorePieChart.types'

import theme from 'theme'

const HealthScorePieChart = ({ healthScore }: HealthScorePieChartType) => {
  const [openHealthScoreDialog, setOpenHealthScoreDialog] = useState(false)

  const LinearGradient = linear('80%')

  const handleChangeHealthScoreDialog = () => {
    setOpenHealthScoreDialog((openHealthScoreDialog) => !openHealthScoreDialog)
  }

  return (
    <Fragment>
      <LinearGradient borderRadius={1} p={4} id="healthScore">
        <Chart.RadialBar
          labels={['Health Score']}
          colors={[colors.lightBlue[100]]}
          foreColor={theme.palette.secondary.contrastText}
          trackBackground={theme.palette.secondary.dark}
          series={[healthScore]}
          height={370}
        />
        <Button variant="text" onClick={handleChangeHealthScoreDialog}>
          <Typography variant="body2" textTransform="initial" color="white">
            Mais informações
          </Typography>
        </Button>
      </LinearGradient>
      <HealthScoreDialog
        open={openHealthScoreDialog}
        onClose={handleChangeHealthScoreDialog}
      />
    </Fragment>
  )
}

export default HealthScorePieChart
