import { useState } from 'react'
import { isEmpty } from 'lodash'

import { AiTicketGenerativeBox, AiTicketGenerativeButton } from './components'

import { AiTicketGenerativeType } from './aiTicketGenerative.types'

import { useSnackbar } from 'hooks'

import { getBaseURL } from 'service/env'
import service from 'service'

const AiTicketGenerative = ({ ticket }: AiTicketGenerativeType) => {
  const daiResponseQuantity = ticket?.daiResponses?.length || 0
  const daiResponse = ticket?.daiResponses?.[daiResponseQuantity - 1]

  const [interactions, setInteractions] = useState(daiResponseQuantity)

  const [generativeSuggestion, setGenerativeSuggestion] = useState(
    daiResponse?.response ?? ''
  )
  const [haveAlreadyGeneratedSuggestion, setHaveAlreadyGeneratedSuggestion] =
    useState(!!daiResponse?.response)
  const [haveStartedGeneratedSuggestion, setHaveStartedGeneratedSuggestion] =
    useState(false)

  const { snackbar } = useSnackbar()

  const handleRequestGenerativeSuggestion = async () => {
    setHaveStartedGeneratedSuggestion(true)
    setInteractions((interactions) => interactions + 1)

    try {
      const url = getBaseURL('dponet')
      const uri = `${url}/advisors/tickets/${ticket?.id}/juridic/generative`

      const responseStream = await fetch(uri, {
        method: 'GET',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Authorization: service.dponet.auth.getToken() || '',
        },
      })

      if (!responseStream.ok || !responseStream.body) {
        throw responseStream.statusText
      }

      const reader = responseStream.body.getReader()
      const decoder = new TextDecoder()
      const loopRunner = true

      const dataRegex = /data: "/g
      const nRegex = /"\n\n/g

      while (loopRunner) {
        const { value, done } = await reader.read()

        if (done) break

        const decodedChunk = decoder.decode(value, { stream: true })

        const decodedChunkFormatted = decodedChunk
          .replaceAll(dataRegex, '')
          .replaceAll(nRegex, '')

        setGenerativeSuggestion(
          (generativeSuggestion) => generativeSuggestion + decodedChunkFormatted
        )
      }

      setHaveAlreadyGeneratedSuggestion(true)
    } catch (error) {
      snackbar.open({
        message:
          'Ocorreu um erro de comunicação com a DAI. Por favor, tente novamente mais tarde.',
        variant: 'error',
      })
    }
  }

  const handleRegenerate = async () => {
    setHaveAlreadyGeneratedSuggestion(false)
    setHaveStartedGeneratedSuggestion(false)
    setGenerativeSuggestion('')
    await handleRequestGenerativeSuggestion()
  }

  const handleFeedback = async (rate: string, reason: string = '') => {
    await service.dponet.daiFeedback.post({
      ticketId: ticket?.id,
      params: {
        daiFeedback: {
          rate,
          reason,
        },
        daiResponseIndex: interactions - 1,
      },
    })
  }

  return (
    <>
      {isEmpty(generativeSuggestion) && (
        <AiTicketGenerativeButton
          handleRequestGenerativeSuggestion={handleRequestGenerativeSuggestion}
          disabled={haveStartedGeneratedSuggestion}
        />
      )}
      {!isEmpty(generativeSuggestion) && (
        <AiTicketGenerativeBox
          haveStartedGeneratedSuggestion={haveStartedGeneratedSuggestion}
          alreadyGeneratedSuggestion={haveAlreadyGeneratedSuggestion}
          daiFeedback={daiResponse?.daiFeedback}
          suggestion={generativeSuggestion}
          handleFeedback={handleFeedback}
          handleRegenerate={handleRegenerate}
        />
      )}
    </>
  )
}

export default AiTicketGenerative
