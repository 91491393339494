import { useCallback, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { createEditor, Descendant, Editor, Transforms } from 'slate'
import {
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react'
import { withHistory } from 'slate-history'
import { Box, FormHelperText } from '@mui/material'

import { MUI } from 'components'
import { Toolbar } from './components'

import { validates } from 'helpers'

import { MarksType } from 'types/slateJs.types'
import { SlateElementInterface } from 'interfaces/slateJs.interfaces'
import { TextEditorType } from './textEditor.types'

import constants from 'constants/index'
import palette from 'theme/palette'
import theme from 'theme'

const TextEditor = ({ controlName = 'content' }: TextEditorType) => {
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])
  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  )
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  )
  const {
    control,
    setValue,
    formState: { errors, isSubmitSuccessful },
    watch,
    reset,
  } = useFormContext()

  const ControlValue = watch(controlName)

  const initialValue = [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ]

  const handleChange = (value: Descendant[]) => {
    const serializedValue = validates.slateJs.serialize(value)
    const html = validates.slateJs.addBrToEmptyTags(serializedValue)

    if (validates.slateJs.hasContent(html)) setValue(controlName, html)
    else setValue(controlName, '')
  }

  const Element = ({ attributes, children, element }: RenderElementProps) => {
    const slateElement = element as SlateElementInterface

    switch (slateElement.type) {
      case constants.slateJs.HEADING_ONE_LABEL_BUTTON:
        return <h1 {...attributes}>{children}</h1>
      case constants.slateJs.HEADING_TWO_LABEL_BUTTON:
        return <h2 {...attributes}>{children}</h2>
      case constants.slateJs.HEADING_THREE_LABEL_BUTTON:
        return <h3 {...attributes}>{children}</h3>
      case 'blockquote':
        return <blockquote {...attributes}>{children}</blockquote>
      case 'ordered-list':
        return <ol {...attributes}>{children}</ol>
      case 'unordered-list':
        return <ul {...attributes}>{children}</ul>
      case 'list-item':
        return <li {...attributes}>{children}</li>
      case 'align-left':
        return (
          <div style={{ textAlign: 'left' }} {...attributes}>
            {children}
          </div>
        )
      case 'align-center':
        return (
          <div style={{ textAlign: 'center' }} {...attributes}>
            {children}
          </div>
        )
      case 'align-right':
        return (
          <div style={{ textAlign: 'right' }} {...attributes}>
            {children}
          </div>
        )
      default:
        return <p {...attributes}>{children}</p>
    }
  }

  const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
    const slateLeaf = leaf as MarksType

    if (slateLeaf.bold) children = <strong>{children}</strong>
    if (slateLeaf.italic) children = <em>{children}</em>
    if (slateLeaf.underline) children = <u>{children}</u>
    if (slateLeaf.strikethrough) children = <del>{children}</del>
    if (slateLeaf.superscript) children = <sup>{children}</sup>
    if (slateLeaf.subscript) children = <sub>{children}</sub>

    return <span {...attributes}>{children}</span>
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      if (isEmpty(ControlValue)) {
        Transforms.select(editor, {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        })
        Transforms.delete(editor)
        Transforms.collapse(editor, { edge: 'end' })
      }
      reset()
    }
  }, [isSubmitSuccessful])

  return (
    <Box>
      <Slate
        editor={editor}
        initialValue={initialValue}
        onChange={handleChange}
      >
        <Toolbar controlName={controlName} />
        <Controller
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          render={({ field }) => (
            <MUI.Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              spellCheck
              onKeyDown={(event) => validates.slateJs.onKeyDown(event, editor)}
              $borderColor={
                errors?.[controlName]
                  ? palette.error.main
                  : palette.textfield.border
              }
              $color={
                errors?.[controlName]
                  ? palette.error.main
                  : theme.palette.common.black
              }
            />
          )}
          control={control}
          name={controlName}
        />
      </Slate>
      {errors?.[controlName] && (
        <FormHelperText error={!!errors?.[controlName]}>
          <>{errors?.[controlName]?.message}</>
        </FormHelperText>
      )}
    </Box>
  )
}

export default TextEditor
