import legalFrameworkLabelByDataType from './legalFrameworkLabelByDataType'
import legalFrameworkValueByDataType from './legalFrameworkValueByDataType'
import orderLegalFrameworks from './orderLegalFrameworks'
import rangeAgeContent from './rangeAgeContent'
import statusColor from './statusColor'
import statusIcon from './statusIcon'
import statusLabel from './statusLabel'
import agentsTypeFilter from './agentsTypeFilter'
import legalFrameworks from './legalFrameworks'

const dataProcesses = {
  agentsTypeFilter,
  legalFrameworkLabelByDataType,
  legalFrameworks,
  legalFrameworkValueByDataType,
  orderLegalFrameworks,
  rangeAgeContent,
  statusColor,
  statusIcon,
  statusLabel,
}

export default dataProcesses
