import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Grid, Paper, useMediaQuery } from '@mui/material'

import { ColorSpecification, LoadingFeedback, PaperHeader } from 'components'
import { PreviewPagePlatform } from './components'

import schema, {
  PlatformThemeControlType,
  PlatformThemeFormType,
} from './schema'

import { formatters } from 'helpers'
import { useFetch, useSnackbar } from 'hooks'
import service from 'service'
import theme from 'theme'

import { PlatformCustomizationBoxType } from './platformCustomizationBox.types'

const PlatformCustomizationBox = ({
  organizationId,
  logoPath,
}: PlatformCustomizationBoxType) => {
  const { snackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)

  const { response: responseTheme } = useFetch(
    service.dponet.whiteLabel.getTheme,
    { organizationId }
  )

  const portalTheme = responseTheme?.data?.clientPortalTheme

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<PlatformThemeFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      primaryColor: portalTheme?.primaryColor || theme.palette.primary.main,
      primaryFontColor: portalTheme?.primaryFontColor || '#FFFFFF',
      secondaryColor:
        portalTheme?.secondaryColor || theme.palette.secondary.main,
    },
  })

  const primaryColor = watch('primaryColor')
  const primaryFontColor = watch('primaryFontColor')
  const secondaryColor = watch('secondaryColor')

  const isExtraLarge = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  const setColor = (name: PlatformThemeControlType) => (color: string) => {
    setValue(name, color)
  }

  useEffect(() => {
    if (portalTheme !== undefined) {
      setValue('primaryColor', `${portalTheme?.primaryColor}`)
      setValue('primaryFontColor', `${portalTheme?.primaryFontColor}`)
      setValue('secondaryColor', `${portalTheme?.secondaryColor}`)
    }
  }, [portalTheme, setValue])

  const onSubmit = async (data: PlatformThemeFormType) => {
    try {
      setLoading(true)
      const formData: PlatformThemeFormType = {
        primaryColor: data?.primaryColor,
        primaryFontColor: data?.primaryFontColor,
        secondaryColor: data?.secondaryColor,
        active: portalTheme?.active,
      }

      organizationId &&
        (portalTheme
          ? await service.dponet.whiteLabel.updateTheme(
              portalTheme?.id,
              formData
            )
          : await service.dponet.whiteLabel.postTheme(formData))

      snackbar.open({
        message: 'Tema salvo com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Paper>
      <LoadingFeedback open={loading} />
      <PaperHeader
        title="Personalização da Plataforma"
        pt={2}
        px={5}
        actionButton={
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            form="privacy-portal-theme-form"
          >
            Salvar
          </Button>
        }
      />
      <form id="privacy-portal-theme-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={isExtraLarge ? 16 : 6} p={5}>
          <Grid item xs={12} lg={5}>
            <Box display="flex" flexDirection="column" gap={6}>
              <ColorSpecification
                colorPicker={primaryColor}
                setColorPicker={setColor('primaryColor')}
                fontColor={primaryFontColor}
                titleName="Especificação da primeira cor"
                subtitleName="(Cabeçalho e rodapé)"
                buttonMainName="Primeira Cor"
                buttonFontName="Cor da Fonte"
                control={control}
                errorMainName={errors?.primaryColor}
                errorFontName={errors?.primaryFontColor}
                controlMainName="primaryColor"
                controlFontName="primaryFontColor"
              />
              <ColorSpecification
                colorPicker={secondaryColor}
                setColorPicker={setColor('secondaryColor')}
                titleName="Especificação da segunda cor"
                subtitleName="(Cards, botões)"
                buttonMainName="Segunda Cor"
                buttonFontName="Segunda Fonte"
                control={control}
                errorMainName={errors?.secondaryColor}
                controlMainName="secondaryColor"
                controlFontName="secondaryFontColor"
                hideFontField={true}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={7}>
            <PreviewPagePlatform
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              primaryTextColor={primaryFontColor}
              secondaryTextColor={primaryFontColor}
              logoPath={logoPath}
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}

export default PlatformCustomizationBox
