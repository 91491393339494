import { useState } from 'react'
import { Clipboard as ClipboardIcon } from 'react-feather'
import { Box, Button, Divider, Paper, Typography, colors } from '@mui/material'

import {
  DataCollecteds,
  DataTreatments,
  DataProcessInfoChip,
  LifeCycles,
  TitleSection,
} from './components'
import { DialogReproveRevision } from '../LegalFrameworksDetails/components'

import { DataProcessInfoType } from './dataProcessInfo.types'

import dataProcessConstants from 'constants/dataProcess'
import DataTreatmentAgents from './components/DataTreatmentAgents'

const DataProcessInfo = ({
  dataProcess,
  changeDataProcessStatus,
  setLoading,
  showDetails,
}: DataProcessInfoType) => {
  const [dialogReproveRevision, setDialogReproveRevision] = useState(false)

  return (
    <Paper
      elevation={0}
      component={Box}
      borderBottom={1}
      borderLeft={1}
      borderRight={1}
      borderColor={colors.grey[300]}
      square
    >
      <Box>
        <Box display="flex" width="100%" p={1}>
          <Box
            width="50%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
            my={1}
            ml={1}
            bgcolor={colors.blue[100]}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="body1" fontWeight={500}>
                #{dataProcess?.id}
              </Typography>
              <Typography variant="h6">{dataProcess?.company?.name}</Typography>
            </Box>
            <ClipboardIcon size={27} />
          </Box>
          <Box
            p={3}
            width="50%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="end"
            border={1}
            mr={1}
            my={1}
            borderColor={colors.blue[100]}
          >
            <Typography variant="body1" fontWeight={500}>
              {dataProcess?.company?.document || '-'}
            </Typography>
            <Typography variant="body1" fontWeight={500}>
              Departamento - {dataProcess?.department?.name || '-'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box m={showDetails ? 4 : 3} textAlign={showDetails ? 'left' : 'center'}>
        <Typography variant="h6">{dataProcess?.name}</Typography>
      </Box>
      {showDetails && (
        <>
          <Divider />
          <Box m={4}>
            <TitleSection title="Por que os dados são tratados?" />
            <DataProcessInfoChip text={dataProcess?.finality} />
          </Box>
          <Divider />
          <DataCollecteds dataCollecteds={dataProcess?.dataCollecteds} />
          <Divider />
          <LifeCycles lifeCycles={dataProcess?.lifeCycles} />
          <Divider />
          <DataTreatments dataTreatments={dataProcess?.dataTreatments} />
          <Divider />
          <DataTreatmentAgents
            dataTreatmentAgents={dataProcess?.dataTreatmentAgents}
          />
          <Divider />
          {dataProcess?.statusId ===
            dataProcessConstants.REVIEWING_STATUS_ID && (
            <>
              <Box p={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setDialogReproveRevision(true)}
                >
                  Reprovar processo
                </Button>
              </Box>
              <DialogReproveRevision
                dataProcess={dataProcess}
                open={dialogReproveRevision}
                setOpen={setDialogReproveRevision}
                setLoading={setLoading}
                onEvent={() =>
                  changeDataProcessStatus(
                    dataProcessConstants.DISAPPROVED_STATUS_ID
                  )
                }
              />
            </>
          )}
        </>
      )}
    </Paper>
  )
}

DataProcessInfo.defaultProps = {
  dataProcess: {},
  changeDataProcessStatus: () => {},
  setLoading: () => {},
  showDetails: true,
}

export default DataProcessInfo
