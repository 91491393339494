import { CircularProgress } from '@mui/material'

import { MUI } from 'components'

import { LoadingFeedbackType } from './loadingFeedback.types'

import theme from 'theme'

const LoadingFeedback = ({ open }: LoadingFeedbackType) => {
  return (
    <MUI.Backdrop $zIndex={theme.zIndex.modal + 1} open={open}>
      <CircularProgress color="primary" />
    </MUI.Backdrop>
  )
}

export default LoadingFeedback
