import { Box, styled } from '@mui/material'
import GreenHexagon from 'images/polygon_light_green_filled.svg'
import BlueHexagon from 'images/polygon_light_blue_filled.svg'

import { HexagonBoxType } from './HexagonBox.types'

const HexagonBox = styled(Box)<HexagonBoxType>(({ typehexagon }) => ({
  backgroundImage:
    typehexagon === 'blueHexagon'
      ? `url(${BlueHexagon})`
      : typehexagon === 'greenHexagon'
      ? `url(${GreenHexagon})`
      : 'none',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}))

export default HexagonBox
