import { Grid } from '@mui/material'

import { Card } from 'components'
import IncidentCheckbox from '../IncidentCheckbox'

import { PersonalDataImpactType } from './personalDataImpact.types'

import constants from 'constants/index'
import IncidentTextArea from '../../../IncidentTextArea'

const PersonalDataImpact = ({ options, incident }: PersonalDataImpactType) => {
  return (
    <Card title="Impactos do Incidente Sobre os Dados Pessoais">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <IncidentCheckbox
            label="De quais formas o incidente afetou os dados pessoais:"
            options={constants.incidents.INCIDENT_VIOLATION_TYPES}
            optionsSelectedItems={incident?.incidentViolationTypes || []}
          />
        </Grid>
        <Grid item xs={12}>
          <IncidentCheckbox
            label="Se aplicável, quais os tipos de dados pessoais sensíveis foram violados:"
            incidentOptions={options.typesSensitivePersonalDataBreached || []}
            incidentOptionsSelectedItems={
              incident?.dataOptionIncidents
                ?.typesSensitivePersonalDataBreached || []
            }
          />
        </Grid>
        <Grid item xs={12}>
          <IncidentCheckbox
            label="Quais os demais tipos de dados pessoais violados?"
            incidentOptions={options.otherTypePersonalDataViolated || []}
            incidentOptionsSelectedItems={
              incident?.dataOptionIncidents?.otherTypePersonalDataViolated || []
            }
            other
          />
        </Grid>
        <Grid item xs={12}>
          <IncidentTextArea
            label="Descrever os tipos de dados pessoais não sensíveis violados, se cabível:"
            value={incident?.nonSensitiveViolationDescription}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default PersonalDataImpact
