import { useState } from 'react'
import { Button } from '@mui/material'
import { PlusCircle as PlusCircleIcon } from 'react-feather'

import { BasicDialog } from 'components'

import { useSnackbar } from 'hooks'

import service from 'service'
import { TagCreateType } from './tagCreate.types'
import { formatters } from 'helpers'
import { TagForm } from '..'

const TagCreate = ({ refresh, setLoading }: TagCreateType) => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [errorMessage, setErrorMessage] = useState<undefined | string>()

  const { snackbar } = useSnackbar()

  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true)
  }

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false)
  }

  const createTag = async (organizationTag: object) => {
    try {
      setLoading(true)

      await service.dponet.tags.create(organizationTag)

      handleCloseCreateDialog()
      refresh()

      snackbar.open({
        message: 'Classificador criado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      setErrorMessage(formatters.errorMessage(error, false))
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<PlusCircleIcon />}
        disableElevation
        onClick={handleOpenCreateDialog}
        aria-label="abrir modal para criar classificador"
      >
        ADICIONAR CLASSIFICADORES
      </Button>

      <BasicDialog
        open={openCreateDialog}
        title="Adicionar Classificador"
        onClose={handleCloseCreateDialog}
        form="tag-creation-form"
      >
        <TagForm onSubmit={createTag} errorMessage={errorMessage} />
      </BasicDialog>
    </>
  )
}

export default TagCreate
