import { Box } from '@mui/material'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

import { RadialBarType } from './radialBar.types'

const RadialBar = ({
  labels,
  colors,
  foreColor,
  series,
  trackBackground,
  height,
}: RadialBarType) => {
  const options: ApexOptions = {
    colors: [foreColor],
    chart: {
      foreColor: foreColor,
    },
    fill: {
      colors: colors,
    },
    dataLabels: {
      enabled: false,
      style: {
        fontSize: '50px',
      },
    },
    plotOptions: {
      radialBar: {
        track: {
          background: trackBackground,
        },
        dataLabels: {
          value: {
            fontSize: '20px',
          },
        },
        hollow: {
          size: '70%',
        },
      },
    },
    labels: labels,
  }

  return (
    <Box width="100%">
      <Chart
        options={options}
        type="radialBar"
        series={series}
        height={height}
      />
    </Box>
  )
}

export default RadialBar
