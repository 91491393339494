import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'
import {
  Avatar,
  Box,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  // TablePagination,
} from '@mui/material'
import { ArrowRight } from 'react-feather'

import { Table as TableComponent, TableRow } from 'components'

import { OrganizationTableType } from './organizationTable.types'

import { formatters } from 'helpers'
import routes from 'constants/routes'
import organizationsConstants from 'constants/organizations'

const OrganizationTable = ({ organizations }: OrganizationTableType) => {
  const navigate = useNavigate()
  const handleClickActionButton = (
    event: React.MouseEvent<unknown>,
    id: number
  ) => {
    event.stopPropagation()

    handleCLickTableRow(id)
  }

  const handleCLickTableRow = (id: number) => {
    navigate(
      reverse(routes.app.organizations.update, {
        organizationId: id,
      })
    )
  }

  return (
    <TableContainer>
      <TableComponent aria-label="listagem dos grupos advisors">
        <TableHead>
          <TableRow>
            <TableCell align="left">Nome</TableCell>
            <TableCell align="left">Criado por</TableCell>
            <TableCell align="left">Finalidade</TableCell>
            <TableCell align="left">Atualizado em</TableCell>
            <TableCell align="center">Empresas</TableCell>
            <TableCell align="center">Usuários</TableCell>
            <TableCell align="center" width="10%">
              Ações
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {organizations.map((organization) => (
            <TableRow
              key={organization.id}
              onClick={() => handleCLickTableRow(organization.id)}
            >
              <TableCell align="left">
                <Box display="flex" flexDirection="row" gap={5}>
                  <Avatar
                    alt={organization?.name}
                    src={organization?.logo}
                    variant="square"
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Typography variant="body2">
                      {organization?.name}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell align="left">{organization.author.name}</TableCell>
              <TableCell align="left">
                {organizationsConstants.KIND_LABELS[organization.kind]}
              </TableCell>
              <TableCell align="left">
                {formatters.date.ptBrFormatDate(organization.updatedAt)}
              </TableCell>
              <TableCell align="center">{organization.companies}</TableCell>
              <TableCell align="center">{organization.users}</TableCell>
              <TableCell align="right">
                <Button
                  variant="outlined"
                  color="neutral"
                  endIcon={<ArrowRight size={20} />}
                  onClick={(event) =>
                    handleClickActionButton(event, organization.id)
                  }
                >
                  DETALHES
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableComponent>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={organizations.length}
        rowsPerPage={5}
        page={0}
        onPageChange={() => {}}
        onRowsPerPageChange={() => {}}
        labelRowsPerPage="Linhas por página"
      /> */}
    </TableContainer>
  )
}

export default OrganizationTable
