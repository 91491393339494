const PENDING_STATUS_LABEL = 'Pendente'
const IN_PROGRESS_STATUS_LABEL = 'Em Andamento'
const ADOPTED_STATUS_LABEL = 'Adotado'
const OVERDUE_STATUS_LABEL = 'Atrasado'
const RISK_ASSUMED_STATUS_LABEL = 'Risco Assumido'
const TREATED_STATUS_LABEL = 'Tratado'

const PENDING_STATUS = 'pending'
const IN_PROGRESS_STATUS = 'in_progress'
const ADOPTED_STATUS = 'adopted'
const OVERDUE_STATUS = 'overdue'
const RISK_ASSUMED_STATUS = 'risk_assumed'
const TREATED_STATUS = 'treated'

const PENDING_STATUS_COLOR = '#FF7A00'
const IN_PROGRESS_STATUS_COLOR = '#0763A0'
const ADOPTED_STATUS_COLOR = '#16A63C'
const OVERDUE_STATUS_COLOR = '#E83A49'
const RISK_ASSUMED_STATUS_COLOR = '#FECC31'
const TREATED_STATUS_COLOR = '#AADD5F'

export default {
  PENDING_STATUS_LABEL,
  IN_PROGRESS_STATUS_LABEL,
  ADOPTED_STATUS_LABEL,
  OVERDUE_STATUS_LABEL,
  RISK_ASSUMED_STATUS_LABEL,
  TREATED_STATUS_LABEL,
  PENDING_STATUS,
  IN_PROGRESS_STATUS,
  ADOPTED_STATUS,
  OVERDUE_STATUS,
  RISK_ASSUMED_STATUS,
  TREATED_STATUS,
  PENDING_STATUS_COLOR,
  IN_PROGRESS_STATUS_COLOR,
  ADOPTED_STATUS_COLOR,
  OVERDUE_STATUS_COLOR,
  RISK_ASSUMED_STATUS_COLOR,
  TREATED_STATUS_COLOR,
}
