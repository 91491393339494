import firstCover from './FirstCover/firstCover'
import lastCover from './LastCover/lastCover'
import chapterCover from './ChapterCover/chapterCover'
import disclaimer from './Disclaimer/disclaimer'
import introduction from './Introduction/introduction'
import glossary from './Glossary/glossary'
import generalInformations from './GeneralInformation/generalInformations'

const components = {
  firstCover,
  lastCover,
  chapterCover,
  disclaimer,
  introduction,
  glossary,
  generalInformations,
}

export default components
