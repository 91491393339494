import autoTable from 'jspdf-autotable'

import { TicketTableType } from './ticketTable.types'

import constants from 'constants/index'
import theme from 'theme'
import { colors } from '@mui/material'

const ticketTable = ({
  pdf,
  head,
  body,
  startY,
  cellWidth = 0.65,
  type,
}: TicketTableType) => {
  const PADDING = constants.jsPdf.PADDING
  const WIDTH_PDF = constants.jsPdf.WIDTH

  const WIDTH = WIDTH_PDF(pdf) - PADDING * 2

  autoTable(pdf, {
    head: head,
    body: body,
    startY: startY,
    tableWidth: WIDTH,
    margin: PADDING,
    columnStyles: {
      0: {
        font: 'Roboto-Bold',
        cellWidth: WIDTH * cellWidth,
        halign: 'left',
        fontStyle: 'bold',
        textColor: theme.palette.primary.main,
      },
    },
    headStyles: {
      textColor: theme.palette.primary.main,
      font: 'Roboto-Bold',
      fillColor: theme.palette.primary.main,
      cellPadding: 6,
    },
    styles: {
      cellWidth: 'auto',
      textColor: theme.palette.primary.main,
    },
    bodyStyles: {
      font: 'Roboto-Regular',
    },
    theme: 'grid',
    didParseCell: function (data) {
      if (data.row.index === 1 && type === 1) {
        data.cell.styles.fillColor = colors.orange[50]
      }
      if (data.row.index === 1 && (type === 2 || type === 3)) {
        data.cell.styles.fillColor = colors.blue[50]
      }
    },
  })
}

export default ticketTable
