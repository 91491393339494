import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import boxBlue from 'images/box_blue.png'

const SupplierBox = styled(Box)(({ theme }) => ({
  height: 190,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
  backgroundImage: `url(${boxBlue})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  borderTopLeftRadius: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1),
}))

export default SupplierBox
