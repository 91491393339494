import api from 'service/api'
import service from 'service'
import axios from 'axios'
import { getBaseURL } from 'service/env'

const dponetAPI = api.create('dponet')
const baseURL = getBaseURL('dponet')

const create = async (data: object) => {
  return await dponetAPI.post('/advisors/organizations', data)
}

const get = async ({
  organizationId = '',
  ...params
}: {
  organizationId?: string | number
  params?: object
}) => {
  return dponetAPI.get(`/advisors/organizations/${organizationId}`, params)
}

const selectOrganization = async ({
  organizationId = '',
}: {
  organizationId?: string | number
}) => dponetAPI.get(`/advisors/organization_users/${organizationId}/select`)

const update = async ({
  organizationId = '',
  ...rest
}: {
  organizationId?: string
}) => {
  return dponetAPI.put(`/advisors/organizations/${organizationId}`, rest)
}

const stats = async ({
  organizationId = '',
}: {
  organizationId?: string | number
}) => {
  return dponetAPI.get(`/advisors/organizations/${organizationId}/stats`)
}

const deleteOrganization = async ({
  organizationId = '',
}: {
  organizationId?: string | number
}) => {
  return dponetAPI.delete(`/advisors/organizations/${organizationId}/`)
}

const uploadLogo = async ({
  organizationId = '',
  logo,
}: {
  organizationId: string | number
  logo: File
}) => {
  const data = new FormData()
  data.append('logo', logo)

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/advisors/organizations/${organizationId}/add_logo`,
    data: data,
    ...config,
  })
}

export default {
  create,
  deleteOrganization,
  get,
  selectOrganization,
  stats,
  update,
  uploadLogo,
}
