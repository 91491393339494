import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = ({
  organizationCompanyId = '',
  params = {},
}: {
  organizationCompanyId?: string | number
  params?: object
}) => {
  return dponetAPI.get(
    `/advisors/organization_companies/${organizationCompanyId}`,
    {
      params,
    }
  )
}

const create = ({
  organizationCompany,
}: {
  organizationId?: string | number
  organizationCompany: object
}) => {
  return dponetAPI.post(`/advisors/organization_companies`, {
    organizationCompany,
  })
}

const removeCompanyMatrix = ({
  organizationCompanyId,
}: {
  organizationCompanyId: string | number
}) => {
  return dponetAPI.patch(
    `/advisors/organization_companies/${organizationCompanyId}/remove_company_matrix`
  )
}

const update = ({
  organizationCompanyId,
  organizationCompany,
}: {
  organizationCompanyId: string | number
  organizationCompany: object
}) => {
  return dponetAPI.put(
    `/advisors/organization_companies/${organizationCompanyId}`,
    {
      organizationCompany,
    }
  )
}

export default { create, get, removeCompanyMatrix, update }
