import { isEmpty } from 'lodash'

import usePermission from 'hooks/usePermission'

import { PermittedType } from './permitted.types'

const Permitted = ({ children, tag, someTags }: PermittedType) => {
  const { isPermitted, somePermitted } = usePermission()

  if (!someTags && !tag) {
    return <>{children}</>
  }

  if (someTags && !isEmpty(someTags) && somePermitted(someTags)) {
    return <>{children}</>
  }

  if (tag && isPermitted(tag)) {
    return <>{children}</>
  }

  return null
}

export default Permitted
