import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import { isEmpty } from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'

import { IncidentInfo } from './components'
import { Card, LabelDescription, LoadingFeedback } from 'components'
import { OpinionAttachment } from 'views/Incidents/components/IncidentsForm/components'

import { useIncidentManegement, useSnackbar } from 'hooks'

import { AvaliationStepType } from './avaliationStep.types'

import schema, { AvaliationStepSchemaType } from './schema'
import service from 'service'
import { formatters } from 'helpers'

const AvaliationStep = ({ refresh }: AvaliationStepType) => {
  const [loading, setLoading] = useState(false)
  const [opinionFile, setOpinionFile] = useState<File | null>(null)
  const [opinionFileError, setOpinionFileError] = useState(false)
  const [notificationOpinionFile, setNotificationOpinionFile] =
    useState<File | null>(null)
  const [notificationOpinionFileError, setNotificationOpinionFileError] =
    useState(false)

  const { incident, wantsToAdvanceStep, resetAdvanceStep, handleNext } =
    useIncidentManegement()
  const { snackbar } = useSnackbar()

  const {
    control,
    formState: { errors },
    watch,
    getValues,
    trigger,
    setValue,
  } = useForm<AvaliationStepSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      legalOpinion: incident?.legalOpinion || '',
      confirmed: String(incident?.confirmed || false),
      shouldNotify: String(incident?.shouldNotify || false),
      notificationOpinion: incident?.notificationOpinion || '',
    },
  })

  const alreadyAnswered = !isEmpty(incident?.legalOpinion)

  const mountAvaliationParams = () => {
    const data = getValues()

    return {
      legalOpinion: data?.legalOpinion,
      confirmed: data?.confirmed,
      shouldNotify: data?.confirmed === 'true' ? data?.shouldNotify : undefined,
      notificationOpinion:
        data?.shouldNotify === 'true' ? data?.notificationOpinion : '',
      attachments: opinionFile,
      notificationOpinionAttachment:
        data?.shouldNotify === 'true' ? notificationOpinionFile : undefined,
    }
  }

  const triggerValidation = async () => {
    let valid = await trigger()

    if (isEmpty(incident?.attachments) && opinionFile === null) {
      valid = false
      setOpinionFileError(true)
    } else if (opinionFileError) {
      setOpinionFileError(false)
    }

    if (
      isEmpty(incident?.notificationOpinionAttachment) &&
      watch('shouldNotify') === 'true' &&
      notificationOpinionFile === null
    ) {
      valid = false
      setNotificationOpinionFileError(true)
    } else if (notificationOpinionFileError) {
      setNotificationOpinionFileError(false)
    }

    return valid
  }

  const onSubmit = async () => {
    if (alreadyAnswered) return handleNext()

    const valid = await triggerValidation()

    if (!valid) return

    try {
      setLoading(true)

      await service.dponet.incidents.put({
        incidentId: incident?.id,
        legalOpinionData: mountAvaliationParams(),
      })

      refresh()
      handleNext()

      snackbar.open({
        message: 'Parecer atualizado com sucesso',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (wantsToAdvanceStep) {
      onSubmit()
      resetAdvanceStep()
    }
  }, [wantsToAdvanceStep])

  useEffect(() => {
    if (watch('confirmed') === 'false') {
      setValue('shouldNotify', 'false')
    }
  }, [watch('confirmed')])

  return (
    <Grid container spacing={4}>
      <LoadingFeedback open={loading} />
      <Grid item xs={12}>
        <IncidentInfo />
      </Grid>
      <Grid item xs={12}>
        <Card title="Avaliação do Incidente">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <LabelDescription title="Essa notificação é realmente um incidente?">
                <Controller
                  control={control}
                  name="confirmed"
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <FormControlLabel
                        disabled={alreadyAnswered}
                        value="false"
                        control={<Radio />}
                        label="Não"
                      />
                      <FormControlLabel
                        disabled={alreadyAnswered}
                        value="true"
                        control={<Radio />}
                        label="Sim"
                      />
                    </RadioGroup>
                  )}
                />
              </LabelDescription>
            </Grid>
            <Grid item xs={12}>
              <LabelDescription title="Parecer">
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      minRows={5}
                      multiline
                      error={!!errors.legalOpinion}
                      helperText={errors?.legalOpinion?.message}
                      fullWidth
                      disabled={alreadyAnswered}
                    />
                  )}
                  control={control}
                  name="legalOpinion"
                />
              </LabelDescription>
              <OpinionAttachment
                name="opinionFile"
                selectedFile={opinionFile}
                setSelectedFile={setOpinionFile}
                attachment={incident?.attachments}
                disabled={alreadyAnswered}
                description="ANEXAR PARECER"
                isNotObserverOrganization
                error={opinionFileError}
              />
            </Grid>
            {watch('confirmed') === 'true' && (
              <>
                <Grid item xs={12}>
                  <LabelDescription title="Houve dano e/ou risco relevante para que seja necessário comunicar a ANPD e os titulares de dados?">
                    <Controller
                      control={control}
                      name="shouldNotify"
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <FormControlLabel
                            disabled={alreadyAnswered}
                            value="false"
                            control={<Radio />}
                            label="Não"
                          />
                          <FormControlLabel
                            disabled={alreadyAnswered}
                            value="true"
                            control={<Radio />}
                            label="Sim"
                          />
                        </RadioGroup>
                      )}
                    />
                  </LabelDescription>
                </Grid>
                {watch('shouldNotify') === 'true' && (
                  <Grid item xs={12}>
                    <LabelDescription title="Comentário sobre a comunicação à titulares de dados">
                      <Controller
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="text"
                            minRows={5}
                            multiline
                            error={!!errors.notificationOpinion}
                            helperText={errors?.notificationOpinion?.message}
                            fullWidth
                            disabled={alreadyAnswered}
                          />
                        )}
                        control={control}
                        name="notificationOpinion"
                      />
                    </LabelDescription>
                    <OpinionAttachment
                      name="notificationOpinionFile"
                      selectedFile={notificationOpinionFile}
                      setSelectedFile={setNotificationOpinionFile}
                      attachment={incident?.notificationOpinionAttachment}
                      disabled={alreadyAnswered}
                      description="ANEXAR DOCUMENTAÇÃO"
                      isNotObserverOrganization
                      error={notificationOpinionFileError}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default AvaliationStep
