import { styled } from '@mui/material/styles'

const LogoDai = styled('img')(() => ({
  maxHeight: '100%',
  height: 'auto',
  maxWidth: 40,
  alignSelf: 'center',
}))

export default LogoDai
