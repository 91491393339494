import { colors } from '@mui/material'
import { isEmpty } from 'lodash'

import components from 'utils/jsPDF/components'

import { treatmentType } from './Treatment.types'

import { formatters } from 'helpers'

import constants from 'constants/index'
import theme from 'theme'

const Treatment = ({ pdf, date, incidents }: treatmentType) => {
  const GAP = constants.jsPdf.GAP
  const BLANK = 'N/A'

  pdf.addPage('a4', 'p')
  components.subtitle({ pdf, sub: 'Tratamento', positionY: GAP })
  components.lineWithDate({
    pdf,
    date: date,
    color: theme.palette.primary.main,
  })

  const tableData = [
    ['ID', incidents?.nonComplianceReport?.id || BLANK],
    ['Descrição (Causa)', incidents?.nonComplianceReport?.cause || BLANK],
    [
      'Risco',
      incidents?.nonComplianceReport?.specificRisks
        .map((riskSpecific) => riskSpecific?.description + '\n')
        .join('') || BLANK,
    ],
    [
      'Prazo',
      formatters.date.ptBrFormatDate(
        incidents?.nonComplianceReport?.deadlineDate
      ) || BLANK,
    ],
    [
      'Status',
      constants.nonComplianceReport.STATUSES_ID[
        incidents?.nonComplianceReport?.status
      ].label || BLANK,
    ],
  ]

  components.table({
    pdf,
    body: tableData,
    startY: GAP * 1.5,
    cellWidth: 0.25,
  })

  components.numberPage({ pdf, color: colors.grey['900'] })

  pdf.addPage('a4', 'p')
  components.subtitle({ pdf, sub: 'Plano de Ação', positionY: GAP })
  components.lineWithDate({
    pdf,
    date: date,
    color: theme.palette.primary.main,
  })

  if (!isEmpty(incidents?.nonComplianceReport?.nonComplianceReportActions)) {
    const nonComplianceTable =
      incidents.nonComplianceReport.nonComplianceReportActions.map((data) => {
        return [
          ['ID do plano de ação', data?.id],
          ['Responsável', data?.responsableUser?.name],
          [
            'Prazo do plano de ação',
            formatters.date.ptBrFormatDate(data?.deadlineDate),
          ],
          ['Ação Corretiva', data?.correctiveAction],
          ['Evidência', data?.correctionEvidence],
        ]
      })

    nonComplianceTable.forEach((data, index) => {
      components.lineWithDate({
        pdf,
        date: date,
        color: theme.palette.primary.main,
      })

      components.table({
        pdf,
        body: data,
        startY: index === 0 ? GAP * 1.5 : pdf.lastAutoTable.finalY + 35,
        cellWidth: 0.25,
      })
      components.numberPage({ pdf, color: colors.grey['900'] })
    })
  } else {
    const defaultNonComplianceTable = [
      ['ID do plano de ação', BLANK],
      ['Responsável', BLANK],
      ['Prazo do plano de ação', BLANK],
      ['Ação Corretiva', BLANK],
      ['Evidência', BLANK],
    ]

    components.table({
      pdf,
      body: defaultNonComplianceTable,
      startY: GAP * 1.5,
      cellWidth: 0.25,
    })
  }

  components.numberPage({ pdf, color: theme.palette.primary.main })
}

export default Treatment
