import { Grid, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { CompanyAutocomplete, UserCompanyAutocomplete } from 'components'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { TaskFormType } from './taskForm.types'
import { PayloadFormType } from 'types/task.types'

import { useSnackbar } from 'hooks'

import service from 'service'
import schema from './schema'

import tasksConstants from 'constants/tasks'
import { countText, formatters } from 'helpers'

const TaskForm = ({ refresh, task, onClose }: TaskFormType) => {
  const { snackbar } = useSnackbar()

  const onSubmit = async (data: PayloadFormType) => {
    try {
      if (task?.id) {
        await service.dponet.tasks.update({
          taskId: task?.id,
          task: { ...data, responsibleId: data?.responsibleId?.userId },
        })
      } else {
        await service.dponet.tasks.create({
          companyId: data?.companyId?.companyId,
          task: { ...data, responsibleId: data?.responsibleId?.userId },
        })
      }
      refresh()
      snackbar.open({
        message: `Atividade ${task?.id ? 'editada' : 'criada'} com sucesso`,
        variant: 'success',
      })
      onClose()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    }
  }

  const defaultCompany = {
    companyId: task?.company?.id,
    label: task?.company?.name,
  }

  const defaultResponsible = {
    userId: task?.responsible?.id,
    label: task?.responsible?.name,
  }

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: task?.name || '',
      description: task?.description || '',
      observations: task?.observations || '',
      startDate: task?.startDate ? new Date(task?.startDate) : undefined,
      dueDate: task?.dueDate ? new Date(task?.startDate) : undefined,
      companyId: task?.company ? defaultCompany : undefined,
      responsibleId: task?.responsible ? defaultResponsible : undefined,
    },
    reValidateMode: 'onChange',
  })

  const fieldsDisabled = task?.status === tasksConstants.FINALIZED_TASK

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form-task">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <CompanyAutocomplete
                label="Empresa"
                error={!!errors.companyId}
                helperText={errors?.companyId?.message}
                isOrganizationCompany={false}
                disabled={!!task?.id}
                {...field}
              />
            )}
            name="companyId"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Título da atividade"
                error={!!errors.name}
                helperText={
                  <>
                    {countText(
                      errors?.name?.message,
                      watch('name')?.length,
                      120
                    )}
                  </>
                }
                disabled={fieldsDisabled}
              />
            )}
            name="name"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <UserCompanyAutocomplete
                label="Responsável"
                error={!!errors.responsibleId}
                helperText={errors?.responsibleId?.message}
                isUserCompany={false}
                companyId={task?.company?.id || watch('companyId')?.companyId}
                disabled={fieldsDisabled}
                {...field}
              />
            )}
            name="responsibleId"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field }) => (
              <DatePicker
                {...field}
                format="dd/MM/yyyy"
                label="Data de início"
                maxDate={watch('dueDate') || null}
                slotProps={{
                  textField: {
                    helperText: errors?.startDate?.message,
                    error: !!errors.startDate,
                  },
                }}
                disabled={fieldsDisabled}
              />
            )}
            control={control}
            name="startDate"
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field }) => (
              <DatePicker
                {...field}
                format="dd/MM/yyyy"
                label="Data Fim"
                minDate={watch('startDate') || null}
                slotProps={{
                  textField: {
                    helperText: errors?.dueDate?.message,
                    error: !!errors.dueDate,
                  },
                }}
                disabled={fieldsDisabled}
              />
            )}
            control={control}
            name="dueDate"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Descrição"
                error={!!errors.description}
                helperText={
                  <>
                    {countText(
                      errors?.description?.message,
                      watch('description')?.length,
                      1200
                    )}
                  </>
                }
                multiline
                minRows={6}
                disabled={fieldsDisabled}
              />
            )}
            name="description"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Observação"
                error={!!errors.observations}
                helperText={
                  <>
                    {countText(
                      errors?.observations?.message,
                      watch('observations')?.length,
                      250
                    )}
                  </>
                }
                multiline
                minRows={3}
              />
            )}
            name="observations"
            control={control}
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default TaskForm
