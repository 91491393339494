import { useState } from 'react'
import { Box, Button, Divider, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { BaseBox, FormLogin, GreyTypography } from './components'
import { GoogleSignInButton } from './components/Login'

import routes from 'constants/routes'
import { getGoogleAuthenticationToken } from 'service/env'

const Login = () => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const goToRegister = () => navigate(routes.auth.register)

  return (
    <BaseBox title="Bem vindo(a)!">
      <Grid item xs={12}>
        <FormLogin isLoading={isLoading} setLoading={setLoading} />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="text"
          color="secondary"
          onClick={goToRegister}
          disabled={isLoading}
          fullWidth
        >
          Criar conta
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider>
          <GreyTypography variant="caption">ou</GreyTypography>
        </Divider>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <GoogleOAuthProvider clientId={getGoogleAuthenticationToken()}>
            <GoogleSignInButton isLoading={isLoading} setLoading={setLoading} />
          </GoogleOAuthProvider>
        </Box>
      </Grid>
    </BaseBox>
  )
}

export default Login
