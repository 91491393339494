import { useEffect } from 'react'
import { Box, Hidden, Typography, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import {
  BoxDescription,
  LogoAdvisor,
  RightBannerBox,
  RightDescriptionBox,
  RootBox,
  WrapperBox,
} from './components'

import { AuthLayoutProps } from './authLayout.types'

import logoAdvisor from 'images/logo_advisor.png'
import theme from 'theme'
import service from 'service'
import routes from 'constants/routes'

const Auth = ({ children, showDescription }: AuthLayoutProps) => {
  const navigate = useNavigate()

  const textAdvantages: string[] = [
    `Tenha uma visão 360° de todo o ecossistema da sua empresa sobre LGPD.`,
    `Visualize um painel completo com indicadores gerais da adequação e status do progresso do projeto.`,
    `Acompanhe individualmente o progresso de cada empresa vinculada e tome decisões de forma ágil com seu grupo.`,
    `Visualize no detalhe os desafios que cada unidade do seu ecossistema está vivenciando sobre a LGPD.`,
  ]

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  useEffect(() => {
    if (service.dponet.auth.isAuthenticated()) {
      navigate(routes.app.organizations.all)
    }
  }, [])

  return (
    <RootBox>
      <WrapperBox>{children}</WrapperBox>
      {isDesktop && showDescription ? (
        <RightDescriptionBox>
          <Box width="50%" mb={16} mt={32}>
            <LogoAdvisor
              src={logoAdvisor}
              alt="Logo DPOnet Advisor"
              width="100%"
            />
          </Box>
          <Hidden mdDown>
            <BoxDescription>
              {textAdvantages.map((advantage, index) => (
                <Typography
                  key={index}
                  color="white"
                  variant="body1"
                  component={Box}
                  display="flex"
                  my={3}
                >
                  <li />
                  {advantage}
                </Typography>
              ))}
            </BoxDescription>
          </Hidden>
        </RightDescriptionBox>
      ) : (
        <RightBannerBox>
          <LogoAdvisor
            width="40%"
            src={logoAdvisor}
            alt="Logo DPOnet Advisor"
          />
        </RightBannerBox>
      )}
    </RootBox>
  )
}

export default Auth
