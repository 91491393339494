const FAQ = 1
const DOCUMENTATION = 2
const LAST_UPDATES = 3

const KINDS = {
  FAQ: 'faq',
  DOCUMENTATION: 'documentation',
}

const VISUALIZATION = {
  c: 'collaborator',
  CC: 'collaborator_and_client',
  CA: 'collaborator_and_advisor',
}

const KINDS_LABELS = {
  F: 'FAQ',
  D: 'Documentação',
}

const CATEGORIES_TYPE = {
  faq: 'faq_category',
  doc: 'doc_category',
}

const SELECT_FAQS_DOCUMENTATION_KIND = [
  { id: KINDS.DOCUMENTATION, name: KINDS_LABELS.D },
  { id: KINDS.FAQ, name: KINDS_LABELS.F },
]

const faqsDocumentations = {
  CATEGORIES_TYPE,
  DOCUMENTATION,
  FAQ,
  KINDS_LABELS,
  KINDS,
  LAST_UPDATES,
  SELECT_FAQS_DOCUMENTATION_KIND,
  VISUALIZATION,
}

export default faqsDocumentations
