import { styled } from '@mui/material/styles'

const RootBox = styled('main')(({ theme }) => ({
  background: theme.palette.primary.main,
  backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main} 50%, ${theme.palette.secondary.main} 100%)`,
  display: 'grid',
  width: '100%',
  minHeight: '100vh',
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  [theme.breakpoints.only('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '5fr 7fr',
  },
}))

export default RootBox
