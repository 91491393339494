import { PersistentDrawer, MobileDrawer } from './components'

import { useDrawer } from 'hooks'

const Sidebar = () => {
  const { isSwipebleDrawer } = useDrawer()

  if (isSwipebleDrawer) return <MobileDrawer />

  return <PersistentDrawer />
}

export default Sidebar
