import { useState } from 'react'
import { Hidden, TablePagination } from '@mui/material'

import {
  ListPageStructure,
  LoadingFeedback,
  NoData,
  PageHead,
  PagePaper,
  Permitted,
} from 'components'
import { TagCreate, TagTable } from './components'

import { useFetch, useOrganization, usePagination } from 'hooks'

import service from 'service'
import permissionTagsConstants from 'constants/permissionTags'

const TagMain = () => {
  const [loading, setLoading] = useState(false)

  const { currentOrganization } = useOrganization()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const {
    response,
    loading: loadingTags,
    refresh,
  } = useFetch<{ params?: object; tagId?: string }>(
    service.dponet.tags.get,
    {
      params: {
        perPage,
        page,
      },
    },
    [currentOrganization?.id, perPage, page]
  )
  const organizationTags = response?.data?.organizationTags

  return (
    <ListPageStructure>
      <LoadingFeedback open={loadingTags || loading} />
      <PageHead
        name="Classificadores"
        actionButton={
          <Permitted tag={permissionTagsConstants.ORGANIZATION_TAG.MANAGE}>
            <TagCreate setLoading={setLoading} refresh={refresh} />
          </Permitted>
        }
      />
      {!organizationTags || organizationTags.length === 0 ? (
        <NoData title="Nenhum classificador foi encontrado." />
      ) : (
        <PagePaper>
          <TagTable tags={response?.data.organizationTags} refresh={refresh} />
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onPageChange={(_, page) => handleChangePage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </PagePaper>
      )}
    </ListPageStructure>
  )
}

export default TagMain
