import constants from 'constants/index'

const optionImpactIncidentHolderStatus = (
  idToFind: string | undefined
): string => {
  const result = constants.incidents.IMPACT_HOLDER.find(
    (element) => element.id === idToFind
  )

  return result?.name || '-'
}

export default optionImpactIncidentHolderStatus
