import permissionTagsConstants from './permissionTags'

const SHOW_TEXT = 'Visualizar'
const MANAGE_TEXT = 'Gerenciar'
const CREATE_TEXT = 'Criar'
const UPDATE_TEXT = 'Atualizar'
const DESTROY_TEXT = 'Excluir'

const ADVISOR_PROFILE_FUNCTIONALITIES = [
  {
    tag: permissionTagsConstants.ADVISOR_PROFILE.VIEW,
    name: SHOW_TEXT,
  },
  {
    tag: permissionTagsConstants.ADVISOR_PROFILE.MANAGE,
    name: MANAGE_TEXT,
  },
]

const COMPANY_FUNCTIONALITIES = [
  {
    tag: permissionTagsConstants.ORGANIZATION_COMPANY.VIEW,
    name: SHOW_TEXT,
  },
  {
    tag: permissionTagsConstants.ORGANIZATION_COMPANY.MANAGE,
    name: MANAGE_TEXT,
  },
]

const QUESTIONNAIRES_FUNCTIONALITIES = [
  {
    tag: permissionTagsConstants.QUESTIONNAIRES.VIEW,
    name: SHOW_TEXT,
  },
  {
    tag: permissionTagsConstants.QUESTIONNAIRES.MANAGE,
    name: MANAGE_TEXT,
  },
]

const INCIDENTS_FUNCTIONALITIES = [
  {
    tag: permissionTagsConstants.INCIDENTS.LIST,
    name: MANAGE_TEXT,
  },
]

const JURIDIC_TICKETS_FUNCTIONALITIES = [
  {
    tag: permissionTagsConstants.JURIDIC_TICKET.VIEW,
    name: SHOW_TEXT,
  },
  {
    tag: permissionTagsConstants.JURIDIC_TICKET.MANAGE,
    name: MANAGE_TEXT,
  },
]

const LEGAL_FRAMEWORK_FUNCTIONALITIES = [
  {
    tag: permissionTagsConstants.LEGAL_FRAMEWORK.MANAGE,
    name: MANAGE_TEXT,
  },
]

const ORGANIZATION_FUNCTIONALITIES = [
  {
    tag: permissionTagsConstants.ORGANIZATION.MANAGE,
    name: MANAGE_TEXT,
  },
  {
    tag: permissionTagsConstants.ORGANIZATION_USER.VIEW,
    name: 'Visualizar usuários',
  },
  {
    tag: permissionTagsConstants.ORGANIZATION_USER.MANAGE,
    name: 'Gerenciar usuários',
  },
]

const PRIVACY_PORTAL_CONFIGURATION_FUNCTIONALITIES = [
  {
    tag: permissionTagsConstants.PRIVACY_PORTAL_CONFIGURATION.MANAGE_THEME,
    name: 'Gerenciar Tema',
  },
  {
    tag: permissionTagsConstants.PRIVACY_PORTAL_CONFIGURATION.VIEW_FORM,
    name: 'Visualizar Formulário',
  },
  {
    tag: permissionTagsConstants.PRIVACY_PORTAL_CONFIGURATION.MANAGE_FORM,
    name: 'Gerenciar Formulário',
  },
]

const PROFILE_FUNCTIONALITIES = [
  {
    tag: permissionTagsConstants.ADVISOR_PROFILE.VIEW,
    name: SHOW_TEXT,
  },
  {
    tag: permissionTagsConstants.ADVISOR_PROFILE.MANAGE,
    name: MANAGE_TEXT,
  },
]

const SOLICITATION_TICKETS_FUNCTIONALITIES = [
  {
    tag: permissionTagsConstants.SOLICITATION_TICKET.VIEW,
    name: SHOW_TEXT,
  },
  {
    tag: permissionTagsConstants.SOLICITATION_TICKET.MANAGE,
    name: MANAGE_TEXT,
  },
]

const TAGS_FUNCTIONALITIES = [
  {
    tag: permissionTagsConstants.ORGANIZATION_TAG.VIEW,
    name: SHOW_TEXT,
  },
  {
    tag: permissionTagsConstants.ORGANIZATION_TAG.MANAGE,
    name: MANAGE_TEXT,
  },
]

const TASK_FUNCTIONALITIES = [
  {
    tag: permissionTagsConstants.TASKS.VIEW,
    name: SHOW_TEXT,
  },
  {
    tag: permissionTagsConstants.TASKS.CREATE,
    name: CREATE_TEXT,
  },
  {
    tag: permissionTagsConstants.TASKS.UPDATE,
    name: UPDATE_TEXT,
  },
  {
    tag: permissionTagsConstants.TASKS.DESTROY,
    name: DESTROY_TEXT,
  },
]

const OBSERVERS_ORGANIZATIONS_FUNCTIONALITIES = [
  {
    tag: permissionTagsConstants.OBSERVERS_ORGANIZATIONS
      .MANAGE_OBSERVERS_INCIDENT,
    name: 'Gerenciar observadores de um incidente',
  },
  {
    tag: permissionTagsConstants.OBSERVERS_ORGANIZATIONS
      .MANAGE_OBSERVERS_TICKET,
    name: 'Gerenciar observadores de um ticket',
  },
]

const LEGITIMATE_INTEREST_FUNCIONALITIES = [
  {
    tag: permissionTagsConstants.LEGITIMATE_INTEREST.LIST,
    name: 'Listar processos LIA',
  },
  {
    tag: permissionTagsConstants.LEGITIMATE_INTEREST.SHOW,
    name: 'Visualizar processos LIA',
  },
  {
    tag: permissionTagsConstants.LEGITIMATE_INTEREST.MANAGE,
    name: 'Gerenciar respostas LIA',
  },
]

export default {
  ADVISOR_PROFILE_FUNCTIONALITIES,
  COMPANY_FUNCTIONALITIES,
  INCIDENTS_FUNCTIONALITIES,
  JURIDIC_TICKETS_FUNCTIONALITIES,
  LEGAL_FRAMEWORK_FUNCTIONALITIES,
  LEGITIMATE_INTEREST_FUNCIONALITIES,
  OBSERVERS_ORGANIZATIONS_FUNCTIONALITIES,
  ORGANIZATION_FUNCTIONALITIES,
  PRIVACY_PORTAL_CONFIGURATION_FUNCTIONALITIES,
  PROFILE_FUNCTIONALITIES,
  QUESTIONNAIRES_FUNCTIONALITIES,
  SOLICITATION_TICKETS_FUNCTIONALITIES,
  TAGS_FUNCTIONALITIES,
  TASK_FUNCTIONALITIES,
}
