import { isEmpty } from 'lodash'

import { Permitted } from 'components'

import { PermittedOrganizationType } from './permittedOrganization.types'

const PermittedOrganization = ({
  children,
  tag,
  someTags,
  permissions,
}: PermittedOrganizationType) => {
  const isPermitted = (tag: string) => {
    return permissions?.find((funct) => funct.tag === tag)?.status ?? true
  }

  const somePermitted = (someTags: string[]) => {
    return someTags.map((tag) => isPermitted(tag)).includes(true)
  }

  if (!permissions) {
    return (
      <Permitted tag={tag} someTags={someTags}>
        {children}
      </Permitted>
    )
  }

  if (!someTags && !tag) {
    return <>{children}</>
  }

  if (someTags && !isEmpty(someTags) && somePermitted(someTags)) {
    return <>{children}</>
  }

  if (tag && isPermitted(tag)) {
    return <>{children}</>
  }

  return null
}

export default PermittedOrganization
