import { Fragment, useEffect, useRef, useState } from 'react'
import {
  ButtonGroup as ButtonGroupMaterial,
  ButtonProps,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { ButtonSubmit, ButtonOption } from './components'
import { MUI } from 'components'

import { ButtonGroupType } from './buttonGroup.types'

const ButtonGroup = ({
  options,
  title,
  type = 'button',
  fullWidth = false,
  buttonGroup,
  setButtonGroup,
  minTitleWidth = 'auto',
  tooltipPlacement = 'bottom',
  form,
  disabled = false,
  ...rest
}: ButtonGroupType & ButtonProps) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [selectedId, setSelectedId] = useState(buttonGroup)

  const handleMenuItemClick = (optionId: number) => {
    if (setButtonGroup) {
      setButtonGroup(optionId)
    }
    setSelectedId(optionId)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    if (buttonGroup !== selectedId) setSelectedId(buttonGroup)
  }, [buttonGroup])

  return (
    <Fragment>
      <ButtonGroupMaterial
        ref={anchorRef}
        aria-label="split button"
        fullWidth={fullWidth}
      >
        <Tooltip
          title={
            selectedId !== undefined
              ? options.find((option) => option.id === selectedId)?.name
              : ''
          }
          placement={tooltipPlacement}
        >
          <ButtonSubmit type={type} form={form} disabled={disabled} {...rest}>
            {title ? (
              <Fragment>
                <Typography
                  variant="button"
                  fontWeight={100}
                  pr={1}
                  minWidth={minTitleWidth}
                >
                  {title}:
                </Typography>
                <Typography
                  variant="button"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  noWrap
                >
                  {selectedId !== undefined
                    ? options.find((option) => option.id === selectedId)?.name
                    : ''}
                </Typography>
              </Fragment>
            ) : (
              <Typography
                variant="button"
                fontWeight={100}
                textOverflow="ellipsis"
                overflow="hidden"
                noWrap
              >
                {selectedId !== undefined
                  ? options.find((option) => option.id === selectedId)?.name
                  : ''}
              </Typography>
            )}
          </ButtonSubmit>
        </Tooltip>
        <ButtonOption
          size="small"
          aria-controls={open ? 'split-ButtonOption-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={disabled}
        >
          <KeyboardArrowDownIcon />
        </ButtonOption>
      </ButtonGroupMaterial>
      <MUI.Popper
        $zIndex={1}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options
                    .slice()
                    .sort((a, b) => (a.id ?? 0) - (b.id ?? 0))
                    .map((option) => (
                      <MenuItem
                        key={option?.id}
                        selected={option?.id === selectedId}
                        onClick={() =>
                          !!option?.id && handleMenuItemClick(option?.id)
                        }
                        disabled={option?.disabled}
                      >
                        <Tooltip title={option?.name}>
                          <Typography
                            variant="body2"
                            maxWidth={400}
                            textOverflow="ellipsis"
                            overflow="hidden"
                            noWrap
                          >
                            {option?.name}
                          </Typography>
                        </Tooltip>
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </MUI.Popper>
    </Fragment>
  )
}

export default ButtonGroup
