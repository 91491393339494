import axios from 'axios'

import service from 'service'
import { getBaseURL } from 'service/env'
import api from 'service/api'

const dponetAPI = api.create('dponet')
const baseURL = getBaseURL('dponet')

const createTicket = async ({ ...data }) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/advisors/tomticket_intermediator`,
    data,
    ...config,
  })
}

const getTomticketToken = async () => {
  return dponetAPI.get('/advisors/tomticket_intermediator/tomticket_token')
}

export default {
  createTicket,
  getTomticketToken,
}
