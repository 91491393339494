import { Box, Button, Grid, Typography, colors } from '@mui/material'

import { MUI } from 'components'

import dashboardPng from 'images/dashboard.png'

import { PreviewPagePlatformType } from './previewPagePlatform.types'
import {
  AlertOctagon as AlertOctagonIcon,
  BarChart2 as BarChartIcon,
  Clipboard as ClipboardIcon,
  File as FileIcon,
  Home as HomeIcon,
  Layers as LayersIcon,
  Play as PlayIcon,
  ShoppingCart as ShoppingCartIcon,
} from 'react-feather'

const PreviewPage = ({
  primaryColor,
  secondaryColor,
  primaryTextColor,
  secondaryTextColor,
  logoPath,
}: PreviewPagePlatformType) => {
  const menuItems = [
    {
      title: 'Marketplace',
      icon: <ShoppingCartIcon size={10} color={secondaryTextColor} />,
    },
    { title: 'Home', icon: <HomeIcon size={10} color={secondaryTextColor} /> },
    {
      title: 'Dashboard',
      icon: <BarChartIcon size={10} color={secondaryTextColor} />,
    },
    {
      title: 'Treinamentos',
      icon: <PlayIcon size={10} color={secondaryTextColor} />,
    },
    {
      title: 'Processos',
      icon: <LayersIcon size={10} color={secondaryTextColor} />,
    },
    {
      title: 'Questionários',
      icon: <FileIcon size={10} color={secondaryTextColor} />,
    },
    {
      title: 'Incidentes',
      icon: <AlertOctagonIcon size={10} color={secondaryTextColor} />,
    },
    {
      title: 'Relatórios',
      icon: <ClipboardIcon size={10} color={secondaryTextColor} />,
    },
  ]

  return (
    <Box bgcolor={colors.grey['A100']} boxShadow={1} borderRadius={1}>
      <Box display="flex" flexDirection="column" p={5}>
        <MUI.Box
          bgcolor={primaryColor}
          $borderTopRightRadius={5}
          $borderTopLeftRadius={5}
          boxShadow={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          pt={3}
        >
          <Box paddingLeft={3} ml={2}>
            <MUI.Img src={logoPath} $maxHeight={30} $maxWidth={50} />
          </Box>
          <Box>
            <Typography
              variant="body1"
              fontWeight={500}
              textTransform="uppercase"
              color={primaryTextColor}
              pb={3}
              pr={3}
            >
              Cabeçalho
            </Typography>
          </Box>
        </MUI.Box>
        <Grid container>
          <Grid item xs={2} bgcolor={primaryColor}>
            <Box bgcolor={primaryColor} width="100%">
              {menuItems.map((menuItem) => (
                <MUI.Box
                  key={menuItem.title}
                  p={1}
                  component={Button}
                  textTransform="capitalize"
                  display="flex"
                  justifyContent="start"
                  width="100%"
                  $hover={{
                    backgroundColor: secondaryColor,
                    color: secondaryTextColor,
                  }}
                >
                  {menuItem.icon}
                  <Typography
                    variant="caption"
                    color={secondaryTextColor}
                    paddingLeft={1}
                    textAlign="start"
                  >
                    {menuItem.title}
                  </Typography>
                </MUI.Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Box width="100%" paddingLeft={8}>
              <img src={dashboardPng} width="100%" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default PreviewPage
