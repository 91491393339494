import { Box, Button, Typography } from '@mui/material'
import routes from 'constants/routes'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(routes.app.organizations.all)
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
    >
      <Typography variant="h1" textAlign="center">
        404
      </Typography>
      <Typography variant="h4" textAlign="center">
        Página não encontrada!
      </Typography>
      <Typography variant="subtitle1" textAlign="center" gutterBottom>
        A página que você está procurando não existe ou pode ter acontecido
        algum erro.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleClick}
      >
        IR PARA HOME
      </Button>
    </Box>
  )
}

export default NotFound
