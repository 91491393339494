import { useEffect, useState } from 'react'

import { FilterInput, Filters } from 'components'

import { FilterHookType } from 'types/filters.types'

const IncidentsFilters = ({ filter }: { filter: FilterHookType }) => {
  const [shouldFetch, setShouldFetch] = useState<boolean>(false)

  useEffect(() => {
    if (filter.drawerOpen && !shouldFetch) setShouldFetch(true)
  }, [filter.drawerOpen])

  return (
    <Filters filter={filter}>
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Identificador', name: 'id' }}
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Data de criação inicial', name: 'createdAtGt' }}
        data-target="createdAtLt"
        data-min
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Data de criação final', name: 'createdAtLt' }}
        data-target="createdAtGt"
        data-max
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Data do incidente', name: 'incidentDate' }}
      />
    </Filters>
  )
}

export default IncidentsFilters
