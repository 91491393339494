import { formatters, incidents as incidentsHelpers } from 'helpers'
import constants from 'constants/index'
import theme from 'theme'

import components from 'utils/jsPDF/components'

import { RiskConsequenceType } from './RiskConsequence.types'

const RiskConsequence = ({ pdf, date, incidents }: RiskConsequenceType) => {
  const GAP = constants.jsPdf.GAP
  const incidentRiskDataHolder = incidents?.incidentRiskDataHolder

  pdf.addPage('a4', 'p')

  components.subtitle({ pdf, sub: 'Riscos e Consequências', positionY: GAP })
  components.lineWithDate({ pdf, date: date })

  components.paragraph({
    pdf,
    text: 'Riscos e Consequências aos Titulares dos Dados',
    positionY: GAP * 1.5,
    font: 'Roboto-Bold',
  })

  const incidentData = [
    [
      'Foi elaborado um Relatório de Impacto à Proteção de Dados Pessoais (RIPD) das atividades de tratamento afetadas pelo incidente?',
      formatters.convertBoolToString(
        incidentRiskDataHolder?.ripdPreparedIncident
      ),
    ],
    [
      'Qual a quantidade total de titulares cujos dados são tratados nas atividades afetadas pelo incidente?',
      incidentRiskDataHolder?.totalHolderAffected || '-',
    ],
    [
      'Se aplicável, descreva as categorias de titulares vulneráveis afetados:',
      incidentRiskDataHolder?.descriptionHolderCategoryAffected || '-',
    ],
    [
      'Quais as categorias de titulares foram afetados pelo incidentes',
      incidents?.dataOptionIncidents?.categoryHolderAffectedIncident
        ? incidents?.dataOptionIncidents?.categoryHolderAffectedIncident
            .map((categoryAffected) => categoryAffected.name + '\n')
            .join('')
        : '-',
    ],
    [
      'Informe a quantidade de titulares afetados, por categoria',
      incidentRiskDataHolder?.descriptionHolderCategoryAmountAffected || '-',
    ],
    [
      'Quais as prováveis consequências do incidente para os titulares?',
      incidents?.dataOptionIncidents?.probableConsequenceIncidentHolder
        ? incidents?.dataOptionIncidents?.probableConsequenceIncidentHolder
            .map((probableConsequence) => probableConsequence.name + '\n')
            .join('')
        : '-',
    ],
    [
      'Se cabível, descreva as prováveis consequências do incidente para cada grupo de titulares:',
      incidentRiskDataHolder?.descriptionPossibleConsequenceGroupHolder || '-',
    ],
    [
      'Qual o provável impacto do incidente sobre os titulares? (admite só uma marcação)',
      incidentsHelpers.optionImpactIncidentHolderStatus(
        incidentRiskDataHolder?.optionImpactIncidentHolder
      ) || '-',
    ],
    [
      'Se cabível, quais medidas foram adotadas para mitigação dos riscos causados pelo incidente aos titulares?',
      incidentRiskDataHolder?.measuresAdoptedMitigateIncidentHolder || '-',
    ],
  ]

  components.table({
    pdf,
    body: incidentData,
    startY: GAP * 1.6,
    cellWidth: 0.4,
  })

  components.numberPage({ pdf, color: theme.palette.primary.main })
}

export default RiskConsequence
