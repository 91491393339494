/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSlate } from 'slate-react'
import { differenceWith, isEqual, size } from 'lodash'
import { Box, Tooltip, Typography } from '@mui/material'

import { MUI } from 'components'
import { MoreOptionsMenu } from './components'

import { validates } from 'helpers'

import { ElementType, MarksType, ToolbarGroupsType } from 'types/slateJs.types'
import { ToolbarType } from './toolbar.types'

import palette from 'theme/palette'
import constants from 'constants/index'

const Toolbar = ({ controlName }: ToolbarType) => {
  const editor = useSlate()
  const {
    formState: { errors },
  } = useFormContext()

  const [hiddenGroups, setHiddenGroups] = useState<ToolbarGroupsType[][]>([])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleCloseMenu = () => setAnchorEl(null)

  const handleClick = (format: keyof MarksType, type: ElementType) => {
    if (type === 'mark') validates.slateJs.toggleMark(editor, format)
    else if (type === 'block') validates.slateJs.toggleBlock(editor, format)
    handleCloseMenu()
  }

  const filteredGroups = differenceWith(
    constants.slateJs.TOOLBAR_GROUPS,
    hiddenGroups,
    isEqual
  )

  return (
    <Box
      border={1}
      borderBottom={0}
      borderColor={
        errors?.[controlName] ? palette.error.main : palette.textfield.border
      }
    >
      <Box display="flex" flexWrap="wrap" gap={4} justifyContent="center">
        {filteredGroups?.map((group, index) => (
          <Box key={index} display="flex">
            {group?.map((element, elementIndex) => {
              const Logo = element?.Icon || Fragment
              const isActive =
                element.type === 'mark'
                  ? validates.slateJs.isMarkActive(
                      editor,
                      element.format as keyof MarksType
                    )
                  : validates.slateJs.isBlockActive(editor, element.format)

              return (
                <Tooltip
                  title={`${element.description} (${element?.shortcutKey})`}
                  key={elementIndex}
                >
                  <MUI.IconButton
                    $borderRadius={0}
                    onMouseDown={(event) => {
                      event.preventDefault()
                      handleClick(
                        element?.format as keyof MarksType,
                        element?.type
                      )
                    }}
                    $color={
                      isActive
                        ? errors?.[controlName]
                          ? palette.error.main
                          : 'black'
                        : 'gray'
                    }
                  >
                    {validates.slateJs.toolbarTitleLabel(element?.format) ? (
                      <Typography>
                        {validates.slateJs.toolbarTitleLabel(element?.format)}
                      </Typography>
                    ) : (
                      <Logo />
                    )}
                  </MUI.IconButton>
                </Tooltip>
              )
            })}
          </Box>
        ))}
        {/* Sera implementado validação na proxima atualização */}
        {size(hiddenGroups) > 0 && (
          <MoreOptionsMenu
            controlName={controlName}
            handleClick={handleClick}
            handleCloseMenu={handleCloseMenu}
            anchorElState={{ anchorEl, setAnchorEl }}
            hiddenGroups={hiddenGroups}
          />
        )}
      </Box>
    </Box>
  )
}

export default Toolbar
