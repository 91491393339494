import { Box, styled } from '@mui/material'

const CardTitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(4),
  width: '100%',
}))

export default CardTitleBox
