import { PostPrivacyPortalThemeInterface } from 'interfaces/privacyPortal.interfaces'

import api from 'service/api'

const dponetAPI = api.create('dponet')

const postTheme = async (
  privacyPortalTheme: PostPrivacyPortalThemeInterface
) => {
  return await dponetAPI.post(
    `/advisors/companies/${privacyPortalTheme.companyId}/privacy_portal/theme`,
    { privacyPortalTheme }
  )
}

const getTheme = async ({
  companyId = '',
}: {
  companyId?: number | string
}) => {
  return await dponetAPI.get(
    `/advisors/companies/${companyId}/privacy_portal/theme`
  )
}

const getQuestions = async ({
  companyId = '',
}: {
  companyId?: number | string
}) => {
  return await dponetAPI.get(`/advisors/question_option_tickets/${companyId}`)
}

const getAnswers = async ({
  answerId = '',
  questionId = '',
}: {
  questionId?: number | string
  answerId?: number | string
}) => {
  return await dponetAPI.get(
    `/advisors/answer_option_tickets/${answerId}/question/${questionId}`
  )
}

const updateQuestion = async ({
  questionId = '',
  questionOptionTicket = {},
}: {
  questionId?: number | string
  questionOptionTicket?: object
}) => {
  return await dponetAPI.put(
    `/advisors/question_option_tickets/${questionId}`,
    {
      questionOptionTicket,
    }
  )
}

const updateAnswer = async ({
  answerId = '',
  answerOptionTicket = {},
}: {
  answerId?: number | string
  answerOptionTicket?: object
}) => {
  return await dponetAPI.put(`/advisors/answer_option_tickets/${answerId}`, {
    answerOptionTicket,
  })
}

const createAnswer = async ({
  questionId = '',
  answerOptionTicket = {},
}: {
  questionId?: number | string
  answerOptionTicket?: object
}) => {
  return await dponetAPI.post(`/advisors/answer_option_tickets/${questionId}`, {
    answerOptionTicket,
  })
}

const createQuestion = async ({
  companyId = '',
  answerId = '',
  questionOptionTicket = {},
}: {
  companyId?: number | string
  answerId?: number | string
  questionOptionTicket?: object
}) => {
  return await dponetAPI.post(
    `/advisors/question_option_tickets/${companyId}/answer/${answerId}`,
    {
      questionOptionTicket,
    }
  )
}

const createSpecifyField = async ({
  answerId = '',
  answerOptionTicket = {},
}: {
  answerId?: number | string
  answerOptionTicket?: object
}) => {
  return await dponetAPI.post(
    `/advisors/answer_option_tickets/${answerId}/specific_field`,
    {
      answerOptionTicket,
    }
  )
}

const updateSpecifyField = async ({
  specifyFieldId = '',
  answerOptionTicket = {},
}: {
  specifyFieldId?: number | string
  answerOptionTicket?: object
}) => {
  return await dponetAPI.put(
    `/advisors/answer_option_tickets/specific_field/${specifyFieldId}`,
    {
      answerOptionTicket,
    }
  )
}

export default {
  postTheme,
  getTheme,
  getQuestions,
  getAnswers,
  updateQuestion,
  updateAnswer,
  createAnswer,
  createQuestion,
  createSpecifyField,
  updateSpecifyField,
}
