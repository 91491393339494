import { Box, Paper, colors } from '@mui/material'

import { PaperHeader, Chart } from 'components'

import { StatusPieChartType } from './statusPieChart.types'

const StatusPieChart = ({ questionnaireStats }: StatusPieChartType) => {
  const questionnairesNames = questionnaireStats.map(
    (questionnaireStat) => questionnaireStat.name
  )

  const sumNonCompliance = questionnaireStats.map((questionnaireStat) => {
    const { onTime, overdue, pending } = questionnaireStat.nonComplianceReports

    return onTime + overdue + pending
  })

  const colorsMui = Object.values(colors)
    .map((color) => Object.values(color).slice(-3))
    .flat()
    .sort()

  return (
    <Paper
      component={Box}
      p={2}
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
    >
      <PaperHeader title="RMCs pendentes por questionários" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
        height="100%"
        py={6}
      >
        <Chart.PieChart
          names={questionnairesNames}
          values={sumNonCompliance}
          colors={colorsMui}
          title="RMCs"
          legendShow
          heightValue={360}
          showLabels={false}
          fixedTitle
        />
      </Box>
    </Paper>
  )
}

export default StatusPieChart
