import { Box, MenuItem, SelectChangeEvent } from '@mui/material'

import { Avatar, Select } from './components'

import { useOrganization, useSnackbar } from 'hooks'
import { organizations as organizationsHelper } from 'helpers'
import { formatters } from 'helpers'

const SelectOrganization = () => {
  const { snackbar } = useSnackbar()

  const { availableOrganizations, loadOrganization, currentOrganization } =
    useOrganization()

  const handleSelectOrganization = (event: SelectChangeEvent<unknown>) => {
    if (event.target.value === undefined) return
    try {
      const selectedOrganization = organizationsHelper.selectOrganization(
        availableOrganizations,
        event.target.value as string
      )

      loadOrganization(selectedOrganization)
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    }
  }

  return (
    <Box width="100%" maxWidth={350} py={2}>
      {availableOrganizations?.length !== 0 && (
        <Select
          fullWidth
          size="small"
          defaultValue=""
          value={currentOrganization?.id || ''}
          onChange={handleSelectOrganization}
          startAdornment={
            currentOrganization && (
              <Avatar alt={currentOrganization?.name} src="/broken-image.jpg" />
            )
          }
        >
          {availableOrganizations?.map((organization) => (
            <MenuItem key={organization.id} value={String(organization.id)}>
              {organization.name}
            </MenuItem>
          ))}

          {availableOrganizations?.length === 0 && (
            <MenuItem>Nenhuma opção</MenuItem>
          )}
        </Select>
      )}
    </Box>
  )
}

export default SelectOrganization
