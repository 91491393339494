import { useRef } from 'react'
import { TextField } from '@mui/material'

import { CodeKeyInputType } from './codeKeyInput.types'

const CodeKeyInput = ({
  disabledInputs,
  index,
  value,
  handleFocus,
  handleInput,
  handleKeyDown,
  handlePaste,
}: CodeKeyInputType) => {
  const ref = useRef(null)
  return (
    <TextField
      disabled={disabledInputs}
      key={index}
      ref={ref}
      autoComplete="off"
      type="text"
      variant="outlined"
      inputProps={{
        id: index?.toString(),
        maxLength: 1,
        value: value,
        style: {
          textAlign: 'center',
        },
        onChange: (e) => handleInput(index, e.currentTarget.value),
        onFocus: handleFocus,
        onKeyDown: handleKeyDown,
        onPaste: handlePaste,
      }}
    />
  )
}

export default CodeKeyInput
