import { Controller, useForm } from 'react-hook-form'
import { Info as InfoIcon } from 'react-feather'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Paper,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { MUI, PaperHeader, Permitted } from 'components'

import { useSnackbar } from 'hooks'

import { formatters } from 'helpers'

import { GeneralInformationCardType } from './generalInformationCard.types'

import schema, { GeneralInformationCardSchemaType } from './schema'
import constants from 'constants/index'
import service from 'service'

const GeneralInformationCard = ({
  disableQuestionnaire = false,
  disableManagement = false,
  disableDefault,
  questionnaire,
  questionnaireId,
  refresh,
  setIsLoading,
}: GeneralInformationCardType) => {
  const { snackbar } = useSnackbar()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<GeneralInformationCardSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      kind: questionnaire?.kind || '',
      visible: questionnaire?.default
        ? questionnaire?.vinculatedCompaniesCount > 0
        : questionnaire?.visible || false,
    },
  })

  const onSubmit = async (data: GeneralInformationCardSchemaType) => {
    try {
      setIsLoading(true)

      await service.dponet.questionnaires.update({
        organizationQuestionnaireId: questionnaireId,
        questionnaire: { ...data },
      })

      snackbar.open({
        message: 'Informações gerais alterada com sucesso!',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Paper
      variant="outlined"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      id="general-information-questionnaires-form"
    >
      <PaperHeader
        title="Informações Gerais"
        actionButton={
          <Permitted tag={constants.permissionTags.QUESTIONNAIRES.MANAGE}>
            <Button
              variant="contained"
              form="general-information-questionnaires-form"
              type="submit"
              disabled={disableManagement}
            >
              Salvar
            </Button>
          </Permitted>
        }
        px={3}
      />
      <Box p={3} display="flex" flexDirection="column" gap={4}>
        <Controller
          render={({ field }) => (
            <FormControl component={Box} width="fit-content">
              <Box display="flex" flexDirection="row">
                <MUI.FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={!!field.value}
                      disabled={disableQuestionnaire || disableManagement}
                    />
                  }
                  label="Ativar questionário"
                  $marginRight={0}
                />
                <Box>
                  <Tooltip title="Para ativar um questionário, é necessário que haja uma questão previamente definida">
                    <IconButton size="small">
                      <InfoIcon size={12} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              {!!errors?.visible && (
                <FormHelperText error={!!errors?.visible}>
                  {errors?.visible?.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
          control={control}
          name="visible"
        />
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors?.kind}
              helperText={errors?.kind?.message}
              disabled={disableManagement || disableDefault}
              label="Título do questionário"
              type="text"
              variant="outlined"
              fullWidth
            />
          )}
          control={control}
          name="kind"
        />
      </Box>
    </Paper>
  )
}

export default GeneralInformationCard
