import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const BoxBase = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 4),
  width: 320,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}))

export default BoxBase
