import { NumberPageTitleType } from './numberPage.types'

import constants from 'constants/index'

const numberPage = ({ pdf, color }: NumberPageTitleType) => {
  const WIDTH = constants.jsPdf.WIDTH(pdf)
  const HEIGHT = constants.jsPdf.HEIGHT(pdf)
  const PADDING = constants.jsPdf.PADDING
  const FONT_SIZE = constants.jsPdf.FONT_SIZE
  const PAGE_NUMBER = pdf.getCurrentPageInfo().pageNumber

  pdf.setFont('Raleway-Bold')
  pdf.setFontSize(FONT_SIZE.PAGE_NUMBER)
  pdf.setTextColor(color)
  pdf.text(PAGE_NUMBER.toString(), WIDTH - PADDING, HEIGHT - PADDING / 2)
}

export default numberPage
