import { OrganizationType } from 'types/organization.types'
import { UserType } from 'types/user.types'

import organizationUsersConstants from 'constants/organizationUsers'
import organizationsConstants from 'constants/organizations'

const selectOrganization = (
  organizations: OrganizationType[],
  selectedOrganizationId: string | number | null
): OrganizationType | undefined => {
  return organizations?.find((organization) => {
    return Number(organization.id) === Number(selectedOrganizationId)
  })
}

const onlyActiveOrganizations = (organizations: OrganizationType[]) =>
  organizations.filter(
    (organization) =>
      organization.organizationUser.status ===
      organizationUsersConstants.ACTIVE_STATUS
  )

const observerOrganizations = (organizations: OrganizationType[]) =>
  organizations.filter(
    (organization) => organization.kind === organizationsConstants.KIND_OBSERVER
  )

const dpoOrganizations = (organizations: OrganizationType[]) =>
  organizations.filter(
    (organization) => organization.kind === organizationsConstants.KIND_DPO
  )

const authorOrganizations = (
  organizations: OrganizationType[],
  user: UserType | undefined
) => organizations.filter((organization) => organization.author.id === user?.id)

export default {
  selectOrganization,
  onlyActiveOrganizations,
  observerOrganizations,
  dpoOrganizations,
  authorOrganizations,
}
