import { useContext } from 'react'

import { SnackbarContext } from 'contexts'

const useSnackbar = () => {
  const context = useContext(SnackbarContext)

  if (context === undefined) {
    throw new Error(
      'useSnackbar must be used within a SnackbarContext.Provider'
    )
  }

  return context
}

export default useSnackbar
