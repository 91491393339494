import formatters from '.'
import { regex } from 'helpers'

const identificationDocument = (document: string) => {
  const clearedDocument = regex.onlyNumbers(document)
  if (clearedDocument.length > 11) {
    return formatters.cnpj(clearedDocument)
  }
  return formatters.cpf(clearedDocument)
}

export default identificationDocument
