import { Fragment, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import qs from 'query-string'

import { AnswerCard, SubAnswerCard } from './components'
import { DefaultPageStructure, LoadingFeedback, PageHead } from 'components'

import { useFetch } from 'hooks'

import { GetAnswerOptionInterface } from 'interfaces/privacyPortal.interfaces'

import constants from 'constants/index'
import routes from 'constants/routes'
import service from 'service'

const PrivacyPortalAnswerEdit = () => {
  const [openEditQuestionModal, setOpenEditQuestionModal] = useState(false)
  const [optionsMenuType, setOptionsMenuType] = useState<
    'suboption' | 'specify' | undefined
  >(undefined)
  const [disableSwitch, setDisableSwitch] = useState(false)

  const { companyId, questionId, answerId } = useParams()
  const query = {
    companyId: companyId,
    tab: constants.privacyPortal.FORM_CONFIGURATION_ID,
  }

  const { response, loading, refresh } = useFetch(
    service.dponet.privacyPortal.getAnswers,
    {
      questionId,
      answerId,
    }
  )

  const AnswerOptionTickets: GetAnswerOptionInterface =
    response?.data?.answerOptionTicket

  return (
    <DefaultPageStructure>
      <LoadingFeedback open={loading} />
      {!loading && (
        <Fragment>
          <PageHead
            name={AnswerOptionTickets?.questionOptionTicket.title}
            returnedUrl={`${
              routes.app.privacyPortal.serviceSettings
            }?${qs.stringify(query)}`}
          />
          <Grid container gap={5}>
            <Grid item xs={12}>
              <AnswerCard
                companyId={companyId}
                answerOptionTickets={AnswerOptionTickets}
                disableSwitch={disableSwitch}
                questionKind={AnswerOptionTickets?.questionOptionTicket?.kind}
                setDisableSwitch={setDisableSwitch}
                setOpenEditQuestionModal={setOpenEditQuestionModal}
                setOptionsMenuType={setOptionsMenuType}
              />
            </Grid>
            {optionsMenuType && (
              <Grid item xs={12}>
                <SubAnswerCard
                  companyId={companyId}
                  answerId={answerId}
                  answerOptionTickets={AnswerOptionTickets}
                  optionsMenuType={optionsMenuType}
                  openEditQuestionModal={openEditQuestionModal}
                  setOpenEditQuestionModal={setOpenEditQuestionModal}
                  setDisableSwitch={setDisableSwitch}
                  refresh={refresh}
                />
              </Grid>
            )}
          </Grid>
        </Fragment>
      )}
    </DefaultPageStructure>
  )
}

export default PrivacyPortalAnswerEdit
