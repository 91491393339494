import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { AppRoute, AuthenticationRoute } from 'routes'
import {
  AuthProvider,
  OrganizationProvider,
  PermissionProvider,
} from 'providers'

const Router = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/app/*"
            element={
              <OrganizationProvider>
                <PermissionProvider>
                  <AppRoute />
                </PermissionProvider>
              </OrganizationProvider>
            }
          />
          <Route path="/*" element={<AuthenticationRoute />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default Router
