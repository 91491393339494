import { Box, BoxProps, Container } from '@mui/material'

import { DefaultPageStructureType } from './defaultPageStructure.types'

const DefaultPageStructure = ({
  children,
  ...rest
}: DefaultPageStructureType & BoxProps) => {
  return (
    <Container component={Box} height="100%">
      <Box paddingTop={12} paddingBottom={12} height="100%" {...rest}>
        {children}
      </Box>
    </Container>
  )
}

export default DefaultPageStructure
