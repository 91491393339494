import { ratio } from 'utils/jsPDF/helpers'

import { TitleType } from './title.types'

import constants from 'constants/index'
import theme from 'theme'

const title = ({
  pdf,
  text,
  positionY = ratio(240),
  textColor = theme.palette.primary.main,
}: TitleType) => {
  const PADDING = constants.jsPdf.PADDING
  const FONT_SIZE = constants.jsPdf.FONT_SIZE

  pdf.setFont('Raleway-Bold')
  pdf.setFontSize(FONT_SIZE.CHAPTER_TITLE)
  pdf.setTextColor(textColor)
  pdf.text(text, PADDING, positionY, undefined, 'left')
}

export default title
