import createTheme from '@mui/material/styles/createTheme'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    neutral: Palette['primary']
    tertiary: Palette['primary']
    support: Palette['primary']
  }

  interface PaletteOptions {
    neutral?: PaletteOptions['primary']
    tertiary?: PaletteOptions['primary']
    support?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
    tertiary: true
    support: true
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    neutral: true
    tertiary: true
    support: true
  }
}

const refTheme = createTheme()

export default {
  success: {
    main: '#16A63C',
  },
  info: {
    main: '#376FB6',
  },
  error: {
    main: '#E83A49',
  },
  warning: {
    main: '#FCC109',
  },
  primary: {
    main: '#233154',
    // light: will be calculated from palette.primary.main,
    // dark: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
  },
  secondary: {
    main: '#0763A0',
  },
  tertiary: refTheme.palette.augmentColor({
    color: {
      main: '#80B92B',
    },
  }),
  support: refTheme.palette.augmentColor({
    color: {
      main: '#BA2980',
    },
  }),
  neutral: refTheme.palette.augmentColor({
    color: {
      main: '#546E7A',
    },
  }),
  background: {
    default: '#F4F6F8',
    paper: '#FFF',
  },
  custom: {
    light: '#F4F6F8',
    main: '#6FA2CF',
    dark: '#657786',
    superDark: '#2E373E',
  },
  platform: {
    lightGreen: '#87F3A4',
  },
  ticketStatus: {
    waitingConfirmation: '#233154',
    awaitingConfirmationLegitimacy: '#376F96',
    awaitingConfirmationIdentity: '#0097a7',
    awaitingCompanyReply: '#00897b',
    awaitingReplyValidation: '#ff8f00',
    replied: '#3f51b5',
  },
  paper: {
    border: '#E0E0E0',
  },
  textfield: {
    border: '#0000003A',
  },
}
