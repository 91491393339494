import { Button, Grid, Typography } from '@mui/material'
import { BasicDialog } from 'components'

import { AttributeRowType, ViewTaskDialogType } from './viewTaskDialog.types'
import { formatters, tasks } from 'helpers'

const AttributeRow = ({ title, description, ...props }: AttributeRowType) => {
  return (
    <Grid item {...props}>
      <Typography variant="subtitle1">
        <b>{title}</b>
      </Typography>
      <Typography variant="body1">{description || '-'}</Typography>
    </Grid>
  )
}

const ViewTaskDialog = ({ open, setOpen, task }: ViewTaskDialogType) => {
  const onClose = () => setOpen(false)

  return (
    <BasicDialog
      title="Visualizar atividade"
      open={open}
      dialogActions={
        <Button color="primary" onClick={onClose}>
          Fechar
        </Button>
      }
    >
      <Grid container spacing={3}>
        <AttributeRow
          {...{ xs: 12 }}
          title="Empresa"
          description={task?.company?.name}
        />
        <AttributeRow
          {...{ xs: 12 }}
          title="Título da atividade"
          description={task?.name}
        />
        <AttributeRow
          {...{ xs: 6 }}
          title="Responsável"
          description={task?.responsible?.name}
        />
        <AttributeRow
          {...{ xs: 6 }}
          title="Status"
          description={task?.status ? tasks.translateStatus(task?.status) : '-'}
        />
        <AttributeRow
          {...{ xs: 6 }}
          title="Data de inicio"
          description={
            task?.startDate
              ? formatters.date.ptBrFormatDate(task?.startDate)
              : '-'
          }
        />
        <AttributeRow
          {...{ xs: 6 }}
          title="Prazo"
          description={
            task?.startDate
              ? formatters.date.ptBrFormatDate(task?.dueDate)
              : '-'
          }
        />
        <AttributeRow
          {...{ xs: 12 }}
          title="Descrição"
          description={task?.description}
        />
        <AttributeRow
          {...{ xs: 12 }}
          title="Observações"
          description={task?.observations}
        />
      </Grid>
    </BasicDialog>
  )
}

export default ViewTaskDialog
