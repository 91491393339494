import { Fragment, useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { uniq } from 'lodash'

import { CheckboxItem } from './components'

import { LegalFrameworkType } from 'types/legalFramework.types'
import { LegalFrameworksChecklistType } from './legalFrameworksChecklist.types'

import theme from 'theme'
import legalFrameworksHelper from 'helpers/dataProcesses/legalFrameworks'
import legalFrameworkConstants from 'constants/legalFramework'

const LegalFrameworksChecklist = ({
  dataProcess,
  legalFrameworkByDataTypes,
  selectedLegalFrameworks,
  setSelectedLegalFrameworks,
  selectedLegalFrameworksNames,
  setSelectedLegalFrameworksNames,
}: LegalFrameworksChecklistType) => {
  const dataTypeActive = (dataType: 1 | 2 | 3) =>
    legalFrameworksHelper.dataTypeActive(dataProcess, dataType)
  const activeMinorDataType =
    legalFrameworksHelper.activeMinorDataType(dataProcess)

  const handleSelectAll = (items: LegalFrameworkType[] = []) => {
    setSelectedLegalFrameworksNames(
      uniq([...selectedLegalFrameworksNames, ...items.map((item) => item.name)])
    )
    setSelectedLegalFrameworks(
      uniq([...selectedLegalFrameworks, ...items.map((item) => item.id)])
    )
  }

  const handleCheckItem = (id: number, name: string) => {
    const itemExists = !!selectedLegalFrameworks?.find((item) => item === id)

    if (itemExists) {
      setSelectedLegalFrameworksNames(
        selectedLegalFrameworksNames?.filter((item) => item !== name)
      )
      return setSelectedLegalFrameworks(
        selectedLegalFrameworks?.filter((item) => item !== id)
      )
    }

    setSelectedLegalFrameworksNames([...selectedLegalFrameworksNames, name])
    return setSelectedLegalFrameworks([...selectedLegalFrameworks, id])
  }

  const definePrediction = (legalFramework: LegalFrameworkType) => {
    return legalFrameworksHelper.predictionByLegalFramework(
      legalFramework,
      dataProcess?.predictions
    )?.prediction
  }

  const isLegalFrameworkDefaultChecked = (
    legalFramework: LegalFrameworkType
  ) => {
    const prediction = legalFrameworksHelper.predictionByLegalFramework(
      legalFramework,
      dataProcess?.predictions
    )?.prediction
    const activeDataType = legalFrameworksHelper.dataTypeActive(
      dataProcess,
      legalFramework.dataType
    )

    if (legalFrameworksHelper.parentConsent(legalFramework, activeDataType)) {
      return true
    }

    return prediction && parseFloat(prediction) > 0.7 && activeDataType
  }

  useEffect(() => {
    legalFrameworkConstants.DATA_TYPES_VALUES?.map((dataType) => {
      const legalFrameworks = legalFrameworkByDataTypes[dataType].items || []

      legalFrameworks.map((legalFramework) => {
        if (isLegalFrameworkDefaultChecked(legalFramework)) {
          setSelectedLegalFrameworksNames((selectedLegalFrameworksNames) => [
            ...selectedLegalFrameworksNames,
            legalFramework.name,
          ])
          setSelectedLegalFrameworks((selectedLegalFrameworks) => [
            ...selectedLegalFrameworks,
            legalFramework.id,
          ])
        }
      })
    })
  }, [dataProcess?.predictions, legalFrameworkByDataTypes])

  return (
    <>
      {Object.values(legalFrameworkByDataTypes)?.map(
        (legalFrameworkDataType) => (
          <Fragment key={legalFrameworkDataType?.label}>
            <Box
              mt={2}
              mb={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pl={5.5}
            >
              <Typography variant="body1" fontWeight="bold">
                {legalFrameworkDataType?.label}
              </Typography>
              <Button
                variant="text"
                color="primary"
                onClick={() => handleSelectAll(legalFrameworkDataType?.items)}
              >
                <Typography
                  variant="caption"
                  fontWeight={500}
                  textTransform="capitalize"
                  color={theme.palette.secondary.light}
                >
                  Selecionar todos
                </Typography>
              </Button>
            </Box>

            {legalFrameworkDataType?.items?.map((legalFramework) => (
              <CheckboxItem
                key={legalFramework?.id}
                prediction={definePrediction(legalFramework)}
                item={legalFramework}
                defaultChecked={selectedLegalFrameworks.includes(
                  legalFramework?.id
                )}
                handleCheck={handleCheckItem}
                disabled={!dataTypeActive(legalFramework.dataType)}
                titularMinor={activeMinorDataType}
              />
            ))}
          </Fragment>
        )
      )}
    </>
  )
}

LegalFrameworksChecklist.defaultProps = {
  predictions: [],
  legalFrameworkByDataTypes: [],
  selectedLegalFrameworks: [],
  setSelectedLegalFrameworks: () => {},
}

export default LegalFrameworksChecklist
