import { useState } from 'react'
import { Play as PlayIcon, Pause as PauseIcon } from 'react-feather'
import { Box, CircularProgress, IconButton, colors } from '@mui/material'

import { useSnackbar } from 'hooks'

import { AvatarType } from './avatar.types'

import { formatters } from 'helpers'
import service from 'service'
import dataProcessConstants from 'constants/dataProcess'

const Avatar = ({
  changeDataProcessStatus,
  dataProcessId,
  status,
}: AvatarType) => {
  const [loading, setLoading] = useState(false)
  const { snackbar } = useSnackbar()

  const isWaitingReview =
    status === dataProcessConstants.WAITING_REVIEW_STATUS_ID
  const isReviewing = status === dataProcessConstants.REVIEWING_STATUS_ID

  const handlePlayPause = async () => {
    try {
      setLoading(true)
      const newStatus = isWaitingReview
        ? dataProcessConstants.REVIEWING_STATUS_ID
        : dataProcessConstants.WAITING_REVIEW_STATUS_ID
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId,
        statusId: newStatus,
      })
      changeDataProcessStatus(newStatus)
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  if (!isWaitingReview && !isReviewing) {
    return <></>
  }

  if (loading) {
    return (
      <Box px={1.5}>
        <CircularProgress size={30} />
      </Box>
    )
  }

  return (
    <IconButton onClick={handlePlayPause}>
      {isWaitingReview ? (
        <PlayIcon size={30} color={colors.grey[300]} />
      ) : (
        <PauseIcon size={30} color={colors.grey[300]} />
      )}
    </IconButton>
  )
}

Avatar.defaultProps = {
  changeDataProcessStatus: () => {},
}

export default Avatar
