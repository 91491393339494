import { useContext } from 'react'

import { PermissionContext } from 'contexts'

const usePermission = () => {
  const context = useContext(PermissionContext)
  if (context === undefined) {
    throw new Error(
      'usePermission must be used within a PermissionContext.Provider'
    )
  }

  return context
}

export default usePermission
