import { Box } from '@mui/material'

import { InformativeBox, LinearProgressWithTitleAndLabel } from 'components'

import { ComplianceByQuestionnaireType } from './complianceByQuestionnaire.types'

const ComplianceByQuestionnaire = ({
  questionnairiesStats,
}: ComplianceByQuestionnaireType) => {
  return (
    <InformativeBox title="Conformidade por questionário">
      <Box display="flex" flexDirection="column" gap={3}>
        {questionnairiesStats.map((questionnairiesStat) => (
          <Box key={questionnairiesStat.name}>
            <LinearProgressWithTitleAndLabel
              thickness={2}
              borderRadius={1}
              color="info"
              title={questionnairiesStat.name}
              value={questionnairiesStat.gapCompliance}
            />
          </Box>
        ))}
      </Box>
    </InformativeBox>
  )
}

export default ComplianceByQuestionnaire
