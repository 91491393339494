import legalFrameworkConstants from 'constants/legalFramework'

const legalFrameworkValueByDataType = (dataType: number) => {
  switch (dataType) {
    case legalFrameworkConstants.TYPE_PERSONAL_AND_FINANCIAL:
      return legalFrameworkConstants.TYPE_PERSONAL_AND_FINANCIAL_VALUE

    case legalFrameworkConstants.TYPE_MINOR:
      return legalFrameworkConstants.TYPE_MINOR_VALUE

    case legalFrameworkConstants.TYPE_SENSITIVE:
      return legalFrameworkConstants.TYPE_SENSITIVE_VALUE

    default:
      return ''
  }
}

export default legalFrameworkValueByDataType
