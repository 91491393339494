import { Alert, Grid, Typography } from '@mui/material'

import { Card } from 'components'
import { Report } from 'views/IncidentManegements/components'

import { useIncidentManegement, useOrganization } from 'hooks'

const DetalingStep = () => {
  const { incident } = useIncidentManegement()
  const { currentOrganization } = useOrganization()

  return (
    <Card title="Detalhamento">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {incident?.simplifiedForm ? (
            <Alert severity="info" variant="filled">
              O formulário de detalhamento não foi preenchido!
            </Alert>
          ) : (
            <>
              <Typography variant="body1" gutterBottom>
                O relatório completo é o documento a ser utilizado caso seja
                necessário notificar a ANPD.
              </Typography>

              {incident && (
                <Report.CompleteIncident
                  organization={currentOrganization}
                  incidents={incident}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

export default DetalingStep
