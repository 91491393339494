import { useRef, useState } from 'react'
import { Box, Button, Grid, TextField } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import { MUI } from 'components'

import { useAuth, useSnackbar } from 'hooks'

import { formatters } from 'helpers'

import { FormType } from './form.types'
import { reverse } from 'named-urls'

import routes from 'constants/routes'
import service from 'service'
import { getGoogleRecaptchaToken } from 'service/env'

import schema, { LoginFormType } from './schema'
import theme from 'theme'

const Form = ({ isLoading, setLoading }: FormType) => {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)
  const [recaptcha, setRecaptcha] = useState<string | null>()
  const navigate = useNavigate()
  const { snackbar } = useSnackbar()

  const envProduction = process.env.REACT_APP_API === 'production'

  const { loadData } = useAuth()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const resetRecaptcha = () => {
    recaptchaRef?.current?.reset()
    setRecaptcha(undefined)
  }

  const expiredRecaptcha = () => {
    setValue('recaptcha', '')
    setRecaptcha(null)
  }

  const handleRecaptcha = (token: string | null) => {
    if (token) {
      setValue('recaptcha', token)
      setRecaptcha(token)
    }
  }

  const onSubmit = async (data: LoginFormType) => {
    try {
      setLoading(true)
      const response = await service.dponet.auth.login(data)
      const accessToken = response?.data?.accessToken
      if (accessToken) {
        navigate(
          reverse(routes.auth.twoFactorAuthentication, { accessToken }),
          { state: { email: data.email } }
        )
      } else {
        await loadData()

        navigate(routes.app.organizations.all)
      }
    } catch (error) {
      console.error(error)
      if (envProduction) {
        resetRecaptcha()
      }
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.email}
                helperText={<>{errors?.email?.message}</>}
                label="E-mail"
                type="text"
                fullWidth
              />
            )}
            name="email"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.password}
                helperText={<>{errors?.password?.message}</>}
                label="Senha"
                type="password"
                autoComplete="on"
                fullWidth
              />
            )}
            name="password"
            control={control}
          />
          <Box width="100%" mt={1}>
            <MUI.Link
              $textDecoration="none"
              $color={theme.palette.neutral.main}
              to={routes.auth.recoveryPassword}
            >
              Esqueceu a sua senha?
            </MUI.Link>
          </Box>
        </Grid>
        {envProduction && (
          <Grid item xs={12}>
            {
              <Controller
                render={({ field }) => (
                  <ReCAPTCHA
                    {...field}
                    ref={recaptchaRef}
                    sitekey={getGoogleRecaptchaToken()}
                    onChange={(token) => handleRecaptcha(token)}
                    onExpired={expiredRecaptcha}
                    size="normal"
                  />
                )}
                name="recaptcha"
                control={control}
              />
            }
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={(!recaptcha && envProduction) || isLoading}
          >
            Acessar
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default Form
