import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Button } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { TextEditor } from 'components'
import { TicketAttachment } from './components'

import { TicketFormType } from './ticketForm.types'

import { useSnackbar } from 'hooks'

import { formatters } from 'helpers'
import service from 'service'
import schema, { TicketFormFormType } from './schema'
import routes from 'constants/routes'

const TicketForm = ({ ticketId, refresh }: TicketFormType) => {
  const [attachments, setAttachments] = useState<File[]>([])

  const navigate = useNavigate()

  const { snackbar } = useSnackbar()
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      content: '',
    },
  })

  const { handleSubmit, reset } = formMethods

  const onSubmit = async (data: TicketFormFormType) => {
    try {
      await service.dponet.ticket.postMyTicketMessages({
        ticketId,
        ticketMessages: {
          ...data,
          attachments: attachments,
        },
      })

      snackbar.open({
        message: 'Mensagem enviada com sucesso!',
        variant: 'success',
      })

      reset()
      refresh()

      setAttachments([])
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  const handleGoBack = () => {
    navigate(routes.app.myTickets.all)
  }

  return (
    <Box mt={4}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextEditor />
          <Box mt={4}>
            <TicketAttachment
              attachments={attachments}
              setAttachments={setAttachments}
            />
          </Box>

          <Box mt={4} display="flex" justifyContent="flex-end" gap={3}>
            <Button variant="outlined" onClick={handleGoBack}>
              Voltar
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Enviar
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}

export default TicketForm
