export default {
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        minHeight: '48px !important',
        '.Mui-expanded': {
          margin: '0px !important',
        },
      },
    },
  },
}
