import { useState } from 'react'
import { Box, Button, IconButton, Menu } from '@mui/material'
import { MoreVertical as MoreVerticalIcon } from 'react-feather'

import { MenuButtonType } from './menuButton.types'

const MenuButton = ({
  id,
  icon = <MoreVerticalIcon />,
  title,
  children,
}: MenuButtonType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const openMenu = Boolean(anchorEl)
  const buttonId = `${id}`
  const menuId = `basic-menu-${buttonId}`

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      {!title && icon && (
        <IconButton
          id={buttonId}
          aria-controls={openMenu ? menuId : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
          onClick={handleOpenMenu}
        >
          {icon}
        </IconButton>
      )}

      {title && (
        <Button
          id={buttonId}
          aria-controls={openMenu ? menuId : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
          onClick={handleOpenMenu}
        >
          {title}
        </Button>
      )}

      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{ 'aria-labelledby': buttonId }}
      >
        {children}
      </Menu>
    </Box>
  )
}

export default MenuButton
