import { Box, colors } from '@mui/material'
import { styled } from '@mui/material/styles'

const Link = styled(Box)(() => ({
  textDecoration: 'none',
  color: colors.lightBlue[200],
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export default Link
