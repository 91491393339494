import { ChipColorType } from 'types/materialUI.types'

import {
  ChipStatusType,
  ConfigurationOptionsType,
} from 'constants/privacyPortal'

import constants from 'constants/index'

const formName = (tabId: ConfigurationOptionsType) => {
  return constants.privacyPortal.USE_FORM_NAME[tabId] || 'not-data'
}

const labelTranslation = (status: ChipStatusType) => {
  return constants.privacyPortal.CHIP_STATUS_TRANSLATION[status] || '-'
}

const statusColor = (status: ChipStatusType) => {
  return (
    (constants.privacyPortal.CHIP_STATUS_COLOR[status] as ChipColorType) ||
    'default'
  )
}

const switchTranslation = (switchValue: boolean) => {
  return (
    constants.privacyPortal.SWITCH_STATUS_TRANSLATION[String(switchValue)] ||
    'inactive'
  )
}

const statusTranslationToSwitch = (status: ChipStatusType) => {
  return constants.privacyPortal.STATUS_TRANSLATION_TO_SWITCH[status] || false
}

export default {
  formName,
  labelTranslation,
  statusColor,
  switchTranslation,
  statusTranslationToSwitch,
}
