import { Autocomplete, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

import service from 'service'

import { UserCompanyType } from 'types/userCompany.types'
import {
  CompanyAutocompleteType,
  AutoselectType,
} from './userCompanyAutocomplete.types'

const CompanyAutocomplete = ({
  error,
  helperText,
  label,
  isUserCompany,
  companyId,
  ...rest
}: CompanyAutocompleteType) => {
  const [loading, setLoading] = useState(false)

  const [usersArray, setUsersArray] = useState<AutoselectType[]>([])

  const getUserCompanies = async () => {
    if (companyId) {
      const responseCompanies = await service.dponet.company.userCompanies({
        companyId,
      })

      const userCompanies: UserCompanyType[] =
        responseCompanies?.data?.userCompanies

      const activeuserCompanies =
        userCompanies?.map((organizationCompany) => {
          return {
            label: organizationCompany.userName,
            userId: isUserCompany
              ? organizationCompany.id
              : organizationCompany.userId,
          }
        }) ?? []
      return activeuserCompanies
    }

    return []
  }

  useEffect(() => {
    const searchCompanies = async () => {
      await getUserCompanies().then((userCompanies) =>
        setUsersArray(userCompanies)
      )
      setLoading(false)
    }
    if (!companyId) {
      return
    }
    setLoading(true)

    const timer = setTimeout(searchCompanies, 500)
    return () => clearTimeout(timer)
  }, [companyId])

  return (
    <Autocomplete
      {...rest}
      noOptionsText="Selecione uma empresa "
      options={companyId ? usersArray : []}
      isOptionEqualToValue={(option, value) => option?.userId === value?.userId}
      loading={loading}
      loadingText="Carregando..."
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || 'Usários'}
          margin="dense"
          error={!!error}
          helperText={helperText}
          InputLabelProps={{
            children: null,
          }}
        />
      )}
      onChange={(_, data: AutoselectType | null) => {
        return rest.onChange(data)
      }}
    />
  )
}

export default CompanyAutocomplete
