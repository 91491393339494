import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { Link as LinkRouterDom } from 'react-router-dom'
import { TLength } from 'types/materialUI.types'

const Link = styled(LinkRouterDom, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $textDecoration?: Property.TextDecoration<TLength> | undefined
  $color?: Property.Color | undefined
  $cursor?: Property.Cursor | undefined
}>(({ theme, $textDecoration, $color, $cursor }) => ({
  textDecoration: $textDecoration,
  color: $color || theme.palette.neutral.main,
  cursor: $cursor,
  fontFamily: theme.typography.fontFamily,
}))

export default Link
