import service from 'service'
import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const resend = async (data: object) => {
  return dponetAPI.post('/advisors/2FA/resend', data)
}

const validateCode = async (data: object) => {
  const response = await dponetAPI.post('/advisors/2FA/validate', data)
  const token = response?.data?.authToken

  if (token) {
    service.dponet.auth.setToken(token)
    return response
  }
}

export default {
  resend,
  validateCode,
}
