import { useState } from 'react'
import { Button, Typography } from '@mui/material'

import { BasicDialog, LoadingFeedback } from 'components'

import { CompanyMatrixDialogType } from './companyMatrixDialog.types'
import service from 'service'
import { useSnackbar } from 'hooks'
import { formatters } from 'helpers'

const CompanyMatrixDialog = ({
  organizationCompany,
  open,
  onClose,
  refresh,
}: CompanyMatrixDialogType) => {
  const [loading, setLoading] = useState(false)

  const { snackbar } = useSnackbar()

  const removeCompanyMatrix = async () => {
    try {
      setLoading(true)

      await service.dponet.organizationCompany.removeCompanyMatrix({
        organizationCompanyId: organizationCompany.id,
      })

      onClose()
      refresh()

      snackbar.open({
        message: 'Empresa matriz desvinculada com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title="Remover empresa matriz"
      dialogActions={
        <>
          <Button color="primary" onClick={onClose}>
            CANCELAR
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={removeCompanyMatrix}
          >
            DESVINCULAR
          </Button>
        </>
      }
    >
      <LoadingFeedback open={loading} />

      <Typography variant="body1">
        Você tem certeza que deseja desvincular a empresa matriz&nbsp;
        <strong>{organizationCompany?.companyMatrix?.networkName}</strong> da
        empresa&nbsp;
        <strong>{organizationCompany?.company?.name}</strong>?
      </Typography>
    </BasicDialog>
  )
}

export default CompanyMatrixDialog
