import tasks, { TaskStatusType } from 'constants/tasks'

type ChipColor =
  | 'default'
  | 'info'
  | 'success'
  | 'error'
  | 'primary'
  | 'secondary'
  | 'warning'

const statusColor = (status: TaskStatusType): ChipColor => {
  return (tasks.CHIP_STATUS_COLOR[status] as ChipColor) || 'default'
}

const translateStatus = (status: TaskStatusType): string => {
  return tasks.STATUS_NAME[status]
}

export default { statusColor, translateStatus }
