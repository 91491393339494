import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import IncidentManegementContext from 'contexts/IncidentManegementContext'

import { IncidentManegementProviderType } from './incidentManegementProvider.types'

import routes from 'constants/routes'
import { IncidentsType } from 'types/incidents.types'
import { isUndefined } from 'lodash'

const IncidentManegementProvider = ({
  children,
}: IncidentManegementProviderType) => {
  const navigate = useNavigate()

  const [activeStep, setActiveStep] = useState(0)
  const [lastActiveStep, setLastActiveStep] = useState(0)
  const [wantsToAdvanceStep, setWantsToAdvanceStep] = useState(false)
  const [wantsToReturnStep, setWantsToReturnStep] = useState(false)

  const [incident, setIncident] = useState<IncidentsType>()

  const handleAdvanceStep = () => {
    setWantsToAdvanceStep(true)
  }

  const handleReturnStep = () => {
    setWantsToReturnStep(true)
  }

  const resetAdvanceStep = () => {
    setWantsToAdvanceStep(false)
  }

  const resetReturnStep = () => {
    setWantsToReturnStep(false)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    if (activeStep === 0) return navigate(routes.app.incidents.all)

    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step?: number) => {
    if (isUndefined(step)) return

    setActiveStep(step)
  }

  const isCompleted = (step?: number) => {
    if (isUndefined(step)) return false

    return lastActiveStep > step
  }

  useEffect(() => {
    if (activeStep > lastActiveStep) {
      setLastActiveStep(activeStep)
    }
  }, [activeStep])

  return (
    <IncidentManegementContext.Provider
      value={{
        activeStep,
        lastActiveStep,
        wantsToAdvanceStep,
        wantsToReturnStep,
        incident,
        setIncident,
        handleAdvanceStep,
        handleReturnStep,
        resetAdvanceStep,
        resetReturnStep,
        handleNext,
        handleBack,
        handleStep,
        isCompleted,
        setActiveStep,
      }}
    >
      {children}
    </IncidentManegementContext.Provider>
  )
}

export default IncidentManegementProvider
