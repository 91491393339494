import api from 'service/api'

const dponetAPI = api.create('dponet')

const changeStatus = async ({
  observerInviteId,
  observerInvite,
}: {
  observerInviteId?: string | number
  observerInvite?: object
}) => {
  return dponetAPI.put(
    `/advisors/observer_invites/${observerInviteId}/change_status`,
    {
      observerInvite,
    }
  )
}

export default {
  changeStatus,
}
