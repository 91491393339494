import { Controller, useForm } from 'react-hook-form'
import { Box, TextField } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { useSnackbar } from 'hooks'

import { formatters } from 'helpers'

import { QuestionFormType } from './questionForm.types'

import schema, { QuestionFormSchemaType } from './schema'
import constants from 'constants/index'
import service from 'service'

const QuestionForm = ({
  questionnaireId,
  questionData,
  setIsLoading,
  formName,
  onClose,
  refresh,
}: QuestionFormType) => {
  const { snackbar } = useSnackbar()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<QuestionFormSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      theme: questionData?.theme || '',
      question: questionData?.question || '',
      affirmative: questionData?.affirmative || '',
      reason: questionData?.reason || '',
      recomendations: questionData?.recomendations || '',
      securityPolicy: questionData?.securityPolicy || '',
    },
  })

  const onSubmit = async (data: QuestionFormSchemaType) => {
    try {
      setIsLoading(true)

      questionData?.isEdit
        ? await service.dponet.questionnaires.updateQuestion({
            organizationQuestionnaireId: questionnaireId,
            organizationQuestionId: questionData?.questionId,
            question: {
              ...data,
            },
          })
        : await service.dponet.questionnaires.createQuestion({
            organizationQuestionnaireId: questionnaireId,
            question: {
              ...data,
            },
          })

      snackbar.open({
        message: `Questão ${
          questionData?.isEdit ? 'editada' : 'criada'
        } com sucesso!`,
        variant: 'success',
      })
      reset()
      refresh()
      onClose()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      id={formName}
      display="flex"
      flexDirection="column"
      gap={5}
    >
      {constants.questionnaire.QUESTION_FORM_FIELDS.map((formField) => (
        <Controller
          key={formField.name}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors?.[formField.name]}
              helperText={errors?.[formField.name]?.message}
              label={formField.label}
              type="text"
              variant="outlined"
              fullWidth
              multiline={formField.multiline}
              rows={formField.rows}
            />
          )}
          control={control}
          name={formField.name}
        />
      ))}
    </Box>
  )
}

export default QuestionForm
