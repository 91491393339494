import { useState } from 'react'
import { Box, Collapse } from '@mui/material'
import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
} from 'react-feather'
import { useNavigate } from 'react-router-dom'

import { Button } from './components'
import { Permitted } from 'components'

import { MenuItemButtonType } from './menuItemButton.types'

import { MenuItemType } from 'constants/menuItems.types'

const MenuItemButton = (props: MenuItemButtonType) => {
  const {
    menuItem: { Icon, title, childrens, children: child, url },
  } = props

  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const hasChildrens = (childrens: undefined | MenuItemType[]) => {
    if (Array.isArray(childrens)) {
      return childrens.length > 0
    }

    return false
  }

  const handleClick = () => {
    if (hasChildrens(childrens)) {
      setOpen((open) => !open)
      return
    }

    navigate(url)
  }

  return (
    <>
      <Button spaced={child} onClick={handleClick} fullWidth disableElevation>
        <Box display="flex" alignItems="center" gap={4}>
          {Icon && <Icon size={24} />}
          {title}
        </Box>
        {hasChildrens(childrens) && (
          <>
            {open ? <ChevronUpIcon size={18} /> : <ChevronDownIcon size={18} />}
          </>
        )}
      </Button>

      {hasChildrens(childrens) && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {childrens?.map((menuItemChild) => (
            <Permitted
              key={menuItemChild.title}
              tag={menuItemChild?.permission}
              someTags={menuItemChild?.somePermissions}
            >
              <MenuItemButton menuItem={menuItemChild} />
            </Permitted>
          ))}
        </Collapse>
      )}
    </>
  )
}

export default MenuItemButton
