import { useState } from 'react'
import { Box, CardHeader, Grid, Paper, colors } from '@mui/material'

import {
  Avatar,
  DataProcessInfo,
  Header,
  LegalFrameworksDetails,
} from './components'

import { RowLegalFrameworkType } from './rowLegalFramework.types'

import dataProcessConstants from 'constants/dataProcess'

const RowLegalFramework = ({
  data,
  legalFrameworks,
  setLoading,
}: RowLegalFrameworkType) => {
  const [dataProcess, setDataProcess] = useState(data || {})

  const changeDataProcessStatus = (statusId: number) => {
    setDataProcess({
      ...dataProcess,
      statusId,
    })
  }

  const showDetails = dataProcessConstants?.STATUSES_WITH_LEGAL_FRAMEWORK.map(
    (status) => status.id
  ).includes(dataProcess?.statusId)

  return (
    <>
      <Paper
        elevation={0}
        component={Box}
        border={1}
        borderColor={colors.grey[300]}
        square
      >
        <CardHeader
          disableTypography
          title={
            <Header
              status={dataProcess?.statusId}
              updatedAt={dataProcess?.updatedAt}
            />
          }
          avatar={
            <Avatar
              dataProcessId={dataProcess?.id}
              status={dataProcess?.statusId}
              changeDataProcessStatus={changeDataProcessStatus}
            />
          }
        />
      </Paper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={showDetails ? 7.5 : 12}>
          <DataProcessInfo
            showDetails={showDetails}
            changeDataProcessStatus={changeDataProcessStatus}
            dataProcess={dataProcess}
            setLoading={setLoading}
          />
        </Grid>
        {showDetails && (
          <Grid item xs={12} md={12} lg={4.5}>
            <LegalFrameworksDetails
              changeDataProcessStatus={changeDataProcessStatus}
              dataProcess={dataProcess}
              legalFrameworkByDataTypes={legalFrameworks}
              setLoading={setLoading}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

RowLegalFramework.defaultProps = {
  data: {},
  legalFrameworks: [],
  setLoading: () => {},
}

export default RowLegalFramework
