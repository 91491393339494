import { FilterInput, Filters } from 'components'
import { useFetch } from 'hooks'
import { FilterHookType } from 'types/filters.types'

import service from 'service'
import { formatters } from 'helpers'
import { OrganizationCompanyType } from 'types/organizationCompany'

import { useEffect, useState } from 'react'
import organizationCompaniesConstants from 'constants/organizationCompanies'
import { Mark } from '@mui/base/useSlider/useSlider.types'

const CompanyFilters = ({ filter }: { filter: FilterHookType }) => {
  const [shouldFetch, setShouldFetch] = useState<boolean>(false)
  const [organizationCompanyFilter, setOrganizationCompanyFilter] = useState<
    string | null
  >()
  const validCompanyFilterInput =
    !!organizationCompanyFilter && organizationCompanyFilter?.length >= 3
  const marks: Mark[] = [
    { value: 0, label: '0%' },
    { value: 50, label: '50%' },
    { value: 100, label: '100%' },
  ]

  useEffect(() => {
    if (filter.drawerOpen && !shouldFetch) setShouldFetch(true)
  }, [filter.drawerOpen])

  const { response: responseCompanies, loading: loadingCompanies } = useFetch(
    service.dponet.organizationCompany.get,
    {
      params: {
        companyName: organizationCompanyFilter,
        perPage: 999,
        minimal: true,
        status: organizationCompaniesConstants.ACTIVE_STATUS,
      },
    },
    [organizationCompanyFilter],
    validCompanyFilterInput
  )

  const { response: responseTags, loading: loadingTags } = useFetch(
    service.dponet.tags.get,
    {
      params: {
        perPage: 999,
        minimal: true,
      },
    },
    [],
    shouldFetch
  )

  return (
    <Filters filter={filter}>
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Nome da Empresa', name: 'companyName' }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        loading={loadingTags}
        {...{
          label: 'Classificadores',
          name: 'oganizationTagsIds',
          options: formatters.autoCompleteOptions(
            responseTags?.data?.organizationTags
          ),
          multiple: true,
        }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        loading={loadingCompanies && validCompanyFilterInput}
        autocompleteOnChange={(data) => {
          setOrganizationCompanyFilter(data)
        }}
        {...{
          noOptionsText: formatters.autoCompleteNoText(
            organizationCompanyFilter || ''
          ),
          label: 'Vínculo',
          name: 'companyMatrixId',
          options: responseCompanies?.data?.organizationCompanies?.map(
            (organizationCompany: OrganizationCompanyType) => ({
              id: organizationCompany?.id,
              name: organizationCompany?.company?.name,
            })
          ),
        }}
      />
      <FilterInput
        fieldConfigs={{ rangeSlider: true }}
        {...{
          name: 'compliance_gap',
          defaultValue: [0, 100],
          valueLabelDisplay: 'auto',
          inputLabel: 'Conformidade dos Questionários (%)',
          marks: marks,
        }}
      />
      <FilterInput
        fieldConfigs={{ rangeSlider: true }}
        {...{
          name: 'health_score',
          defaultValue: [0, 100],
          valueLabelDisplay: 'auto',
          inputLabel: 'Health Score (%)',
          marks: marks,
        }}
      />
    </Filters>
  )
}

export default CompanyFilters
