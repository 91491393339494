import * as yup from 'yup'

const schema = yup
  .object({
    legalOpinion: yup.string().required().max(1000),
    confirmed: yup.string().required(),
    shouldNotify: yup.string(),
    notificationOpinion: yup.string().when('shouldNotify', {
      is: 'true',
      then: () => yup.string().required(),
    }),
  })
  .required()

export type AvaliationStepSchemaType = yup.InferType<typeof schema>

export default schema
