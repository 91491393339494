import { ComponentsPropsList, Theme } from '@mui/material'

export default {
  MuiTableRow: {
    variants: [
      {
        props: {
          hover: true,
        },
        style: {
          cursor: 'pointer',
        },
      },
    ],
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({
        ownerState,
        theme,
      }: {
        ownerState: ComponentsPropsList['MuiTableCell']
        theme: Theme
      }) => {
        if (ownerState.variant === 'head') {
          return {
            color: theme.palette.primary.main,
          }
        }
      },
    },
  },
}
