import { Box, Typography } from '@mui/material'

import { LinearProgress } from 'components'

import { LinearProgressWithLabelType } from './linearProgressWithLabel.types'

const LinearProcessWithLabel = ({
  value,
  valueLabel,
  thickness,
  borderRadius,
  ...rest
}: LinearProgressWithLabelType) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          $thickness={thickness}
          $borderRadius={borderRadius}
          value={value}
          {...rest}
        />
      </Box>
      <Box minWidth={35} display="flex" justifyContent="center">
        <Typography variant="body2" color="text.secondary">
          {valueLabel ?? `${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

export default LinearProcessWithLabel
