import { Fragment, SyntheticEvent, useState } from 'react'
import { Hidden, Tab, TablePagination, Tabs } from '@mui/material'
import { isEmpty } from 'lodash'

import {
  ListPageStructure,
  LoadingFeedback,
  NoData,
  PageHead,
  PagePaper,
} from 'components'
import { TicketTable } from 'components/Tickets'

import { useFetch, useFilter, usePagination, useOrganization } from 'hooks'

import service from 'service'
import ticketsConstants from 'constants/tickets'
import organizationConstants from 'constants/organizations'

const TicketMain = () => {
  const filter = useFilter()
  const { currentOrganization } = useOrganization()
  const { KIND_OBSERVER } = organizationConstants

  const isObserver = currentOrganization?.kind === KIND_OBSERVER

  const [selectedTab, setSelectedTab] = useState<number | null>(
    isObserver
      ? ticketsConstants.ALL_TICKETS_ID
      : ticketsConstants.NEW_TICKET_ID
  )

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ resetPage: selectedTab })

  const { response, loading } = useFetch(
    service.dponet.ticket.list,
    {
      params: {
        ...filter.filters,
        page,
        perPage,
        statuses_ids: selectedTab
          ? ticketsConstants.STATUSES_BY_TAB[selectedTab]
          : undefined,
      },
    },
    [filter.filters, page, perPage, selectedTab]
  )

  const solicitationTickets = response?.data?.tickets ?? []

  const handleTabChange = (_: SyntheticEvent, clickedTab: number) =>
    setSelectedTab(clickedTab)

  return (
    <ListPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead name="Solicitações" />
      <PagePaper>
        <Tabs
          value={selectedTab}
          variant="scrollable"
          onChange={handleTabChange}
        >
          {ticketsConstants.PRIVACY_PORTAL_TICKET_STATUS_TABS.map((tab) => (
            <Tab key={tab.id} label={tab.tabName} value={tab.id} />
          ))}
        </Tabs>
        {loading || isEmpty(solicitationTickets) ? (
          <NoData
            title={
              isObserver
                ? 'Você não possui permissões de visualização autorizadas pelo DPO responsável pela organização.'
                : 'Nenhuma solicitação foi encontrada.'
            }
            withoutPaper
          />
        ) : (
          <Fragment>
            <TicketTable
              selectedTab={selectedTab}
              solicitationTickets={solicitationTickets}
              isLegalRequest={false}
            />
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={(_, page) => handleChangePage(page)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Fragment>
        )}
      </PagePaper>
    </ListPageStructure>
  )
}

export default TicketMain
