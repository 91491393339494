import { useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

import {
  OrganizationUserFormType,
  ProfileOption,
} from './organizationUserForm.types'

import { getGoogleRecaptchaToken } from 'service/env'
import constants from 'constants/index'
import schema from './schema'

const OrganizationUserForm = ({
  onSubmit,
  errorMessage,
  profileOptions,
  organization,
  setRecaptcha,
  envProduction,
}: OrganizationUserFormType) => {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      email: '',
      profileId: '',
    },
  })

  const resetRecaptcha = () => {
    recaptchaRef?.current?.reset()
    setRecaptcha(undefined)
  }

  const expiredRecaptcha = () => {
    setValue('recaptcha', '')
    setRecaptcha(null)
  }

  const handleRecaptcha = (token: string | null) => {
    if (token) {
      setValue('recaptcha', token)
      setRecaptcha(token)
    }
  }

  const submit = async (data: object) => {
    await onSubmit(data)
    if (envProduction) {
      resetRecaptcha()
    }
  }

  const defaultProfileOptionsByKind =
    organization?.kind === constants.organizations.KIND_DPO
      ? constants.profiles.DEFAULT_DPO_PROFILES_OPTIONS
      : constants.profiles.DEFAULT_OBSERVER_PROFILES_OPTIONS

  const profiles: ProfileOption[] = [
    ...defaultProfileOptionsByKind,
    ...(profileOptions ? profileOptions : []),
  ]

  return (
    <Box
      id="organization-user-creation-form"
      pt={2}
      component="form"
      onSubmit={handleSubmit(submit)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.name || !!errorMessage}
                helperText={errorMessage ?? errors?.name?.message}
                label="Nome do Usuário"
                type="text"
                variant="outlined"
                fullWidth
              />
            )}
            name="name"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.email || !!errorMessage}
                helperText={errorMessage ?? errors?.email?.message}
                label="E-mail"
                type="text"
                variant="outlined"
                fullWidth
              />
            )}
            name="email"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <FormControl
                fullWidth
                margin="dense"
                error={!!errors?.profileId?.message}
              >
                <InputLabel id="organization-id-select-label">
                  Perfil
                </InputLabel>
                <Select
                  {...field}
                  labelId="organization-id-select-label"
                  id="organization-id-select"
                  label="Perfil"
                >
                  {profiles?.map((profile) => (
                    <MenuItem key={profile.id} value={String(profile.id)}>
                      {profile.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors?.profileId?.message}</FormHelperText>
              </FormControl>
            )}
            name="profileId"
            control={control}
          />
        </Grid>
        {envProduction && (
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <ReCAPTCHA
                  {...field}
                  ref={recaptchaRef}
                  sitekey={getGoogleRecaptchaToken()}
                  onChange={(token: string | null) => handleRecaptcha(token)}
                  onExpired={expiredRecaptcha}
                  size="normal"
                />
              )}
              name="recaptcha"
              control={control}
            />
            <FormHelperText error={!!errors?.recaptcha}>
              {errors?.recaptcha?.message}
            </FormHelperText>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default OrganizationUserForm
