import { CompanyTrail } from 'types/organizationCompany'
import companyTrailsConstant from 'constants/companyTrails'
import { CompanyTrailStatsType } from 'types/organization.types'

const filterTrails = (companyTrails: CompanyTrail[]) => {
  const dashboardCompanyTrails = companyTrails.filter((companyTrail) =>
    companyTrailsConstant.DASHBOARD_TRAILS.includes(companyTrail.name)
  )

  if (dashboardCompanyTrails.length === 0) return []

  dashboardCompanyTrails.push({
    id: 0,
    name: companyTrailsConstant.ALL_TRAILS.RMC,
    completed: false,
  })

  return dashboardCompanyTrails
}

const filterTrailsStats = (companyTrails: CompanyTrailStatsType[]) => {
  const dashboardCompanyTrails = companyTrails.filter((companyTrail) =>
    companyTrailsConstant.DASHBOARD_TRAILS.includes(companyTrail.name)
  )

  if (dashboardCompanyTrails.length === 0) return []

  dashboardCompanyTrails.push({
    name: companyTrailsConstant.ALL_TRAILS.RMC,
    completed: 0,
    notCompleted: dashboardCompanyTrails.length,
  })

  return dashboardCompanyTrails
}

const orderTrails = (companyTrails: CompanyTrail[]) => {
  return companyTrails.sort((prev, current) => {
    const prevIndex = companyTrailsConstant.DASHBOARD_TRAILS.indexOf(prev.name)
    const currentIndex = companyTrailsConstant.DASHBOARD_TRAILS.indexOf(
      current.name
    )

    if (prevIndex > currentIndex) return 1

    return -1
  })
}
const orderTrailsStats = (companyTrails: CompanyTrailStatsType[]) => {
  return companyTrails.sort((prev, current) => {
    const prevIndex = companyTrailsConstant.DASHBOARD_TRAILS.indexOf(prev.name)
    const currentIndex = companyTrailsConstant.DASHBOARD_TRAILS.indexOf(
      current.name
    )

    if (prevIndex > currentIndex) return 1

    return -1
  })
}

export default {
  filterTrails,
  filterTrailsStats,
  orderTrails,
  orderTrailsStats,
}
