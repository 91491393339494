import { Typography as TypographyMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'

const Typography = styled(TypographyMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $wordBreak?: Property.WordBreak | undefined
}>(({ $wordBreak }) => ({
  wordBreak: $wordBreak,
}))

export default Typography
