import { createContext } from 'react'

import { AuthContextType } from 'types/auth.types'

const contextValues: AuthContextType = {
  loadData: async () => {},
}

const ProviderContext = createContext(contextValues)

export default ProviderContext
