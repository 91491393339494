import { Button as ButtonMUI } from '@mui/material'
import { styled } from '@mui/material/styles'

import { ButtonType } from './button.types'

const Button = styled(ButtonMUI, {
  shouldForwardProp: (props) => props !== 'spaced',
})<ButtonType>(({ spaced, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textTransform: 'none',
  color: theme.palette.primary.contrastText,
  paddingLeft: spaced ? theme.spacing(12) : undefined,
}))

export default Button
