import generalInformation from './GeneralInformation'
import impactDescription from './ImpactDescription'
import occurrenceScience from './OccurrenceScience'
import riskConsequence from './RiskConsequence'
import securityMeasures from './SecurityMeasures'
import timmingCommunication from './TimmingCommunication'

const components = {
  generalInformation,
  impactDescription,
  occurrenceScience,
  riskConsequence,
  securityMeasures,
  timmingCommunication,
}

export default components
