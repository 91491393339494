import { useEffect } from 'react'
import { Box, Button, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import {
  Stepper,
  AvaliationStep,
  CommunicationStep,
  DetalingStep,
  DetectionStep,
  InvestigationStep,
  LessonsLearnedStep,
  TreatmentStep,
} from './components'

import { useIncidentManegement } from 'hooks'

import { FormType } from './form.types'

import incidentsHelper from 'helpers/incidents'
import incidentManegementConstants from 'constants/incidentManegement'
import routes from 'constants/routes'

const Form = ({ incident, refresh }: FormType) => {
  const {
    activeStep,
    wantsToAdvanceStep,
    handleNext,
    resetAdvanceStep,
    handleAdvanceStep,
    handleBack,
  } = useIncidentManegement()
  const navigate = useNavigate()

  const stepComponents = {
    detection: <DetectionStep />,
    investigation: <InvestigationStep />,
    avaliation: <AvaliationStep refresh={refresh} />,
    detaling: <DetalingStep />,
    treatment: <TreatmentStep />,
    communication: <CommunicationStep />,
    lessons_learned: <LessonsLearnedStep />,
  }
  const steps = incidentsHelper.enumSteps(incident)
  const step = steps[activeStep]

  const advanceButtonText =
    step === incidentManegementConstants.LESSONS_LEARNED_ENUM
      ? 'Finalizar'
      : 'Avançar'

  useEffect(() => {
    if (wantsToAdvanceStep) {
      resetAdvanceStep()
      if (step === incidentManegementConstants.LESSONS_LEARNED_ENUM) {
        navigate(routes.app.incidents.all)
      } else if (step !== incidentManegementConstants.AVALIATION_ENUM) {
        handleNext()
      }
    }
  }, [wantsToAdvanceStep])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Stepper />
      </Grid>
      <Grid item xs={12}>
        <Box mt={3} />

        {stepComponents[step]}
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end" mt={4} gap={2}>
          <Button variant="outlined" onClick={handleBack}>
            Voltar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAdvanceStep}
          >
            {advanceButtonText}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Form
