import { Fragment } from 'react'
import {
  TableContainer,
  TableHead,
  TableBody,
  Typography,
  Avatar,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material'
import { ArrowRight as ArrowRightIcon } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'
import { isEmpty } from 'lodash'

import { MUI, Table as TableComponent, TableRow } from 'components'

import { formatters, validates } from 'helpers'

import { TicketTableType } from './ticketTableTypes'

import palette from 'theme/palette'
import routesConstants from 'constants/routes'
import organizationsConstants from 'constants/organizations'

const TicketTable = ({
  solicitationTickets,
  selectedTab,
  isLegalRequest = false,
}: TicketTableType) => {
  const navigate = useNavigate()

  const route = () => {
    return isLegalRequest
      ? routesConstants.app.tickets.message
      : routesConstants.app.privacyPortal.ticketMessage
  }

  const handleClickTableRow = (id: number) => {
    navigate(
      reverse(route(), {
        ticketId: id,
      })
    )
  }

  const filteredTickets = isEmpty(selectedTab)
    ? solicitationTickets
    : solicitationTickets?.filter(
        (ticket) => ticket?.status?.id === selectedTab
      )

  return (
    <TableContainer>
      <TableComponent aria-label="listagem de tickets">
        <TableHead>
          <TableRow>
            <MUI.TableCell align="center">ID</MUI.TableCell>
            <MUI.TableCell align="left">Organização</MUI.TableCell>
            <MUI.TableCell align="left">Solicitante</MUI.TableCell>
            <MUI.TableCell align="left">Assunto</MUI.TableCell>
            <MUI.TableCell align="center">Atualizado em</MUI.TableCell>
            {!isLegalRequest && (
              <Fragment>
                <MUI.TableCell align="center">Confirmado em</MUI.TableCell>
                <MUI.TableCell align="center">Prazo</MUI.TableCell>
              </Fragment>
            )}
            <MUI.TableCell align="left">Responsável</MUI.TableCell>
            <MUI.TableCell align="center">Status</MUI.TableCell>
            <MUI.TableCell align="center">Ação</MUI.TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTickets?.map((ticket) => (
            <TableRow
              key={ticket?.id}
              onClick={() => handleClickTableRow(ticket?.id)}
            >
              <MUI.TableCell align="center">
                <Typography fontWeight={500} variant="body2">
                  {ticket?.id}
                </Typography>
              </MUI.TableCell>
              <MUI.TableCell align="left" $maxWidth={300}>
                <Box display="flex" flexDirection="row" gap={5}>
                  <Avatar
                    alt={
                      ticket?.company?.name ||
                      ticket?.requesterOrganization?.name
                    }
                    src={ticket?.company?.logo || undefined}
                    variant="square"
                  />
                  <Box display="flex" flexDirection="column">
                    <Tooltip title={ticket?.company?.name}>
                      <Typography
                        variant="body2"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {ticket?.company?.name ||
                          ticket?.requesterOrganization?.name}
                      </Typography>
                    </Tooltip>
                    <Typography variant="body2" color={palette.custom.dark}>
                      {ticket?.company?.document ||
                        (ticket?.requesterOrganization?.kind &&
                          organizationsConstants.KIND_LABELS[
                            ticket?.requesterOrganization?.kind
                          ])}
                    </Typography>
                  </Box>
                </Box>
              </MUI.TableCell>
              <Tooltip title={ticket?.user?.name}>
                <MUI.TableCell align="left" $maxWidth={250}>
                  {ticket?.user?.name}
                </MUI.TableCell>
              </Tooltip>
              <MUI.TableCell align="left" $maxWidth={250}>
                <Tooltip
                  title={
                    ticket?.info?.questionDemand?.answer?.title || ticket?.title
                  }
                >
                  <Typography
                    variant="body2"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {ticket?.info?.questionDemand?.answer?.title ||
                      ticket?.title}
                  </Typography>
                </Tooltip>
                <Typography variant="body2" color={palette.custom.dark}>
                  {validates.tickets.labelTranslation(ticket?.ticketType)}
                </Typography>
              </MUI.TableCell>

              <MUI.TableCell align="center">
                <Typography fontWeight={500} variant="body2">
                  {formatters.date.ptBrFormatDateTime(ticket?.updatedAt)}
                </Typography>
              </MUI.TableCell>
              {!isLegalRequest && (
                <Fragment>
                  <MUI.TableCell align="center">
                    <Typography fontWeight={500} variant="body2">
                      {ticket?.confirmedAt
                        ? formatters.date.ptBrFormatDateTime(
                            ticket?.confirmedAt
                          )
                        : '-'}
                    </Typography>
                  </MUI.TableCell>
                  <MUI.TableCell align="center">
                    {ticket?.daysToDeadlineDate ? (
                      <MUI.Chip
                        label={validates.tickets.deadlineChipTranslation(
                          ticket?.daysToDeadlineDate
                        )}
                        variant="filled"
                        size="small"
                        color={validates.tickets.deadlineChipColor(
                          ticket?.daysToDeadlineDate
                        )}
                      />
                    ) : (
                      '-'
                    )}
                  </MUI.TableCell>
                </Fragment>
              )}
              <Tooltip title={ticket?.responsible?.name}>
                <MUI.TableCell $maxWidth={200}>
                  {ticket?.responsible?.name ? ticket?.responsible?.name : '-'}
                </MUI.TableCell>
              </Tooltip>
              <MUI.TableCell align="center">
                {ticket?.status?.name && ticket?.status?.id ? (
                  <MUI.Chip
                    label={ticket?.status?.name.toUpperCase()}
                    variant="filled"
                    size="small"
                    $background={validates.tickets.statusBackgroundColor(
                      ticket?.status?.id
                    )}
                    $color="white"
                    $fontWeight={500}
                  />
                ) : (
                  '-'
                )}
              </MUI.TableCell>
              <MUI.TableCell align="center">
                <IconButton onClick={() => handleClickTableRow(ticket?.id)}>
                  <ArrowRightIcon color={palette.custom.dark} />
                </IconButton>
              </MUI.TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableComponent>
    </TableContainer>
  )
}

export default TicketTable
