import { Box, Typography } from '@mui/material'

import { IdentifyIcon } from './components'

import { PasswordValidatorType } from './passwordValidator.types'

const PasswordValidator = ({
  passwordInput,
  justSubmitted,
}: PasswordValidatorType) => {
  const eightCharacters = () => {
    return /^.{8,}$/.test(passwordInput)
  }

  const uppercaseLetter = () => {
    return /.*[A-Z].*/.test(passwordInput)
  }

  const lowercaseLetter = () => {
    return /.*[a-z].*/.test(passwordInput)
  }

  const specialCharacters = () => {
    //eslint-disable-next-line
    return /[ `*!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(passwordInput)
  }

  const numberCharacters = () => {
    return /.*[0-9].*/.test(passwordInput)
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" mb={1}>
        <IdentifyIcon
          regexValidator={eightCharacters}
          justSubmitted={justSubmitted}
        >
          <Typography>Ao menos 8 caracteres</Typography>
        </IdentifyIcon>
      </Box>

      <Box display="flex" alignItems="center" mb={1}>
        <IdentifyIcon
          regexValidator={lowercaseLetter}
          justSubmitted={justSubmitted}
        >
          <Typography>Letras minúsculas</Typography>
        </IdentifyIcon>
      </Box>

      <Box display="flex" alignItems="center" mb={1}>
        <IdentifyIcon
          regexValidator={uppercaseLetter}
          justSubmitted={justSubmitted}
        >
          <Typography>Letras maiúsculas</Typography>
        </IdentifyIcon>
      </Box>

      <Box display="flex" alignItems="center" mb={1}>
        <IdentifyIcon
          regexValidator={specialCharacters}
          justSubmitted={justSubmitted}
        >
          <Typography>Caractere especial (Ex: # $)</Typography>
        </IdentifyIcon>
      </Box>

      <Box display="flex" alignItems="center">
        <IdentifyIcon
          regexValidator={numberCharacters}
          justSubmitted={justSubmitted}
        >
          <Typography>Números</Typography>
        </IdentifyIcon>
      </Box>
    </Box>
  )
}

export default PasswordValidator
