import { Fragment } from 'react'
import { useTheme } from '@mui/material/styles'
import { Tab, TablePagination, Tabs, useMediaQuery } from '@mui/material'
import { isEmpty } from 'lodash'

import {
  PageHead,
  ListPageStructure,
  PagePaper,
  LoadingFeedback,
  FilterButton,
  NoData,
} from 'components'
import { IncidentsFilters, IncidentsTable } from './components'

import { useFetch, useFilter, usePagination, useOrganization } from 'hooks'

import service from 'service'
import constants from 'constants/index'

const IncidentsMain = () => {
  const filter = useFilter()
  const theme = useTheme()
  const { currentOrganization } = useOrganization()

  const { KIND_OBSERVER } = constants.organizations

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ initialPerPage: 10 })

  const { response, loading } = useFetch(
    service.dponet.incidents.get,
    {
      params: {
        ...filter.filters,
        perPage,
        page,
      },
    },
    [filter.filters, page, perPage]
  )

  const incidentsResponse = response?.data?.incidents
  const isObserver = currentOrganization?.kind === KIND_OBSERVER

  return (
    <ListPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead
        name="Incidentes"
        actionButton={<FilterButton setDrawerOpen={filter.setDrawerOpen} />}
      />
      <PagePaper>
        <Tabs value="all" variant="scrollable">
          <Tab label="Todos" value="all" />
        </Tabs>
        {loading || isEmpty(incidentsResponse) ? (
          <NoData
            title={
              isObserver
                ? 'Você não possui permissões de visualização autorizadas pelo DPO responsável pela organização.'
                : 'Nenhum incidente foi encontrado.'
            }
            withoutPaper
          />
        ) : (
          <Fragment>
            <IncidentsTable incidents={incidentsResponse} />
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={(_, page) => handleChangePage(page)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              slotProps={{
                actions: {
                  nextButton: {
                    size: 'small',
                  },
                  previousButton: {
                    size: 'small',
                  },
                },
              }}
            />
          </Fragment>
        )}
      </PagePaper>
      <IncidentsFilters filter={filter} />
    </ListPageStructure>
  )
}

export default IncidentsMain
