import api from 'service/api'

const dponetAPI = api.create('dponet')

const create = async (organizationTag: object) => {
  return dponetAPI.post(`/advisors/organization_tags`, {
    organizationTag,
  })
}

const get = async ({
  tagId = '',
  params = {},
}: {
  tagId?: string
  params?: object
}) => {
  return dponetAPI.get(`/advisors/organization_tags/${tagId}`, {
    params,
  })
}

const destroy = async ({ tagId }: { tagId?: number }) => {
  return dponetAPI.delete(`/advisors/organization_tags/${tagId}`)
}

export default {
  create,
  get,
  destroy,
}
