import { Fragment } from 'react'
import { Box, Fade, Typography, colors } from '@mui/material'

import AiOptions from 'components/AiOptions/AiOptions'

import { AiGenerativeBoxType } from './aiGenerativeBox.types'

const AiGenerativeBox = ({
  suggestion,
  handleFeedback,
  daiFeedback,
  alreadyGeneratedSuggestion = false,
  handleRegenerate,
}: AiGenerativeBoxType) => {
  const suggestionSpplited = suggestion?.split(/\\n/g) || []

  return (
    <>
      {suggestion && (
        <Fade in timeout={1000}>
          <Box m={2} mt={0}>
            <Box p={2} bgcolor={colors.blue[100]} borderRadius="4px 4px 0 0">
              <Typography
                variant="body2"
                color="text.secondary"
                fontWeight={500}
              >
                Resposta gerada pela DAI
              </Typography>
            </Box>

            <Box
              p={2}
              bgcolor={colors.lightBlue[50]}
              borderRadius="0 0 4px 4px"
            >
              <Typography
                variant="body2"
                textAlign="justify"
                color="text.secondary"
                whiteSpace="pre-line"
              >
                {suggestionSpplited.map((suggestion) => (
                  <Fragment key={suggestion}>
                    {suggestion}
                    <br />
                  </Fragment>
                ))}
              </Typography>

              <AiOptions
                alreadyGeneratedSuggestion={alreadyGeneratedSuggestion}
                suggestion={suggestion}
                handleFeedback={handleFeedback}
                daiFeedback={daiFeedback}
                handleRegenerate={handleRegenerate}
              />
            </Box>
          </Box>
        </Fade>
      )}
    </>
  )
}

export default AiGenerativeBox
