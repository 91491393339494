import { colors } from '@mui/material'

const FINALIZED_STATUS = 'finalized'
const ON_TIME_STATUS = 'onTime'
const OVERDUE_STATUS = 'overdue'
const PENDING_STATUS = 'pending'
const RISK_ACCEPTED_STATUS = 'riskAccepted'
const UNDEFINED_STATUS = 'undefined'

const ALL_STATUSES = [
  PENDING_STATUS,
  ON_TIME_STATUS,
  OVERDUE_STATUS,
  RISK_ACCEPTED_STATUS,
  FINALIZED_STATUS,
] as const

const STATUSES_COLORS = {
  [PENDING_STATUS]: colors.yellow['100'],
  [ON_TIME_STATUS]: colors.blue['100'],
  [OVERDUE_STATUS]: colors.red['A100'],
  [RISK_ACCEPTED_STATUS]: colors.lightGreen['A100'],
  [FINALIZED_STATUS]: colors.green['A700'],
}

const STATUSES_TEXT_COLORS = {
  [PENDING_STATUS]: colors.yellow['900'],
  [ON_TIME_STATUS]: colors.blue['700'],
  [OVERDUE_STATUS]: colors.red['900'],
  [RISK_ACCEPTED_STATUS]: colors.lightGreen['900'],
  [FINALIZED_STATUS]: colors.grey[100],
}

const STATUSES_LABELS = {
  [PENDING_STATUS]: 'Pendente',
  [ON_TIME_STATUS]: 'No prazo',
  [OVERDUE_STATUS]: 'Vencido',
  [RISK_ACCEPTED_STATUS]: 'Risco assumido',
  [FINALIZED_STATUS]: 'Finalizado',
  [UNDEFINED_STATUS]: '-',
}

const STATUSES_INFO = {
  [PENDING_STATUS]: {
    color: STATUSES_TEXT_COLORS[PENDING_STATUS],
    background: STATUSES_COLORS[PENDING_STATUS],
    label: STATUSES_LABELS[PENDING_STATUS],
  },
  [ON_TIME_STATUS]: {
    color: STATUSES_TEXT_COLORS[ON_TIME_STATUS],
    background: STATUSES_COLORS[ON_TIME_STATUS],
    label: STATUSES_LABELS[ON_TIME_STATUS],
  },
  [OVERDUE_STATUS]: {
    color: STATUSES_TEXT_COLORS[OVERDUE_STATUS],
    background: STATUSES_COLORS[OVERDUE_STATUS],
    label: STATUSES_LABELS[OVERDUE_STATUS],
  },
  [RISK_ACCEPTED_STATUS]: {
    color: STATUSES_TEXT_COLORS[RISK_ACCEPTED_STATUS],
    background: STATUSES_COLORS[RISK_ACCEPTED_STATUS],
    label: STATUSES_LABELS[RISK_ACCEPTED_STATUS],
  },
  [FINALIZED_STATUS]: {
    color: STATUSES_TEXT_COLORS[FINALIZED_STATUS],
    background: STATUSES_COLORS[FINALIZED_STATUS],
    label: STATUSES_LABELS[FINALIZED_STATUS],
  },
  [UNDEFINED_STATUS]: {
    label: STATUSES_LABELS[UNDEFINED_STATUS],
  },
} as const

const STATUSES_ID = {
  [1]: STATUSES_INFO[ON_TIME_STATUS],
  [2]: STATUSES_INFO[OVERDUE_STATUS],
  [3]: STATUSES_INFO[FINALIZED_STATUS],
  [4]: STATUSES_INFO[PENDING_STATUS],
  [6]: STATUSES_INFO[RISK_ACCEPTED_STATUS],
  undefined: STATUSES_INFO[UNDEFINED_STATUS],
}

export { STATUSES_LABELS }

export default {
  ALL_STATUSES,
  FINALIZED_STATUS,
  ON_TIME_STATUS,
  OVERDUE_STATUS,
  PENDING_STATUS,
  RISK_ACCEPTED_STATUS,
  STATUSES_COLORS,
  STATUSES_INFO,
  STATUSES_ID,
  STATUSES_LABELS,
  STATUSES_TEXT_COLORS,
}
