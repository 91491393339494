import { useForm, Controller } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import { Button, Grid, TextField } from '@mui/material'
import { PasswordValidator } from 'components'

import { useSnackbar } from 'hooks'

import { FormTypes, PayloadData } from './form.types'

import { formatters } from 'helpers'
import routes from 'constants/routes'
import schema from './schema'
import service from 'service'

const Form = ({ isLoading, setLoading }: FormTypes) => {
  const { resetPasswordToken } = useParams()

  const navigate = useNavigate()
  const { snackbar } = useSnackbar()
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
  })

  const onSubmit = async (data: PayloadData) => {
    try {
      setLoading(true)
      const response = await service.dponet.auth.newPassword({
        resetPasswordToken,
        ...data,
      })
      setLoading(false)
      snackbar.open({
        message:
          response?.data?.message || 'Sua senha foi alterada com sucesso.',
        variant: 'success',
      })
      navigate(routes.auth.login)
    } catch (error) {
      console.error(error)
      setLoading(false)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PasswordValidator
            passwordInput={watch('password')}
            justSubmitted={isSubmitted}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.password}
                helperText={<>{errors.password?.message}</>}
                label="Nova senha"
                type="password"
                autoComplete="off"
                placeholder="Digite sua nova senha"
                fullWidth
              />
            )}
            name="password"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.passwordConfirm}
                helperText={<>{errors.passwordConfirm?.message}</>}
                label="Confirmar nova senha"
                type="password"
                autoComplete="off"
                placeholder="Confirme sua nova senha"
                fullWidth
              />
            )}
            name="passwordConfirm"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={isLoading}
          >
            Cadastrar nova senha
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default Form
