import { Box as BoxMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength, TTime } from 'types/materialUI.types'

type HoverType = {
  backgroundColor?: Property.BackgroundColor | undefined
  color?: Property.Color | undefined
}

const Box = styled(BoxMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $borderBottomLeftRadius?: Property.BorderBottomLeftRadius<TLength>
  $borderBottomRightRadius?: Property.BorderBottomRightRadius<TLength>
  $borderTopLeftRadius?: Property.BorderTopLeftRadius<TLength>
  $borderTopRightRadius?: Property.BorderTopRightRadius<TLength>
  $cursor?: Property.Cursor
  $hover?: HoverType
  $transition?: Property.Transition<TTime>
}>(
  ({
    $borderBottomLeftRadius,
    $borderBottomRightRadius,
    $borderTopLeftRadius,
    $borderTopRightRadius,
    $cursor,
    $hover,
    $transition,
  }) => ({
    borderBottomLeftRadius: $borderBottomLeftRadius,
    borderBottomRightRadius: $borderBottomRightRadius,
    borderTopLeftRadius: $borderTopLeftRadius,
    borderTopRightRadius: $borderTopRightRadius,
    cursor: $cursor,
    transition: $transition,
    '&:hover': {
      backgroundColor: $hover?.backgroundColor,
      color: $hover?.color,
    },
  })
)

export default Box
