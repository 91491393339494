import globalComponents from 'utils/jsPDF/components'
import globalTemplates from 'utils/jsPDF/templates'

import { TicketInfoType } from './ticketInfo.types'

import { formatters, validates } from 'helpers'
import constants from 'constants/index'
import theme from 'theme'

const ticketInfo = ({ pdf, date, ticket, ticketMessages }: TicketInfoType) => {
  const GAP = constants.jsPdf.GAP

  const createdAt = formatters.date.ptBrFormatDateTime(ticket?.createdAt)

  globalTemplates.chapterCover({
    pdf,
    title: 'INFORMAÇÕES',
    subtitle:
      'Síntese das principais informações referente a essa solicitação de atendimento',
  })

  pdf.addPage('a4', 'p')

  globalComponents.lineWithDate({
    pdf,
    date: date,
  })

  globalComponents.numberAndTitle({ pdf, number: '1', title: 'Informações' })

  const tableRows = [
    ['Número do ticket', ticket?.ticketRef || 'Não informado'],
    ['Solicitante', ticket?.user?.name || 'Não informado'],
    ['Organização', ticket?.company?.name || 'Não informado'],
  ]

  if (ticket?.info?.anpdJob) {
    tableRows.push(['Cargo na ANPD', ticket?.info?.anpdJob])
  }

  if (ticket?.title) {
    tableRows.push(['Título da solicitação', ticket?.title])
  }

  if (ticket?.content) {
    tableRows.push(['Descrição da solicitação', ticket?.content])
  }

  if (ticket?.ticketType === 'titular') {
    if (
      ticket?.info.questionRequest?.title &&
      ticket?.info?.questionRequest?.answer?.title
    ) {
      tableRows.push([
        ticket?.info.questionRequest?.title,
        ticket?.info?.questionRequest?.answer?.title,
      ])
    }
    if (
      ticket?.info.questionRequest?.answer?.subOption?.title &&
      ticket?.info.questionRequest?.answer?.subOption?.subAnswer
    ) {
      tableRows.push([
        ticket?.info.questionRequest?.answer?.subOption?.title,
        ticket?.info.questionRequest?.answer?.subOption?.subAnswer,
      ])
    }
    if (
      ticket?.info.questionRequest?.answer?.specificField?.title &&
      ticket?.info.questionRequest?.answer?.specificField?.response
    ) {
      tableRows.push([
        ticket?.info.questionRequest?.answer?.specificField?.title,
        ticket?.info.questionRequest?.answer?.specificField?.response,
      ])
    }

    if (
      ticket?.info.questionRelationshipSelf?.title &&
      ticket?.info?.questionRelationshipSelf?.answer?.title
    ) {
      tableRows.push([
        ticket?.info.questionRelationshipSelf?.title,
        ticket?.info?.questionRelationshipSelf?.answer?.title,
      ])
    }
    if (
      ticket?.info.questionRelationshipSelf?.answer?.subOption?.title &&
      ticket?.info.questionRelationshipSelf?.answer?.subOption?.subAnswer
    ) {
      tableRows.push([
        ticket?.info.questionRelationshipSelf?.answer?.subOption?.title,
        ticket?.info.questionRelationshipSelf?.answer?.subOption?.subAnswer,
      ])
    }
    if (
      ticket?.info.questionRelationshipSelf?.answer?.specificField?.title &&
      ticket?.info.questionRelationshipSelf?.answer?.specificField?.response
    ) {
      tableRows.push([
        ticket?.info.questionRelationshipSelf?.answer?.specificField?.title,
        ticket?.info.questionRelationshipSelf?.answer?.specificField?.response,
      ])
    }

    if (
      ticket?.info.questionDemand?.title &&
      ticket?.info?.questionDemand?.answer?.title
    ) {
      tableRows.push([
        ticket?.info.questionDemand?.title,
        ticket?.info?.questionDemand?.answer?.title,
      ])
    }
    if (
      ticket?.info.questionDemand?.answer?.subOption?.title &&
      ticket?.info.questionDemand?.answer?.subOption?.subAnswer
    ) {
      tableRows.push([
        ticket?.info.questionDemand?.answer?.subOption?.title,
        ticket?.info.questionDemand?.answer?.subOption?.subAnswer,
      ])
    }
    if (
      ticket?.info.questionDemand?.answer?.specificField?.title &&
      ticket?.info.questionDemand?.answer?.specificField?.response
    ) {
      tableRows.push([
        ticket?.info.questionDemand?.answer?.specificField?.title,
        ticket?.info.questionDemand?.answer?.specificField?.response,
      ])
    }
    if (ticket?.user?.document) {
      tableRows.push(['Três primeiros dígitos do CPF', ticket?.user?.document])
    }
  }

  tableRows.push(
    [
      'Responsável da empresa',
      ticket?.company?.responsibleUser?.name || 'Não informado',
    ],
    ['Data de entrada do ticket', createdAt || 'Não possui data definida'],
    [
      'Data de confirmação',
      (ticket?.confirmedAt &&
        formatters.date.ptBrFormatDateTime(ticket?.confirmedAt)) ||
        'Não possui data definida',
    ],
    [
      'Data de resolução',
      validates.tickets.resolutionDate(ticket, ticketMessages) ||
        'Não possui data definida',
    ],
    [
      'Prazo para atendimento',
      (ticket?.deadlineDate &&
        formatters.date.ptBrFormatDate(ticket?.deadlineDate)) ||
        'Não possui data definida',
    ]
  )

  globalComponents.table({
    pdf,
    body: tableRows,
    startY: GAP * 3.5,
    cellWidth: ticket?.ticketType === 'juridic' ? 0.3 : 0.4,
  })

  globalComponents.numberPage({ pdf, color: theme.palette.primary.main })
}

export default ticketInfo
