import { IconButton as IconButtonMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

const IconButton = styled(IconButtonMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $borderRadius?: Property.BorderRadius<TLength>
  $color?: Property.Color
}>(({ $borderRadius, $color }) => ({
  borderRadius: $borderRadius,
  color: $color,
}))

export default IconButton
