const APPROVED = 4
const PENDING_LIA = 10 // AGUARDANDO LIA NO ADVISOR
const REVIEW_LIA_CLIENT = 11 // PENDENTE LIA NO ADVISOR
const REVIEW_LIA_DPO = 12 //  EM REVISÃO NO ADVISOR
const DISAPPROVED_LIA = 13 // REPROVADOS NO ADVISOR

const STATUSES_LIA = {
  PENDING_LIA,
  REVIEW_LIA_CLIENT,
  REVIEW_LIA_DPO,
  DISAPPROVED_LIA,
  APPROVED,
}

const ALL_STATUS_LIA = [
  APPROVED,
  PENDING_LIA,
  REVIEW_LIA_CLIENT,
  REVIEW_LIA_DPO,
  DISAPPROVED_LIA,
] as const

const NATURE_OF_PERSONAL_DATA = 'nature_of_personal_data'
const DATA_CHILDREN_TEENAGERS = 'data_children_teenagers'
const LEGITIMATE_INTEREST_FINALITY = 'legitimate_interest_finality'
const CONCRETE_SITUATION = 'concrete_situation'

const FINALITY_KINDS = [
  NATURE_OF_PERSONAL_DATA,
  DATA_CHILDREN_TEENAGERS,
  LEGITIMATE_INTEREST_FINALITY,
  CONCRETE_SITUATION,
]

const TREATMENT_INTENDED_PURPOSE = 'treatment_intended_purpose'
const MINIMIZATION = 'minimization'
const LEGITIMATE_EXPECTATION = 'legitimate_expectation'
const RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS =
  'risks_impacts_fundamental_rights_freedoms'
const SAFEGUARDS_AND_MECHANISMS = 'safeguards_and_mechanisms'

const CONCLUSION_LIA = 'conclusion_lia'

const NECESSITY_KINDS = [TREATMENT_INTENDED_PURPOSE, MINIMIZATION]

const BALANCING_SAFEGUARDING_KINDS = [
  LEGITIMATE_EXPECTATION,
  RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS,
  SAFEGUARDS_AND_MECHANISMS,
]

const ALL_KINDS = {
  CONCRETE_SITUATION,
  DATA_CHILDREN_TEENAGERS,
  LEGITIMATE_EXPECTATION,
  LEGITIMATE_INTEREST_FINALITY,
  MINIMIZATION,
  NATURE_OF_PERSONAL_DATA,
  RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS,
  SAFEGUARDS_AND_MECHANISMS,
  TREATMENT_INTENDED_PURPOSE,
}

const LIA_QUESTION_KINDS = {
  ALL_KINDS,
  BALANCING_SAFEGUARDING_KINDS,
  FINALITY_KINDS,
  NECESSITY_KINDS,
  CONCLUSION_LIA,
}

const DPO = 'dpo'
const CLIENT = 'client'

const ROLES = { DPO, CLIENT }

const PENDING = 'pending'
const DISAPPROVED = 'disapproved'
const REVIEW_DISAPPROVED = 'review_disapproved'

const STATUSES_LIA_ANSWER = { PENDING, DISAPPROVED, REVIEW_DISAPPROVED }

const STATUSES_LABEL = [
  {
    label: 'TODOS',
    value: [
      APPROVED,
      DISAPPROVED_LIA,
      PENDING_LIA,
      REVIEW_LIA_CLIENT,
      REVIEW_LIA_DPO,
    ],
  },
  {
    label: 'APROVADO',
    value: [APPROVED],
  },
  {
    label: 'PENDENTE LIA',
    value: [REVIEW_LIA_CLIENT],
  },
  {
    label: 'EM REVISÃO LIA',
    value: [REVIEW_LIA_DPO],
  },
  {
    label: 'REPROVADOS LIA',
    value: [DISAPPROVED_LIA],
  },
  {
    label: 'AGUARDANDO LIA',
    value: [PENDING_LIA],
  },
]

const CHIP_STATUS_BACKGROUND_COLOR = {
  [APPROVED]: '#4CAF50',
  [PENDING_LIA]: '#087DF1',
  [REVIEW_LIA_CLIENT]: '#087DF1',
  [REVIEW_LIA_DPO]: '#FF9800',
  [DISAPPROVED_LIA]: '#B71C1C',
}

const CHIP_STATUS_LABEL = {
  [APPROVED]: 'APROVADO',
  [PENDING_LIA]: 'AGUARDANDO LIA',
  [REVIEW_LIA_CLIENT]: 'PENDENTE LIA',
  [REVIEW_LIA_DPO]: 'EM REVISÃO LIA',
  [DISAPPROVED_LIA]: 'REPROVADO LIA',
}

export type StatusLiaIdType = (typeof ALL_STATUS_LIA)[number]

const COLORS = {
  lightBlue: '#B8DAF7',
  greenLight: '#E3FFBB',
}

const STEPS_DPO = {
  FINALITY: 0,
  NECESSITY: 1,
  BALANCING_SAFEGUARDING: 2,
}

const LEGAL_FRAMEWORKS_LIA = {
  LEGITIMATE_INTEREST: 'Legítimo interesse do controlador ou de terceiro',
  FRAUD_PREVENTION: 'Prevenção à fraude e segurança do titular',
}

const legitimateInterest = {
  CHIP_STATUS_BACKGROUND_COLOR,
  CHIP_STATUS_LABEL,
  COLORS,
  LEGAL_FRAMEWORKS_LIA,
  LIA_QUESTION_KINDS,
  ROLES,
  STATUSES_LABEL,
  STATUSES_LIA_ANSWER,
  STATUSES_LIA,
  STEPS_DPO,
}

export default legitimateInterest
