import { Controller } from 'react-hook-form'
import { ChevronDown as ChevronDownIcon } from 'react-feather'
import { isEmpty } from 'lodash'
import {
  Accordion,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Switch,
  Typography,
} from '@mui/material'

import { MUI } from 'components'

import { usePermission } from 'hooks'

import { PermissionSectionType } from './permissionSection.types'

import permissionTagsConstants from 'constants/permissionTags'

const PermissionSection = ({ name, items, control }: PermissionSectionType) => {
  const { isPermitted } = usePermission()

  const editionPermitted = isPermitted(
    permissionTagsConstants.ADVISOR_PROFILE.MANAGE
  )

  return (
    <Grid item xs={12}>
      <Accordion variant="outlined">
        <AccordionSummary expandIcon={<ChevronDownIcon />}>
          <Typography>{name}</Typography>
        </AccordionSummary>
        <Divider />
        <MUI.AccordionDetails $padding={0}>
          {isEmpty(items) && (
            <Box p={2}>
              <Typography>Nenhum item encontrado</Typography>
            </Box>
          )}
          {items.map((item) => (
            <Box key={item.tag}>
              <Box
                p={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography> {item.name}</Typography>
                <Controller
                  render={({ field }) => (
                    <Switch
                      {...field}
                      checked={!!field.value}
                      disabled={!editionPermitted}
                    />
                  )}
                  control={control}
                  name={`permissions.${item.tag}`}
                />
              </Box>
              <Divider />
            </Box>
          ))}
        </MUI.AccordionDetails>
      </Accordion>
    </Grid>
  )
}

export default PermissionSection
