import { Box } from '@mui/material'

import { Sidebar, Content } from './components'

const Body = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Box position="relative" gridArea="sidebar">
        <Sidebar />
      </Box>
      <Content gridArea="content">{children}</Content>
    </>
  )
}

export default Body
