import { Box, colors } from '@mui/material'

import { Chart } from 'components'

import { AnswerPieChartType } from './answerPieChart.types'

import theme from 'theme'

const QuestionnaireAnswerPieChart = ({
  generalQuestionnaireStats,
}: AnswerPieChartType) => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      className="borderless-donut-box"
    >
      <Chart.PieChart
        names={['RESPONDIDAS', 'NÃO RESPONDIDAS']}
        values={[
          generalQuestionnaireStats.answeredQuestions,
          generalQuestionnaireStats.pendingQuestions,
        ]}
        colors={[colors.green['A700'], colors.red['A700']]}
        title="Questões"
        heightValue={320}
        legendColor={theme.palette.common.white}
        valueColor={theme.palette.common.white}
      />
    </Box>
  )
}

export default QuestionnaireAnswerPieChart
