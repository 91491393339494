/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@mui/material'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

import { PieChartType } from './pieChart.types'

import theme from 'theme'

const PieChart = ({
  id,
  names,
  values,
  colors,
  title,
  legendShow,
  heightValue,
  showLabels,
  fixedTitle = false,
  legendColor = theme.palette.text.primary,
  valueColor = theme.palette.common.black,
  showName = true,
}: PieChartType) => {
  const options: ApexOptions = {
    chart: {
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      foreColor: legendColor,
      fontFamily: 'Roboto',
      toolbar: {
        show: false,
      },
      type: 'donut',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          background: 'transparent',
        },
        dataLabels: {
          name: {},
          value: {},
          total: {},
        },
      },
      pie: {
        customScale: 1.0,
        donut: {
          size: showLabels ? '80px' : '88px',
          labels: {
            show: true,
            name: {
              show: showName,
              color: legendColor,
              fontSize: '12px',
              formatter: function (val) {
                return fixedTitle ? title : val
              },
            },
            value: {
              fontSize: '22px',
              fontWeight: 900,
              color: valueColor,
              offsetY: 0,
            },
            total: {
              show: true,
              fontSize: '16px',
              fontWeight: 500,
              color: legendColor,
              label: title,
              formatter: (w: any) => {
                return w.globals.seriesTotals
                  .reduce((a: number, b: number) => a + b, 0)
                  .toFixed(0)
              },
            },
          },
        },
      },
    },
    colors: colors,
    dataLabels: {
      enabled: showLabels,
      style: {
        fontSize: '15px',
        fontWeight: 500,
      },
      formatter: (_: any, opts: any) => {
        return opts.w.config.series[opts.seriesIndex]
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      padding: {
        right: 0,
        bottom: 12,
        left: 10,
      },
    },
    labels: names as string[],
    legend: {
      show: legendShow,
      position: 'bottom',
      fontSize: '11px',
      fontWeight: 500,
      showForZeroSeries: true,
      offsetY: -6,
      itemMargin: {
        vertical: 4,
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
    },
    stroke: {
      show: true,
      curve: 'straight',
      lineCap: 'round',
      colors: undefined,
      width: 1,
      dashArray: 1,
    },
    xaxis: {
      labels: {
        trim: true,
        style: {},
      },
      title: {
        style: {
          fontWeight: 700,
        },
      },
    },
    yaxis: {
      labels: {
        style: {},
      },
      title: {
        style: {
          fontWeight: 700,
        },
      },
    },
  }

  const series = values

  return (
    <Box width="100%">
      <Chart
        id={id}
        options={options}
        series={series}
        type="donut"
        height={heightValue}
      />
    </Box>
  )
}

PieChart.defaultProps = {
  loading: false,
  heightValue: 360,
  legendShow: true,
  showLabels: true,
}

export default PieChart
