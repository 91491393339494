import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { DefaultPageStructure, LoadingFeedback, PageHead } from 'components'
import { ProfileForm } from './components'

import { useSnackbar } from 'hooks'

import service from 'service'
import { formatters } from 'helpers'
import routes from 'constants/routes'

const ProfileUpdate = () => {
  const [loading, setLoading] = useState(false)
  const [profile, setProfile] = useState()

  const { snackbar } = useSnackbar()
  const { profileId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const loadProfile = async () => {
      setLoading(true)
      const profile = await service.dponet.profiles.get({
        profileId,
        params: {},
      })

      setProfile(profile?.data?.profile)
      setLoading(false)
    }

    if (profileId && !profile) loadProfile()
  }, [profileId])

  const onSubmit = async (data: object) => {
    try {
      setLoading(true)
      await service.dponet.profiles.update(profileId || '', { profile: data })

      snackbar.open({
        message: 'Perfil editado com sucesso!',
        variant: 'success',
      })

      navigate(routes.app.profiles.all)
    } catch (error) {
      console.error(error)

      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <DefaultPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead name="Editar perfil de usuário" />
      {profile && <ProfileForm onSubmit={onSubmit} profile={profile} />}
    </DefaultPageStructure>
  )
}

export default ProfileUpdate
