import { colors } from '@mui/material'
import { isEmpty } from 'lodash'

import components from 'utils/jsPDF/components'

import { incidentType } from './Incident.types'

import { formatters } from 'helpers'

import constants from 'constants/index'
import theme from 'theme'

const Incident = ({ pdf, date, incidents }: incidentType) => {
  const GAP = constants.jsPdf.GAP
  const BLANK = 'N/A'

  pdf.addPage('a4', 'p')

  components.subtitle({ pdf, sub: 'Incidentes', positionY: GAP })

  components.lineWithDate({
    pdf,
    date: date,
    color: theme.palette.primary.main,
  })

  components.numberPage({ pdf, color: colors.grey['900'] })

  const tableData = [
    ['ID', incidents?.id],
    [
      'Data do Incidente',
      formatters.date.ptBrFormatDate(incidents?.incidentDatetime),
    ],
    [
      'Hora do Incidente',
      formatters.date.ptBrFormatHours(incidents?.incidentDatetime),
    ],
    [
      'Quantidade estimada de titulares afetados',
      incidents?.affectedHolders || BLANK,
    ],
    ['Como ocorreu o incidente', incidents?.description || BLANK],
    [
      'Por que o incidente ocorreu',
      incidents?.descriptionRootIncident || BLANK,
    ],
    [
      'Medidas adotadas para corrigir as causas do incidente',
      incidents?.descriptionMeasures || BLANK,
    ],
    [
      'Medidas de segurança adotadas antes do incidente',
      incidents?.dataOptionIncidents?.whichSecurityMeasuresAdopted
        ? incidents.dataOptionIncidents.whichSecurityMeasuresAdopted
            .map((measureAdopted) => measureAdopted.name + '\n')
            .join('')
        : BLANK,
    ],
    [
      'Medidas de segurança técnicas e administrativas adotadas antes do incidente',
      incidents?.descriptionOtherTechnicalAdministrativeSecurityMeasure ||
        BLANK,
    ],
    [
      'Formas que o incidente afetou os dados pessoais',
      !isEmpty(incidents?.incidentViolationTypes)
        ? incidents.incidentViolationTypes
            .map((incidentViolation) => incidentViolation?.name + '\n')
            .join('')
        : BLANK,
    ],
    [
      'Tipos de dados pessoais sensíveis violados',
      incidents?.dataOptionIncidents?.typesSensitivePersonalDataBreached
        ? incidents.dataOptionIncidents.typesSensitivePersonalDataBreached
            .map((dataType) => dataType.name + '\n')
            .join('')
        : BLANK,
    ],

    [
      'Demais tipos de dados pessoais violados',
      incidents?.dataOptionIncidents?.otherTypePersonalDataViolated
        ? incidents.dataOptionIncidents.otherTypePersonalDataViolated
            .map((dataType) => dataType.name + '\n')
            .join('')
        : BLANK,
    ],
    [
      'Tipos de dados pessoais não sensíveis violados',
      incidents?.nonSensitiveViolationDescription || BLANK,
    ],
  ]

  components.table({
    pdf,
    body: tableData,
    startY: GAP * 1.5,
    cellWidth: 0.25,
  })

  components.numberPage({ pdf, color: colors.grey['900'] })
}

export default Incident
