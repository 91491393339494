import organizationCompaniesConstant from 'constants/organizationCompanies'

const statusColor = (status: string) => {
  if (status === organizationCompaniesConstant.ACTIVE_STATUS) return 'success'
  if (status === organizationCompaniesConstant.INACTIVE_STATUS) return 'default'

  return 'success'
  // return 'info'QUANDO EXISTIR O STATUS PENDENTE ALTERAR
}

const statusTranslation = (status: string) => {
  if (status === organizationCompaniesConstant.ACTIVE_STATUS) return 'ATIVA'
  if (status === organizationCompaniesConstant.INACTIVE_STATUS) return 'INATIVA'

  return 'ATIVA'
  // return 'PENDENTE' QUANDO EXISTIR O STATUS PENDENTE ALTERAR
}

const complianceColor = (compliance: number) => {
  if (compliance < 25) return 'error'
  if (compliance < 50) return 'warning'
  if (compliance < 75) return 'info'

  return 'success'
}

export default {
  statusColor,
  statusTranslation,
  complianceColor,
}
