const VERY_BAD_RATING = 'very_bad'
const BAD_RATING = 'bad'
const NEUTRAL_RATING = 'neutral'
const GOOD_RATING = 'good'
const VERY_GOOD_RATING = 'very_good'

const TICKET_FEEDBACK_RATING_LABELS = {
  [VERY_BAD_RATING]: 'Muito ruim',
  [BAD_RATING]: 'Ruim',
  [NEUTRAL_RATING]: 'Razoável',
  [GOOD_RATING]: 'Bom',
  [VERY_GOOD_RATING]: 'Muito bom',
} as const

const TICKET_FEEDBACK_RATINGS = [
  VERY_BAD_RATING,
  BAD_RATING,
  NEUTRAL_RATING,
  GOOD_RATING,
  VERY_GOOD_RATING,
] as const

export default {
  TICKET_FEEDBACK_RATINGS,
  TICKET_FEEDBACK_RATING_LABELS,
  VERY_BAD_RATING,
  BAD_RATING,
  NEUTRAL_RATING,
  GOOD_RATING,
  VERY_GOOD_RATING,
}
