import { isUndefined, isEmpty } from 'lodash'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material'

import { MUI } from 'components'

import { IncidentOptionType } from 'types/incidents.types'

import { IncidentCheckboxType, SimpleOption } from './incidentCheckbox.types'

import { incidents as incidentsHelper } from 'helpers'

const IncidentViolation = ({
  label,
  options,
  optionsSelectedItems,
  incidentOptions,
  incidentOptionsSelectedItems,
  other = false,
}: IncidentCheckboxType) => {
  const incidentOptionChecked = (option: IncidentOptionType) => {
    if (isUndefined(incidentOptionsSelectedItems)) return false

    return incidentOptionsSelectedItems
      .map((item) => item?.keyName)
      .includes(option?.keyName)
  }

  const optionChecked = (option: SimpleOption) => {
    if (isUndefined(optionsSelectedItems)) return false

    return optionsSelectedItems
      .map((item) => item?.value)
      .includes(option?.value)
  }

  const otherOption = incidentsHelper.otherOption(
    incidentOptionsSelectedItems || []
  )
  const otherOptionSelected = !isEmpty(otherOption)

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <Box px={0}>
        <Typography variant="body1" fontWeight="500" gutterBottom>
          {label}
        </Typography>
      </Box>
      <FormGroup>
        {options?.map((option, index) => (
          <Box px={0} key={index}>
            <FormControlLabel
              control={
                <MUI.Checkbox disabled checked={optionChecked(option)} />
              }
              label={option?.name}
            />
            {option?.description && (
              <Typography variant="subtitle2">{option?.description}</Typography>
            )}
          </Box>
        ))}
        {incidentOptions?.map((option, index) => (
          <Box px={0} key={index}>
            <FormControlLabel
              control={
                <MUI.Checkbox
                  disabled
                  checked={incidentOptionChecked(option)}
                />
              }
              label={option?.name}
            />
          </Box>
        ))}
        {other && (
          <Box px={0}>
            <FormControlLabel
              control={<MUI.Checkbox disabled checked={otherOptionSelected} />}
              label="Outras"
            />
          </Box>
        )}

        {otherOptionSelected && (
          <TextField
            type="text"
            minRows={5}
            multiline
            fullWidth
            disabled
            defaultValue={otherOption}
          />
        )}
      </FormGroup>
    </FormControl>
  )
}

export default IncidentViolation
