import { useEffect, useState } from 'react'
import { Box, Paper, Typography } from '@mui/material'
import { isEmpty } from 'lodash'

import { ButtonGroup, MUI } from 'components'

import { usePermission } from 'hooks'

import { validates } from 'helpers'

import { TicketAssignedType } from './ticketAssigned.types'
import { OptionsType } from 'components/ButtonGroup/buttonGroup.types'

import constants from 'constants/index'

const TicketAssigned = ({
  ticket,
  organizationUsers,
  responsibleButtonGroup,
  setResponsibleButtonGroup,
  statusButtonGroup,
  isJuridic,
}: TicketAssignedType) => {
  const [options, setOptions] = useState<OptionsType[]>([])

  const { isPermitted } = usePermission()

  const permissionTag =
    ticket.ticketType === constants.tickets.JURIDIC_TICKET_KIND
      ? constants.permissionTags.JURIDIC_TICKET.MANAGE
      : constants.permissionTags.SOLICITATION_TICKET.MANAGE

  const formType = () => {
    return isJuridic ? 'juridic-ticket-content-form' : '"ticket-content-form"'
  }

  useEffect(() => {
    const extractOptions = () => {
      const extractedOptions = organizationUsers.map((item) => ({
        id: item?.user?.id,
        name: item?.user?.name,
      }))
      setOptions(extractedOptions)
    }

    extractOptions()
  }, [])

  return (
    <Paper component={Box} p={5} gap={2} display="flex" flexDirection="column">
      {isJuridic ? (
        <Box display="flex" alignItems="center" gap={2}>
          <MUI.Chip label={ticket?.ticketRef} variant="filled" size="medium" />
          <MUI.Chip
            label={ticket?.status?.name.toUpperCase()}
            variant="filled"
            size="medium"
            $background={validates.tickets.statusBackgroundColor(
              ticket?.status?.id
            )}
            $color="white"
            $fontWeight={500}
          />
        </Box>
      ) : (
        <Typography variant="subtitle1" fontWeight={500} color="primary">
          Ticket:&nbsp;
          {ticket?.ticketRef ? ticket?.ticketRef : '-'}
        </Typography>
      )}
      <Box>
        <Typography variant="subtitle2">
          {ticket?.responsible?.name ? 'Atribuido para:' : ''}
        </Typography>
        {!isEmpty(options) && ticket && (
          <ButtonGroup
            options={options}
            title={!ticket?.responsible?.name ? 'ATRIBUIR PARA' : ''}
            fullWidth
            minTitleWidth={103}
            buttonGroup={responsibleButtonGroup}
            setButtonGroup={setResponsibleButtonGroup}
            disableTouchRipple
            tooltipPlacement="left"
            form={formType()}
            disabled={
              !isPermitted(permissionTag) ||
              statusButtonGroup ===
                constants.tickets.WAITING_CONFIRMATION_TICKET_ID
            }
          />
        )}
      </Box>
    </Paper>
  )
}

export default TicketAssigned
