import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material'

import { Table, TableRow } from 'components'

import { NonComplianceReportActionsSectionType } from './nonComplianceReportActionsSection.types'

import { formatters } from 'helpers'
import { isEmpty } from 'lodash'

const NonComplianceReportActionsSection = ({
  nonComplianceReport,
}: NonComplianceReportActionsSectionType) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Planos de ação
      </Typography>
      {isEmpty(nonComplianceReport?.nonComplianceReportActions) ? (
        <Typography variant="body2">
          Não há planos de ação cadastrados.
        </Typography>
      ) : (
        <TableContainer>
          <Table aria-label="Planos de ação">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Responsável</TableCell>
                <TableCell>Ação corretiva</TableCell>
                <TableCell>Evidência da implantação</TableCell>
                <TableCell>Prazo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonComplianceReport?.nonComplianceReportActions.map(
                (nonComplianceReportAction) => (
                  <TableRow key={nonComplianceReportAction.id}>
                    <TableCell>{nonComplianceReportAction.id}</TableCell>
                    <TableCell>
                      {nonComplianceReportAction.responsableUser.name}
                    </TableCell>
                    <TableCell>
                      {nonComplianceReportAction.correctiveAction}
                    </TableCell>
                    <TableCell>
                      {nonComplianceReportAction.correctionEvidence}
                    </TableCell>
                    <TableCell>
                      {formatters.date.ptBrFormatDate(
                        nonComplianceReportAction.deadlineDate
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default NonComplianceReportActionsSection
