import { Box, Typography, colors } from '@mui/material'

import { DataProcessInfoChipType } from './dataProcessInfoChip.types'

import theme from 'theme'

const DataProcessInfoChip = ({
  text,
  color,
  textColor,
  complement,
}: DataProcessInfoChipType) => {
  return (
    <Box my={1} mr={1} display="flex">
      <Box
        display="flex"
        bgcolor={color ?? colors.grey[100]}
        borderRadius={5}
        pt={0.5}
        px={2}
      >
        <Typography
          color={textColor ?? theme.palette.secondary.main}
          fontWeight={500}
          variant="body2"
        >
          {text}
          {!!complement && (
            <Typography
              color={colors.blueGrey[600]}
              fontWeight={500}
              variant="caption"
            >
              {` - ${complement}`}
            </Typography>
          )}
        </Typography>
      </Box>
    </Box>
  )
}

export default DataProcessInfoChip
