import { EntityType } from '../components/AllowObservers/allowObserver.types'

const observersNameWithComma = (entity: EntityType | undefined) => {
  const observer_comma = entity?.observers?.map((observer) => {
    return `${observer.name}, `
  })

  return observer_comma?.join('')?.slice(0, -2)
}

export default { observersNameWithComma }
