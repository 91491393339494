import { useState, useEffect, useRef } from 'react'
import { isEmpty } from 'lodash'
import { Grid, Typography, Box, CircularProgress, Link } from '@mui/material'
import { isNull } from 'lodash'

import { AttachedDocumentsType } from './attachedDocuments.types'

import { useSnackbar } from 'hooks'

import { AttachedDocumentsTable } from './components'

const AttachedDocuments = ({
  dataDocument,
  setDataDocument,
}: AttachedDocumentsType) => {
  const [confirmAddDocument, setConfirmAddDocument] = useState(false)
  const [loadingDocument, setLoadingDocument] = useState(false)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const { snackbar } = useSnackbar()

  useEffect(() => {
    const inputElement = document.getElementById(
      'documents-tomticket'
    ) as HTMLInputElement | null
    if (!confirmAddDocument && inputElement) {
      inputElement.value = ''
    }
  }, [confirmAddDocument])

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const onChangeCreate = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    const files = e.target.files
    const arrayData: File[] = []

    if (isNull(files)) return

    try {
      setLoadingDocument(true)

      Array.from(files).forEach((file) => arrayData.push(file))

      setDataDocument((documents: File[]) => [...documents, ...arrayData])
      setConfirmAddDocument(true)

      e.target.value = ''
    } catch (error) {
      snackbar.open({
        message: 'Ocorreu um erro ao adicionar o(s) documento(s)!',
        variant: 'error',
      })
    }
    setLoadingDocument(false)
  }

  const handleDelete = (fileName: string) => {
    const filterDelete = dataDocument.filter(
      (document) => document.name !== fileName
    )
    setDataDocument(filterDelete)
  }

  return (
    <>
      <Box padding={2}>
        {!loadingDocument ? (
          <Box>
            <Typography variant="body1">
              Para anexar arquivos,&nbsp;
              <Link
                href="#"
                underline="hover"
                color="inherit"
                onClick={handleClick}
              >
                Clique aqui.
              </Link>
            </Typography>
            <Typography variant="caption">
              Arquivos suportados: xlsx, xls, imagens, doc, docx, ppt, pptx,
              txt, pdf
            </Typography>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
      </Box>
      <div>
        <input
          multiple
          type="file"
          id="documents-tomticket"
          accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
          style={{ display: 'none' }}
          onChange={onChangeCreate}
          ref={fileInputRef}
        />
      </div>
      <Grid container xs={12} item>
        {!isEmpty(dataDocument) && (
          <AttachedDocumentsTable
            dataDocument={dataDocument}
            handleDelete={handleDelete}
          />
        )}
      </Grid>
    </>
  )
}

export default AttachedDocuments
