import { FormEvent, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { PaperHeader } from 'components'

import { useAuth } from 'hooks'

import { TwoFactorAuthenticationType } from './twoFactorAuthentication.types'

const TwoFactorAuthentication = ({
  onSubmit,
  organization,
}: TwoFactorAuthenticationType) => {
  const { user } = useAuth()

  const [active, setActive] = useState(!!organization?.twoFactorAuthentication)
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    await onSubmit({ twoFactorAuthentication: active }, false)
  }

  return (
    <form id="two-factor-authentication-form" onSubmit={handleSubmit}>
      <Paper component={Box} p={2} mb={6}>
        <PaperHeader
          title="Verificação em duas etapas"
          actionButton={
            <Button
              color="secondary"
              form="two-factor-authentication-form"
              type="submit"
              variant="contained"
            >
              SALVAR
            </Button>
          }
        />
        <Box px={2} pt={4} pb={2}>
          <Typography paragraph>
            A verificação em duas etapas é uma medida de segurança que adiciona
            um segundo fator de autenticação para acessar sua conta, além da sua
            senha.
          </Typography>
          <Box mb={2}>
            <FormControl>
              <FormLabel id="radio-two-factor-authentication">
                Ativar verificação em duas etapas?
              </FormLabel>
              <RadioGroup
                row
                onChange={() => setActive((active) => !active)}
                defaultValue={organization?.twoFactorAuthentication}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio checked={active} />}
                  label="SIM"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio checked={!active} />}
                  label="NÃO"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Typography gutterBottom>
            Seu e-mail de verificação é {user?.email}
          </Typography>
        </Box>
      </Paper>
    </form>
  )
}

export default TwoFactorAuthentication
