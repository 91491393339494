import { Box, Typography } from '@mui/material'

import { InformativeBox, MUI } from 'components'

import { DetailsStatusType } from './detailsStatus.types'

import nonComplianceReportConstant from 'constants/nonComplianceReport'
import theme from 'theme'

const DetailsStatus = ({ title, nonComplianceStats }: DetailsStatusType) => {
  return (
    <InformativeBox title={title}>
      <Box display="flex" flexDirection="column" gap={3}>
        {nonComplianceReportConstant.ALL_STATUSES.map(
          (nonComplianceReportStatus) => {
            const statusInfo =
              nonComplianceReportConstant.STATUSES_INFO[
                nonComplianceReportStatus
              ]

            return (
              <Box
                key={title + nonComplianceReportStatus}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1">{statusInfo.label}</Typography>
                <MUI.Chip
                  label={nonComplianceStats[nonComplianceReportStatus]}
                  $color={statusInfo.color}
                  $background={statusInfo.background}
                  $width={theme.spacing(20)}
                />
              </Box>
            )
          }
        )}
      </Box>
    </InformativeBox>
  )
}

export default DetailsStatus
