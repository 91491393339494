import { useState } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'

import { BasicDialog } from 'components'

import { useAuth, useOrganization } from 'hooks'

import { SelectOrganizationDialogType } from './selectOrganizationDialog.types'
import { SelectOrganizationDialogSchemaType } from './selectOrganizationDialogSchema'

import organizationUsersConstants from 'constants/organizationUsers'
import organizationsConstants from 'constants/organizations'
import selectOrganizationDialogSchema from './selectOrganizationDialogSchema'
import { formatters } from 'helpers'
import service from 'service'

const SelectOrganizationDialog = ({
  open,
  onClose,
  onSave,
  observer = false,
}: SelectOrganizationDialogType) => {
  const [errorMessage, setErrorMessage] = useState<undefined | string>()

  const { organizations } = useOrganization()
  const { user } = useAuth()

  const organizationKind = observer
    ? organizationsConstants.KIND_OBSERVER
    : organizationsConstants.KIND_DPO
  const organizationKindLabel = observer ? 'Observador' : 'DPO'

  const availableOrganizations = organizations.filter((organization) => {
    const active =
      organization.organizationUser.status ===
      organizationUsersConstants.ACTIVE_STATUS
    const kind = organization.kind === organizationKind
    const author = organization.author.id === user?.id

    return active && kind && author
  })

  const hasAvailableorganizations = !isEmpty(availableOrganizations)

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(selectOrganizationDialogSchema),
    defaultValues: {
      action: hasAvailableorganizations ? 'link' : 'create',
      organization: '',
      organizationId: '',
    },
  })

  const onSubmit = async (data: SelectOrganizationDialogSchemaType) => {
    try {
      if (data.action === 'create') {
        const response = await service.dponet.organization.create({
          name: data.organization,
          kind: organizationKind,
        })

        data.organizationId = response.data?.organization?.id
      }

      onSave(data)
    } catch (error) {
      console.error(error)
      setErrorMessage(formatters.errorMessage(error, false))
    }
  }

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      title="Vincule a um grupo"
      form="select-organization-form"
    >
      <Typography
        variant="body1"
        fontWeight={500}
        textAlign="justify"
        gutterBottom
      >
        Bem-vindo(a)! Estamos empolgados por tê-lo(a) a bordo como&nbsp;
        {organizationKindLabel}.
      </Typography>

      <Typography variant="body2" textAlign="justify" gutterBottom>
        Para começar a utilizar todas as funcionalidades e recursos que nossa
        plataforma oferece, é necessário participar de um área de trabalho,
        chamado &quot;
        <strong>Grupo Advisor {organizationKindLabel}</strong>&quot;. Essa área
        será o ponto central das suas atividades e interações com outros
        colaboradores.
      </Typography>

      <Typography variant="body2" textAlign="justify">
        Ao criar a área de trabalho, você poderá:
      </Typography>

      <ul>
        <li>
          <Typography variant="body2" textAlign="justify">
            Acessar informações do Dashboard.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" textAlign="justify">
            Gerar relatórios.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" textAlign="justify">
            Acompanhar a organização na adequação à LGPD.
          </Typography>
        </li>
        {!observer && (
          <>
            <li>
              <Typography variant="body2" textAlign="justify">
                Realizar enquadramentos legais.
              </Typography>
            </li>
            <li>
              <Typography variant="body2" textAlign="justify">
                Atender às solicitações dos titulares de dados.
              </Typography>
            </li>
          </>
        )}
      </ul>

      <Box
        component="form"
        id="select-organization-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="action"
          render={({ field }) => (
            <RadioGroup
              row
              aria-labelledby="organization-type-action"
              {...field}
            >
              <FormControlLabel
                value="create"
                control={<Radio />}
                label="Criar Grupo"
              />
              <FormControlLabel
                value="link"
                control={<Radio />}
                disabled={!hasAvailableorganizations}
                label="Vincular Grupo"
              />
            </RadioGroup>
          )}
        />

        {watch('action') === 'create' && (
          <Controller
            name="organization"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Criar grupo"
                type="text"
                fullWidth
                helperText={
                  errorMessage ??
                  errors?.organization?.message ??
                  'Máximo de 50 caracteres'
                }
                error={!!errorMessage || !!errors.organization?.message}
              />
            )}
          />
        )}

        {watch('action') === 'link' && (
          <Controller
            name="organizationId"
            control={control}
            render={({ field }) => (
              <FormControl
                fullWidth
                margin="dense"
                error={!!errors.organizationId?.message}
              >
                <InputLabel id="organization-id-select-label">
                  Vincular Grupo
                </InputLabel>
                <Select
                  {...field}
                  labelId="organization-id-select-label"
                  id="organization-id-select"
                  label="Vincular Grupo"
                >
                  {availableOrganizations?.map((organization) => (
                    <MenuItem
                      key={organization.id}
                      value={String(organization.id)}
                    >
                      {organization.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {errors.organizationId?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        )}
      </Box>
    </BasicDialog>
  )
}

export default SelectOrganizationDialog
