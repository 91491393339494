import { colors } from '@mui/material'

import { NumberAndTitleType } from './numberAndTitle.types'

import { ratio } from '../../helpers'
import constants from 'constants/index'
import theme from 'theme'

const numberAndTitle = ({ pdf, number, title }: NumberAndTitleType) => {
  const PADDING = constants.jsPdf.PADDING
  const FONT_SIZE = constants.jsPdf.FONT_SIZE

  pdf.setFontSize(FONT_SIZE.CHAPTER_NUMBER)
  pdf.setTextColor(colors.pink[700])
  pdf.text(number, PADDING, ratio(430), undefined, 'left')
  pdf.setFontSize(FONT_SIZE.CHAPTER_TITLE)
  pdf.setTextColor(theme.palette.primary.main)
  pdf.setFont('Raleway-Bold')
  pdf.text(title, PADDING, ratio(650), undefined, 'left')
}

export default numberAndTitle
