import api from 'service/api'

import { PayloadType } from 'views/Organization/components/PlatformCustomizationBox/schema'

const dponetAPI = api.create('dponet')

type ThemeRequestType = {
  organizationId?: string | number
}

const postTheme = async (client_portal_theme: PayloadType) => {
  return await dponetAPI.post(`/advisors/organizations/client_portal_themes`, {
    client_portal_theme,
  })
}

const updateTheme = async (
  client_portal_theme_id: number,
  client_portal_theme: PayloadType
) => {
  return await dponetAPI.put(
    `/advisors/organizations/client_portal_themes/${client_portal_theme_id}`,
    { client_portal_theme }
  )
}

const getTheme = async ({ organizationId = '' }: ThemeRequestType) =>
  await dponetAPI.get(
    `/advisors/organizations/client_portal_themes/${organizationId}`
  )

const postClientPortalThemeCompany = async (
  client_portal_theme_company: object
) => {
  return await dponetAPI.post(
    `/advisors/organizations/client_portal_theme_companies`,
    { client_portal_theme_company }
  )
}

const updateClientPortalThemeCompany = async (
  client_portal_theme_company: object,
  client_portal_theme_company_id: number
) => {
  return await dponetAPI.put(
    `/advisors/organizations/client_portal_theme_companies/${client_portal_theme_company_id}`,
    { client_portal_theme_company }
  )
}

const getStatus = async (company_id: number) =>
  await dponetAPI.get(
    `/advisors/organizations/client_portal_theme_companies/${company_id}`
  )

export default {
  postTheme,
  getTheme,
  updateTheme,
  postClientPortalThemeCompany,
  updateClientPortalThemeCompany,
  getStatus,
}
