import ProfileMain from './ProfileMain'
import ProfileNew from './ProfileNew'
import ProfileUpdate from './ProfileUpdate'

const Organization = {
  ProfileMain,
  ProfileNew,
  ProfileUpdate,
}

export default Organization
