import * as yup from 'yup'

const envProduction = process.env.REACT_APP_API === 'production'

const schema = yup
  .object({
    email: yup.string().email().required(),
    name: yup.string().required(),
    password: yup
      .string()
      .required()
      .min(8)
      .matches(
        //eslint-disable-next-line
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ `!*@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,}$/,
        'Sua nova senha deve possuir letras minúsculas, maiúsculas, números e pelo menos um caractere especial'
      ),
    confirmPassword: yup.string().required(),
    confirmTerms: yup.bool().oneOf([true], 'Aceite os termos de uso'),
    recaptcha: envProduction ? yup.string().required() : yup.string(),
  })
  .required()

export default schema
