import { Box, styled } from '@mui/material'

const CompanyBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  margin: theme.spacing(4, 0),
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(4),
}))

export default CompanyBox
