import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const GridStructure = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '72px 1fr',
  gridTemplateAreas: `
    "header"
    "body"
  `,
  minHeight: '100vh',
}))

export default GridStructure
