import api from 'service/api'

const dponetAPI = api.create('dponet')

const dponetAPIWithNoTreatError = api.create('dponet', false)

type replyDpoType = {
  answersDpo?: {
    liaQuestionOrder: string
    answer: string | Date | undefined
  }[]
  dataProcessId?: string | number
  conclusionStep?: boolean
}

const get = async (params?: object) => {
  return await dponetAPI.get(`/advisors/lia_answers`, { params })
}

const replyDpo = async ({
  answersDpo,
  dataProcessId,
  conclusionStep,
}: replyDpoType) => {
  return await dponetAPIWithNoTreatError.put(
    `/advisors/lia_answers/reply_dpo`,
    { answersDpo: answersDpo, dataProcessId, conclusionStep }
  )
}

const liaAnswer = { get, replyDpo }

export default liaAnswer
