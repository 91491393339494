import { useContext } from 'react'

import { IncidentManegementContext } from 'contexts'

const useIncidentManegement = () => {
  const context = useContext(IncidentManegementContext)

  if (context === undefined) {
    throw new Error(
      'useIncidentManegement must be used within a IncidentManegementContext.Provider'
    )
  }

  return context
}

export default useIncidentManegement
