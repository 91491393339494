import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import qs from 'query-string'

const useSearchCompany = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const search = qs.parse(location.search)
  const companyId = search?.companyId as string
  const tab = search?.tab as string

  const [query, setQuery] = useState({ companyId: companyId, tab: tab })

  const handleSearch = (newCompanyId: string, newTab: string) => {
    setQuery({ companyId: newCompanyId, tab: newTab })
  }

  useEffect(() => {
    navigate(`${location.pathname}?${qs.stringify(query)}`)
  }, [location.pathname, query, navigate])

  return { companyId, tab, handleSearch }
}

export default useSearchCompany
