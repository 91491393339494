import api from 'service/api'

const dponetAPIWithNoTreatError = api.create('dponet', false)

const get = async ({
  faqsDocumentationId = '',
  params = {},
}: {
  faqsDocumentationId?: string | number
  params?: object
}) => {
  return await dponetAPIWithNoTreatError.get(
    `/advisors/faqs_documentations/${faqsDocumentationId}`,
    {
      params,
    }
  )
}

const getCategories = async ({
  faqsDocumentationsCategoryId = '',
  params = {},
}: {
  faqsDocumentationsCategoryId?: string | number
  params?: object
}) => {
  return await dponetAPIWithNoTreatError.get(
    `/advisors/faqs_documentations_categories/${faqsDocumentationsCategoryId}`,
    {
      params,
    }
  )
}
export default { get, getCategories }
