import { lastCoverType } from './lastCover.types'

import { ratio } from '../../helpers'
import constants from 'constants/index'
import theme from 'theme'
import { logoAdvisorWhite } from '../../assets/logoAdvisorWhite64'

const lastCover = ({ pdf }: lastCoverType) => {
  const PADDING = constants.jsPdf.PADDING
  const WIDTH_PDF = constants.jsPdf.WIDTH
  const HEIGHT_PDF = constants.jsPdf.HEIGHT

  const WIDTH = WIDTH_PDF(pdf)
  const HEIGHT = HEIGHT_PDF(pdf)

  pdf.addPage('a4', 'p')

  pdf.setFillColor(theme.palette.primary.main)
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')

  pdf.addImage(
    logoAdvisorWhite,
    'png',
    WIDTH / 2 - PADDING * 1.25,
    HEIGHT / 2,
    ratio(500),
    ratio(203)
  )
}

export default lastCover
