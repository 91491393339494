import InviteObserver from './InviteObserver'
import InviteOrganization from './InviteOrganization'
import InviteOutsourcedDpo from './InviteOutsourcedDpo'

const Invite = {
  InviteObserver,
  InviteOrganization,
  InviteOutsourcedDpo,
}

export default Invite
