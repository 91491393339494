import { isNil } from 'lodash'

import { formatters } from 'helpers'

import { TicketMessageType } from 'types/ticketMessages.types'
import { ChipColorType } from 'types/materialUI.types'
import { TicketKindType } from 'constants/tickets'
import { TicketType } from 'types/ticket.types'
import tickets, {
  MessageTicketIdType,
  StatusTicketIdType,
} from 'constants/tickets'

import constants from 'constants/index'

const statusBackgroundColor = (statusId: StatusTicketIdType) => {
  return tickets.CHIP_STATUS_BACKGROUND_COLOR[statusId] || ''
}

const discoveringKindMessage = (kind: MessageTicketIdType) => {
  return tickets.MESSAGE_TYPE[kind] || '-'
}

const colorKindMessage = (kind: MessageTicketIdType, option: string) => {
  if (option === 'background') {
    return tickets.MESSAGE_TYPE_BOX_COLOR[kind] || 'primary'
  } else if (option === 'icon') {
    return tickets.MESSAGE_TYPE_ICON_COLOR[kind] || 'primary'
  }
}

const labelTranslation = (kind: TicketKindType) => {
  return constants.tickets.TICKET_KIND_TRANSLATION[kind] || '-'
}

const deadlineChipTranslation = (deadline?: number | null): string => {
  if (isNil(deadline)) return 'N/A'

  return deadline >= 1
    ? `${deadline} DIA${deadline !== 1 ? 'S' : ''}`
    : 'ATRASADA'
}

const deadlineChipColor = (deadline: number): ChipColorType => {
  return deadline >= 7 ? 'success' : deadline <= 3 ? 'error' : 'warning'
}

const resolutionDate = (
  ticket: TicketType,
  ticketMessages: TicketMessageType[]
) => {
  const isFinished =
    ticket?.status?.id === constants.tickets.FINISHED_TICKET_ID ||
    ticket?.status?.id === constants.tickets.CANCELED_TICKET_ID

  const dateTime = ticketMessages
    ?.filter((message) => message?.kind === 2)
    .map((message) => message.createdAt)
    .shift()

  return (
    isFinished &&
    formatters.date.ptBrFormatDateTime(dateTime, 'Não possui data definida')
  )
}

export default {
  statusBackgroundColor,
  discoveringKindMessage,
  colorKindMessage,
  labelTranslation,
  deadlineChipTranslation,
  deadlineChipColor,
  resolutionDate,
}
