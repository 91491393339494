import { TextField } from '@mui/material'

import { LabelDescription } from 'components'

import { IncidentTextAreaType } from './incidentTextArea.types'

const IncidentTextArea = ({ label, value = '' }: IncidentTextAreaType) => {
  return (
    <LabelDescription title={label}>
      <TextField
        type="text"
        minRows={5}
        multiline
        fullWidth
        disabled
        defaultValue={value}
      />
    </LabelDescription>
  )
}

export default IncidentTextArea
