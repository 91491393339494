import { Box, Typography } from '@mui/material'

import { ComplianceType } from './compliance.types'

import theme from 'theme'

const Compliance = ({ gapCompliance }: ComplianceType) => {
  return (
    <Box
      bgcolor={theme.palette.primary.main}
      color={theme.palette.primary.contrastText}
      p={5}
      borderRadius={1}
      height="100%"
    >
      <Box display="flex" alignItems="flex-end">
        <Typography variant="h1" fontFamily="Roboto">
          {gapCompliance.toFixed(0)}
        </Typography>
        <Typography variant="h6" fontFamily="Roboto">
          %
        </Typography>
      </Box>
      <Typography variant="overline">CONFORMIDADE DE QUESTIONÁRIOS</Typography>
    </Box>
  )
}

export default Compliance
