import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { BasicDialog } from 'components'

import { ReasonOutsourcedDpoDialogType } from './reasonDialog.types'
import schema from './reasonOutsourcedDpoDialogSchema'

const ReasonOutsourcedDpoDialog = ({
  onClose,
  open,
  company,
  onSave,
  observer,
}: ReasonOutsourcedDpoDialogType) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      reason: 'Não reconheço essa empresa',
      anotherReason: '',
    },
  })

  const typeTitle = observer ? 'Observador' : 'DPO Responsável'
  const title = `Você foi convidado para participar como ${typeTitle} da ${company?.companyName}`

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title={title}
      maxWidth="md"
      dialogActions={
        <>
          <Button onClick={onClose}>CANCELAR</Button>
          <Button
            color="error"
            variant="contained"
            type="submit"
            form="outsourced-dpo-reason-form"
          >
            CONFIRMAR
          </Button>
        </>
      }
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Typography variant="body1" fontWeight="500">
          {company?.name}
        </Typography>

        <Chip label="RECUSAR CONVITE" color="error" variant="outlined" />
      </Box>

      <form onSubmit={handleSubmit(onSave)} id="outsourced-dpo-reason-form">
        <Controller
          control={control}
          name="reason"
          render={({ field }) => (
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Não reconheço essa empresa"
              {...field}
            >
              <FormControlLabel
                value="Não reconheço essa empresa"
                control={<Radio />}
                label="Não reconheço essa empresa"
              />
              <FormControlLabel
                value="Tenho outros projetos que me impossibilitam de participar"
                control={<Radio />}
                label="Tenho outros projetos que me impossibilitam de participar"
              />
              <FormControlLabel
                value="O convite não era pra mim"
                control={<Radio />}
                label="O convite não era pra mim"
              />
              <FormControlLabel
                value="another"
                control={<Radio />}
                label="Outro"
              />
            </RadioGroup>
          )}
        />

        {watch('reason') === 'another' && (
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.anotherReason}
                helperText={errors?.anotherReason?.message}
                label="Especifique"
                type="text"
                variant="outlined"
                fullWidth
              />
            )}
            name="anotherReason"
            control={control}
          />
        )}
      </form>
    </BasicDialog>
  )
}

ReasonOutsourcedDpoDialog.defaultProps = {
  observer: false,
}

export default ReasonOutsourcedDpoDialog
