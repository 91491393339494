import { isEmpty } from 'lodash'
import { TitularAgeRangeType } from 'types/dataCollected.type'

const rangeAgeContent = (titularRangeAges: TitularAgeRangeType[]) => {
  const ranges = titularRangeAges?.map((range) => range.name)
  if (isEmpty(ranges)) return 'Nenhuma faixa etária'

  return ranges.toString().replaceAll(',', ' - ')
}

export default rangeAgeContent
