import { FieldType } from './form.types'

export const fields: FieldType = [
  {
    name: 'name',
    type: 'text',
    label: 'Nome',
    placeholder: 'Digite o seu nome',
  },
  {
    name: 'email',
    type: 'email',
    label: 'E-mail',
    placeholder: 'Digite o seu e-mail',
  },
  {
    name: 'password',
    type: 'password',
    label: 'Senha',
    placeholder: 'Digite a sua senha',
    showPasswordValidator: true,
    props: {
      autoComplete: 'on',
    },
  },
  {
    name: 'confirmPassword',
    type: 'password',
    label: 'Confirmar senha',
    placeholder: 'Confirme a sua senha',
    props: {
      autoComplete: 'on',
    },
  },
]
