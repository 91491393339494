import { Popper as PopperMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'

const Popper = styled(PopperMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $zIndex?: Property.ZIndex | undefined
}>(({ $zIndex }) => ({
  zIndex: $zIndex,
}))

export default Popper
