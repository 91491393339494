import { useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'

import { BasicDialog, LoadingFeedback } from 'components'

import { DialogConfirmationPossibilitiesType } from './dialogConfirmationDialogPossibilities.types'

import schema from './schema'
import service from 'service'
import { useSnackbar } from 'hooks'

const DialogConfirmationPossibilities = ({
  onClose,
  open,
  result,
  ticketId,
  refresh,
}: DialogConfirmationPossibilitiesType) => {
  const [loading, setLoading] = useState(false)

  const { snackbar } = useSnackbar()
  const { control, getValues, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { comment: '' },
  })

  const onSubmit = async () => {
    try {
      setLoading(true)
      const params = getValues()

      await service.dponet.ticket.reviewLegitimacySolicitation({
        ticketId,
        ticketSteps: { result: result, info: { comment: params.comment } },
      })

      snackbar.open({
        visible: true,
        message: 'Solicitação revisada com sucesso',
        variant: 'success',
      })

      onClose()
      refresh()

      setLoading(false)
    } catch (error) {
      setLoading(false)
      snackbar.open({
        visible: true,
        message: 'Ocorreu um erro ao revisar a solicitação',
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    setValue('comment', '')
  }, [open])

  return (
    <BasicDialog
      open={open}
      title="Confirmação"
      onClose={onClose}
      dialogActions={
        <Box display="flex" gap={2}>
          <Button onClick={onClose}>CANCELAR</Button>
          <Button color="primary" variant="contained" onClick={onSubmit}>
            CONFIRMO
          </Button>
        </Box>
      }
    >
      <LoadingFeedback open={loading} />

      <Typography variant="body1">
        {result
          ? 'Você confirma que essa solicitação é possível?'
          : 'Você confirma que essa solicitação não é possível?'}
      </Typography>

      {!result && (
        <Box mt={4}>
          <Controller
            name="comment"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Comentários (opcional)"
                placeholder="Descreva o motivo"
                variant="outlined"
                multiline
                rows={4}
              />
            )}
          />
        </Box>
      )}
    </BasicDialog>
  )
}

export default DialogConfirmationPossibilities
