import { useState } from 'react'
import { Box } from '@mui/material'

import { DefaultTicketMessage, TicketMessageNotification } from './components'

import { HiddenContentType, TicketMessageType } from './ticketMessage.types'

const TicketMessage = ({ ticket, ticketMessages }: TicketMessageType) => {
  const [hiddenContent, setHiddenContent] = useState<HiddenContentType>({})

  const handleClickChange = (messageId: number | string) => {
    setHiddenContent((prevState) => ({
      ...prevState,
      [messageId]: !prevState[messageId],
    }))
  }

  return (
    <Box display="flex" flexDirection="column" gap={4} paddingRight={3} py={1}>
      {ticketMessages?.map((message) => (
        <Box key={message?.id} display="flex" flexDirection="column" gap={4}>
          {message?.ticketMessageInteractions?.map((interaction, index) => (
            <Box key={index}>
              <TicketMessageNotification interaction={interaction} />
            </Box>
          ))}

          <DefaultTicketMessage
            ticket={ticket}
            message={message}
            handleClickChange={handleClickChange}
            hiddenContent={hiddenContent}
          />
        </Box>
      ))}
    </Box>
  )
}

export default TicketMessage
