import { styled } from '@mui/material/styles'

const LogoAdvisor = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(4),
  maxHeight: '100%',
  height: 'auto',
  maxWidth: 108,
  alignSelf: 'center',
}))

export default LogoAdvisor
