import { Box, Typography } from '@mui/material'

import { AnswerPieChart } from './components'

import { ComplianceCardType } from './complianceCard.types'

import theme from 'theme'
import { dashboard as dashboardHelper } from 'helpers'

const Compliance = ({
  gapCompliance,
  questionnaireStats,
}: ComplianceCardType) => {
  const generalQuestionnaireStats = dashboardHelper.questionnaires.general(
    questionnaireStats ?? []
  )

  return (
    <Box
      bgcolor={theme.palette.primary.main}
      color={theme.palette.primary.contrastText}
      p={5}
      borderRadius={1}
      height="100%"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="flex-end">
          <Typography variant="h1" fontFamily="Roboto">
            {gapCompliance.toFixed(0)}
          </Typography>
          <Typography variant="h6" fontFamily="Roboto">
            %
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-end">
          <Typography variant="body2">MINHA CONFORMIDADE</Typography>
        </Box>
      </Box>
      <Box>
        <AnswerPieChart generalQuestionnaireStats={generalQuestionnaireStats} />
      </Box>
    </Box>
  )
}

export default Compliance
