import * as yup from 'yup'

const schema = yup.object({
  confuse: yup.boolean(),
  incomplete: yup.boolean(),
  outOfContext: yup.boolean(),
  notInCompliance: yup.boolean(),
  other: yup.boolean(),
  otherReason: yup.string(),
})

export type FeedbackDialogFormType = yup.InferType<typeof schema>

export default schema
