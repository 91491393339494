import { Box } from '@mui/material'

import { InformativeBox, LinearProgressWithTitleAndLabel } from 'components'

import { ProgressType } from './progress.types'
import { QuestionnaireType } from 'types/organizationCompany'

const Progress = ({ questionnaires }: ProgressType) => {
  const calculateQuestionnaireProgress = (questionnaire: QuestionnaireType) => {
    if (questionnaire.totalQuestions === 0) return 0

    return (
      (questionnaire.answeredQuestions / questionnaire.totalQuestions) * 100
    )
  }

  return (
    <InformativeBox title="Progresso por questionário">
      <Box display="flex" flexDirection="column" gap={3}>
        {questionnaires.map((questionnaire) => (
          <Box key={questionnaire.name}>
            <LinearProgressWithTitleAndLabel
              thickness={2}
              borderRadius={1}
              color="info"
              title={questionnaire.name}
              value={calculateQuestionnaireProgress(questionnaire)}
            />
          </Box>
        ))}
      </Box>
    </InformativeBox>
  )
}

export default Progress
