import { Button } from '@mui/material'

import { Filter as FilterIcon } from 'react-feather'

const FilterButton = ({
  setDrawerOpen,
}: {
  setDrawerOpen: (state: boolean) => void
}) => {
  return (
    <Button
      startIcon={<FilterIcon width={18} />}
      variant="outlined"
      onClick={() => setDrawerOpen(true)}
    >
      Filtros
    </Button>
  )
}

export default FilterButton
