const ALL_COMPANIES = 'TODAS'
const ACTIVE_COMPANIES = 'ATIVAS'
const INACTIVE_COMPANIES = 'INATIVAS'

const TABS = [
  ALL_COMPANIES,
  // ACTIVE_COMPANIES,
  //  INACTIVE_COMPANIES
]

const EXIST = 'EXIST'
const NOT_EXIST = 'NOT_EXIST'
const SOLICITATION_TEXT =
  'Solicito a inclusão de uma empresa no grupo do advisor.'

export default {
  ACTIVE_COMPANIES,
  ALL_COMPANIES,
  INACTIVE_COMPANIES,
  TABS,
  EXIST,
  NOT_EXIST,
  SOLICITATION_TEXT,
}
