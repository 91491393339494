import { Grid, Typography } from '@mui/material'

import { Card, LabelDescription, MUI } from 'components'
import {
  DocumentsSection,
  NonComplianceReportActionsSection,
  SpecificRisksSection,
} from './components'

import { useIncidentManegement } from 'hooks'

import nonComplianceReportConstants from 'constants/nonComplianceReport'
import { formatters } from 'helpers'

const TreatmentStep = () => {
  const { incident } = useIncidentManegement()

  const nonComplianceReport = incident?.nonComplianceReport
  const statusInfo =
    nonComplianceReport?.status &&
    nonComplianceReportConstants.STATUSES_ID[nonComplianceReport?.status]

  return (
    <Card title="Tratamento">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <LabelDescription title="Status">
            {statusInfo ? (
              <MUI.Chip
                label={statusInfo?.label?.toUpperCase()}
                $color={statusInfo?.color}
                $background={statusInfo?.background}
              />
            ) : (
              <Typography variant="body1" color="text.secondary">
                -
              </Typography>
            )}
          </LabelDescription>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <LabelDescription
            title="Criado em"
            description={
              nonComplianceReport?.createdAt
                ? formatters.date.ptBrFormatDate(nonComplianceReport?.createdAt)
                : '-'
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <LabelDescription
            title="Prazo"
            description={
              nonComplianceReport?.deadlineDate
                ? formatters.date.ptBrFormatDate(
                    nonComplianceReport?.deadlineDate
                  )
                : '-'
            }
          />
        </Grid>
        <Grid item xs={12}>
          <LabelDescription
            title="Melhoria contínua"
            description={nonComplianceReport?.nonCompliance || '-'}
          />
        </Grid>
        <Grid item xs={12}>
          <LabelDescription
            title="Causa"
            description={nonComplianceReport?.cause || '-'}
          />
        </Grid>
        <Grid item xs={12}>
          {nonComplianceReport && (
            <SpecificRisksSection nonComplianceReport={nonComplianceReport} />
          )}
        </Grid>
        <Grid item xs={12}>
          {nonComplianceReport && (
            <NonComplianceReportActionsSection
              nonComplianceReport={nonComplianceReport}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {nonComplianceReport && (
            <DocumentsSection nonComplianceReport={nonComplianceReport} />
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

export default TreatmentStep
