import { Box, Grid, Typography, useMediaQuery } from '@mui/material'

import { BaseBoxType } from './baseBox.types'

import theme from 'theme'

const BaseBox = ({ children, title }: BaseBoxType) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  return (
    <Box
      display="flex"
      justifyContent="center"
      height="100%"
      width="100%"
      alignItems="center"
    >
      <Box width={isMobile ? '90%' : '50%'}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </Grid>
          {children}
        </Grid>
      </Box>
    </Box>
  )
}

export default BaseBox
