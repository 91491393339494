const MAX_FILE_SIZE = 10 * 1024 * 1024
const FILE_TYPES_IMAGE = '.jpg,.gif,.png,.jpeg,.svg,.webp'
const FILE_TYPES_DOCS =
  '.doc,.docx,.ppt,.pptx,.txt,.pdf,.xls,.xlsx,.csv,.ods,.odt,.rtf'

export default {
  MAX_FILE_SIZE,
  FILE_TYPES_IMAGE,
  FILE_TYPES_DOCS,
}
