import { Box, BoxProps } from '@mui/material'

import { ListPageStructureType } from './listPageStructure.types'

const ListPageStructure = ({
  children,
  ...rest
}: ListPageStructureType & BoxProps) => {
  return (
    <Box paddingX={6} paddingY={12} {...rest}>
      {children}
    </Box>
  )
}

export default ListPageStructure
