import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

const LabelHtml = styled('label', {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $width?: Property.Width<TLength> | undefined
  $display?: Property.Display | undefined
  $justifyContent?: Property.JustifyContent | undefined
  $flexDirection?: Property.FlexDirection | undefined
  $alignItems?: Property.AlignItems | undefined
  $cursor?: Property.Cursor | undefined
}>(
  ({
    $width,
    $display,
    $justifyContent,
    $flexDirection,
    $alignItems,
    $cursor,
  }) => ({
    width: $width,
    display: $display,
    justifyContent: $justifyContent,
    flexDirection: $flexDirection,
    alignItems: $alignItems,
    cursor: $cursor,
  })
)

export default LabelHtml
