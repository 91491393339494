import { Fragment, useState } from 'react'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import { Hidden, Tab, TablePagination, Tabs, Box, Button } from '@mui/material'
import { isEmpty } from 'lodash'

import {
  FilterButton,
  ListPageStructure,
  LoadingFeedback,
  NoData,
  PageHead,
  PagePaper,
} from 'components'

import { CompanyFilters, CompanyTable, DialogLinkedCompany } from './components'

import { useFetch, useFilter, usePagination } from 'hooks'

import companiesConstants from 'constants/companies'
import service from 'service'

const CompanyMain = () => {
  const filter = useFilter()
  const [selectedTab, setSelectedTab] = useState(
    companiesConstants.ALL_COMPANIES
  )
  const [openLikeCompany, setOpenLikeCompany] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const { response, loading } = useFetch(
    service.dponet.organizationCompany.get,
    {
      params: {
        status: 'ACTIVE',
        ...filter.filters,
        perPage,
        page,
      },
    },
    [filter.filters, page, perPage]
  )

  const organizationCompanies = response?.data?.organizationCompanies

  const handleChangeTab = (_: React.SyntheticEvent, clickedTab: string) => {
    setSelectedTab(clickedTab)
  }

  return (
    <ListPageStructure>
      <LoadingFeedback open={loading || loadingModal} />
      <PageHead
        name="Empresas"
        actionButton={
          <Box display="flex" gap={2}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<PlusCircleIcon />}
              onClick={() => setOpenLikeCompany(true)}
            >
              VINCULAR EMPRESA
            </Button>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        }
      />
      <PagePaper>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          aria-label="tabs de organizações"
          textColor="secondary"
        >
          {companiesConstants.TABS.map((tab) => (
            <Tab key={tab} label={tab} value={tab} />
          ))}
        </Tabs>
        {loading || isEmpty(organizationCompanies) ? (
          <NoData
            title="Nenhuma empresa foi encontrada."
            subTitle="Solicite para o representante lhe adicionar como responsável."
            withoutPaper
          />
        ) : (
          <Fragment>
            <CompanyTable organizationCompanies={organizationCompanies} />
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={(_, page) => handleChangePage(page)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Fragment>
        )}
      </PagePaper>
      <CompanyFilters filter={filter} />
      {openLikeCompany && (
        <DialogLinkedCompany
          open={openLikeCompany}
          onClose={() => setOpenLikeCompany(false)}
          setLoading={setLoadingModal}
        />
      )}
    </ListPageStructure>
  )
}

export default CompanyMain
