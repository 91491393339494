import { Avatar as AvatarMUI } from '@mui/material'
import { styled } from '@mui/material/styles'

const OrganizationAvatarHover = styled(AvatarMUI)(() => ({
  width: '100%',
  height: 'auto',
  aspectRatio: '1/1',
  maxWidth: 124,
  position: 'relative',
  '&:hover': {
    cursor: 'pointer',
    filter: 'opacity(0.5)',
    '&:after': {
      content: '"Adicionar Logo"',
      fontSize: '14px',
      fontWeight: 'bold',
      color: 'black',
      position: 'absolute',
    },
  },
}))

export default OrganizationAvatarHover
