import { isEmpty, deburr, replace } from 'lodash'
import { TomTicketsType } from 'types/tomTickets.types'

const formatDataTomticket = (data: TomTicketsType, dataDocument: File[]) => {
  const filesObj: { [key: string]: File } = {}

  if (!isEmpty(dataDocument)) {
    dataDocument.forEach((file, index) => {
      filesObj[`files[${index}]`] = renameFile(file)
    })
  }

  return {
    tomticket: {
      title: data?.title,
      description: data?.description,
      ...filesObj,
    },
  }
}

const renameFile = (file: File) => {
  const nameParts = file.name.split('.')
  const extension = nameParts.pop()
  const fileNameWithoutExtension = nameParts.join('.')

  let sanitizedFileName = replace(
    deburr(fileNameWithoutExtension),
    /[^a-zA-Z0-9]+/g,
    '_'
  )
  sanitizedFileName = replace(sanitizedFileName, /^_+|_+$/g, '')

  const finalFileName = `${sanitizedFileName}.${extension}`.toLowerCase()

  return new File([file], finalFileName, { type: file.type })
}

export default formatDataTomticket
