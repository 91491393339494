import { InformativeBox, LinearProgressWithTitleAndLabel } from 'components'

import { DetailsRisksType } from './detailsRisks.types'
import { Box } from '@mui/material'

const DetailsStatus = ({ generalDataProcessStats }: DetailsRisksType) => {
  const calculateRisksPercentage = (risk: number) => {
    const total = Object.values(generalDataProcessStats.risks).reduce(
      (prev, current) => prev + current
    )

    return (risk / total) * 100
  }

  const risks = [
    {
      label: 'Risco Baixo',
      value: calculateRisksPercentage(generalDataProcessStats.risks.low),
      valueLabel: generalDataProcessStats.risks.low,
    },
    {
      label: 'Risco Médio',
      value: calculateRisksPercentage(generalDataProcessStats.risks.medium),
      valueLabel: generalDataProcessStats.risks.medium,
    },
    {
      label: 'Risco Alto',
      value: calculateRisksPercentage(generalDataProcessStats.risks.high),
      valueLabel: generalDataProcessStats.risks.high,
    },
    {
      label: 'Risco Severo',
      value: calculateRisksPercentage(generalDataProcessStats.risks.severe),
      valueLabel: generalDataProcessStats.risks.severe,
    },
  ]

  return (
    <InformativeBox title="Processos por risco">
      <Box display="flex" flexDirection="column" gap={3}>
        {risks.map((risk) => (
          <Box key={risk.label}>
            <LinearProgressWithTitleAndLabel
              thickness={2}
              borderRadius={1}
              color="info"
              title={risk.label}
              value={risk.value}
              valueLabel={risk.valueLabel}
            />
          </Box>
        ))}
      </Box>
    </InformativeBox>
  )
}

export default DetailsStatus
