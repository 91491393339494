import { SwipeableDrawer as SwipeableDrawerMUI } from '@mui/material'
import { styled } from '@mui/material/styles'

const SwipeableDrawer = styled(SwipeableDrawerMUI)(({ theme }) => ({
  '& .MuiPaper-root': {
    background: `linear-gradient(to bottom, ${theme.palette.primary.main} 80%, ${theme.palette.secondary.main} 100%)`,
  },
}))

export default SwipeableDrawer
