import { AppBar, Box, IconButton } from '@mui/material'
import { Menu as MenuIcon } from 'react-feather'

import { AccountInfo, SelectOrganization, Toolbar } from './components'

import theme from 'theme'
import { useDrawer } from 'hooks'

const Header = () => {
  const { handleActionSwipeDrawer, isSwipebleDrawer } = useDrawer()

  return (
    <AppBar position="fixed" elevation={0}>
      <Toolbar variant="dense">
        <Box display="flex" alignItems="center" gap={2} width="100%" mr={1}>
          {isSwipebleDrawer && (
            <IconButton
              aria-label="open swipeble menu"
              onClick={handleActionSwipeDrawer}
            >
              <MenuIcon color={theme.palette.primary.contrastText} />
            </IconButton>
          )}
          <SelectOrganization />
        </Box>
        <AccountInfo />
      </Toolbar>
    </AppBar>
  )
}

export default Header
