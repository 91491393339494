import { Button, colors, styled } from '@mui/material'

const StyledAttachmentButton = styled(Button)(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  backgroundColor: colors.grey[100],
  paddingInline: theme.spacing(4),
  color: theme.palette.support.main,
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: colors.grey[200],
  },
}))

export default StyledAttachmentButton
