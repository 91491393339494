import Login from './Login'
import NewPassword from './NewPassword'
import Recovery from './Recovery'
import Register from './Register'
import TwoFactorAuthentication from './TwoFactorAuthentication'

const Authentication = {
  Login,
  NewPassword,
  Recovery,
  Register,
  TwoFactorAuthentication,
}

export default Authentication
