import { Alert } from '@mui/material'

const AwaitingReplyAlert = () => {
  return (
    <Alert severity="info" variant="filled" icon={false}>
      O ticket está aguardando uma validação da resposta. Responda externamente
      e coloque no status respondido para dar continuidade no atendimento.
    </Alert>
  )
}

export default AwaitingReplyAlert
