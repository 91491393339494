import { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

import { BasicDialog, LoadingFeedback } from 'components'
import { OrganizationUserForm } from './components'

import { useSnackbar } from 'hooks'

import { OrganizationUserInviteDialogType } from './organizationUserInviteDialog.types'
import { ProfileOption } from './components/OrganizationUserForm/organizationUserForm.types'

import service from 'service'
import { formatters } from 'helpers'

const OrganizationUserInviteDialog = ({
  open,
  onClose,
  organization,
  refreshOrganizationUsers,
}: OrganizationUserInviteDialogType) => {
  const [loading, setLoading] = useState(false)
  const [profileOptions, setProfileOptions] = useState<ProfileOption[]>()
  const [recaptcha, setRecaptcha] = useState<string | null>()

  const envProduction = process.env.REACT_APP_API === 'production'

  const { snackbar } = useSnackbar()

  useEffect(() => {
    const loadProfileOptions = async () => {
      setLoading(true)
      const response = await service.dponet.profiles.get({
        params: {
          active: true,
          perPage: 999,
          organizationId: organization?.id,
        },
      })
      setProfileOptions(response.data?.profiles)
      setLoading(false)
    }

    if (!profileOptions && open) {
      loadProfileOptions()
    }
  }, [open])

  const createUserOrganization = async (organizationUser: object) => {
    try {
      setLoading(true)

      await service.dponet.organizationUser.create({
        organizationId: organization?.id,
        organizationUser,
      })

      onClose()
      refreshOrganizationUsers()

      snackbar.open({
        message: 'Usuário vinculado a organização com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BasicDialog
      open={open}
      title="Convidar Usuário"
      onClose={onClose}
      dialogActions={
        <Button
          variant="contained"
          color="primary"
          type="submit"
          form="organization-user-creation-form"
          disabled={(!recaptcha && envProduction) || loading}
        >
          CONVIDAR USUÁRIO
        </Button>
      }
    >
      <LoadingFeedback open={loading} />

      <Box mb={6}>
        <ul>
          <li>
            <Typography variant="body1" textAlign="justify">
              Convide mais usuários para fazer parte do seu projeto de adequação
              dentro do DPO Advisor.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" textAlign="justify">
              Ao convidar mais usuários para colaborar com seu Grupo Advisor,
              você avança de forma mais ágil e efetiva, otimizando a comunicação
              e a produtividade na adequação das empresas à LGPD.
            </Typography>
          </li>
        </ul>
      </Box>

      <OrganizationUserForm
        organization={organization}
        onSubmit={createUserOrganization}
        profileOptions={profileOptions}
        setRecaptcha={setRecaptcha}
        envProduction={envProduction}
      />
    </BasicDialog>
  )
}

export default OrganizationUserInviteDialog
