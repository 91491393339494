import { take, orderBy } from 'lodash'
import { FragilityByDepartment } from 'types/organizationCompany'

const threatsByDepartment = (
  departments: FragilityByDepartment[],
  orderByCriteria: string[],
  orderDirection: 'asc'[] | 'desc'[],
  limit: number = 4
) => {
  const orderedDepartments = take(
    orderBy(departments, orderByCriteria, orderDirection),
    limit
  )

  const names = orderedDepartments.map((department) => department.name)
  const lowValues = orderedDepartments.map((department) => department.stats.low)
  const mediumValues = orderedDepartments.map(
    (department) => department.stats.medium
  )
  const highValues = orderedDepartments.map(
    (department) => department.stats.high
  )
  const severeValues = orderedDepartments.map(
    (department) => department.stats.severe
  )

  return { names, lowValues, mediumValues, highValues, severeValues }
}

export default threatsByDepartment
