import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import polygonFilled from 'images/polygon_green_filled.svg'

const PolygonFilled = styled(Box)(() => ({
  backgroundImage: `url(${polygonFilled})`,
  height: 110,
  width: 97,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

export default PolygonFilled
