import { useState } from 'react'
import { Box, Divider, Grid } from '@mui/material'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { BaseBox, FormRegister, GreyTypography } from './components'
import { GoogleSignOnButton } from './components/Register'

import { getGoogleAuthenticationToken } from 'service/env'

const Register = () => {
  const [isLoading, setLoading] = useState<boolean>(false)

  return (
    <BaseBox title="Criar conta">
      <Grid item xs={12}>
        <FormRegister isLoading={isLoading} setLoading={setLoading} />
      </Grid>
      <Grid item xs={12}>
        <Divider>
          <GreyTypography variant="caption">ou</GreyTypography>
        </Divider>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <GoogleOAuthProvider clientId={getGoogleAuthenticationToken()}>
            <GoogleSignOnButton />
          </GoogleOAuthProvider>
        </Box>
      </Grid>
    </BaseBox>
  )
}

export default Register
