import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async (params?: object) => {
  return dponetAPI.get('/advisors/legal_frameworks', { params })
}

export default {
  get,
}
