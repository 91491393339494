import { Box, Paper } from '@mui/material'

import { Chart, PaperHeader } from 'components'

import { RisksWithSecurityMeasureType } from './risksWithSecurityMeasure.types'

import securityMeasureConstants from 'constants/securityMeasure'

const RisksWithSecurityMeasure = ({
  fragilityStats,
}: RisksWithSecurityMeasureType) => {
  const securityMeasures = fragilityStats?.securityMeasures || 0
  const noSecurityMeasures =
    fragilityStats?.fragility?.totalCount - fragilityStats?.securityMeasures ||
    0

  const colors = [
    securityMeasureConstants.ADOPTED_STATUS_COLOR,
    securityMeasureConstants.OVERDUE_STATUS_COLOR,
  ]

  const values = [securityMeasures, noSecurityMeasures]

  const labels = ['Sim', 'Não']

  return (
    <Paper component={Box} p={2} width="100%" height="100%">
      <PaperHeader title="Ameaças com medidas vinculadas" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
        height="100%"
        py={6}
      >
        <Chart.PieChart
          names={labels}
          values={values}
          colors={colors}
          title="Ameaças"
          legendShow
          heightValue={295}
          showName={false}
        />
      </Box>
    </Paper>
  )
}

export default RisksWithSecurityMeasure
