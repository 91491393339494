import { Typography } from '@mui/material'

import { LabelDescriptionType } from './labelDescription.types'

const LabelDescription = ({
  title,
  description,
  overflow = false,
  children,
}: LabelDescriptionType) => {
  return (
    <>
      <Typography variant="body1" fontWeight="500" gutterBottom>
        {title}
      </Typography>

      {description && (
        <Typography
          variant="body1"
          color="text.secondary"
          {...(overflow && {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          })}
        >
          {description}
        </Typography>
      )}

      {children && children}
    </>
  )
}

export default LabelDescription
