import { inRange, size } from 'lodash'
import { SupplierType } from 'types/organizationCompany'

const countSupplierByCompliance = (
  supplierStats: SupplierType[],
  complianceMin: number,
  complianceMax: number
) => {
  const suppliers = supplierStats?.filter(
    (supplier) =>
      supplier.compliance &&
      inRange(supplier.compliance, complianceMin, complianceMax)
  )
  return size(suppliers)
}

export default { countSupplierByCompliance }
