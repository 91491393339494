import 'utils/jsPDF/fonts/Raleway-Bold-normal'
import 'utils/jsPDF/fonts/Raleway-Thin-normal'
import 'utils/jsPDF/fonts/Raleway-Regular-normal'
import 'utils/jsPDF/fonts/Roboto-Regular-normal'
import 'utils/jsPDF/fonts/Roboto-Bold-normal'

import { Fragment, useState } from 'react'
import { Button } from '@mui/material'
import moment from 'moment'

import { jsPDF } from 'jspdf'

import { useSnackbar } from 'hooks'

//import pages from 'helpers/reports/dashboard/pages'

import LoadingFeedback from 'components/LoadingFeedback'

import { OrganizationPdfType } from './organizationPdf.types'

import { formatters } from 'helpers'
import templates from 'utils/jsPDF/templates'

moment.locale('pt-br')
let dateNow = moment().format('MMM YYYY')

const OrganizationPdf = ({
  organizationStats,
  gapCompliance,
  incidentStats,
  dataProcessStats,
  companyStats,
}: OrganizationPdfType) => {
  const [loading, setLoading] = useState(false)

  const { snackbar } = useSnackbar()

  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    userUnit: 300,
  })

  dateNow = dateNow.charAt(0).toUpperCase() + dateNow.slice(1)

  const exportPdf = async () => {
    try {
      setLoading(true)

      templates.firstCover({
        pdf,
        companyName: organizationStats?.name,
        date: dateNow,
        reportName: 'Relatório Gerencial da Adequação à LGPD \nVisão 360',
      })

      templates.disclaimer({ pdf, date: dateNow })

      templates.introduction({ pdf, date: `Introdução | ${dateNow}` })

      templates.glossary({ pdf, date: `Glossário | ${dateNow}` })

      await templates.generalInformations({
        pdf,
        date: `Visão Geral | ${dateNow}`,
        incidentsStats: incidentStats,
        processStats: dataProcessStats,
        gapCompliance: gapCompliance,
        companyStats: companyStats,
      })

      templates.lastCover({ pdf })

      pdf.save(`Relatório_Gerencial_${organizationStats?.name}`)

      snackbar.open({
        variant: 'success',
        message: 'Relatório gerado com sucesso, aguarde o download começar',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        variant: 'error',
        message: formatters.errorMessage(error, false),
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <LoadingFeedback open={loading} />
      <Button onClick={exportPdf} fullWidth variant="contained">
        Gerar Relatório
      </Button>
    </Fragment>
  )
}

export default OrganizationPdf
