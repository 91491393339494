import { Box, Paper } from '@mui/material'

import { PaperHeader, Chart } from 'components'

import { StatusBarChartType } from './statusBarChart.types'

import nonComplianceReportConstants from 'constants/nonComplianceReport'

const StatusPieChart = ({ questionnaireStats }: StatusBarChartType) => {
  const questionnairesNames = questionnaireStats.map(
    (questionnaireStat) => questionnaireStat.name
  )

  const nonComplianceReportByQuestionnaire = () => {
    const nonComplianceReportStatuses: { [x: string]: number[] } = {}

    nonComplianceReportConstants.ALL_STATUSES.map((status) => {
      nonComplianceReportStatuses[status] = []

      questionnaireStats.map((questionnaireStat) => {
        nonComplianceReportStatuses[status].push(
          questionnaireStat.nonComplianceReports[status]
        )
      })
    })

    return nonComplianceReportStatuses
  }

  const values = nonComplianceReportConstants.ALL_STATUSES.map((status) => ({
    name: nonComplianceReportConstants.STATUSES_LABELS[status],
    data: nonComplianceReportByQuestionnaire()[status],
  }))
  const colors = Object.values(nonComplianceReportConstants.STATUSES_COLORS)

  return (
    <Paper
      component={Box}
      p={2}
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
    >
      <PaperHeader title="Status dos RMCs de questionários" />
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        justifyContent="center"
      >
        <Chart.BarChart
          names={questionnairesNames}
          values={values}
          colors={colors}
          legendShow
          heightValue={315}
          showLabels={false}
        />
      </Box>
    </Paper>
  )
}

export default StatusPieChart
