import { Box, Typography, Avatar, CircularProgress, Alert } from '@mui/material'

import { CompanyBox } from './components'

import { CompanySectionType } from './companySection.types'

const CompanySection = ({
  company,
  details,
  loading,
  error,
}: CompanySectionType) => {
  const outsourcedDpo = company?.outsourcedDpo
    ? company?.outsourcedDpo?.name ?? company?.outsourcedDpo?.email
    : 'DPOnet'

  if (!company && !loading && !error) return <></>

  if (loading)
    return (
      <CompanyBox>
        <Box display="flex" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      </CompanyBox>
    )

  if (error)
    return (
      <Box paddingY={4}>
        <Alert severity="error">
          <strong>Empresa não cadastrada.</strong> Verifique se o CNPJ está
          digitado corretamente.
        </Alert>
      </Box>
    )

  return (
    <CompanyBox>
      <Box display="flex" alignItems="center" gap={3}>
        <Box>
          <Avatar
            alt={company?.name}
            src={company?.logotypeUrl}
            variant="rounded"
          />
        </Box>
        <Box>
          <Typography variant="body1" fontWeight="500">
            {company?.name}
          </Typography>
          <Typography variant="subtitle2">
            CNPJ: {company?.document.value}
          </Typography>

          {details && (
            <Box display="flex">
              <Typography variant="subtitle2">
                DPO responsável:&nbsp;
              </Typography>

              <Typography variant="subtitle2" fontWeight="500">
                {outsourcedDpo}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </CompanyBox>
  )
}

CompanySection.defaultProps = {
  details: false,
  loading: false,
  error: false,
}

export default CompanySection
