const TYPE_PERSONAL_AND_FINANCIAL = 1
const TYPE_SENSITIVE = 2
const TYPE_MINOR = 3

const TYPE_PERSONAL_AND_FINANCIAL_VALUE = 'personalAndFinancial'
const TYPE_SENSITIVE_VALUE = 'sensitive'
const TYPE_MINOR_VALUE = 'minor'

const TYPE_PERSONAL_AND_FINANCIAL_LABEL = 'Pessoais'
const TYPE_SENSITIVE_LABEL = 'Pessoais sensíveis'
const TYPE_MINOR_LABEL = 'De menores'

const PARENTAL_CONSENT = 'Consentimento dos pais'

const DATA_TYPES = [
  TYPE_PERSONAL_AND_FINANCIAL,
  TYPE_SENSITIVE,
  TYPE_MINOR,
] as const

const DATA_TYPES_VALUES = [
  TYPE_PERSONAL_AND_FINANCIAL_VALUE,
  TYPE_SENSITIVE_VALUE,
  TYPE_MINOR_VALUE,
] as const

const legalFramework = {
  DATA_TYPES,
  DATA_TYPES_VALUES,
  PARENTAL_CONSENT,
  TYPE_MINOR_LABEL,
  TYPE_MINOR_VALUE,
  TYPE_MINOR,
  TYPE_PERSONAL_AND_FINANCIAL_LABEL,
  TYPE_PERSONAL_AND_FINANCIAL_VALUE,
  TYPE_PERSONAL_AND_FINANCIAL,
  TYPE_SENSITIVE_LABEL,
  TYPE_SENSITIVE_VALUE,
  TYPE_SENSITIVE,
}

export default legalFramework
