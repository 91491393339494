import { Fragment, useState } from 'react'
import { Box, Divider, Paper } from '@mui/material'

import QuestionMenuTitle from '../QuestionMenuTitle'
import QuestionForm from '../QuestionForm'
import AnswerForm from '../AnswerForm'
import { SubAnswerTable } from './components'

import { SubAnswerCardType } from './subAnswerCard.types'
import { QuestionDataType } from '../QuestionMenuTitle/questionMenuTitle.types'
import { BasicDialog } from 'components'

const SubAnswerCard = ({
  companyId,
  answerId,
  answerOptionTickets,
  optionsMenuType,
  openEditQuestionModal,
  setOpenEditQuestionModal,
  setDisableSwitch,
  refresh,
}: SubAnswerCardType) => {
  const [openNewQuestionModal, setOpenNewQuestionModal] = useState(false)
  const [formIsLoading, setFormIsLoading] = useState(false)
  const [questionData, setQuestionData] = useState<QuestionDataType>()

  const isSuboption = optionsMenuType === 'suboption'

  const isSpecifyField = optionsMenuType === 'specify'

  const hasItemsValidation =
    (isSuboption && !!answerOptionTickets?.childrenQuestionOptionTicket) ||
    (isSpecifyField && !!answerOptionTickets?.textFieldTicket)

  const titleValidation =
    (isSuboption && answerOptionTickets?.childrenQuestionOptionTicket?.title) ||
    (isSpecifyField && answerOptionTickets?.textFieldTicket?.title) ||
    ''

  const questionIdValidation =
    (isSuboption && answerOptionTickets?.childrenQuestionOptionTicket?.id) ||
    (isSpecifyField && answerOptionTickets?.textFieldTicket?.id) ||
    undefined

  const handleCloseQuestionModal = () => {
    setOpenEditQuestionModal(false)
  }

  const handleCloseQuestionModalWithSwitch = () => {
    if (!titleValidation) setDisableSwitch(true)
    setOpenEditQuestionModal(false)
  }

  const handleCloseNewAnswerModal = () => {
    setOpenNewQuestionModal(false)
  }

  return (
    <Paper>
      <Box>
        <Box p={4}>
          <QuestionMenuTitle
            optionsMenuType={optionsMenuType}
            setOpenEditQuestionModal={setOpenEditQuestionModal}
            setOpenNewQuestionModal={setOpenNewQuestionModal}
            questionId={questionIdValidation}
            setQuestionData={setQuestionData}
            title={titleValidation}
          />
        </Box>
        {isSuboption && (
          <Fragment>
            <Divider />
            <SubAnswerTable
              childrenAnswerOptionTickets={
                answerOptionTickets?.childrenQuestionOptionTicket
                  ?.answerOptionTickets
              }
              refresh={refresh}
            />
          </Fragment>
        )}
      </Box>
      <BasicDialog
        open={openEditQuestionModal}
        onClose={handleCloseQuestionModalWithSwitch}
        title={hasItemsValidation ? 'Editar pergunta' : 'Criar pergunta'}
        isSaving={formIsLoading}
        form="privacy-portal-question-form"
      >
        <QuestionForm
          isEdit={hasItemsValidation}
          isSpecifyField={isSpecifyField}
          onClose={handleCloseQuestionModal}
          companyId={companyId}
          answerId={answerId}
          questionId={questionData?.questionId}
          specifyFieldId={answerOptionTickets?.textFieldTicket?.id}
          refresh={refresh}
          setIsLoading={setFormIsLoading}
          title={questionData?.title}
        />
      </BasicDialog>
      <BasicDialog
        open={openNewQuestionModal}
        onClose={handleCloseNewAnswerModal}
        title="Nova resposta"
        isSaving={formIsLoading}
        form="privacy-portal-answer-form"
      >
        <AnswerForm
          onClose={handleCloseNewAnswerModal}
          questionId={questionData?.questionId}
          refresh={refresh}
          setIsLoading={setFormIsLoading}
        />
      </BasicDialog>
    </Paper>
  )
}

export default SubAnswerCard
