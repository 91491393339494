import { Fragment } from 'react'
import { Box } from '@mui/material'

import { TitleSection, TopicSection, DataProcessInfoChip } from '..'

import { LifeCyclesType } from './lifeCycles.types'

const LifeCycles = ({ lifeCycles }: LifeCyclesType) => {
  return (
    <Box m={4}>
      <TitleSection title="Todos salvaguardas e ciclo de vida" />
      {lifeCycles?.map((lifeCycle) => (
        <Fragment key={lifeCycle?.id}>
          <TopicSection text={lifeCycle?.storageLocation?.name} />
          <Box display="flex" flexWrap="wrap">
            <DataProcessInfoChip
              text="Ambiente de alocação"
              complement={lifeCycle?.internalAllocationMode?.name}
            />
            <DataProcessInfoChip
              text="Tempo  de armazenamento"
              complement={
                lifeCycle?.value
                  ? `${lifeCycle?.value} ${lifeCycle?.volumetry}`
                  : 'Indefinido'
              }
            />
            <DataProcessInfoChip
              text="Forma de proteção"
              complement={lifeCycle?.protection?.name}
            />
            <DataProcessInfoChip
              text="Como os dados são dispostos"
              complement={lifeCycle?.discardMode?.name}
            />
            <DataProcessInfoChip
              text="Justificativa de retenção"
              complement={lifeCycle?.retentionFinality}
            />
          </Box>
        </Fragment>
      ))}
    </Box>
  )
}

LifeCycles.defaultProps = {
  lifeCycles: [],
}

export default LifeCycles
