import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ params = {} }: { params?: object }) => {
  return dponetAPI.get(`/advisors/organization_questionnaires/`, {
    params,
  })
}

const show = async ({
  organizationQuestionnaireId = '',
  params = {},
}: {
  organizationQuestionnaireId?: number | string
  params?: object
}) => {
  return dponetAPI.get(
    `/advisors/organization_questionnaires/${organizationQuestionnaireId}`,
    {
      params,
    }
  )
}

const post = async ({ questionnaire = {} }: { questionnaire?: object }) => {
  return dponetAPI.post(`/advisors/organization_questionnaires`, {
    questionnaire,
  })
}

const update = async ({
  organizationQuestionnaireId = '',
  questionnaire = {},
}: {
  organizationQuestionnaireId?: number | string
  questionnaire?: object
}) => {
  return dponetAPI.put(
    `/advisors/organization_questionnaires/${organizationQuestionnaireId}`,
    {
      questionnaire,
    }
  )
}

const linkCompanies = async ({
  organizationQuestionnaireId = '',
  questionnaire = {},
}: {
  organizationQuestionnaireId?: number | string
  questionnaire?: object
}) => {
  return dponetAPI.patch(
    `/advisors/organization_questionnaires/${organizationQuestionnaireId}/vinculate_companies`,
    {
      questionnaire,
    }
  )
}

const uncoupleCompanies = async ({
  organizationQuestionnaireId = '',
  questionnaire = {},
}: {
  organizationQuestionnaireId?: number | string
  questionnaire?: object
}) => {
  return dponetAPI.patch(
    `/advisors/organization_questionnaires/${organizationQuestionnaireId}/unvinculate_company`,
    {
      questionnaire,
    }
  )
}

const getQuestions = async ({
  organizationQuestionnaireId = '',
  params = {},
}: {
  organizationQuestionnaireId?: number | string
  params?: object
}) => {
  return dponetAPI.get(
    `/advisors/organization_questionnaires/${organizationQuestionnaireId}/organization_questions`,
    {
      params,
    }
  )
}

const createQuestion = async ({
  organizationQuestionnaireId = '',
  question = {},
}: {
  organizationQuestionnaireId?: number | string
  question?: object
}) => {
  return dponetAPI.post(
    `/advisors/organization_questionnaires/${organizationQuestionnaireId}/organization_questions`,
    {
      question,
    }
  )
}

const updateQuestion = async ({
  organizationQuestionnaireId = '',
  organizationQuestionId = '',
  question = {},
}: {
  organizationQuestionnaireId?: number | string
  organizationQuestionId?: number | string
  question?: object
}) => {
  return dponetAPI.put(
    `/advisors/organization_questionnaires/${organizationQuestionnaireId}/organization_questions/${organizationQuestionId}`,
    {
      question,
    }
  )
}

const inactiveQuestion = async ({
  organizationQuestionnaireId = '',
  organizationQuestionId = '',
  question = {},
}: {
  organizationQuestionnaireId?: number | string
  organizationQuestionId?: number | string
  question?: object
}) => {
  return dponetAPI.put(
    `/advisors/organization_questionnaires/${organizationQuestionnaireId}/organization_questions/${organizationQuestionId}/inactivate`,
    {
      question,
    }
  )
}

const getVinculatedCompanies = async ({
  organizationQuestionnaireId = '',
  params = {},
}: {
  organizationQuestionnaireId?: number | string
  params?: object
}) => {
  return dponetAPI.get(
    `/advisors/organization_questionnaires/${organizationQuestionnaireId}/list_vinculated_companies`,
    {
      params,
    }
  )
}

export default {
  get,
  show,
  post,
  update,
  linkCompanies,
  uncoupleCompanies,
  getQuestions,
  createQuestion,
  updateQuestion,
  inactiveQuestion,
  getVinculatedCompanies,
}
