import { Avatar as AvatarMUI } from '@mui/material'
import { styled } from '@mui/material/styles'

const Avatar = styled(AvatarMUI)(({ theme }) => ({
  width: theme.spacing(8),
  height: theme.spacing(8),
  fontSize: '1.5rem',
  background: theme.palette.secondary.main,
  marginLeft: theme.spacing(-3),
  marginRight: theme.spacing(2),
}))

export default Avatar
