import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DefaultPageStructure, LoadingFeedback, PageHead } from 'components'
import { ProfileForm } from './components'

import { useSnackbar } from 'hooks'

import service from 'service'
import { formatters } from 'helpers'
import routes from 'constants/routes'

const ProfileNew = () => {
  const [loading, setLoading] = useState(false)
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()

  const onSubmit = async (data: object) => {
    try {
      setLoading(true)

      await service.dponet.profiles.create({ profile: data })

      snackbar.open({
        message: 'Perfil criado com sucesso!',
        variant: 'success',
      })

      navigate(routes.app.profiles.all)
    } catch (error) {
      console.error(error)

      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <DefaultPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead name="Novo perfil de usuário" />
      <ProfileForm onSubmit={onSubmit} />
    </DefaultPageStructure>
  )
}

export default ProfileNew
