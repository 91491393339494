import {
  XCircle as XCircleIcon,
  CheckCircle as CheckCircleIcon,
} from 'react-feather'

import { ColorError, ColorMissing, ColorSuccess } from '..'

import { IdenityIconType } from 'components/PasswordValidator/passwordValidator.types'

const IdentifyIcon = ({
  children,
  regexValidator,
  justSubmitted,
}: IdenityIconType) => {
  if (regexValidator())
    return (
      <ColorSuccess>
        <CheckCircleIcon size={24} stroke="currentColor" />
        {children}
      </ColorSuccess>
    )

  if (justSubmitted)
    return (
      <ColorError>
        <XCircleIcon size={24} stroke="currentColor" />
        {children}
      </ColorError>
    )
  return (
    <ColorMissing>
      <XCircleIcon size={24} stroke="currentColor" />
      {children}
    </ColorMissing>
  )
}

export default IdentifyIcon
