import { ResponsibleType } from 'types/task.types'
import * as yup from 'yup'

const schema = yup.object({
  companyId: yup
    .object({
      companyId: yup.number().required(),
      label: yup.string().required(),
    })
    .required(),
  name: yup.string().required().max(120),
  responsibleId: yup
    .object({
      userId: yup.number(),
      label: yup.string(),
    })
    .nullable(),
  startDate: yup
    .date()
    .nullable()
    .when('responsibleId', {
      is: (responsible: ResponsibleType) => !!responsible?.userId,
      then: (schema) => schema.required(),
    }),
  dueDate: yup
    .date()
    .nullable()
    .when('responsibleId', {
      is: (responsible: ResponsibleType) => !!responsible?.userId,
      then: (schema) => schema.required(),
    }),
  description: yup.string().required().max(1200),
  observations: yup.string().max(250),
})

export default schema
