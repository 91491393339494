import { ParagraphType } from './paragraph.types'

import constants from 'constants/index'
import theme from 'theme'

const paragraph = ({
  pdf,
  text,
  positionY,
  textColor = theme.palette.primary.main,
  font = 'Roboto-Regular',
}: ParagraphType) => {
  const PADDING = constants.jsPdf.PADDING
  const WIDTH_PDF = constants.jsPdf.WIDTH
  const FONT_SIZE = constants.jsPdf.FONT_SIZE

  const WIDTH = WIDTH_PDF(pdf)

  pdf.setFont(font)
  pdf.setFontSize(FONT_SIZE.PARAGRAPH)
  pdf.setTextColor(textColor)

  const splited_paragraphs = pdf.splitTextToSize(text, WIDTH - PADDING * 2)

  pdf.text(splited_paragraphs, PADDING, positionY, undefined, 'justify')
}

export default paragraph
