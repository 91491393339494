import api from 'service/api'

const dponetAPI = api.create('dponet')

const post = ({
  ticketId,
  ticketMessageId,
  params = {},
}: {
  ticketId?: string | number
  ticketMessageId?: string | number
  params?: object
}) => {
  const route = ticketMessageId
    ? `/advisors/tickets/${ticketId}/juridic/messages/${ticketMessageId}/generative/feedback`
    : `/advisors/tickets/${ticketId}/juridic/generative/feedback`

  return dponetAPI.post(route, params)
}

const postSolicitationTickets = ({
  ticketId,
  params = {},
}: {
  ticketId?: string | number
  params?: object
}) => {
  return dponetAPI.post(
    `/advisors/tickets/${ticketId}/privacy_portal/ticket_generative_feedback`,
    params
  )
}
const incidents = {
  post,
  postSolicitationTickets,
}

export default incidents
