import organizationUsersConstant from 'constants/organizationUsers'

const statusColor = (status: string) => {
  if (status === organizationUsersConstant.ACTIVE_STATUS) return 'success'
  if (status === organizationUsersConstant.INACTIVE_STATUS) return 'default'

  return 'info'
}

const statusTranslation = (status: string) => {
  if (status === organizationUsersConstant.ACTIVE_STATUS) return 'ATIVA'
  if (status === organizationUsersConstant.INACTIVE_STATUS) return 'INATIVA'

  return 'PENDENTE'
}

export default {
  statusColor,
  statusTranslation,
}
