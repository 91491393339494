import { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

import { BasicDialog, LoadingFeedback } from 'components'
import { OrganizationForm } from 'views/Organization/components'

import { useSnackbar, useOrganization } from 'hooks'

import { formatters } from 'helpers'
import service from 'service'

const FirstAccessMain = () => {
  const [loading, setLoading] = useState(false)

  const { snackbar } = useSnackbar()
  const { loadOrganizations } = useOrganization()

  const createOrganization = async (organization: object) => {
    try {
      setLoading(true)

      await service.dponet.organization.create(organization)

      snackbar.open({
        message: 'Organização criada com sucesso!',
        variant: 'success',
      })

      await loadOrganizations()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BasicDialog
      open
      title="Crie seu grupo advisor"
      onClose={() => {}}
      maxWidth="md"
      dialogActions={
        <Button
          variant="contained"
          type="submit"
          form="organization-creation-form"
        >
          CRIAR GRUPO ADVISOR
        </Button>
      }
    >
      <LoadingFeedback open={loading} />

      <Box mb={6}>
        <Typography variant="body1" fontWeight="bold" gutterBottom>
          Bem-vindo à DPO Advisor! Estamos empolgados por tê-lo(a) a bordo.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Para começar a utilizar todas as funcionalidades e recursos que nossa
          plataforma oferece, é necessário participar de um time de trabalho,
          chamado &quot;<strong>Grupo Advisor</strong>&quot;. Esse time será o
          ponto central das suas atividades e interações com outros
          colaboradores.
        </Typography>
        <Typography variant="body1" gutterBottom>
          A criação do &quot;<strong>Grupo Advisor</strong>&quot; permitirá que
          você:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              Acesse informações compartilhadas com outros membros do time.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Colabore de forma mais efetiva em projetos e tarefas em conjunto.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Receba atualizações e notificações relevantes sobre atividades do
              grupo.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Aproveite a plataforma ao máximo, otimizando a comunicação e a
              produtividade.
            </Typography>
          </li>
        </ul>
      </Box>
      <OrganizationForm onSubmit={createOrganization} canDisable={false} />
    </BasicDialog>
  )
}

export default FirstAccessMain
