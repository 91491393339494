import * as yup from 'yup'

const schema = yup.object({
  title: yup.string().required(),
  description: yup.string().required(),
  ticketType: yup.string().required(),
})

export type HelpDialogFormType = yup.InferType<typeof schema>

export default schema
