import * as yup from 'yup'

const schema = yup.object({
  document: yup
    .string()
    .min(14, 'CPF ou CNPJ inválido')
    .max(18, 'CPF ou CNPJ inválido')
    .required(),
})

export default schema
