import {
  ClipboardEvent,
  FocusEvent,
  FormEvent,
  KeyboardEvent,
  useEffect,
  useState,
} from 'react'
import { Box } from '@mui/material'
import { CodeKeyInput } from '..'

import { useNavigate, useParams } from 'react-router-dom'
import { useAuth, useSnackbar } from 'hooks'
import { ErrorResponse, FormType } from './form.types'

import routes from 'constants/routes'
import service from 'service'

import theme from 'theme'

const Form = ({ setLoading }: FormType) => {
  const params = useParams()
  const navigate = useNavigate()

  const { loadData } = useAuth()
  const { snackbar } = useSnackbar()

  const accessToken = params?.accessToken

  const [inputValues, setInputValues] = useState(Array(5).fill(''))
  const [selectedInput, setSelectedInput] = useState(0)
  const [disableInputs, setDisableInputs] = useState(false)

  const normalizeIndex = (index: number) => {
    if (index > 4) return 4
    if (index < 0) return 0

    return index
  }

  const handleForward = (index: number) => {
    setSelectedInput(normalizeIndex(index + 1))
  }

  const handleInput = (index: number, value: string) => {
    setInputValues([
      ...inputValues.slice(0, index),
      value,
      ...inputValues.slice(index + 1),
    ])

    if (value !== '' && index !== 4) handleForward(index)
  }

  const defineErrorMessage = (error: ErrorResponse) => {
    const errorMessage = error?.response?.data?.error?.message

    if (errorMessage === 'não autorizado')
      return 'O código de autenticação é inválido ou expirou. Verifique-o e tente novamente ou solicite o reenvio.'

    return errorMessage || 'Ocorreu algum erro! Tente novamente!'
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    setDisableInputs(true)

    try {
      await service.dponet.twoFactorAuthentication.validateCode({
        accessToken,
        code: inputValues.join(''),
      })
      await loadData()
      navigate(routes.app.organizations.all)
    } catch (error) {
      snackbar.open({
        message: defineErrorMessage(error as ErrorResponse),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      setDisableInputs(false)
    }
  }

  const handleKeyDown = (
    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === 'Backspace') {
      if (inputValues[selectedInput] !== '') return

      setSelectedInput((selectedInput) => normalizeIndex(selectedInput - 1))
    }
  }

  const handleFocus = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputIndex = Number(e.target.id)

    if (selectedInput !== inputIndex) {
      return setSelectedInput(inputIndex)
    }
    if (e.target.value) {
      e.target.select()
    }
  }

  const handlePaste = (
    pastedValue: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const pasteValues = pastedValue.clipboardData.getData('text')
    const newObject = inputValues.map((_, index) => pasteValues.at(index) || '')
    setInputValues(newObject)
  }

  useEffect(() => {
    document.getElementById(normalizeIndex(selectedInput)?.toString())?.focus()
  }, [selectedInput])

  return (
    <Box
      gap={theme.spacing(1)}
      my={2}
      component="form"
      display="grid"
      gridTemplateColumns="repeat(5, 1fr)"
      id="two-factor-authentication-form"
      onSubmit={handleSubmit}
    >
      {inputValues.map((value, index) => (
        <CodeKeyInput
          disabledInputs={disableInputs}
          index={index}
          key={index}
          value={value}
          handleFocus={handleFocus}
          handleInput={handleInput}
          handleKeyDown={handleKeyDown}
          handlePaste={handlePaste}
        />
      ))}
    </Box>
  )
}

export default Form
