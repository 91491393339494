import { ChevronsRight as ChevronsRightIcon } from 'react-feather'
import { Box, Typography } from '@mui/material'

import { TopicSectionType } from './topicSection.types'

const TopicSection = ({ text }: TopicSectionType) => {
  return (
    <Box my={2} display="flex" alignItems="center">
      <Box mr={1}>
        <ChevronsRightIcon size={18} />
      </Box>
      <Typography variant="body2">{text}</Typography>
    </Box>
  )
}

export default TopicSection
