import { SwipeableDrawer } from './components'
import Items from '../Items'

import { useDrawer } from 'hooks'

const MobileDrawer = () => {
  const { handleActionSwipeDrawer, openedDrawer } = useDrawer()

  return (
    <SwipeableDrawer
      open={openedDrawer}
      onClose={handleActionSwipeDrawer}
      onOpen={handleActionSwipeDrawer}
    >
      <Items />
    </SwipeableDrawer>
  )
}

export default MobileDrawer
