import { Box, Button, Typography } from '@mui/material'

import { BasicDialog } from 'components'

import { HealthScoreDialogType } from './healthScoreDialog.types'

const HealthScoreDialog = ({ open, onClose }: HealthScoreDialogType) => {
  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title="O que é HealthScore?"
      dialogActions={
        <Button color="primary" onClick={onClose}>
          Fechar
        </Button>
      }
      maxWidth="md"
    >
      <Box textAlign="justify">
        <Typography gutterBottom>
          Em uma organização, o HealthScore pode medir a saúde da empresa com
          base em métricas da metodologia de adequação à LGPD dentro da trilha
          da DPOnet, através de indicadores-chave.
        </Typography>
        <Typography gutterBottom>
          <strong>Aviso importante:</strong> o HealthScore não é uma forma
          oficial de indicar a adequação da empresa e não possui quaisquer fins
          comprobatórios de adequação à lei perante a ANPD.
        </Typography>
        <Typography gutterBottom>
          O HealthScore no DPO Advisor corresponde ao cruzamento de diversos
          indicadores: Processos, Diagnósticos (questionários, medidas de
          segurança e ameaças) e Incidentes.
        </Typography>
      </Box>
    </BasicDialog>
  )
}

export default HealthScoreDialog
