import { RectangleType } from './rectangle.types'

import { ratio } from '../../helpers'
import theme from 'theme'

const addRectangle = ({
  pdf,
  positionX,
  positionY,
  width,
  color,
  ratioHeight,
  label = '',
  data,
  labelY = positionY + ratio(150) - 5,
  fontSize = 80,
  dataBold = true,
  fontColor = theme.palette.primary.light,
  labelColor = theme.palette.grey[500],
  splited = false,
}: RectangleType) => {
  const RADIUS = ratio(20)

  pdf.setFillColor(color)
  pdf.roundedRect(
    positionX,
    positionY,
    width,
    ratio(300) + ratioHeight,
    RADIUS,
    RADIUS,
    'F'
  )

  pdf.setFontSize(ratio(48))
  dataBold ? pdf.setFont('Raleway-Regular') : pdf.setFont('Raleway-Bold')
  pdf.setTextColor(labelColor)
  pdf.text(label, positionX + ratio(40), labelY, undefined, 'left')
  pdf.setFontSize(ratio(fontSize))
  dataBold ? pdf.setFont('Raleway-Bold') : pdf.setFont('Raleway-Regular')

  const dataSplited = pdf.splitTextToSize(data, width - 20)
  pdf.setTextColor(fontColor)

  pdf.text(
    dataSplited,
    positionX + ratio(40),
    splited ? labelY + ratio(90) : labelY + ratio(60),
    undefined,
    'left'
  )
}

export default addRectangle
