import legalFrameworkConstants from 'constants/legalFramework'

const legalFrameworkLabelByDataType = (dataType: number) => {
  switch (dataType) {
    case legalFrameworkConstants.TYPE_PERSONAL_AND_FINANCIAL:
      return legalFrameworkConstants.TYPE_PERSONAL_AND_FINANCIAL_LABEL

    case legalFrameworkConstants.TYPE_MINOR:
      return legalFrameworkConstants.TYPE_MINOR_LABEL

    case legalFrameworkConstants.TYPE_SENSITIVE:
      return legalFrameworkConstants.TYPE_SENSITIVE_LABEL

    default:
      return ''
  }
}

export default legalFrameworkLabelByDataType
