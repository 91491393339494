import { Box, Divider, Paper, Tooltip, Typography, colors } from '@mui/material'

import { TicketInfoTableType } from './ticketInfoTable.types'

import { formatters, validates } from 'helpers'

import constants from 'constants/index'

const TicketInfoTable = ({ ticket, ticketMessages }: TicketInfoTableType) => {
  const anpdTicket = ticket?.ticketType === 'anpd'

  const isFinished =
    ticket?.status?.id === constants.tickets.FINISHED_TICKET_ID ||
    ticket?.status?.id === constants.tickets.CANCELED_TICKET_ID

  const optionsTable = [
    {
      title: 'Título da solicitação',
      value: ticket?.title,
      visible: true,
      highlight: true,
    },
    {
      title: ticket?.info?.questionRequest?.title,
      value: ticket?.info?.questionRequest?.answer?.title,
      visible: !anpdTicket,
    },
    {
      title: ticket?.info?.questionRequest?.answer?.subOption?.title,
      value: ticket?.info?.questionRequest?.answer?.subOption?.subAnswer,
      visible: !anpdTicket && ticket?.info?.questionRequest?.answer?.subOption,
    },
    {
      title: ticket?.info?.questionRequest?.answer?.specificField?.title,
      value: ticket?.info?.questionRequest?.answer?.specificField?.response,
      visible:
        !anpdTicket && ticket?.info?.questionRequest?.answer?.specificField,
    },
    {
      title: ticket?.info?.questionRelationshipSelf?.title,
      value: ticket?.info?.questionRelationshipSelf?.answer?.title,
      visible: !anpdTicket,
    },
    {
      title: ticket?.info?.questionRelationshipSelf?.answer?.subOption?.title,
      value:
        ticket?.info?.questionRelationshipSelf?.answer?.subOption?.subAnswer,
      visible:
        !anpdTicket &&
        ticket?.info?.questionRelationshipSelf?.answer?.subOption,
    },
    {
      title:
        ticket?.info?.questionRelationshipSelf?.answer?.specificField?.title,
      value:
        ticket?.info?.questionRelationshipSelf?.answer?.specificField?.response,
      visible:
        !anpdTicket &&
        ticket?.info?.questionRelationshipSelf?.answer?.specificField,
    },
    {
      title: ticket?.info?.questionDemand?.title,
      value: ticket?.info?.questionDemand?.answer?.title,
      visible: !anpdTicket,
    },
    {
      title: ticket?.info?.questionDemand?.answer?.subOption?.title,
      value: ticket?.info?.questionDemand?.answer?.subOption?.subAnswer,
      visible: !anpdTicket && ticket?.info?.questionDemand?.answer?.subOption,
    },
    {
      title: ticket?.info?.questionDemand?.answer?.specificField?.title,
      value: ticket?.info?.questionDemand?.answer?.specificField?.response,
      visible:
        !anpdTicket && ticket?.info?.questionDemand?.answer?.specificField,
    },
    {
      title: 'Três primeiros dígitos do CPF',
      value: ticket?.user?.document,
      visible: !anpdTicket,
    },
    {
      title: 'Cargo na ANPD',
      value: ticket?.info?.anpdJob,
      visible: anpdTicket,
    },
    {
      title: 'Solicitante',
      value: [
        ticket?.user?.name && ticket?.user?.name,
        ticket?.user?.email && ticket?.user?.email,
        ticket?.user?.phone && formatters.phoneDynamicMask(ticket?.user?.phone),
      ].filter(Boolean),
      visible: true,
    },
    {
      title: 'Responsável da empresa',
      value: [
        ticket?.company?.responsibleUser?.name &&
          ticket?.company?.responsibleUser?.name,
        ticket?.company?.responsibleUser?.email &&
          ticket?.company?.responsibleUser?.email,
        ticket?.company?.responsibleUser?.phone &&
          formatters.phoneDynamicMask(ticket?.company?.responsibleUser?.phone),
      ].filter(Boolean),
      visible: true,
    },
    {
      title: 'Controle de prazos',
      value: [
        ticket?.deadlineDate &&
          `Prazo para atendimento: ${formatters.date.ptBrFormatDate(
            ticket?.deadlineDate
          )}`,
        `Data de criação: ${formatters.date.ptBrFormatDateTime(
          ticket?.createdAt
        )}`,
        ticket?.confirmedAt &&
          `Data de confirmação: ${formatters.date.ptBrFormatDateTime(
            ticket?.confirmedAt
          )}`,
        isFinished &&
          `Data de resolução: ${
            validates.tickets.resolutionDate(ticket, ticketMessages) ||
            'Não possui data definida'
          }`,
      ].filter(Boolean),
      visible: true,
    },
  ]

  const commonTypography = (value?: string | false | null) => (
    <Typography
      variant="subtitle2"
      color="text.secondary"
      textOverflow="ellipsis"
      overflow="hidden"
      whiteSpace="pre-line"
    >
      {value ?? '-'}
    </Typography>
  )

  return (
    <Paper component={Box}>
      <Box px={5} py={3}>
        <Typography variant="subtitle1" fontWeight={500} color="primary">
          Informações
        </Typography>
      </Box>
      {optionsTable.map(
        (option, index) =>
          option.visible && (
            <Box key={index}>
              <Divider />
              <Tooltip title={!Array.isArray(option.value) && option.value}>
                <Box
                  p={4}
                  bgcolor={
                    option?.highlight ? colors.lightGreen['A100'] : 'white'
                  }
                >
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    fontWeight={500}
                  >
                    {option.title}
                  </Typography>
                  {Array.isArray(option.value)
                    ? option.value.map((value, subIndex) => (
                        <Tooltip title={value} key={subIndex}>
                          {commonTypography(value)}
                        </Tooltip>
                      ))
                    : commonTypography(option.value)}
                </Box>
              </Tooltip>
            </Box>
          )
      )}
    </Paper>
  )
}

export default TicketInfoTable
