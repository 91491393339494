export default {
  MuiButton: {
    variants: [
      {
        props: {
          color: 'tertiary' as const,
        },
        style: {
          color: 'white',
        },
      },
    ],
  },
}
