import { colors } from '@mui/material'

import { TextTopicType } from './textTopic.types'

import constants from 'constants/index'
import theme from 'theme'

const textTopic = ({
  pdf,
  identifier,
  text,
  title,
  positionY,
  textColor = theme.palette.primary.main,
}: TextTopicType) => {
  const PADDING = constants.jsPdf.PADDING
  const WIDTHPdf = constants.jsPdf.WIDTH
  const FONT_SIZE = constants.jsPdf.FONT_SIZE

  const WIDTH = WIDTHPdf(pdf)

  pdf.setFont('Roboto-Regular')
  pdf.setFontSize(FONT_SIZE.PARAGRAPH)
  pdf.setTextColor(textColor)

  pdf.setFillColor(textColor)
  pdf.circle(PADDING + 2, positionY - 3, 8, 'DF')

  pdf.setTextColor(colors.grey[50])
  pdf.text(identifier, PADDING, positionY, undefined, 'justify')

  const splitedParagraphs = pdf.splitTextToSize(text, WIDTH - PADDING * 3)

  pdf.setFont('Roboto-Regular')
  pdf.setFontSize(FONT_SIZE.PARAGRAPH)
  pdf.setTextColor(textColor)
  pdf.text(
    splitedParagraphs,
    PADDING + 35,
    positionY + 15,
    undefined,
    'justify'
  )

  pdf.setFont('Raleway-Bold')
  pdf.setFontSize(FONT_SIZE.TOPIC)
  pdf.setTextColor(textColor)
  pdf.text(title, PADDING + 35, positionY, undefined, 'left')
}

export default textTopic
