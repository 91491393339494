import { useFormContext } from 'react-hook-form'
import { Box, Paper, Typography } from '@mui/material'

import Question from '../Question'

import { FinalityType } from './finality.types'
import { LiaQuestionType } from 'types/liaQuestion.types'
import { QuestionsDpoFormType } from '../../schema'

import constants from 'constants/index'

const Finality = ({ questions }: FinalityType) => {
  const {
    NATURE_OF_PERSONAL_DATA,
    DATA_CHILDREN_TEENAGERS,
    LEGITIMATE_INTEREST_FINALITY,
    CONCRETE_SITUATION,
  } = constants.legitimateInterest.LIA_QUESTION_KINDS.ALL_KINDS

  const filterQuestionsNaturePersonalData = (questions: LiaQuestionType[]) => {
    return questions?.filter((question) => {
      return question?.kind === NATURE_OF_PERSONAL_DATA
    })
  }

  const filterQuestionsDataChildrenTeenagers = (
    questions: LiaQuestionType[]
  ) => {
    return questions?.filter((question) => {
      return question?.kind === DATA_CHILDREN_TEENAGERS
    })
  }

  const filterQuestionsLegitimateInterestFinality = (
    questions: LiaQuestionType[]
  ) => {
    return questions?.filter((question) => {
      return question?.kind === LEGITIMATE_INTEREST_FINALITY
    })
  }

  const filterQuestionsConcreteSituation = (questions: LiaQuestionType[]) => {
    return questions?.filter((question) => {
      return question?.kind === CONCRETE_SITUATION
    })
  }

  const questionsNaturePersonalData =
    filterQuestionsNaturePersonalData(questions)
  const questionsDataChildrenTeenagers =
    filterQuestionsDataChildrenTeenagers(questions)
  const questionsLegitimateInterestFinality =
    filterQuestionsLegitimateInterestFinality(questions)
  const questionsConcreteSituation = filterQuestionsConcreteSituation(questions)

  const {
    control,
    formState: { errors },
  } = useFormContext<QuestionsDpoFormType>()

  return (
    <Paper component={Box} mb={3} p={4}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontWeight={700} color="primary">
          Natureza dos dados pessoais
        </Typography>
        {questionsNaturePersonalData?.map((question) => (
          <Question
            key={question?.id}
            question={question}
            control={control}
            errors={errors}
          />
        ))}
      </Box>
      <Box display="flex" flexDirection="column" gap={3} mt={6}>
        <Typography fontWeight={700} color="primary">
          Dados de crianças e adolescentes
        </Typography>
        {questionsDataChildrenTeenagers?.map((question) => (
          <Question
            key={question?.id}
            question={question}
            control={control}
            errors={errors}
          />
        ))}
      </Box>
      <Box display="flex" flexDirection="column" gap={3} mt={6}>
        <Typography fontWeight={700} color="primary">
          Interesses e finalidades legítimas
        </Typography>
        {questionsLegitimateInterestFinality?.map((question) => (
          <Question
            key={question?.id}
            question={question}
            control={control}
            errors={errors}
          />
        ))}
      </Box>
      <Box display="flex" flexDirection="column" gap={3} mt={6}>
        <Typography fontWeight={700} color="primary">
          Situação concreta
        </Typography>
        {questionsConcreteSituation?.map((question) => (
          <Question
            key={question?.id}
            question={question}
            control={control}
            errors={errors}
          />
        ))}
      </Box>
    </Paper>
  )
}

export default Finality
