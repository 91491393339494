import api from 'service/api'

const dponetAPI = api.create('dponet')

const me = async ({ params = {} }: { params: object }) =>
  await dponetAPI.get('/advisors/me', { params })

const identify = async () =>
  await dponetAPI.get('/advisors/organization_users/identify')

const permissions = async () =>
  await dponetAPI.get('/advisors/organization_users/permissions')

export default {
  me,
  identify,
  permissions,
}
