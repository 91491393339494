import { useEffect } from 'react'

import { OutsideClickHookType } from 'types/hook.types'

const useOutsideClick = <T extends HTMLElement>({
  ref,
  callback,
}: OutsideClickHookType<T>) => {
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback(event)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}

export default useOutsideClick
