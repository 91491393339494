import { Stepper, styled } from '@mui/material'

const StyledStepper = styled(Stepper)(() => ({
  padding: 0,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#D9D9D9',
  borderRadius: 4,
  backgroundColor: '#FFFFFF',
}))

export default StyledStepper
