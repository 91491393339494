import { Box as BoxMui } from '@mui/material'
import { styled } from '@mui/material/styles'

const DescriptionFaq = styled(BoxMui)(() => ({
  width: '-webkit-fill-available',
  '& img': {
    width: '80%',
    height: 'auto',
    margin: 'auto',
    display: 'block',
  },
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 500,
}))

export default DescriptionFaq
