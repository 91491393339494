import { colors } from '@mui/material'

import { disclaimerType } from './disclaimer.types'

import { ratio } from '../../helpers'
import constants from 'constants/index'
import theme from 'theme'
import { logoAdvisorBlue } from '../../assets/logoAdvisorBlue64'

const disclaimer = ({ pdf, date }: disclaimerType) => {
  const PADDING = constants.jsPdf.PADDING
  const WIDTHPdf = constants.jsPdf.WIDTH
  const HEIGHTPdf = constants.jsPdf.HEIGHT
  const FONT_SIZE = constants.jsPdf.FONT_SIZE

  const WIDTH = WIDTHPdf(pdf)
  const HEIGHT = HEIGHTPdf(pdf)

  pdf.addPage('a4', 'p')
  pdf.setFillColor(colors.grey['A100'])
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')

  pdf.addImage(
    logoAdvisorBlue,
    'png',
    WIDTH / 2 - PADDING * 1.25,
    PADDING,
    ratio(500),
    ratio(203)
  )
  pdf.setFontSize(FONT_SIZE.SUBTITLE)
  pdf.setLineHeightFactor(1.2)
  pdf.setFont('Raleway-Bold')

  pdf.setTextColor(theme.palette.primary.main)
  pdf.text(
    'Informação Importante',
    PADDING,
    HEIGHT / 2 - ratio(300),
    undefined,
    'left'
  )

  pdf.setFontSize(FONT_SIZE.PARAGRAPH)
  pdf.setFont('Raleway-Regular')
  const splited_infos = pdf.splitTextToSize(
    'Esse relatório fornece informações gerais sobre o progresso da adequação da organização, contudo, não se trata de um documento oficial, isto é, que comprova a adequação perante autoridades. O documento exigido pela LGPD é o Relatório de Impacto à Proteção de Dados (RIPD), um documento que atende a todos os requisitos técnicos exigidos pela Autoridade Nacional de Proteção de Dados (ANPD).\n\nDessa forma, a DPOnet alerta que este Relatório Gerencial não possui fins técnicos ou comprobatórios, mas somente gráficos que ajudam no gerenciamento de atividades. A DPOnet não se responsabiliza pelo uso indevido desse relatório como comprovação de adequação à LGPD, ou quaisquer outros fins.',
    WIDTH - PADDING * 2
  )
  pdf.text(
    splited_infos,
    PADDING,
    HEIGHT / 2 - ratio(150),
    undefined,
    'justify'
  )

  pdf.text(date, WIDTH / 2, HEIGHT - PADDING, undefined, 'justify')
}

export default disclaimer
