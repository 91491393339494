import { useEffect, useState } from 'react'

import { FilterInput, Filters } from 'components'

import { FilterHookType } from 'types/filters.types'

import { useFetch } from 'hooks'

import { formatters } from 'helpers'
import service from 'service'
import constants from 'constants/index'

const FaqsFilters = ({ filter }: { filter: FilterHookType }) => {
  const [shouldFetch, setShouldFetch] = useState<boolean>(false)

  useEffect(() => {
    if (filter.drawerOpen && !shouldFetch) setShouldFetch(true)
  }, [filter.drawerOpen])

  const { response: responseCategories, loading: loadingCategories } = useFetch(
    service.dponet.faqs.getCategories,
    {
      params: {
        perPage: 999,
        minimal: true,
        categoryType: constants.faqs.CATEGORIES_TYPE.faq,
      },
    },
    [],
    shouldFetch
  )

  const faqCategories = responseCategories?.data?.faqsDocumentationsCategories

  return (
    <Filters filter={filter}>
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        loading={loadingCategories}
        {...{
          label: 'Classificadores',
          name: 'categories',
          options: formatters.autoCompleteOptions(faqCategories),
          multiple: true,
        }}
      />
    </Filters>
  )
}

export default FaqsFilters
