import { Box, BoxProps, Paper, Typography } from '@mui/material'

import { NoDataType } from './NoData.types'

import theme from 'theme'
import ErrorImage from 'images/rectangle_213.svg'

const NoData = ({
  title,
  subTitle,
  withoutPaper = false,
  ...rest
}: NoDataType & BoxProps) => {
  const commonContent = (
    <Box display="flex" gap={15} p={10}>
      <img src={ErrorImage} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        color={theme.palette.primary.main}
      >
        <Typography variant="h6" fontWeight={900}>
          {title}
        </Typography>
        <Typography>{subTitle}</Typography>
      </Box>
    </Box>
  )

  return withoutPaper ? (
    commonContent
  ) : (
    <Paper component={Box} {...rest} variant="outlined">
      {commonContent}
    </Paper>
  )
}

export default NoData
