import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const ColorError = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.error.main,
  gap: theme.spacing(2),
}))

export default ColorError
