import { useForm } from 'react-hook-form'
import { Box, Typography } from '@mui/material'

import { useSnackbar } from 'hooks'

import { formatters } from 'helpers'

import { LinkInactivationFormType } from './linkInactivationForm.types'

import service from 'service'

const LinkInactivationForm = ({
  formName,
  onClose,
  companyData,
  questionnaireId,
  refresh,
  setIsLoading,
}: LinkInactivationFormType) => {
  const { snackbar } = useSnackbar()
  const { handleSubmit } = useForm()

  const onSubmit = async () => {
    try {
      setIsLoading(true)

      await service.dponet.questionnaires.uncoupleCompanies({
        organizationQuestionnaireId: questionnaireId,
        questionnaire: {
          companyId: companyData?.id,
        },
      })

      refresh()
      onClose()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      id={formName}
      display="flex"
      flexDirection="column"
    >
      <Typography>
        Deseja realmente desvincular a empresa&nbsp;
        <strong>{companyData?.name}</strong> com o questionário?
      </Typography>
    </Box>
  )
}

export default LinkInactivationForm
