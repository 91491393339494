import displayedOther from './displayedOther'
import mountOption from './mountOption'
import otherOption from './otherOption'
import holderAffectedInformedStatus from './holderAffectedInformedStatus'
import optionImpactIncidentHolderStatus from './optionImpactIncidentHolderStatus'
import dataProtectedNotPossibleIdentifyHolderStatus from './dataProtectedNotPossibleIdentifyHolderStatus'
import enumSteps from './enumSteps'

export default {
  displayedOther,
  mountOption,
  otherOption,
  holderAffectedInformedStatus,
  optionImpactIncidentHolderStatus,
  dataProtectedNotPossibleIdentifyHolderStatus,
  enumSteps,
}
