import { useState } from 'react'
import { Box, Paper, Typography } from '@mui/material'

import { LoadingFeedback } from 'components'
import { RatingImage } from './components'

import { TicketFeedbackComponentType } from './ticketFeedback.types'

import { useSnackbar } from 'hooks'

import service from 'service'

import badColorful from 'images/ratings/bad-colorful.png'
import veryBadColorful from 'images/ratings/very-bad-colorful.png'
import neutralColorful from 'images/ratings/neutral-colorful.png'
import goodColorful from 'images/ratings/good-colorful.png'
import veryGoodColorful from 'images/ratings/very-good-colorful.png'
import { formatters } from 'helpers'

const TicketFeedback = ({
  ticketId,
  ticketFeedback,
  refresh,
}: TicketFeedbackComponentType) => {
  const [loading, setLoading] = useState(false)

  const { snackbar } = useSnackbar()

  const ratings = [
    { name: 'very_bad', image: veryBadColorful },
    { name: 'bad', image: badColorful },
    { name: 'neutral', image: neutralColorful },
    { name: 'good', image: goodColorful },
    { name: 'very_good', image: veryGoodColorful },
  ]

  const handleSelectFeedback = async (ratingName: string) => {
    if (ticketFeedback || loading) return

    try {
      setLoading(true)

      await service.dponet.ticket.createFeedback({
        ticketId,
        ticketFeedback: { rating: ratingName },
      })

      refresh()
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Paper component={Box} variant="outlined" mt={3} p={3}>
      <LoadingFeedback open={loading} />

      <Typography variant="body1" color="primary">
        Como você avalia nosso suporte?
      </Typography>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        gap={2}
      >
        {ratings.map((rating) => (
          <Box
            key={rating.name}
            onClick={() => handleSelectFeedback(rating.name)}
          >
            <RatingImage
              src={rating.image}
              alt={rating.name}
              disabled={
                !!ticketFeedback && ticketFeedback?.rating !== rating.name
              }
              selected={ticketFeedback?.rating === rating.name}
            />
          </Box>
        ))}
      </Box>
    </Paper>
  )
}

export default TicketFeedback
