import { dataProcesses } from 'helpers'

import { isEmpty, isArray } from 'lodash'

import {
  FormattedLegalFrameworkType,
  LegalFrameworkType,
} from 'types/legalFramework.types'

const orderLegalFrameworks = (legalFrameworks: LegalFrameworkType[]) => {
  if (!isArray(legalFrameworks)) return
  const formattedLegalFrameworks: FormattedLegalFrameworkType = {}

  legalFrameworks.map((legalFramework) => {
    const dataTypeValue = dataProcesses.legalFrameworkValueByDataType(
      legalFramework.dataType
    )

    if (isEmpty(formattedLegalFrameworks[dataTypeValue])) {
      formattedLegalFrameworks[dataTypeValue] = {
        label: dataProcesses.legalFrameworkLabelByDataType(
          legalFramework.dataType
        ),
        items: [legalFramework],
      }
    } else {
      formattedLegalFrameworks[dataTypeValue].items = [
        ...formattedLegalFrameworks[dataTypeValue].items,
        legalFramework,
      ]
    }
    return legalFramework
  })

  return formattedLegalFrameworks
}

export default orderLegalFrameworks
