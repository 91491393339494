import { useState } from 'react'
import LegitimateInterestContext from 'contexts/LegitimateInterestContext'

import { LegitimateInterestProviderType } from './LegitimateInterestProvider.types'

const LegitimateInterestProvider = ({
  children,
}: LegitimateInterestProviderType) => {
  const [activeStep, setActiveStep] = useState<number>(0)
  const [stepFinalityCompleted, setStepFinalityCompleted] =
    useState<boolean>(false)
  const [stepNecessityCompleted, setStepNecessityCompleted] =
    useState<boolean>(false)
  const [
    stepBalancingSafeguardingCompleted,
    setStepBalancingSafeguardingCompleted,
  ] = useState<boolean>(false)
  const [stepFinalityIncomplete, setStepFinalityIncomplete] =
    useState<boolean>(false)
  const [stepNecessityIncomplete, setStepNecessityIncomplete] =
    useState<boolean>(false)
  const [
    stepBalancingSafeguardingIncomplete,
    setStepBalancingSafeguardingIncomplete,
  ] = useState<boolean>(false)

  return (
    <LegitimateInterestContext.Provider
      value={{
        activeStep,
        setActiveStep,
        stepFinalityCompleted,
        setStepFinalityCompleted,
        stepNecessityCompleted,
        setStepNecessityCompleted,
        stepBalancingSafeguardingCompleted,
        setStepBalancingSafeguardingCompleted,
        stepFinalityIncomplete,
        setStepFinalityIncomplete,
        stepNecessityIncomplete,
        setStepNecessityIncomplete,
        stepBalancingSafeguardingIncomplete,
        setStepBalancingSafeguardingIncomplete,
      }}
    >
      {children}
    </LegitimateInterestContext.Provider>
  )
}

export default LegitimateInterestProvider
