import * as yup from 'yup'

import organizationConstants from 'constants/organizations'

const schema = yup.object({
  name: yup.string().max(50).required(),
  kind: yup.string().required(),
  kindPerson: yup.string().when('kind', {
    is: organizationConstants.KIND_DPO,
    then: () => yup.string().required(),
  }),
  responsible: yup.string().when('kindPerson', {
    is: 'juridic',
    then: () => yup.string().required(),
  }),
  substitute: yup.string().when('kindPerson', {
    is: 'juridic',
    then: () => yup.string().required(),
  }),
  cnpj: yup.string().when('kindPerson', {
    is: 'juridic',
    then: () => yup.string().required(),
  }),
  cpf: yup.string().when('kindPerson', {
    is: 'personal',
    then: () => yup.string().required(),
  }),
  dpo: yup.string().when('kind', {
    is: organizationConstants.KIND_DPO,
    then: () => yup.string().required(),
  }),
})

export default schema
