import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const consultDocument = ({ document = '' }: { document?: string | number }) => {
  return dponetAPI.get(`/advisors/companies/consult/${document}`)
}

const userCompanies = ({ companyId }: { companyId: string | number }) => {
  return dponetAPI.get(`/advisors/companies/${companyId}/user_companies`)
}

const profiles = ({ companyId }: { companyId?: string | number }) => {
  return dponetAPI.get(`/advisors/companies/${companyId}/profiles`)
}

export default { consultDocument, userCompanies, profiles }
