import { Paper, Typography, Box } from '@mui/material'

import { BoxPolygonFilled, BoxPolygonOutlined } from 'components'

import { ComplianceByPercentageType } from './complianceByPercentage.types'

import theme from 'theme'

const ComplianceByPercentage = ({
  title,
  subtitle,
  zeroToTwenty,
  twentyToForty,
  fortyToSixty,
  sixtyToEighty,
  eightyToHundred,
}: ComplianceByPercentageType) => {
  return (
    <Paper component={Box} height="100%" id="complianceByPorcentage">
      <Box
        bgcolor={theme.palette.primary.main}
        height="100%"
        borderRadius={1}
        p={4}
        pb={5}
        display="grid"
        gridTemplateRows="auto 1fr"
        gap={6}
      >
        <Box>
          <Typography
            variant="body1"
            color={theme.palette.primary.contrastText}
            fontWeight={500}
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle2"
            color={theme.palette.primary.contrastText}
          >
            {subtitle}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={6}
          >
            <BoxPolygonOutlined value={zeroToTwenty} label="0 - 20%" />
            <BoxPolygonOutlined value={twentyToForty} label="20 - 40%" />
          </Box>
          <Box display="flex" justifyContent="center">
            <BoxPolygonFilled value={eightyToHundred} label="Acima de 80%" />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={6}
          >
            <BoxPolygonOutlined value={fortyToSixty} label="40 - 60%" />
            <BoxPolygonOutlined value={sixtyToEighty} label="60 - 80%" />
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default ComplianceByPercentage
