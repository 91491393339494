import { Controller, useForm } from 'react-hook-form'
import { Box, TextField } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { useSnackbar } from 'hooks'

import { formatters } from 'helpers'

import { QuestionnairesCreateFormType } from './questionnairesCreateForm.types'

import schema, { QuestionnairesCreateFormSchemaType } from './schema'
import service from 'service'

const QuestionnairesCreateForm = ({
  formName,
  onClose,
  refresh,
  setIsLoading,
}: QuestionnairesCreateFormType) => {
  const { snackbar } = useSnackbar()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<QuestionnairesCreateFormSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      kind: '',
    },
  })

  const onSubmit = async (data: QuestionnairesCreateFormSchemaType) => {
    try {
      setIsLoading(true)
      await service.dponet.questionnaires.post({ questionnaire: { ...data } })
      snackbar.open({
        message: 'Questionário criado com sucesso!',
        variant: 'success',
      })
      reset()
      refresh()
      onClose()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      id={formName}
      display="flex"
      flexDirection="column"
    >
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors?.kind}
            helperText={errors?.kind?.message}
            label="Título do questionário"
            type="text"
            variant="outlined"
            fullWidth
          />
        )}
        control={control}
        name="kind"
      />
    </Box>
  )
}

export default QuestionnairesCreateForm
