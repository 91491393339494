import { BoxProps, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { PolygonOutlined } from './components'
import theme from 'theme'
import { BoxPolygonType } from './boxPolygon.types'

const BoxPolygonOutlined = ({
  value,
  label,
  ...rest
}: BoxPolygonType & BoxProps) => {
  return (
    <PolygonOutlined {...rest}>
      <Typography variant="h6" color={theme.palette.primary.contrastText}>
        {value}
      </Typography>
      <Typography variant="body2" color={theme.palette.primary.contrastText}>
        {label}
      </Typography>
    </PolygonOutlined>
  )
}

BoxPolygonOutlined.propTypes = {
  data: PropTypes.array,
}

export default BoxPolygonOutlined
