import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import { reverse } from 'named-urls'

import { BasicDialog } from 'components'

import { OrganizationCreateType } from './organizationDestroy.types'

import service from 'service'
import routes from 'constants/routes'

const OrganizationDestroy = ({
  refresh,
  setLoading,
  organization,
}: OrganizationCreateType) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const navigate = useNavigate()

  const deleteOrganization = async () => {
    try {
      setLoading(true)

      await service.dponet.organization.deleteOrganization({
        organizationId: organization?.id,
      })
      refresh()
      navigate(reverse(routes.app.organizations.all))
    } finally {
      setLoading(false)
    }
  }

  const handleOpenDestroyDialog = () => setOpenDeleteDialog(true)
  const handleCloseDestroyDialog = () => setOpenDeleteDialog(false)

  return (
    <>
      <Button
        variant="contained"
        color="error"
        onClick={handleOpenDestroyDialog}
        aria-label="abrir modal para excluir grupo"
      >
        EXCLUIR GRUPO
      </Button>
      <BasicDialog
        open={openDeleteDialog}
        title="Excluir Grupo"
        onClose={handleCloseDestroyDialog}
        dialogActions={
          <>
            <Button color="primary" onClick={handleCloseDestroyDialog}>
              CANCELAR
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={deleteOrganization}
            >
              EXCLUIR
            </Button>
          </>
        }
      >
        <Typography variant="body1">
          Você tem certeza que quer excluir esse Grupo Advisor? <br />
          Isso fará que empresas, usuários e demais vínculos sejam perdidos.
          <br />
          Essa ação <strong>NÃO</strong> pode ser desfeita.
        </Typography>
      </BasicDialog>
    </>
  )
}

OrganizationDestroy.defaultProps = {
  refresh: () => {},
}

export default OrganizationDestroy
