import { colors } from '@mui/material'

import constants from 'constants/index'
import { formatters } from 'helpers'

import components from 'utils/jsPDF/components'
import { generalInformationType } from './GeneralInformation.types'

const GeneralInformation = ({
  pdf,
  date,
  incidents,
}: generalInformationType) => {
  const GAP = constants.jsPdf.GAP
  const MARGIN_TABLE = constants.jsPdf.MARGIN_TABLE
  const controllerData = incidents?.incidentDataAgents?.controller?.[0]
  const commissionerData = incidents?.incidentDataAgents?.commissioner?.[0]
  const representativeData = incidents?.incidentDataAgents?.representative?.[0]

  pdf.addPage('a4', 'p')

  components.subtitle({
    pdf,
    sub: 'Formulário de Comunicação de Incidente \nde Segurança com Dados Pessoais - ANPD',
    positionY: GAP,
    textColor: colors.lightBlue['900'],
  })

  components.subtitle({ pdf, sub: 'Informações gerais', positionY: GAP * 1.85 })

  components.lineWithDate({ pdf, date: date })

  components.paragraph({
    pdf,
    text: 'Dados do Incidente',
    positionY: GAP * 2.25,
    font: 'Roboto-Bold',
  })

  const incidentData = [
    [
      'Data do Incidente',
      formatters.date.ptBrFormatDate(incidents?.incidentDatetime),
    ],
    [
      'Hora do Incidente',
      formatters.date.ptBrFormatHours(incidents?.incidentDatetime),
    ],
    ['Quantidade estimada de titulares afetados', incidents?.affectedHolders],
  ]

  components.table({
    pdf,
    body: incidentData,
    startY: GAP * 2.35,
    cellWidth: 0.4,
  })

  components.paragraph({
    pdf,
    text: 'Dados do Controlador',
    positionY: pdf.lastAutoTable.finalY + MARGIN_TABLE,
    font: 'Roboto-Bold',
  })

  const controllerDataTable = [
    ['Nome da Empresa', controllerData?.name],
    ['CNPJ', controllerData?.document],
    ['CEP', controllerData?.cep],
    ['Cidade', controllerData?.city],
    ['Estado', controllerData?.state],
    ['Telefone', controllerData?.phone],
    ['E-mail', controllerData?.email],
    [
      'Declara ser Microempresa ou Empresa de Pequeno Porte?',
      formatters.convertBoolToString(controllerData?.declaresSmallCompany),
    ],
    [
      'Declara ser Agente de Tratamento de Pequeno Porte?',
      formatters.convertBoolToString(
        controllerData?.declaresSmallAgentTreatment
      ),
    ],
    [
      'Número aproximado de titulares cujos dados são tratados por sua organização',
      controllerData?.treatmentHolder,
    ],
  ]

  components.table({
    pdf,
    body: controllerDataTable,
    startY: pdf.lastAutoTable.finalY + MARGIN_TABLE + GAP * 0.1,
    cellWidth: 0.4,
  })

  components.paragraph({
    pdf,
    text: 'Dados do Encarregado pelo tratamento de Dados Pessoais',
    positionY: pdf.lastAutoTable.finalY + MARGIN_TABLE,
    font: 'Roboto-Bold',
  })

  const dpoData = [
    ['Nome do encarregado', commissionerData?.name],
    ['CPF/CNPJ', commissionerData?.document],
    ['Telefone', commissionerData?.phone],
    ['E-mail', commissionerData?.email],
  ]

  components.table({
    pdf,
    body: dpoData,
    startY: pdf.lastAutoTable.finalY + MARGIN_TABLE + GAP * 0.1,
    cellWidth: 0.4,
  })

  components.numberPage({ pdf, color: colors.grey['900'] })

  pdf.addPage('a4', 'p')

  components.lineWithDate({ pdf, date: date })

  components.paragraph({
    pdf,
    text: 'Dados do Notificante/Representante Legal',
    positionY: GAP,
    font: 'Roboto-Bold',
  })

  const notifierData = [
    ['Nome do Representante Legal', representativeData?.name],
    ['CPF/CNPJ', representativeData?.document],
    ['Telefone', representativeData?.phone],
    ['E-mail', representativeData?.email],
  ]

  components.table({
    pdf,
    body: notifierData,
    startY: GAP * 1.1,
    cellWidth: 0.4,
  })

  components.numberPage({ pdf, color: colors.grey['900'] })
}

export default GeneralInformation
