const ALL_TASKS = 'all'
const PENDING_TASK = 'pending'
const ONGOING_TASK = 'ongoing'
const OVERDUE_TASK = 'overdue'
const FINALIZED_TASK = 'finalized'

const ALL_TASKS_LABEL = 'Todas'
const PENDING_TASK_LABEL = 'Pendente'
const ONGOING_TASK_LABEL = 'Em andamento'
const OVERDUE_TASK_LABEL = 'Atrasada'
const FINALIZED_TASK_LABEL = 'Finalizada'

const TASKS_STATUS_TABS = [
  { id: ALL_TASKS, name: ALL_TASKS_LABEL },
  { id: PENDING_TASK, name: `${PENDING_TASK_LABEL}s` },
  { id: ONGOING_TASK, name: ONGOING_TASK_LABEL },
  { id: OVERDUE_TASK, name: `${OVERDUE_TASK_LABEL}s` },
  { id: FINALIZED_TASK, name: `${FINALIZED_TASK_LABEL}s` },
]

const ALL_STATUSES = [
  PENDING_TASK,
  ONGOING_TASK,
  OVERDUE_TASK,
  FINALIZED_TASK,
] as const

const CHIP_STATUS_COLOR = {
  [PENDING_TASK]: 'info',
  [ONGOING_TASK]: 'warning',
  [OVERDUE_TASK]: 'error',
  [FINALIZED_TASK]: 'success',
}

const STATUS_NAME = {
  [PENDING_TASK]: PENDING_TASK_LABEL,
  [ONGOING_TASK]: ONGOING_TASK_LABEL,
  [OVERDUE_TASK]: OVERDUE_TASK_LABEL,
  [FINALIZED_TASK]: FINALIZED_TASK_LABEL,
}

export type TaskStatusType = (typeof ALL_STATUSES)[number]

export default {
  ALL_TASKS,
  CHIP_STATUS_COLOR,
  FINALIZED_TASK,
  PENDING_TASK,
  STATUS_NAME,
  TASKS_STATUS_TABS,
}
