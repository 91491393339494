import constants from 'constants/index'

const labelTranslation = (visible: boolean) => {
  return visible
    ? constants.questionnaire.ACTIVE_QUESTIONNAIRE_LABEL.toUpperCase()
    : constants.questionnaire.INACTIVE_QUESTIONNAIRE_LABEL.toUpperCase()
}

const statusColor = (visible: boolean) => {
  return visible ? 'success' : 'neutral'
}

export default {
  labelTranslation,
  statusColor,
}
