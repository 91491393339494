import { Step, styled } from '@mui/material'

const StyledStep = styled(Step)(({ theme }) => ({
  padding: theme.spacing(2),
  border: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.background.default,
  width: '100%',
  display: 'flex',
  '& span': {
    flexDirection: 'row !important',
  },
}))

export default StyledStep
