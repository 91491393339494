import * as yup from 'yup'

import constants from 'constants/index'

const schema = yup.object({
  reason: yup
    .string()
    .max(1000)
    .when('status', {
      is: constants.questionnaire.INACTIVE_QUESTION,
      then: () => yup.string().max(1000).required(),
    }),
  status: yup.number().required(),
})

export type QuestionChangeStatusFormSchemaType = yup.InferType<typeof schema>

export default schema
