import { Fragment } from 'react'
import {
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  Table as TableComponent,
  TableRow,
  Tooltip,
  IconButton,
} from '@mui/material'
import { reverse } from 'named-urls'
import { useNavigate } from 'react-router-dom'
import { ArrowRight as ArrowRightIcon, Slash as SlashIcon } from 'react-feather'

import { Permitted } from 'components'
import { ChipStatus } from './components'

import { LegitimateInterestTableType } from './LegitimateInterestTabletypes'

import permissionTags from 'constants/permissionTags'
import date from 'helpers/formatters/date'
import constants from 'constants/index'
import palette from 'theme/palette'

const LegitimateInterestTable = ({
  dataProcessesWithLia,
}: LegitimateInterestTableType) => {
  const { DISAPPROVED_LIA, PENDING_LIA, APPROVED, REVIEW_LIA_DPO } =
    constants.legitimateInterest.STATUSES_LIA

  const notPermittedReviewStatus = [DISAPPROVED_LIA, PENDING_LIA, APPROVED]

  const navigate = useNavigate()

  const goToReviewPage = (statusId: number, id: number) => {
    if (statusId === REVIEW_LIA_DPO) {
      return navigate(
        reverse(constants.routes.app.legitimateInterest.reviewDpo, {
          dataProcessId: id,
        })
      )
    }

    navigate(
      reverse(constants.routes.app.legitimateInterest.reviewClient, {
        dataProcessId: id,
      })
    )
  }

  return (
    <Fragment>
      <TableContainer>
        <TableComponent aria-label="Listagem processos com legítimo interesse">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nome do processo</TableCell>
              <TableCell>Empresa</TableCell>
              <TableCell>Documento</TableCell>
              <TableCell align="center">Criado em</TableCell>
              <TableCell align="center">Status</TableCell>
              <Permitted
                someTags={[
                  permissionTags.LEGITIMATE_INTEREST.MANAGE,
                  permissionTags.LEGITIMATE_INTEREST.SHOW,
                ]}
              >
                <TableCell align="center">Ações</TableCell>
              </Permitted>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataProcessesWithLia?.map((dataProcess) => (
              <TableRow key={dataProcess?.id}>
                <TableCell>{dataProcess?.id}</TableCell>
                <TableCell>{dataProcess?.name}</TableCell>
                <TableCell>{dataProcess?.company?.name}</TableCell>
                <TableCell>{dataProcess?.company?.document}</TableCell>
                <TableCell align="center">
                  {date.ptBrFormatDate(dataProcess?.updatedAt)}
                </TableCell>
                <TableCell align="center">
                  <ChipStatus statusId={dataProcess?.statusId} />
                </TableCell>
                <TableCell align="center">
                  {notPermittedReviewStatus.includes(dataProcess?.statusId) ? (
                    <SlashIcon color={palette.custom.dark} />
                  ) : (
                    <Permitted
                      someTags={[
                        permissionTags.LEGITIMATE_INTEREST.MANAGE,
                        permissionTags.LEGITIMATE_INTEREST.SHOW,
                      ]}
                    >
                      <Tooltip title="Revisar">
                        <IconButton
                          onClick={() =>
                            goToReviewPage(
                              dataProcess?.statusId,
                              dataProcess?.id
                            )
                          }
                        >
                          <ArrowRightIcon color={palette.custom.dark} />
                        </IconButton>
                      </Tooltip>
                    </Permitted>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableComponent>
      </TableContainer>
    </Fragment>
  )
}

export default LegitimateInterestTable
