import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Box, Container } from '@mui/material'

import { FilterButton, NoData, PageHead } from 'components'

import useFilter from 'hooks/useFilter'

import { FaqsType } from 'types/faqs.types'

import { CardFaqs, FaqsFilter, SearchBox } from './components'

import service from 'service'
import constants from 'constants/index'

const Main = () => {
  const [loading, setLoading] = useState(false)
  const [faqs, setFaqs] = useState<FaqsType[]>([])
  const [searchInput, setSearchInput] = useState('')
  const [valueInput, setValueInput] = useState('')
  const [filteredResults, setFilteredResults] = useState<FaqsType[]>([])

  const filter = useFilter()

  useEffect(() => {
    setLoading(true)
    const loadFaqsDocumentations = async () => {
      const response = await service.dponet.faqs.get({
        params: {
          kind: constants.faqs.KINDS.FAQ,
          draft: false,
          visualization: constants.faqs.VISUALIZATION.CA,
          minimal: true,
          perPage: 10000000,
          active: true,
          ...filter.filters,
        },
      })
      const data = response?.data?.faqsDocumentations
      setFaqs(data)
    }
    loadFaqsDocumentations()
    setLoading(false)
  }, [filter.filters])

  return (
    <Container component={Box} paddingY={12}>
      <PageHead
        name="Central de Ajuda"
        actionButton={<FilterButton setDrawerOpen={filter.setDrawerOpen} />}
      />
      {loading || isEmpty(faqs) ? (
        <NoData title="Nenhuma faq foi encontrada." />
      ) : (
        <>
          <SearchBox
            faqs={faqs}
            searchInput={searchInput}
            setFilteredResults={setFilteredResults}
            setSearchInput={setSearchInput}
            setValueInput={setValueInput}
            valueInput={valueInput}
          />
          {!loading && searchInput.length <= 0 && valueInput === '' ? (
            <CardFaqs faqs={faqs} />
          ) : (
            <CardFaqs faqs={filteredResults} />
          )}
        </>
      )}

      <FaqsFilter filter={filter} />
    </Container>
  )
}

export default Main
