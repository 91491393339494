import { Box, Paper } from '@mui/material'

import { Chart, PaperHeader } from 'components'

import { DepartmentsByThreatsType } from './departmentsBtThreats.types'

import securityMeasureConstants from 'constants/securityMeasure'
import dashboardHelper from 'helpers/dashboard'

const DepartmentsByThreats = ({
  title,
  departments,
  orderByCriteria,
  orderDirection,
}: DepartmentsByThreatsType) => {
  const threatsByDepartment = dashboardHelper.threatsByDepartment(
    departments,
    orderByCriteria,
    orderDirection
  )

  const kinds = threatsByDepartment.names

  const series = [
    { name: 'Baixo', data: threatsByDepartment?.lowValues },
    { name: 'Médio', data: threatsByDepartment?.mediumValues },
    { name: 'Alto', data: threatsByDepartment?.highValues },
    { name: 'Severo', data: threatsByDepartment?.severeValues },
  ]

  const colors = [
    securityMeasureConstants.ADOPTED_STATUS_COLOR,
    securityMeasureConstants.RISK_ASSUMED_STATUS_COLOR,
    securityMeasureConstants.IN_PROGRESS_STATUS_COLOR,
    securityMeasureConstants.OVERDUE_STATUS_COLOR,
  ]

  return (
    <Paper component={Box} p={2} width="100%" height="100%">
      <PaperHeader title={title} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
        height="100%"
        py={6}
      >
        <Chart.BarChart
          names={kinds}
          values={series}
          colors={colors}
          legendShow
          showLabels
          heightValue={250}
        />
      </Box>
    </Paper>
  )
}

export default DepartmentsByThreats
