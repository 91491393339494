import { colors } from '@mui/material'
import { isEmpty } from 'lodash'

import constants from 'constants/index'

import components from 'utils/jsPDF/components'

import { ImpactDescriptionType } from './ImpactDescription.types'

const ImpactDescription = ({ pdf, date, incidents }: ImpactDescriptionType) => {
  const GAP = constants.jsPdf.GAP
  const MARGIN_TABLE = constants.jsPdf.MARGIN_TABLE

  pdf.addPage('a4', 'p')

  components.subtitle({
    pdf,
    sub: 'Descrição e Impacto',
    positionY: GAP,
  })

  components.lineWithDate({
    pdf,
    date: date,
  })

  components.paragraph({
    pdf,
    text: 'Descrição do Incidente',
    positionY: GAP * 1.5,
    font: 'Roboto-Bold',
  })

  const incidentData = [
    [
      'Qual o tipo de incidente? (Informe o tipo mais específico)',
      incidents?.dataOptionIncidents?.whatTypeIncident
        ? incidents?.dataOptionIncidents?.whatTypeIncident
            .map((incident) => incident.name + '\n')
            .join('')
        : '-',
    ],
    [
      'Descreva, resumidamente, como ocorreu o incidente:',
      incidents?.description || '-',
    ],
    [
      'Explique, resumidamente, por que o incidente ocorreu (identifique a causa raiz, se conhecida):',
      incidents?.descriptionRootIncident || '-',
    ],
    [
      'Quais medidas foram adotadas para corrigir as causas do incidente?',
      incidents?.descriptionMeasures || '-',
    ],
  ]

  components.table({
    pdf,
    body: incidentData,
    startY: GAP * 1.6,
    cellWidth: 0.4,
  })

  const tableHeight = pdf.lastAutoTable.finalY + 10

  components.paragraph({
    pdf,
    text: 'Impactos do Incidente Sobre os Dados Pessoais',
    positionY: tableHeight + MARGIN_TABLE,
    font: 'Roboto-Bold',
  })

  const controllerDataTable = [
    [
      'De que formas o incidente afetou os dados pessoais:',
      !isEmpty(incidents?.incidentViolationTypes)
        ? incidents?.incidentViolationTypes
            .map((incidentViolationType) => incidentViolationType.name + '\n')
            .join('')
        : '-',
    ],
    [
      'Se aplicável, quais os tipos de dados pessoais sensíveis foram violados?',
      incidents?.dataOptionIncidents?.typesSensitivePersonalDataBreached
        ? incidents?.dataOptionIncidents?.typesSensitivePersonalDataBreached
            .map((sensitiveData) => sensitiveData.name + '\n')
            .join('')
        : '-',
    ],
    [
      'Se aplicável, descreva os tipos de dados pessoais sensíveis violados:',
      incidents?.descriptionTypePersonalBreached || '-',
    ],
    [
      'Quais os demais tipos de dados pessoais violados?',
      incidents?.dataOptionIncidents?.otherTypePersonalDataViolated
        ? incidents?.dataOptionIncidents?.otherTypePersonalDataViolated
            .map((dataType) => dataType.name + '\n')
            .join('')
        : '-',
    ],
    [
      'Descreva os tipos de dados pessoais não sensíveis violados, se cabível:',
      incidents?.nonSensitiveViolationDescription || '-',
    ],
  ]

  components.table({
    pdf,
    body: controllerDataTable,
    startY: tableHeight + MARGIN_TABLE + GAP * 0.1,
    cellWidth: 0.4,
  })

  components.numberPage({ pdf, color: colors.grey['900'] })
}

export default ImpactDescription
