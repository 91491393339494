import { Box, styled, colors } from '@mui/material'

const StyledBox = styled(Box)(() => ({
  fontSize: '1.3986rem',
  fontFamily: 'Roboto',
  height: 150,
  border: '1px solid',
  borderColor: colors.grey[400],
  overflow: 'auto',
  cursor: 'text',
}))

export default StyledBox
