import { Autocomplete, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

import service from 'service'
import organizationCompaniesContants from 'constants/organizationCompanies'

import { OrganizationCompanyType } from 'types/organizationCompany'
import {
  CompaniesAutocompleteType,
  AutoselectType,
} from './companiesAutocomplete.types'
import { formatters } from 'helpers'

const CompaniesAutocomplete = ({
  error,
  helperText,
  label = '',
  isOrganizationCompany = false,
  inputRef,
  defaultCompanies = [],
  placeholder,
  ...rest
}: CompaniesAutocompleteType) => {
  const [loading, setLoading] = useState(false)
  const [companyName, setCompanyName] = useState('')

  const [companiesArray, setCompaniesArray] =
    useState<AutoselectType[]>(defaultCompanies)

  const [selectedCompanies, setSelectedCompanies] =
    useState<AutoselectType[]>(defaultCompanies)

  const getCompanies = async (name: string) => {
    const responseCompanies = await service.dponet.organizationCompany.get({
      params: {
        companyName: name,
        status: organizationCompaniesContants.ACTIVE_STATUS,
        perPage: 999,
      },
    })

    const organizationCompanies: OrganizationCompanyType[] =
      responseCompanies?.data?.organizationCompanies

    const activeOrganizationCompanies: AutoselectType[] =
      organizationCompanies?.map((organizationCompany) => {
        const document = organizationCompany?.company?.document

        return {
          label: `${organizationCompany?.company?.name} - ${document}`,
          document,
          companyId: isOrganizationCompany
            ? organizationCompany?.id
            : organizationCompany?.company.id,
        }
      }) ?? []

    return activeOrganizationCompanies
  }

  useEffect(() => {
    const searchCompanies = async () => {
      await getCompanies(companyName).then((organizations) =>
        setCompaniesArray(organizations)
      )
      setLoading(false)
    }
    if (companyName?.length < 3) {
      return
    }
    setLoading(true)

    const timer = setTimeout(searchCompanies, 500)
    return () => clearTimeout(timer)
  }, [companyName])

  return (
    <Autocomplete
      {...rest}
      ref={inputRef}
      noOptionsText={formatters.autoCompleteNoText(companyName)}
      options={companiesArray}
      isOptionEqualToValue={(option, value) =>
        option?.companyId === value?.companyId
      }
      loading={loading}
      loadingText="Carregando..."
      multiple
      value={selectedCompanies}
      onChange={(_, data: AutoselectType[] | null) => {
        const value = data === null ? [] : data
        setSelectedCompanies(value)
        return rest.onChange(value)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="dense"
          error={!!error}
          helperText={helperText}
          InputLabelProps={{
            children: null,
          }}
          onChange={(data) => setCompanyName(data.target.value)}
          placeholder={placeholder}
        />
      )}
    />
  )
}

export default CompaniesAutocomplete
