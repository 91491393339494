import { useState } from 'react'
import {
  Chip,
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as TableRowDefault,
} from '@mui/material'

import { MenuButton, Table as TableComponent, TableRow } from 'components'
import { PermittedOrganization } from 'views/Organization/components'
import { OrganizationUserInactivationDialog } from 'views/Organization/components/OrganizationUser/components'

import { OrganizationUserTableType } from './organizationUserTable.types'
import { OrganizationUserType } from 'types/organization.types'

import {
  formatters,
  organizationUsers as organizationUsersHelper,
} from 'helpers'
import organizationUsersConstants from 'constants/organizationUsers'
import permissionTagsConstants from 'constants/permissionTags'

const OrganizationUsersTable = ({
  organizationUsers,
  organizationId,
  permissions,
  refreshOrganizationUsers,
}: OrganizationUserTableType) => {
  const [selectedOrganizationUser, setSelectedOrganizationUser] = useState<
    OrganizationUserType | undefined
  >()

  const handleCloseInactivationDialog = () => {
    setSelectedOrganizationUser(undefined)
  }

  const handleOpenInactivationDialog = (user: OrganizationUserType) => {
    setSelectedOrganizationUser(user)
  }

  return (
    <>
      <TableContainer>
        <TableComponent aria-label="listagem dos grupos advisors">
          <TableHead>
            <TableRowDefault>
              <TableCell align="left">Nome</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Criado em</TableCell>
              <TableCell align="left">Perfil</TableCell>
              <TableCell align="center">Status</TableCell>
              <PermittedOrganization
                tag={permissionTagsConstants.ORGANIZATION_USER.MANAGE}
                permissions={permissions}
              >
                <TableCell align="right">Ação</TableCell>
              </PermittedOrganization>
            </TableRowDefault>
          </TableHead>
          <TableBody>
            {organizationUsers.map((organizationUser) => (
              <TableRow key={organizationUser.id}>
                <TableCell align="left">{organizationUser.user.name}</TableCell>
                <TableCell align="left">
                  {organizationUser.user.email}
                </TableCell>
                <TableCell align="left">
                  {formatters.date.ptBrFormatDate(organizationUser.createdAt)}
                </TableCell>
                <TableCell align="left">
                  {organizationUser?.profile?.name}
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={organizationUsersHelper.statusTranslation(
                      organizationUser.status
                    )}
                    color={organizationUsersHelper.statusColor(
                      organizationUser.status
                    )}
                    size="small"
                    variant="filled"
                  />
                </TableCell>
                <PermittedOrganization
                  tag={permissionTagsConstants.ORGANIZATION_USER.MANAGE}
                  permissions={permissions}
                >
                  <TableCell align="right">
                    {organizationUser.status !==
                      organizationUsersConstants.INACTIVE_STATUS && (
                      <MenuButton
                        id={`user-organization-${organizationUser.id}`}
                      >
                        <MenuItem
                          onClick={() =>
                            handleOpenInactivationDialog(organizationUser)
                          }
                        >
                          INATIVAR
                        </MenuItem>
                      </MenuButton>
                    )}
                  </TableCell>
                </PermittedOrganization>
              </TableRow>
            ))}
          </TableBody>
        </TableComponent>
      </TableContainer>

      <OrganizationUserInactivationDialog
        open={!!selectedOrganizationUser}
        onClose={handleCloseInactivationDialog}
        organizationId={organizationId}
        organizationUser={selectedOrganizationUser}
        refreshOrganizationUsers={refreshOrganizationUsers}
      />
    </>
  )
}

export default OrganizationUsersTable
