import { Fragment, SyntheticEvent, useState } from 'react'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import { Box, Button, Hidden, Tab, TablePagination, Tabs } from '@mui/material'
import { isEmpty } from 'lodash'

import {
  DestroyTaskDialog,
  TaskFilters,
  TaskFormDialog,
  TaskTable,
  ViewTaskDialog,
} from './components'
import {
  FilterButton,
  ListPageStructure,
  LoadingFeedback,
  NoData,
  PageHead,
  PagePaper,
  Permitted,
} from 'components'

import { useFetch, useFilter, usePagination } from 'hooks'

import { TaskType } from 'types/task.types'

import tasksConstants from 'constants/tasks'
import permissionTagsConstants from 'constants/permissionTags'
import service from 'service'

const TaskMain = () => {
  const [selectedTab, setSelectedTab] = useState(tasksConstants.ALL_TASKS)
  const [openView, setOpenView] = useState(false)
  const [openForm, setOpenForm] = useState(false)
  const [openDestroy, setOpenDestroy] = useState(false)
  const [task, setTask] = useState<TaskType | undefined>()

  const handleTabChange = (_: SyntheticEvent, clickedTab: string) => {
    setSelectedTab(clickedTab)
  }

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()
  const filter = useFilter()

  const { response, loading, refresh } = useFetch(
    service.dponet.tasks.get,
    {
      params: {
        ...filter.filters,
        page,
        perPage,
        status: selectedTab === tasksConstants.ALL_TASKS ? '' : selectedTab,
      },
    },
    [filter.filters, page, perPage, selectedTab]
  )

  const handleOpenView = (task: TaskType) => {
    setTask(task)
    setOpenView(true)
  }

  const handleCreate = () => {
    setTask(undefined)
    setOpenForm(true)
  }

  const handleEdit = (task: TaskType) => {
    setTask(task)
    setOpenForm(true)
  }

  const handleDestroy = (task: TaskType) => {
    setTask(task)
    setOpenDestroy(true)
  }

  return (
    <ListPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead
        name="Atividades"
        actionButton={
          <Box display="flex">
            <Permitted tag={permissionTagsConstants.TASKS.CREATE}>
              <Box mr={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<PlusCircleIcon />}
                  disableElevation
                  onClick={handleCreate}
                  aria-label="abrir modal para criar classificador"
                >
                  CRIAR ATIVIDADE
                </Button>
              </Box>
            </Permitted>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        }
      />
      <PagePaper>
        <Tabs
          value={selectedTab}
          variant="scrollable"
          onChange={handleTabChange}
        >
          {tasksConstants.TASKS_STATUS_TABS.map((tab) => (
            <Tab key={tab.name} label={tab.name} value={tab.id} />
          ))}
        </Tabs>
        {loading || isEmpty(response?.data?.tasks) ? (
          <NoData title="Nenhuma atividade foi encontrada." withoutPaper />
        ) : (
          <Fragment>
            <TaskTable
              tasks={response?.data?.tasks}
              loading={loading}
              handleOpenView={handleOpenView}
              handleOpenEdit={handleEdit}
              handleDestroy={handleDestroy}
            />
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={(_, page) => handleChangePage(page)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Fragment>
        )}
        <TaskFilters filter={filter} />
        <ViewTaskDialog open={openView} setOpen={setOpenView} task={task} />
        <TaskFormDialog
          open={openForm}
          setOpen={setOpenForm}
          task={task}
          refresh={refresh}
        />
        <DestroyTaskDialog
          open={openDestroy}
          setOpen={setOpenDestroy}
          taskId={task?.id}
          refresh={refresh}
        />
      </PagePaper>
    </ListPageStructure>
  )
}

export default TaskMain
