import * as yup from 'yup'

const schema = yup.object({
  content: yup.string().required(),
  attachment: yup.mixed(),
})

export type TicketContentFormType = yup.InferType<typeof schema>

export type TicketContentControlType = 'content' | 'attachment'

export default schema
