import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

import DrawerContext from 'contexts/DrawerContext'

import { DrawerProviderType } from './drawerProvider.types'

import theme from 'theme'

const DrawerProvider = ({ children }: DrawerProviderType) => {
  const [openedDrawer, setOpenedDrawer] = useState(false)

  const { pathname } = useLocation()
  const isSwipebleDrawer = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  })

  const handleActionSwipeDrawer = () => {
    if (!isSwipebleDrawer) return

    setOpenedDrawer((openedDrawer) => !openedDrawer)
  }

  useEffect(() => {
    if (openedDrawer) handleActionSwipeDrawer()
  }, [pathname])

  return (
    <DrawerContext.Provider
      value={{
        handleActionSwipeDrawer,
        isSwipebleDrawer,
        openedDrawer,
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}

export default DrawerProvider
