import * as yup from 'yup'

const schema = yup.object({
  reason: yup.string().required(),
  anotherReason: yup.string().when('reason', {
    is: 'another',
    then: () => yup.string().required(),
  }),
})

export type ReasonOutsourcedDpoDialogSchemaType = yup.InferType<typeof schema>

export default schema
