import { Box, Grid, Paper } from '@mui/material'

import { LabelDescription, PaperHeader } from 'components'

import { CompanyDetailsType } from './companyDetails.types'

const CompanyDetails = ({ company }: CompanyDetailsType) => {
  const outsourcedDpo = company?.outsourcedDpo
    ? company?.outsourcedDpo?.name || company?.outsourcedDpo?.email
    : 'DPOnet'

  return (
    <Paper component={Box} p={2} height="100%">
      <PaperHeader title="Dados básicos" />

      <Box padding={2}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <LabelDescription
              title="Nome Fantasia"
              description={company?.name}
              overflow
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelDescription
              title="CNPJ"
              description={company?.document}
              overflow
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelDescription
              title="URL"
              description={company?.siteUrl === '' ? '-' : company?.siteUrl}
              overflow
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelDescription
              title="DPO"
              description={outsourcedDpo}
              overflow
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default CompanyDetails
