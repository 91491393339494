const avatarLetters = (name?: string) => {
  if (name) {
    const [firstName, lastName] = name.split(' ')

    return firstName.charAt(0) + (lastName?.charAt(0) || '')
  }

  return 'DA'
}

export default avatarLetters
