import { colors } from '@mui/material'

const PENDING_STATUS_ID = 1
const WAITING_REVIEW_STATUS_ID = 2
const DISAPPROVED_STATUS_ID = 3
const APPROVED_STATUS_ID = 4
const REVIEWING_STATUS_ID = 5
const INACTIVE_STATUS_ID = 6
const AUTO_REVIEW_STATUS_ID = 9
const PENDING_LIA_STATUS_ID = 10
const REVIEW_LIA_CLIENT_STATUS_ID = 11
const REVIEW_LIA_DPO_STATUS_ID = 12
const DISAPPROVED_LIA_STATUS_ID = 13

const LOW_RISKS = 'low'
const MEDIUM_RISKS = 'medium'
const HIGH_RISKS = 'high'
const SEVERE_RISKS = 'severe'

const RISKS = [LOW_RISKS, MEDIUM_RISKS, HIGH_RISKS, SEVERE_RISKS] as const

const PENDING_STATUS = 'pending'
const REVIEW_APPROVED_STATUS = 'reviewApproved'
const REVIEW_DISAPPROVED_STATUS = 'reviewDisapproved'
const REVIEWING_STATUS = 'reviewing'
const INACTIVE_STATUS = 'inactive'
const REVIEW_LIA = 'reviewingLia'

const STATUSES = [
  PENDING_STATUS,
  REVIEW_APPROVED_STATUS,
  REVIEW_DISAPPROVED_STATUS,
  REVIEWING_STATUS,
  INACTIVE_STATUS,
  REVIEW_LIA,
] as const

const STATUSES_COLORS = {
  [PENDING_STATUS]: colors.blue['A200'],
  [REVIEW_APPROVED_STATUS]: colors.green['A700'],
  [REVIEW_DISAPPROVED_STATUS]: colors.red['A700'],
  [REVIEWING_STATUS]: colors.yellow['A700'],
  [INACTIVE_STATUS]: colors.grey['A400'],
  [REVIEW_LIA]: colors.pink['A700'],
}

const PENDING_STATUS_LABEL = 'Pendente'
const WAITING_REVIEW_STATUS_LABEL = 'Aguardando revisão'
const REVIEW_DISAPPROVED_STATUS_LABEL = 'Reprovado'
const REVIEW_APPROVED_STATUS_LABEL = 'Aprovado'
const REVIEWING_STATUS_LABEL = 'Revisando'
const INACTIVE_STATUS_LABEL = 'Inativo'
const AUTO_REVIEW_STATUS_LABEL = 'Revisão automática'
const REVIEW_LIA_LABEL = 'Revisão LIA'

const STATUSES_LABELS = [
  PENDING_STATUS_LABEL,
  REVIEW_APPROVED_STATUS_LABEL,
  REVIEW_DISAPPROVED_STATUS_LABEL,
  REVIEWING_STATUS_LABEL,
  INACTIVE_STATUS_LABEL,
  REVIEW_LIA_LABEL,
] as const

const WAITING_REVIEW_LABEL = 'AGUARDANDO REVISÃO'
const REVIEWING_LABEL = 'EM REVISÃO'

const STATUSES_IDS_WITH_LEGAL_FRAMEWORK = [
  PENDING_STATUS_ID,
  WAITING_REVIEW_STATUS_ID,
  DISAPPROVED_STATUS_ID,
  APPROVED_STATUS_ID,
  REVIEWING_STATUS_ID,
  INACTIVE_STATUS_ID,
  AUTO_REVIEW_STATUS_ID,
  PENDING_LIA_STATUS_ID,
  REVIEW_LIA_CLIENT_STATUS_ID,
  REVIEW_LIA_DPO_STATUS_ID,
  DISAPPROVED_LIA_STATUS_ID,
] as const

const STATUSES_LABELS_WITH_LEGAL_FRAMEWORK = [
  PENDING_STATUS_LABEL,
  WAITING_REVIEW_STATUS_LABEL,
  REVIEW_DISAPPROVED_STATUS_LABEL,
  REVIEW_APPROVED_STATUS_LABEL,
  REVIEWING_STATUS_LABEL,
  INACTIVE_STATUS_LABEL,
  AUTO_REVIEW_STATUS_LABEL,
  PENDING_LIA_STATUS_ID,
  REVIEW_LIA_CLIENT_STATUS_ID,
  REVIEW_LIA_DPO_STATUS_ID,
  DISAPPROVED_LIA_STATUS_ID,
] as const

const STATUSES_WITH_LEGAL_FRAMEWORK = [
  { id: WAITING_REVIEW_STATUS_ID, name: WAITING_REVIEW_LABEL },
  { id: REVIEWING_STATUS_ID, name: REVIEWING_LABEL },
]

export default {
  PENDING_STATUS_ID,
  PENDING_LIA_STATUS_ID,
  WAITING_REVIEW_STATUS_ID,
  DISAPPROVED_STATUS_ID,
  APPROVED_STATUS_ID,
  REVIEWING_STATUS_ID,
  INACTIVE_STATUS_ID,
  AUTO_REVIEW_STATUS_ID,
  RISKS,
  STATUSES,
  STATUSES_COLORS,
  STATUSES_LABELS,
  STATUSES_IDS_WITH_LEGAL_FRAMEWORK,
  STATUSES_LABELS_WITH_LEGAL_FRAMEWORK,
  STATUSES_WITH_LEGAL_FRAMEWORK,
}
