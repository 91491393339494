import { styled } from '@mui/material/styles'

import { Property } from 'csstype'

const InputHtml = styled('input', {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $display?: Property.Display | undefined
}>(({ $display }) => ({
  display: $display,
}))

export default InputHtml
