import PerfectScrollbar from 'react-perfect-scrollbar'
import { Button, Typography, useMediaQuery } from '@mui/material'
import { isEmpty } from 'lodash'

import { LogsTable } from './components'
import { BasicDialog } from 'components'

import { DialogLogsType } from './dialogLogs.types'

import theme from 'theme'
import { Fragment } from 'react'

const DialogLogs = ({
  logs,
  onClose,
  open,
  showRegisterSession,
}: DialogLogsType) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title="Histórico de alterações"
      maxWidth="md"
      fullScreen={isMobile}
      dialogActions={
        <Fragment>
          <Button color="primary" onClick={onClose}>
            Fechar
          </Button>
        </Fragment>
      }
    >
      <PerfectScrollbar>
        {isEmpty(logs) || !logs ? (
          <Typography>
            Não existe nenhum histórico de alterações para este registro.
          </Typography>
        ) : (
          <LogsTable logs={logs} showRegisterSession={showRegisterSession} />
        )}
      </PerfectScrollbar>
    </BasicDialog>
  )
}

export default DialogLogs
