import dataProcessConstants from 'constants/dataProcess'

const statusLabel = (statusId: number) => {
  switch (statusId) {
    case dataProcessConstants.PENDING_STATUS_ID:
      return 'Pendente'
    case dataProcessConstants.WAITING_REVIEW_STATUS_ID:
      return 'Aguardando revisão'
    case dataProcessConstants.DISAPPROVED_STATUS_ID:
      return 'Reprovado'
    case dataProcessConstants.APPROVED_STATUS_ID:
      return 'Aprovado'
    case dataProcessConstants.REVIEWING_STATUS_ID:
      return 'Em revisão'
    case dataProcessConstants.INACTIVE_STATUS_ID:
      return 'Inativo'
    case dataProcessConstants.AUTO_REVIEW_STATUS_ID:
      return 'Revisão automática'
    default:
      return ''
  }
}

export default statusLabel
