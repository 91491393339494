import { FormControlLabel as FormControlLabelMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

const FormControlLabel = styled(FormControlLabelMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $color?: Property.Color | undefined
  $marginRight?: Property.MarginRight<TLength> | undefined
}>(({ $color, $marginRight }) => ({
  color: $color,
  marginRight: $marginRight,
}))

export default FormControlLabel
