import { Box, Grid, Paper } from '@mui/material'

import { LabelDescription, PaperHeader } from 'components'

import { OrganizationDetailsType } from './organizationDetails.types'

import { formatters } from 'helpers'

const OrganizationDetails = ({
  organization,
  organizationStats,
}: OrganizationDetailsType) => {
  return (
    <Paper component={Box} p={2} height="100%">
      <PaperHeader title="Dados básicos" />

      <Box padding={2}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <LabelDescription
              title="Criado por"
              description={organization?.author?.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelDescription
              title="Data de criação"
              description={formatters.date.ptBrFormatDate(
                organization?.createdAt || ''
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelDescription
              title="Quantidade de membros"
              description={organizationStats.quantityUsers.toString()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelDescription
              title="Quantidade de empresas"
              description={organizationStats.quantityCompanies.toString()}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default OrganizationDetails
