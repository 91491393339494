import { useState } from 'react'
import { Box, IconButton, colors } from '@mui/material'
import {
  ThumbsUp as ThumbsUpIcon,
  ThumbsDown as ThumbsDownIcon,
  Clipboard as ClipboardIcon,
  RotateCcw as RotateCcwIcon,
} from 'react-feather'

import { LoadingFeedback } from 'components'
import { FeedbackDialog } from './components'

import { AiOptionsType } from './aiOptionsType.types'

import { useSnackbar } from 'hooks'

const AiOptions = ({
  alreadyGeneratedSuggestion,
  suggestion,
  handleFeedback,
  daiFeedback,
  handleRegenerate,
}: AiOptionsType) => {
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false)
  const [feedback, setFeedback] = useState(daiFeedback?.rate || '')
  const [loading, setLoading] = useState(false)

  const { snackbar } = useSnackbar()

  const handleCopySuggestion = () => {
    if (!suggestion) return

    const suggestionSpplited = suggestion?.replace(/\\n/g, '\n') || ''

    navigator.clipboard.writeText(suggestionSpplited)
  }

  const handleClickThumbUp = async () => {
    if (feedback) return

    setLoading(true)

    handleFeedback('good')
      .then(() => {
        setFeedback('good')
        successSnackbar()
      })
      .catch(errorSnackbar)
      .finally(() => setLoading(false))
  }

  const handleClickThumbDown = () => {
    if (feedback) return

    setOpenFeedbackDialog(true)
  }

  const handleSubmitNegativeFeedback = (reason: string) => {
    setLoading(true)

    handleFeedback('bad', reason)
      .then(() => {
        setFeedback('bad')
        successSnackbar()
        handleCloseFeedbackDialog()
      })
      .catch(errorSnackbar)
      .finally(() => setLoading(false))
  }

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false)
  }
  const errorSnackbar = () =>
    snackbar.open({
      message: 'Ocorreu um erro ao enviar o feedback.',
      variant: 'error',
    })
  const successSnackbar = () =>
    snackbar.open({
      message: 'Obrigado pelo feedback!',
      variant: 'success',
    })

  return (
    <Box display="flex" mt={1}>
      <LoadingFeedback open={loading} />

      <IconButton onClick={handleCopySuggestion}>
        <ClipboardIcon size={16} />
      </IconButton>

      {alreadyGeneratedSuggestion && (
        <>
          <IconButton onClick={handleClickThumbUp} disabled={!!feedback}>
            <ThumbsUpIcon
              size={16}
              color={(feedback === 'good' && colors.green[500]) || undefined}
            />
          </IconButton>
          <IconButton onClick={handleClickThumbDown} disabled={!!feedback}>
            <ThumbsDownIcon
              size={16}
              color={(feedback === 'bad' && colors.red[500]) || undefined}
            />
          </IconButton>
          <IconButton onClick={handleRegenerate}>
            <RotateCcwIcon size={16} />
          </IconButton>
        </>
      )}

      <FeedbackDialog
        open={openFeedbackDialog}
        onClose={handleCloseFeedbackDialog}
        onSubmit={handleSubmitNegativeFeedback}
      />
    </Box>
  )
}

export default AiOptions
