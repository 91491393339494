import * as yup from 'yup'

const schema = yup.object({
  companyId: yup
    .object()
    .shape({
      label: yup.string().required(),
      document: yup.string().required(),
      companyId: yup.number().required(),
    })
    .nullable(),
})

export type PrivacyPortalSettingFormType = yup.InferType<typeof schema>

export default schema
