import { LinearProgress as LinearProgressMUI } from '@mui/material'
import { styled } from '@mui/material/styles'

const LinearProgress = styled(LinearProgressMUI, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $thickness?: number
  $borderRadius?: number
}>(({ $thickness = 1, $borderRadius = 0, theme }) => ({
  height: theme.spacing($thickness),
  borderRadius: theme.spacing($borderRadius),
}))

export default LinearProgress
