import {
  AlertOctagon as AlertOctagonIcon,
  Briefcase as BriefcaseIcon,
  Grid as GridIcon,
  UserCheck as UserCheckIcon,
  Globe as GlobeIcon,
  Book as BookIcon,
  Layers as LayersIcon,
  MessageCircle as MessageCircleIcon,
  File as FileIcon,
  Clipboard as ClipboardIcon,
} from 'react-feather'

import routes from 'constants/routes'
import permissionTagsConstants from 'constants/permissionTags'

export default [
  {
    Icon: GridIcon,
    title: 'Dashboard',
    url: routes.app.organizations.dashboard,
    children: false,
  },
  {
    Icon: BriefcaseIcon,
    title: 'Empresas',
    url: '/app/companies',
    children: false,
    somePermissions: [
      permissionTagsConstants.ORGANIZATION_COMPANY.VIEW,
      permissionTagsConstants.ORGANIZATION_COMPANY.MANAGE,
      permissionTagsConstants.ORGANIZATION_TAG.VIEW,
      permissionTagsConstants.ORGANIZATION_TAG.MANAGE,
    ],
    childrens: [
      {
        title: 'Lista de Empresas',
        url: routes.app.companies.all,
        children: true,
        somePermissions: [
          permissionTagsConstants.ORGANIZATION_COMPANY.VIEW,
          permissionTagsConstants.ORGANIZATION_COMPANY.MANAGE,
        ],
      },
      {
        title: 'Classificadores',
        url: routes.app.tags.all,
        children: true,
        somePermissions: [
          permissionTagsConstants.ORGANIZATION_TAG.VIEW,
          permissionTagsConstants.ORGANIZATION_TAG.MANAGE,
        ],
      },
    ],
  },
  {
    Icon: FileIcon,
    title: 'Questionários',
    url: routes.app.questionnaires.all,
    somePermissions: [
      permissionTagsConstants.QUESTIONNAIRES.VIEW,
      permissionTagsConstants.QUESTIONNAIRES.MANAGE,
    ],
    children: false,
  },
  {
    Icon: MessageCircleIcon,
    title: 'Solicitações',
    url: routes.app.tickets.all,
    somePermissions: [
      permissionTagsConstants.JURIDIC_TICKET.VIEW,
      permissionTagsConstants.JURIDIC_TICKET.MANAGE,
    ],
    children: false,
  },
  {
    Icon: UserCheckIcon,
    title: 'Canal de Atendimento',
    url: '/app',
    children: false,
    somePermissions: [
      permissionTagsConstants.SOLICITATION_TICKET.VIEW,
      permissionTagsConstants.SOLICITATION_TICKET.MANAGE,
      permissionTagsConstants.PRIVACY_PORTAL_CONFIGURATION.MANAGE_FORM,
      permissionTagsConstants.PRIVACY_PORTAL_CONFIGURATION.MANAGE_THEME,
    ],
    childrens: [
      {
        title: 'Todas as Solicitações',
        url: routes.app.privacyPortal.ticket,
        children: true,
        somePermissions: [
          permissionTagsConstants.SOLICITATION_TICKET.VIEW,
          permissionTagsConstants.SOLICITATION_TICKET.MANAGE,
        ],
      },
      {
        title: 'Configurações',
        url: routes.app.privacyPortal.serviceSettings,
        somePermissions: [
          permissionTagsConstants.PRIVACY_PORTAL_CONFIGURATION.MANAGE_FORM,
          permissionTagsConstants.PRIVACY_PORTAL_CONFIGURATION.MANAGE_THEME,
        ],
        children: true,
      },
    ],
  },
  {
    Icon: LayersIcon,
    title: 'Atividades',
    url: routes.app.tasks.all,
    children: false,
    somePermissions: [
      permissionTagsConstants.TASKS.VIEW,
      permissionTagsConstants.TASKS.CREATE,
      permissionTagsConstants.TASKS.UPDATE,
      permissionTagsConstants.TASKS.DESTROY,
    ],
  },
  {
    Icon: ClipboardIcon,
    title: 'Legítimo Interesse',
    url: routes.app.legitimateInterest.all,
    children: false,
    somePermissions: [
      permissionTagsConstants.LEGITIMATE_INTEREST.LIST,
      permissionTagsConstants.LEGITIMATE_INTEREST.MANAGE,
      permissionTagsConstants.LEGITIMATE_INTEREST.SHOW,
    ],
  },
  {
    Icon: BookIcon,
    title: 'Enquadramento Legal',
    url: routes.app.legalFrameworks.all,
    somePermissions: [permissionTagsConstants.LEGAL_FRAMEWORK.MANAGE],
    children: false,
  },
  {
    Icon: AlertOctagonIcon,
    title: 'Incidentes',
    url: routes.app.incidents.all,
    somePermissions: [permissionTagsConstants.INCIDENTS.LIST],
    children: false,
  },
  {
    Icon: GlobeIcon,
    title: 'Advisor',
    url: '/app',
    children: false,
    childrens: [
      {
        title: 'Grupo Advisor',
        url: routes.app.organizations.all,
        children: true,
      },
      {
        title: 'Perfis de Usuários',
        url: routes.app.profiles.all,
        children: true,
        somePermissions: [
          permissionTagsConstants.ADVISOR_PROFILE.VIEW,
          permissionTagsConstants.ADVISOR_PROFILE.MANAGE,
        ],
      },
    ],
  },
]
