import { Fragment, SyntheticEvent, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'
import { Button, Grid, Paper, Tab, Tabs, useMediaQuery } from '@mui/material'

import {
  CompanyAutocomplete,
  ListPageStructure,
  NoData,
  PageHead,
  PaperHeader,
} from 'components'
import { PrivacyPortalForm, PrivacyPortalTheme } from './components'
import { CompanySection } from 'views/Company/components'

import { useFetch, usePermission, useSearchCompany } from 'hooks'

import { validates } from 'helpers'
import { regex } from 'helpers'

import { CompanyType } from 'types/company.types'
import { OrganizationCompanyType } from 'types/organizationCompany'
import { AutoselectType } from 'components/CompanyAutocomplete/companyAutocomplete.types'

import service from 'service'
import schema, { PrivacyPortalSettingFormType } from './schema'
import { ConfigurationOptionsType } from 'constants/privacyPortal'
import constants from 'constants/index'
import theme from 'theme'

const PrivacyPortalSettings = () => {
  const [companiesArray, setCompaniesArray] = useState<AutoselectType[]>([])
  const [selectedTab, setSelectedTab] = useState<ConfigurationOptionsType>(
    constants.privacyPortal.THEME_CONFIGURATION_ID as ConfigurationOptionsType
  )

  const { companyId, tab } = useSearchCompany()
  const { isPermitted } = usePermission()
  const {
    watch,
    control,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<PrivacyPortalSettingFormType>({
    resolver: yupResolver(schema),
  })

  const companyInput = watch('companyId')

  const isPermittedManageTheme = isPermitted(
    constants.permissionTags.PRIVACY_PORTAL_CONFIGURATION.MANAGE_THEME
  )

  const isPermittedViewForm = isPermitted(
    constants.permissionTags.PRIVACY_PORTAL_CONFIGURATION.VIEW_FORM
  )

  const isPermittedManageForm = isPermitted(
    constants.permissionTags.PRIVACY_PORTAL_CONFIGURATION.MANAGE_FORM
  )

  const isExtraLarge = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  const formatJson = (property: 'document' | 'companyId') => {
    if (companyInput && property === 'document')
      return regex.onlyNumbers(companyInput.document)
    else if (companyInput && property === 'companyId')
      return companyInput.companyId
  }

  const {
    response: responseCompany,
    loading: loadingCompany,
    refresh: refreshCompany,
  } = useFetch(
    service.dponet.company.consultDocument,
    {
      document: formatJson('document'),
    },
    [companyInput],
    !!companyInput,
    true
  )

  const company: CompanyType = responseCompany?.data?.company

  const mountCompanyParams = (organizationCompany: OrganizationCompanyType) => {
    const document = organizationCompany.company.document

    return {
      label: `${organizationCompany.company.name} - ${document}`,
      document,
      companyId: organizationCompany.company.id,
    }
  }

  const getCompany = async () => {
    const response = await service.dponet.organizationCompany.get({
      params: {
        companyId: companyId,
        status: constants.organizationCompanies.ACTIVE_STATUS,
      },
    })

    const organizationCompanies: OrganizationCompanyType[] =
      response?.data?.organizationCompanies

    return organizationCompanies?.map(mountCompanyParams) ?? []
  }

  const handleTabChange = (
    event: SyntheticEvent,
    clickedTab: ConfigurationOptionsType
  ) => {
    setSelectedTab(clickedTab)
  }

  useEffect(() => {
    if (companyInput) {
      refreshCompany()
    }
  }, [companyInput])

  useEffect(() => {
    if (companyInput) {
      trigger('companyId')
    }
  }, [companyInput])

  useEffect(() => {
    if (tab) setSelectedTab(Number(tab) as ConfigurationOptionsType)
  }, [tab])

  useEffect(() => {
    if (companyId) getCompany().then((company) => setCompaniesArray(company))
  }, [companyId])

  useEffect(() => {
    if (!isEmpty(companiesArray)) setValue('companyId', companiesArray[0])
  }, [companiesArray])

  return (
    <ListPageStructure>
      <PageHead name="Configuração de Atendimento" />
      <Paper>
        <PaperHeader
          title="Editar tema do canal de comunicação"
          px={5}
          actionButton={
            selectedTab === constants.privacyPortal.THEME_CONFIGURATION_ID &&
            isPermittedManageTheme && (
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                form={validates.privacyPortal.formName(selectedTab)}
                disabled={!companyInput}
              >
                Salvar
              </Button>
            )
          }
        />
        <Grid container px={5} pt={4}>
          <Grid item xs={12}>
            {(!companyId ||
              (!isEmpty(companiesArray) && watch('companyId'))) && (
              <Controller
                render={({ field: { ref, ...rest } }) => (
                  <CompanyAutocomplete
                    {...rest}
                    inputRef={ref}
                    error={!!errors?.companyId}
                    helperText={errors?.companyId?.message}
                    defaultCompanies={companiesArray}
                    label="Empresa"
                  />
                )}
                name="companyId"
                control={control}
              />
            )}
            {companyInput && (
              <CompanySection
                company={company}
                details
                loading={loadingCompany}
              />
            )}
          </Grid>
          {companyInput ? (
            <Fragment>
              <Grid item xs={12} py={5}>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                  {isPermittedManageTheme && (
                    <Tab
                      value={constants.privacyPortal.THEME_CONFIGURATION_ID}
                      label={constants.privacyPortal.THEME_CONFIGURATION_LABEL}
                    />
                  )}
                  {isPermittedViewForm && (
                    <Tab
                      value={constants.privacyPortal.FORM_CONFIGURATION_ID}
                      label={constants.privacyPortal.FORM_CONFIGURATION_LABEL}
                    />
                  )}
                </Tabs>
              </Grid>
              {/* Configuração do tema do canal de atendimento */}
              {selectedTab === constants.privacyPortal.THEME_CONFIGURATION_ID &&
                isPermittedManageTheme && (
                  <Grid item xs={12}>
                    <PrivacyPortalTheme
                      companyDocument={companyInput}
                      isExtraLarge={isExtraLarge}
                      companyId={companyInput?.companyId}
                      triggerSettings={trigger}
                      selectedTab={selectedTab}
                      loadingCompany={loadingCompany}
                    />
                  </Grid>
                )}
              {/* Configuração dos formularios do canal de atendimento */}
              {selectedTab === constants.privacyPortal.FORM_CONFIGURATION_ID &&
                isPermittedViewForm && (
                  <Grid item xs={12} pb={5}>
                    <PrivacyPortalForm
                      companyDocument={companyInput}
                      companyId={companyInput?.companyId}
                      selectedTab={selectedTab}
                      loadingCompany={loadingCompany}
                      isPermittedManageForm={isPermittedManageForm}
                    />
                  </Grid>
                )}
            </Fragment>
          ) : (
            <Grid item xs={12} pb={6}>
              <NoData
                title="Nenhuma empresa foi selecionada."
                subTitle="Por favor, selecione uma empresa."
                mt={4}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    </ListPageStructure>
  )
}

export default PrivacyPortalSettings
