import { Box, Paper } from '@mui/material'

import { Chart, PaperHeader } from 'components'

import { StatusPieChartType } from './statusPieChart.types'

import dataProcessConstants from 'constants/dataProcess'

const StatusPieChart = ({ generalDataProcessStats }: StatusPieChartType) => {
  const colors = Object.values(dataProcessConstants.STATUSES_COLORS)
  const labels = Object.values(dataProcessConstants.STATUSES_LABELS)
  const values = dataProcessConstants.STATUSES.map(
    (status) => generalDataProcessStats.statuses[status]
  )

  return (
    <Paper component={Box} p={2} width="100%" height="100%">
      <PaperHeader title="Status dos processos" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
        height="100%"
        py={6}
      >
        <Chart.PieChart
          names={labels}
          values={values}
          colors={colors}
          title="Processos"
          legendShow
          heightValue={360}
          showLabels={false}
        />
      </Box>
    </Paper>
  )
}

export default StatusPieChart
