import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Paper,
  Select,
} from '@mui/material'

import { ConditionalLoading, MUI, PaperHeader } from 'components'

import { useFetch, useSnackbar } from 'hooks'

import { formatters } from 'helpers'

import { IdentifySelectionType } from './IdentifySelectionForm.types'

import schema, { IdentifySelectionFormType } from './schema'
import service from 'service'

const IdentifySelectionForm = ({
  organizationId,
  companyId,
}: IdentifySelectionType) => {
  const { snackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)

  const { response: responseThemeCompany, loading: loadingThemeCompany } =
    useFetch(service.dponet.whiteLabel.getStatus, companyId)

  const { response: responseTheme, loading: loadingTheme } = useFetch(
    service.dponet.whiteLabel.getTheme,
    { organizationId }
  )

  const themeId = responseTheme?.data?.clientPortalTheme?.id

  const clientPortalThemeId =
    responseThemeCompany?.data?.clientPortalThemeCompany?.clientPortalThemeId
  const portalTheme = responseThemeCompany?.data?.clientPortalThemeCompany
  const portalThemeId = portalTheme?.id
  const themeStatus = portalTheme?.status

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IdentifySelectionFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      visualIdentifySelection: `${themeStatus}` || '',
    },
  })

  useEffect(() => {
    if (themeStatus !== undefined) {
      setValue('visualIdentifySelection', `${themeStatus}`)
    }
  }, [themeStatus, setValue])

  const onSubmit = async (data: IdentifySelectionFormType) => {
    try {
      setLoading(true)
      const formData = {
        status: data?.visualIdentifySelection,
        companyId: companyId,
        clientPortalThemeId: clientPortalThemeId,
      }

      organizationId && portalThemeId
        ? await service.dponet.whiteLabel.updateClientPortalThemeCompany(
            formData,
            clientPortalThemeId
          )
        : await service.dponet.whiteLabel.postClientPortalThemeCompany(formData)

      snackbar.open({
        message: 'Identidade visual alterada com sucesso',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <ConditionalLoading
      loading={loadingThemeCompany || loading || loadingTheme}
      centered
      height="100%"
    >
      {!!themeId && (
        <Paper component={Box} p={2} height="100%">
          <PaperHeader
            title="Identidade Visual"
            actionButton={
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                form="identify-selection-form"
              >
                Salvar
              </Button>
            }
          />
          <form id="identify-selection-form" onSubmit={handleSubmit(onSubmit)}>
            <Box padding={2}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth margin="dense">
                    <InputLabel id="primary-font-select-label">
                      Defina a identidade visual
                    </InputLabel>
                    <Select
                      {...field}
                      error={!!errors?.visualIdentifySelection}
                      labelId="primary-font-select-label"
                      id="primary-font-select"
                      label="Defina a identidade visual"
                    >
                      <MUI.MenuItem value={`${false}`}>
                        Template Padrão (DPOnet)
                      </MUI.MenuItem>
                      <MUI.MenuItem value={`${true}`}>
                        Template personalizado
                      </MUI.MenuItem>
                    </Select>
                  </FormControl>
                )}
                name="visualIdentifySelection"
                control={control}
              />
            </Box>
          </form>
        </Paper>
      )}
    </ConditionalLoading>
  )
}

export default IdentifySelectionForm
