import { Box, Paper } from '@mui/material'

import { PapePaperType } from './pagePaper.types'

const PagePaper = ({
  children,
  marginTop = 6,
  marginBottom = 0,
  ...rest
}: PapePaperType) => {
  return (
    <Box mt={marginTop} maxWidth="100%" mb={marginBottom}>
      <Paper variant="outlined" {...rest}>
        <Box paddingX={2} paddingY={1}>
          {children}
        </Box>
      </Paper>
    </Box>
  )
}

export default PagePaper
