import ByCategoryCard from './ByCategoryCard'
import DepartmentsByThreats from './DepartmentsByThreats'
import RisksPerSeverity from './RisksPerSeverity'
import RisksWithSecurityMeasure from './RisksWithSecurityMeasure'
import StatusByCategoryBarChart from './StatusByCategoryBarChart'
import StatusPieChart from './StatusPieChart'

export default {
  ByCategoryCard,
  DepartmentsByThreats,
  RisksPerSeverity,
  RisksWithSecurityMeasure,
  StatusByCategoryBarChart,
  StatusPieChart,
}
