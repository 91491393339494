export const onlyNumbers = (value: string) => {
  return value.replace(/\D/g, '')
}

export const onlyLetters = (value: string) => {
  return value.replace(/[^a-zA-Z0-9 ]/g, '')
}

export const removeSpaces = (value: string) => {
  return value.replace(/\s+/g, '')
}

export default {
  onlyNumbers,
  onlyLetters,
  removeSpaces,
}
