import { Box } from '@mui/material'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

import { BarChartType } from './barChart.types'

import theme from 'theme'
import { size } from 'lodash'

const BarChart = ({
  id,
  names,
  values,
  colors,
  heightValue,
  showLabels,
}: BarChartType) => {
  const options: ApexOptions = {
    chart: {
      stacked: true,
      foreColor: theme.palette.text.primary,
      fontFamily: 'Roboto',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: 20,
        dataLabels: {
          total: {
            enabled: false,
            style: {
              fontSize: '4px',
            },
          },
        },
      },
    },
    colors: colors,
    dataLabels: {
      enabled: showLabels,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      padding: {
        right: 0,
        bottom: 12,
        left: 10,
      },
    },
    labels: names as string[],
    legend: {
      showForZeroSeries: true,
      position: 'right',
      horizontalAlign: 'center',
      fontSize: '10px',
      fontFamily: 'Roboto',
      fontWeight: 500,
      offsetY: (heightValue * 0.7) / size(values),
      markers: {
        fillColors: colors,
        radius: 15,
      },
      itemMargin: {
        vertical: 4,
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
    },
    stroke: {
      show: true,
      curve: 'straight',
      lineCap: 'round',
      colors: undefined,
      width: 1,
      dashArray: 1,
    },
    xaxis: {
      type: 'category',
      categories: names,
      labels: {
        rotate: 0,
        trim: true,
        hideOverlappingLabels: false,
        style: {
          fontSize: '10px',
        },
      },
    },
    yaxis: {
      title: {
        style: {
          fontWeight: 700,
        },
      },
    },
  }

  const series = values

  return (
    <Box width="100%">
      <Chart
        id={id}
        options={options}
        series={series}
        type="bar"
        height={heightValue}
      />
    </Box>
  )
}

BarChart.defaultProps = {
  loading: false,
  heightValue: 360,
  legendShow: true,
  showLabels: true,
}

export default BarChart
