import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const TinymceBox = styled(Box)(() => ({
  '& span > a': {
    display: 'none !important',
  },
}))

export default TinymceBox
