import {
  QuestionControlStatusByCategoryType,
  QuestionControlStatusType,
} from 'types/organizationCompany'

const questionControlStatsByStatus = (
  statusByCategory: QuestionControlStatusByCategoryType[],
  status: keyof QuestionControlStatusType
) =>
  statusByCategory?.map((category) =>
    category.stats[status] !== undefined ? category.stats[status] : 0
  )

export default questionControlStatsByStatus
