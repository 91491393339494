import { useState } from 'react'
import { Tab, Tabs } from '@mui/material'
import { isEmpty } from 'lodash'

import {
  ListPageStructure,
  LoadingFeedback,
  NoData,
  PageHead,
  PagePaper,
} from 'components'
import {
  OrganizationCreate,
  OrganizationTable,
  OrganizationRequest,
} from './components'

import { useOrganization } from 'hooks'

import organizationConstants from 'constants/organizations'

const OrganizationMain = () => {
  const [selectedTab, setSelectedTab] = useState(
    organizationConstants.ALL_ORGANIZATIONS
  )

  const { availableOrganizations, loadOrganizations: refresh } =
    useOrganization()

  const [loading, setLoading] = useState(false)

  const handleChangeTab = (event: React.SyntheticEvent, clickedTab: string) => {
    setSelectedTab(clickedTab)
  }

  return (
    <ListPageStructure>
      <PageHead
        name="Grupo Advisor"
        actionButton={
          <OrganizationCreate refresh={refresh} setLoading={setLoading} />
        }
      />
      <LoadingFeedback open={loading} />
      <PagePaper>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          aria-label="tabs de organizações"
          textColor="secondary"
        >
          {organizationConstants.TABS.map((tab) => (
            <Tab key={tab} label={tab} value={tab} />
          ))}
        </Tabs>
        {isEmpty(availableOrganizations) ? (
          <NoData title="Nenhum grupo foi encontrado." withoutPaper />
        ) : (
          <OrganizationTable organizations={availableOrganizations} />
        )}
      </PagePaper>
      <OrganizationRequest />
    </ListPageStructure>
  )
}

export default OrganizationMain
