import { useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material'
import { Edit2 as Edit } from 'react-feather'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty, map, isNil } from 'lodash'

import {
  BasicDialog as AllowObserverModal,
  LoadingFeedback,
  Permitted,
} from 'components'

import { useSnackbar } from 'hooks'

import { AllowObserverType } from './allowObserver.types'

import permissionTags from 'constants/permissionTags'
import service from 'service'
import schema, { AllowObserverSchemaType } from './schema'
import { observers } from 'helpers'

const AllowObserver = ({
  entity,
  isTicket = false,
  isIncident = false,
  refresh,
}: AllowObserverType) => {
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const { snackbar } = useSnackbar()

  const { control, handleSubmit } = useForm<AllowObserverSchemaType>({
    resolver: yupResolver(schema),
  })

  const allowObserver = async (data: AllowObserverSchemaType) => {
    const { observers } = data

    const observersIds = map(observers, 'id')

    try {
      setLoading(true)
      if (isTicket) {
        await service.dponet.ticket.allowObservers({
          ticketId: entity?.id,
          allowedObserversIds: observersIds,
        })
      } else if (isIncident) {
        await service.dponet.incidents.allowObservers({
          incidentId: entity?.id,
          allowedObserversIds: observersIds,
        })
      }
      refresh()
      setOpenModal(false)
    } catch (error) {
      snackbar.open({
        message: `Ocorreu um erro ao dar acesso do observador ao ${
          isTicket ? 'ticket' : 'incidente'
        }`,
        variant: 'error',
      })
    } finally {
      setOpenModal(false)
      setLoading(false)
    }
  }

  const observerIsNil =
    isNil(entity?.company?.observerOrganization) && isEmpty(entity?.observers)

  return (
    <Paper component={Box} p={5}>
      <Tooltip title={observerIsNil ? 'Nenhum observador cadastrado' : ''}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1" color="primary" fontWeight={500}>
            Visualizadores
          </Typography>
          <Permitted
            someTags={[
              permissionTags.OBSERVERS_ORGANIZATIONS.MANAGE_OBSERVERS_INCIDENT,
              permissionTags.OBSERVERS_ORGANIZATIONS.MANAGE_OBSERVERS_TICKET,
            ]}
          >
            <Button
              startIcon={<Edit size={14} />}
              variant="outlined"
              color="primary"
              onClick={() => setOpenModal(true)}
              disabled={observerIsNil}
            >
              <Typography variant="button" fontWeight={500}>
                Editar
              </Typography>
            </Button>
          </Permitted>
        </Box>
      </Tooltip>
      <Box mt={2} display="flex">
        {isEmpty(entity?.observers) ? (
          <Typography variant="subtitle2" color="primary">
            Nenhum grupo adicionado
          </Typography>
        ) : (
          <Typography ml={1} variant="subtitle2" color="primary">
            {observers.observersNameWithComma(entity)}
          </Typography>
        )}
      </Box>
      <LoadingFeedback open={loading} />
      <AllowObserverModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="Editar Observador"
        maxWidth="sm"
        form="allow-observer"
        closeButtonName="Cancelar"
      >
        <form id="allow-observer" onSubmit={handleSubmit(allowObserver)}>
          <Typography color="primary" fontWeight={400} variant="subtitle1">
            Selecione o Grupo Advisor para permitir a visualização
          </Typography>
          <Controller
            control={control}
            name="observers"
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                defaultValue={entity?.observers}
                options={[entity?.company?.observerOrganization]}
                getOptionLabel={(option) => option?.name || ''}
                filterSelectedOptions
                onChange={(_, data) => field.onChange(data)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    InputLabelProps={{
                      children: null,
                    }}
                  />
                )}
              />
            )}
          />
        </form>
      </AllowObserverModal>
    </Paper>
  )
}

export default AllowObserver
