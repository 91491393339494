import { Fragment } from 'react'
import { Box, colors } from '@mui/material'
import { isEmpty } from 'lodash'

import { TitleSection, TopicSection, DataProcessInfoChip } from '..'

import { DataCollectedType } from 'types/dataCollected.type'
import { DataCollectedsType } from './dataCollecteds.types'

import theme from 'theme'
import dataCollectedConstants from 'constants/dataCollected'
import dataProcessesHelper from 'helpers/dataProcesses'

const DataCollecteds = ({ dataCollecteds }: DataCollectedsType) => {
  const { dataCollectedLabelOptions } = dataCollectedConstants
  const dataCollectedsByType = (dataCollected: DataCollectedType) =>
    Object.entries(dataCollectedLabelOptions).map(([type, label]) => ({
      label,
      items: dataCollected?.collectedFields?.filter(
        (collectedField) =>
          parseInt(type) === collectedField?.dataCollectedOption?.dataType
      ),
    }))

  const titularCategoryLabel = (dataCollected: DataCollectedType) =>
    `Faixa Etária: ${dataProcessesHelper.rangeAgeContent(
      dataCollected?.titularAgeRange
    )}`

  return (
    <Box m={4}>
      <TitleSection title="Dados tratados" />

      {dataCollecteds?.map((dataCollected) => {
        const collectedFields = dataCollectedsByType(dataCollected)

        return (
          <Fragment key={dataCollected?.id}>
            <TopicSection text={dataCollected?.titularCategory?.name} />
            <Box mb={4}>
              <DataProcessInfoChip
                color={colors.blueGrey[600]}
                textColor="white"
                text={titularCategoryLabel(dataCollected)}
              />
            </Box>
            {collectedFields.map((collectedField) => (
              <Fragment key={collectedField?.label}>
                {!isEmpty(collectedField?.items) && (
                  <>
                    <DataProcessInfoChip
                      text={collectedField?.label}
                      color={theme.palette.secondary.main}
                      textColor="white"
                    />
                    <Box display="flex" flexWrap="wrap" mt={0.5}>
                      {collectedField?.items?.map((item) => (
                        <DataProcessInfoChip
                          key={item?.id}
                          text={item?.dataCollectedOption?.name}
                        />
                      ))}
                    </Box>
                  </>
                )}
              </Fragment>
            ))}
          </Fragment>
        )
      })}
    </Box>
  )
}

DataCollecteds.defaultProps = {
  dataCollecteds: [],
}

export default DataCollecteds
