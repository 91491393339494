import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

import { MUI, PasswordValidator } from 'components'

import { useSnackbar } from 'hooks'

import { FormTypes, PayloadData } from './form.types'

import { fields } from './form.fields'
import { formatters } from 'helpers'
import { getGoogleRecaptchaToken } from 'service/env'
import routes from 'constants/routes'
import schema from './schema'
import service from 'service'
import theme from 'theme'
import { isEmpty } from 'lodash'

const Form = ({ isLoading, setLoading }: FormTypes) => {
  const [recaptcha, setRecaptcha] = useState<string | null>()
  const [showPasswordValidator, setShowPasswordValidator] = useState(false)

  const navigate = useNavigate()
  const { snackbar } = useSnackbar()
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      confirmTerms: false,
    },
  })

  const envProduction = process.env.REACT_APP_API === 'production'

  const resetRecaptcha = () => {
    recaptchaRef?.current?.reset()
    setRecaptcha(undefined)
  }

  const expiredRecaptcha = () => {
    setValue('recaptcha', '')
    setRecaptcha(null)
  }

  const handleRecaptcha = (token: string | null) => {
    if (token) {
      setValue('recaptcha', token)
      setRecaptcha(token)
    }
  }

  const onSubmit = async (data: PayloadData) => {
    try {
      delete data.confirmTerms
      setLoading(true)
      await service.dponet.auth.createAccount({ user: data })
      setLoading(false)
      snackbar.open({
        message: 'Conta criada com sucesso.',
        variant: 'success',
      })
      navigate(routes.auth.login)
    } catch (error) {
      if (envProduction) {
        resetRecaptcha()
      }
      setLoading(false)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (!isEmpty(watch('password')) && !showPasswordValidator) {
      setShowPasswordValidator(true)
    }
  }, [watch('password')])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {fields.map((formField, index) => (
          <React.Fragment key={index}>
            {formField.showPasswordValidator && showPasswordValidator && (
              <Grid item xs={12}>
                <PasswordValidator
                  passwordInput={watch(formField?.name)}
                  justSubmitted={isSubmitted}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors[formField?.name]}
                    helperText={<>{errors[formField?.name]?.message}</>}
                    label={formField?.label}
                    type={formField?.type}
                    placeholder={formField?.placeholder}
                    fullWidth
                    {...formField?.props}
                  />
                )}
                name={formField?.name}
                control={control}
              />
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox />}
                label={
                  <>
                    Eu aceito os&nbsp;
                    <MUI.HtmlLink
                      $textDecoration="none"
                      $color={theme.palette.neutral.main}
                      href="https://www.dponet.com.br/termos-e-avisos/"
                      target="_blank"
                    >
                      Termos e Condições
                    </MUI.HtmlLink>
                  </>
                }
              />
            )}
            name="confirmTerms"
            control={control}
          />
          <FormHelperText error={!!errors?.confirmTerms}>
            {errors?.confirmTerms?.message}
          </FormHelperText>
        </Grid>
        {envProduction && (
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <ReCAPTCHA
                  {...field}
                  ref={recaptchaRef}
                  sitekey={getGoogleRecaptchaToken()}
                  onChange={(token) => handleRecaptcha(token)}
                  onExpired={expiredRecaptcha}
                  size="normal"
                />
              )}
              name="recaptcha"
              control={control}
            />
            <FormHelperText error={!!errors?.confirmTerms}>
              {errors?.recaptcha?.message}
            </FormHelperText>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={(!recaptcha && envProduction) || isLoading}
          >
            Cadastrar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Já tenho cadastro.&nbsp;
            <MUI.Link $textDecoration="none" to="/">
              Entrar
            </MUI.Link>
          </Typography>
        </Grid>
      </Grid>
    </form>
  )
}

export default Form
