import { Box, colors } from '@mui/material'
import { styled } from '@mui/material/styles'

const ColorMissing = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: colors.blueGrey[700],
  gap: theme.spacing(2),
}))

export default ColorMissing
