import { useState } from 'react'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { reverse } from 'named-urls'
import { useNavigate } from 'react-router-dom'

import { useAuth, useSnackbar } from 'hooks'

import service from 'service'
import routes from 'constants/routes'
import { formatters } from 'helpers'
import { LoadingFeedback } from 'components'

const GoogleSignOnButton = () => {
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const { snackbar } = useSnackbar()
  const { loadData } = useAuth()

  const onSubmit = async (credentials: CredentialResponse) => {
    try {
      setLoading(true)

      const params = {
        socialProvider: 'google',
        credential: {
          token: credentials?.credential,
        },
      }

      await service.dponet.auth.createSocialAccount(params)

      const response = await service.dponet.auth.loginSocialProvider(params)

      const accessToken = response?.data?.accessToken
      const user = response?.data?.user

      if (accessToken) {
        navigate(
          reverse(routes.auth.twoFactorAuthentication, { accessToken }),
          { state: { email: user?.email } }
        )
      } else {
        await loadData()

        navigate(routes.app.organizations.all)
      }
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <GoogleLogin
        onSuccess={onSubmit}
        type="standard"
        shape="rectangular"
        text="continue_with"
        ux_mode="popup"
      />
    </>
  )
}

export default GoogleSignOnButton
