import { styled } from '@mui/material/styles'

import { Property } from 'csstype'

const Span = styled('span', {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $color?: Property.Color | undefined
}>(({ $color }) => ({
  color: $color,
}))

export default Span
