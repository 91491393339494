import { Alert, Box, Grid, TextField, Typography } from '@mui/material'

import { Card } from 'components'

import { useIncidentManegement } from 'hooks'

const LessonsLearnedStep = () => {
  const { incident } = useIncidentManegement()

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Alert severity="info" variant="filled">
          Comunique as lições aprendidas e treine os colaboradores sobre as
          mudanças implementadas após o incidente de segurança.
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Card title="Lições Aprendidas">
          <Typography variant="body1">
            As lições aprendidas em um incidente de segurança com dados pessoais
            são cruciais para fortalecer as defesas e prevenir problemas
            futuros. Esta etapa visa fornecer conclusões e ideias sobre o
            incidente ocorrido, além de oferecer um campo para registro de
            melhorias e conclusões sobre o que você e sua equipe implementaram e
            aprenderam para mitigar riscos e danos.
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title="Pontos de Análise">
          <Box>
            <Typography
              variant="body1"
              color="primary"
              fontWeight="bold"
              gutterBottom
            >
              O que motivou o incidente?
            </Typography>
            <Typography variant="body1" gutterBottom>
              Busque entender os fatores ou eventos que levaram ao ocorrido.
              Investigue as causas subjacentes do incidente, procurando
              identificar falhas em sistemas, processos ou comportamentos que
              contribuíram para o evento. Essa análise visa não apenas corrigir
              problemas imediatos, mas também prevenir futuros incidentes,
              abordando as raízes do problema.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography
              variant="body1"
              color="primary"
              fontWeight="bold"
              gutterBottom
            >
              Avalie as medidas de segurança adotadas antes do incidente
            </Typography>
            <Typography variant="body1" gutterBottom>
              Essa análise envolve revisar políticas, procedimentos e
              ferramentas de segurança implementadas pela organização.
              Identificar falhas ou lacunas nessas medidas pode ajudar a
              fortalecer a postura de segurança, garantindo uma proteção mais
              eficaz contra ameaças futuras.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography variant="body1" color="primary" fontWeight="bold">
              Avalie as medidas que passaram a ser adotas após o incidente
            </Typography>
            <Typography variant="body1">
              Avaliar as medidas adotadas após o incidente é crucial para
              entender como a organização respondeu e se adaptou à situação.
              Isso envolve analisar as ações tomadas para fortalecer a segurança
              e prevenir futuros incidentes semelhantes. Identificar as novas
              políticas, procedimentos, tecnologias ou treinamentos
              implementados pode fornecer insights valiosos sobre o aprendizado
              da organização com o incidente e sua dedicação em melhorar sua
              postura de segurança.
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title="Quais foram as lições aprendidas">
          <TextField
            minRows={5}
            multiline
            fullWidth
            defaultValue={incident?.lessonLearned}
            disabled
          />
        </Card>
      </Grid>
    </Grid>
  )
}

export default LessonsLearnedStep
