import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const Content = styled(Box)(({ theme }) => ({
  maxWidth: 'calc(100vw - 256px)',
  overflow: 'scroll',
  overflowY: 'auto',
  overflowX: 'auto',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100vw',
  },
}))

export default Content
