import { Box, Paper } from '@mui/material'

import { Chart, PaperHeader } from 'components'

import { StatusPieChartType } from './statusPieChart.types'

import securityMeasureConstants from 'constants/securityMeasure'

const StatusPieChart = ({ questionControlStats }: StatusPieChartType) => {
  const colors = [
    securityMeasureConstants.ADOPTED_STATUS_COLOR,
    securityMeasureConstants.PENDING_STATUS_COLOR,
    securityMeasureConstants.OVERDUE_STATUS_COLOR,
    securityMeasureConstants.RISK_ASSUMED_STATUS_COLOR,
    securityMeasureConstants.TREATED_STATUS_COLOR,
    securityMeasureConstants.IN_PROGRESS_STATUS_COLOR,
  ]

  const labels = [
    securityMeasureConstants.ADOPTED_STATUS_LABEL,
    securityMeasureConstants.PENDING_STATUS_LABEL,
    securityMeasureConstants.OVERDUE_STATUS_LABEL,
    securityMeasureConstants.RISK_ASSUMED_STATUS_LABEL,
    securityMeasureConstants.TREATED_STATUS_LABEL,
    securityMeasureConstants.IN_PROGRESS_STATUS_LABEL,
  ]

  const measuresOnAdoptedStatus = questionControlStats?.status?.adopted || 0
  const measuresOnPendingStatus = questionControlStats?.status?.pending || 0
  const measuresOnOverdueStatus = questionControlStats?.status?.overdue || 0
  const measuresOnRiskAssumedStatus =
    questionControlStats?.status?.riskAssumed || 0
  const measuresOnTreatedStatus = questionControlStats?.status?.treated || 0
  const measuresOnInProgressStatus =
    questionControlStats?.status?.inProgress || 0

  const values = [
    measuresOnAdoptedStatus,
    measuresOnPendingStatus,
    measuresOnOverdueStatus,
    measuresOnRiskAssumedStatus,
    measuresOnTreatedStatus,
    measuresOnInProgressStatus,
  ]

  return (
    <Paper component={Box} p={2} width="100%" height="100%">
      <PaperHeader title="Status de medidas" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Chart.PieChart
          names={labels}
          values={values}
          colors={colors}
          title=""
          showName={false}
          legendShow
          heightValue={295}
        />
      </Box>
    </Paper>
  )
}

export default StatusPieChart
