import { Button, Typography } from '@mui/material'

import { BasicDialog } from 'components'

import { useSnackbar } from 'hooks'

import { DialogApproveRevisionType } from './dialogApproveRevision.types'

import { formatters } from 'helpers'
import service from 'service'
import dataProcessConstants from 'constants/dataProcess'

const DialogApproveRevision = ({
  data,
  open,
  setOpen,
  setLoading,
  onEvent,
  changePendingLiaStatus,
}: DialogApproveRevisionType) => {
  const { snackbar } = useSnackbar()

  const { APPROVED_STATUS_ID, PENDING_LIA_STATUS_ID } = dataProcessConstants
  const handleApproveRevision = async () => {
    setLoading(true)
    try {
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId: data.id,
        statusId: changePendingLiaStatus
          ? PENDING_LIA_STATUS_ID
          : APPROVED_STATUS_ID,
      })

      await onEvent()

      snackbar.open({
        message: 'Processamento de dados aprovado com sucesso!',
        variant: 'success',
      })

      setLoading(false)
      setOpen(false)
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
      setLoading(false)
      setOpen(false)
    }
  }

  return (
    <BasicDialog
      open={open}
      onClose={() => setOpen(false)}
      title="Aprovar processamento de dados"
      dialogActions={
        <>
          <Button color="primary" onClick={() => setOpen(false)}>
            CANCELAR
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleApproveRevision}
          >
            APROVAR
          </Button>
        </>
      }
    >
      <Typography>
        {/* {data?.company?.kind === constants.companies.KINDS.TEMPLATE
          ? `Este processo será incluído em uma empresa template que possui empresas reais criadas a partir dela. A inclusão deste processo será informada a todas as empresas reais e será exibida na aba "Processos sugeridos" para as empresas reais. Você tem certeza de que deseja incluir esse novo processo na empresa template?` */}
        Deseja realmente aprovar este processamento de dados?
      </Typography>
    </BasicDialog>
  )
}

DialogApproveRevision.defaultProps = {
  setOpen: () => {},
  setLoading: () => {},
  reloadOptions: () => {},
  onEvent: () => {},
}

export default DialogApproveRevision
