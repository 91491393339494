import { styled } from '@mui/material/styles'

const LogoAdvisor = styled('img')(({ theme }) => ({
  maxHeight: '100%',
  height: 'auto',
  [theme.breakpoints.down('md')]: {
    maxWidth: '240px',
  },
}))

export default LogoAdvisor
