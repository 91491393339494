const THEME_CONFIGURATION_ID = 1
const FORM_CONFIGURATION_ID = 2

const THEME_CONFIGURATION_LABEL = 'Personalização de Tema'
const FORM_CONFIGURATION_LABEL = 'Personalização de Formulário'

const ALL_CONFIGURATION_OPTIONS = [
  THEME_CONFIGURATION_ID,
  FORM_CONFIGURATION_ID,
] as const

const CHIP_ACTIVE_STATUS = 'active'
const CHIP_INACTIVE_STATUS = 'inactive'

const ALL_CHIP_STATUS = [CHIP_ACTIVE_STATUS, CHIP_INACTIVE_STATUS] as const

const USE_FORM_NAME = {
  [THEME_CONFIGURATION_ID]: 'privacy-portal-theme-form',
  [FORM_CONFIGURATION_ID]: 'privacy-portal-form-customization',
}

const CHIP_STATUS_TRANSLATION = {
  [CHIP_ACTIVE_STATUS]: 'ATIVA',
  [CHIP_INACTIVE_STATUS]: 'INATIVA',
}

const CHIP_STATUS_COLOR = {
  [CHIP_ACTIVE_STATUS]: 'success',
  [CHIP_INACTIVE_STATUS]: 'neutral',
}

const SWITCH_STATUS_TRANSLATION: { [key: string]: string } = {
  true: CHIP_ACTIVE_STATUS,
  false: CHIP_INACTIVE_STATUS,
}

const STATUS_TRANSLATION_TO_SWITCH: { [key: string]: boolean } = {
  [CHIP_ACTIVE_STATUS]: true,
  [CHIP_INACTIVE_STATUS]: false,
}

export type ConfigurationOptionsType =
  (typeof ALL_CONFIGURATION_OPTIONS)[number]

export type ChipStatusType = (typeof ALL_CHIP_STATUS)[number]

export default {
  THEME_CONFIGURATION_ID,
  FORM_CONFIGURATION_ID,
  THEME_CONFIGURATION_LABEL,
  FORM_CONFIGURATION_LABEL,
  USE_FORM_NAME,
  CHIP_ACTIVE_STATUS,
  CHIP_INACTIVE_STATUS,
  CHIP_STATUS_TRANSLATION,
  CHIP_STATUS_COLOR,
  SWITCH_STATUS_TRANSLATION,
  STATUS_TRANSLATION_TO_SWITCH,
}
