import { colors } from '@mui/material'

import { styled } from '@mui/material/styles'

export const TypeButton = styled('button')(() => ({
  color: 'GrayText',
  fontFamily: 'Roboto',
  fontSize: 15,
  fontWeight: 500,
  padding: '5px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  border: 'none',
  backgroundColor: 'transparent',
  borderRadius: 4,
  '&:hover': {
    backgroundColor: colors.grey[200],
  },
  '&:focus': {
    border: 'none',
  },
  '&:disabled': {
    border: '1px solid',
    borderColor: colors.grey[200],
    backgroundColor: colors.grey[50],
    cursor: 'default',
    color: 'gray',
  },
}))

export const TypeButtonActive = styled('button')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'Roboto',
  fontSize: 15,
  fontWeight: 500,
  padding: '5px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  border: 'none',
  backgroundColor: colors.grey[200],
  borderRadius: 5,
  '&:focus': {
    border: 'none',
  },
  '&:disabled': {
    border: '1px solid',
    borderColor: colors.grey[200],
    backgroundColor: colors.grey[50],
    cursor: 'default',
    color: 'gray',
  },
}))
