import { Box, BoxProps, Divider, Tooltip, Typography } from '@mui/material'

import { PaperHeaderType } from './paperHeader.types'

const PaperHeader = ({
  title,
  actionButton,
  ...rest
}: PaperHeaderType & BoxProps) => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
        {...rest}
      >
        <Tooltip title={title}>
          <Typography
            variant="body1"
            component="h2"
            fontWeight="500"
            noWrap
            py={actionButton ? 0 : 1.5}
          >
            {title}
          </Typography>
        </Tooltip>

        {actionButton && actionButton}
      </Box>

      <Box>
        <Divider />
      </Box>
    </>
  )
}

export default PaperHeader
