import { reverse } from 'named-urls'
import { useNavigate } from 'react-router-dom'
import {
  IconButton,
  TableBody,
  TableContainer,
  TableHead,
  Tooltip,
  Typography,
} from '@mui/material'
import { ArrowRight as ArrowRightIcon } from 'react-feather'

import { MUI, Table as TableComponent, TableRow } from 'components'

import { TicketTableType } from './ticketTable.types'

import { formatters, validates } from 'helpers'
import palette from 'theme/palette'
import routes from 'constants/routes'

const TicketTable = ({ tickets }: TicketTableType) => {
  const navigate = useNavigate()

  const handleClickTableRow = (id: number) => {
    navigate(
      reverse(routes.app.myTickets.message, {
        ticketId: id,
      })
    )
  }

  return (
    <TableContainer>
      <TableComponent aria-label="listagem de tickets">
        <TableHead>
          <TableRow>
            <MUI.TableCell align="center">Ticket</MUI.TableCell>
            <MUI.TableCell align="left">Solicitação</MUI.TableCell>
            <MUI.TableCell align="center">Status</MUI.TableCell>
            <MUI.TableCell align="center">Atualizado em</MUI.TableCell>
            <MUI.TableCell align="center">Ação</MUI.TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets?.map((ticket) => (
            <TableRow
              key={ticket?.id}
              onClick={() => handleClickTableRow(ticket?.id)}
            >
              <MUI.TableCell align="center">
                <Typography fontWeight={500} variant="body2">
                  {ticket?.ticketRef ? ticket?.ticketRef : '-'}
                </Typography>
              </MUI.TableCell>

              <MUI.TableCell align="left" $maxWidth={250}>
                <Tooltip title={ticket?.title}>
                  <Typography
                    variant="body2"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {ticket?.title}
                  </Typography>
                </Tooltip>
                <Typography variant="body2" color={palette.custom.dark}>
                  {validates.tickets.labelTranslation(ticket?.ticketType)}
                </Typography>
              </MUI.TableCell>

              <MUI.TableCell align="center">
                <Typography fontWeight={500} variant="body2">
                  {formatters.date.ptBrFormatDateTime(ticket?.updatedAt)}
                </Typography>
              </MUI.TableCell>
              <MUI.TableCell align="center">
                {ticket?.status?.name && ticket?.status?.id ? (
                  <MUI.Chip
                    label={ticket?.status?.name.toUpperCase()}
                    variant="filled"
                    size="small"
                    $background={validates.tickets.statusBackgroundColor(
                      ticket?.status?.id
                    )}
                    $color="white"
                    $fontWeight={500}
                  />
                ) : (
                  '-'
                )}
              </MUI.TableCell>
              <MUI.TableCell align="center">
                <IconButton onClick={() => handleClickTableRow(ticket?.id)}>
                  <ArrowRightIcon color={palette.custom.dark} />
                </IconButton>
              </MUI.TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableComponent>
    </TableContainer>
  )
}

export default TicketTable
