import { Select as SelectMUI } from '@mui/material'
import { styled } from '@mui/material/styles'

const Select = styled(SelectMUI)(({ theme }) => ({
  color: 'white',
  borderColor: theme.palette.primary.light,
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 20,
  '& fieldset ': {
    border: 0,
  },
  '& svg': {
    color: 'white',
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(3),
  },
}))

export default Select
