import {
  Box,
  CircularProgress,
  IconButton,
  TableCell,
  Typography,
} from '@mui/material'
import { reverse } from 'named-urls'
import { useNavigate } from 'react-router-dom'
import { ChevronRight as ChevronRightIcon } from 'react-feather'

import { AbsoluteCenteredBox, TableRow } from 'components'

import { CompanyComplianceRowType } from './companyComplianceRow.types'

import routes from 'constants/routes'

const CompanyComplianceRow = ({
  company,
  isPermittedToAccessCompanies,
}: CompanyComplianceRowType) => {
  const navigate = useNavigate()

  const handleNavigateToOrganizationCompany = (
    organizationCompanyId: number
  ) => {
    if (!isPermittedToAccessCompanies) return

    navigate(reverse(routes.app.companies.update, { organizationCompanyId }))
  }

  return (
    <TableRow
      key={company.organizationCompanyId}
      onClick={() =>
        handleNavigateToOrganizationCompany(company.organizationCompanyId)
      }
    >
      <TableCell width="20%">
        <Box position="relative" display="flex" justifyContent="center">
          <CircularProgress
            color="success"
            variant="determinate"
            value={company.healthScore}
          />
          <AbsoluteCenteredBox>
            <Typography variant="caption">
              {company.healthScore.toFixed(0)}
            </Typography>
          </AbsoluteCenteredBox>
        </Box>
      </TableCell>
      <TableCell width={isPermittedToAccessCompanies ? '60%' : '80%'}>
        {company.name}
      </TableCell>
      {isPermittedToAccessCompanies && (
        <TableCell width="20%">
          <IconButton
            onClick={() =>
              handleNavigateToOrganizationCompany(company.organizationCompanyId)
            }
          >
            <ChevronRightIcon size={16} />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  )
}

export default CompanyComplianceRow
