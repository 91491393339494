import { Box, Paper } from '@mui/material'

import { Chart, PaperHeader } from 'components'

import { StatusByCategoryBarChartType } from './statusByCategoryBarChart.types'

import securityMeasureConstants from 'constants/securityMeasure'
import dashboardHelper from 'helpers/dashboard'

const StatusByCategoryBarChart = ({
  questionControlStats,
}: StatusByCategoryBarChartType) => {
  const statusesByCategory = questionControlStats?.statusByCategory

  const kinds = statusesByCategory?.map((category) => category.kind)

  const adoptedValues = dashboardHelper.questionControlStatsByStatus(
    statusesByCategory,
    'adopted'
  )

  const treatedValues = dashboardHelper.questionControlStatsByStatus(
    statusesByCategory,
    'treated'
  )

  const inProgressValues = dashboardHelper.questionControlStatsByStatus(
    statusesByCategory,
    'inProgress'
  )

  const pendingValues = dashboardHelper.questionControlStatsByStatus(
    statusesByCategory,
    'pending'
  )

  const overdueValues = dashboardHelper.questionControlStatsByStatus(
    statusesByCategory,
    'overdue'
  )

  const riskAssumedValues = dashboardHelper.questionControlStatsByStatus(
    statusesByCategory,
    'riskAssumed'
  )

  const series = [
    { name: 'Adotado', data: adoptedValues || [] },
    { name: 'Tratado', data: treatedValues || [] },
    { name: 'Em Andamento', data: inProgressValues || [] },
    { name: 'Pendente', data: pendingValues || [] },
    { name: 'Atrasado', data: overdueValues || [] },
    { name: 'Risco Assumido', data: riskAssumedValues || [] },
  ]

  const colors = [
    securityMeasureConstants.ADOPTED_STATUS_COLOR,
    securityMeasureConstants.TREATED_STATUS_COLOR,
    securityMeasureConstants.IN_PROGRESS_STATUS_COLOR,
    securityMeasureConstants.PENDING_STATUS_COLOR,
    securityMeasureConstants.OVERDUE_STATUS_COLOR,
    securityMeasureConstants.RISK_ASSUMED_STATUS_COLOR,
  ]

  return (
    <Paper component={Box} p={2} width="100%">
      <PaperHeader title="Status de medidas por categoria" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
        height="100%"
        py={6}
      >
        <Chart.BarChart
          names={kinds}
          values={series}
          colors={colors}
          legendShow
          heightValue={250}
        />
      </Box>
    </Paper>
  )
}

export default StatusByCategoryBarChart
