import { useState } from 'react'
import { Box, Button, Chip, Grid, Paper, Typography } from '@mui/material'
import { Edit2 as Edit2Icon, Link2 as Link2Icon } from 'react-feather'

import { LabelDescription, PaperHeader, Permitted } from 'components'
import {
  CompanyUpdateDialog,
  CompanyMatrixDialog,
} from 'views/Company/components'

import { OrganizationCompanyDetailsType } from './organizationCompanyDetails.types'

import permissionTagsConstants from 'constants/permissionTags'

const CompanyDetails = ({
  organizationCompany,
  refresh,
}: OrganizationCompanyDetailsType) => {
  const [openCompanyMatrixDialog, setOpenCompanyMatrixDialog] = useState(false)
  const [openCompanyUpdateDialog, setOpenCompanyUpdateDialog] = useState(false)

  const tags = organizationCompany?.organizationTags || []

  const handleChangeCompanyMatrixDialog = () => {
    setOpenCompanyMatrixDialog(
      (openCompanyMatrixDialog) => !openCompanyMatrixDialog
    )
  }

  const handleChangeCompanyUpdateDialog = () => {
    setOpenCompanyUpdateDialog(
      (openCompanyUpdateDialog) => !openCompanyUpdateDialog
    )
  }

  return (
    <>
      <Paper component={Box} p={2}>
        <PaperHeader
          title="Vínculos"
          actionButton={
            <Permitted
              tag={permissionTagsConstants.ORGANIZATION_COMPANY.MANAGE}
            >
              <Box display="flex" gap={2}>
                {organizationCompany?.companyMatrix && (
                  <Button
                    startIcon={<Link2Icon size={18} />}
                    variant="outlined"
                    color="error"
                    onClick={handleChangeCompanyMatrixDialog}
                  >
                    Remover
                  </Button>
                )}
                <Button
                  startIcon={<Edit2Icon size={18} />}
                  variant="outlined"
                  onClick={handleChangeCompanyUpdateDialog}
                >
                  Editar
                </Button>
              </Box>
            </Permitted>
          }
        />

        <Box padding={2}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <LabelDescription
                title="Rede"
                description={
                  organizationCompany?.companyMatrix?.networkName || '-'
                }
              />
            </Grid>

            <Grid item xs={12}>
              <LabelDescription title="Classificadores">
                {tags?.length > 0 ? (
                  tags.map((tag) => (
                    <Chip
                      component={Box}
                      mr={2}
                      mt={2}
                      key={tag.id}
                      label={tag.name}
                    />
                  ))
                ) : (
                  <Typography variant="body1">-</Typography>
                )}
              </LabelDescription>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <CompanyMatrixDialog
        organizationCompany={organizationCompany}
        open={openCompanyMatrixDialog}
        onClose={handleChangeCompanyMatrixDialog}
        refresh={refresh}
      />

      <CompanyUpdateDialog
        organizationCompany={organizationCompany}
        open={openCompanyUpdateDialog}
        onClose={handleChangeCompanyUpdateDialog}
        refresh={refresh}
      />
    </>
  )
}

export default CompanyDetails
