import { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Check as CheckIcon,
  File as FileIcon,
  Moon as MoonIcon,
} from 'react-feather'
import { Grid, colors } from '@mui/material'

import { ListPageStructure, LoadingFeedback, PageHead } from 'components'
import {
  GeneralInformationCard,
  LinkedCompaniesTable,
  QuestionInfoCard,
  QuestionTable,
} from './components'

import { useFetch, usePermission } from 'hooks'

import { QuestionnaireType } from 'types/questionnaire'

import service from 'service'
import constants from 'constants/index'

const QuestionnairesEdit = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [disableQuestionnaire, setDisableQuestionnaire] = useState(false)

  const { questionnaireId } = useParams()
  const { isPermitted } = usePermission()

  const {
    response: questionnaireResponse,
    loading: loadingQuestionnaire,
    refresh: questionnaireRefresh,
  } = useFetch(
    service.dponet.questionnaires.show,
    {
      organizationQuestionnaireId: questionnaireId,
    },
    []
  )

  const questionnaire: QuestionnaireType =
    questionnaireResponse?.data?.questionnaire

  const isPermittedManage = isPermitted(
    constants.permissionTags.QUESTIONNAIRES.MANAGE
  )

  return (
    <ListPageStructure>
      <LoadingFeedback open={isLoading || loadingQuestionnaire} />
      <PageHead name="Edição de questionários" />
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4} xl={2.3}>
              <QuestionInfoCard
                title="Todas as Questões"
                value={questionnaire?.questionsCount}
                icon={<FileIcon color="white" size={20} />}
                boxColor={colors.blueGrey[900]}
              />
            </Grid>
            <Grid item xs={12} lg={4} xl={2.3}>
              <QuestionInfoCard
                title="Ativa"
                value={questionnaire?.activeQuestionsCount}
                icon={<CheckIcon color="white" size={20} />}
                boxColor={colors.green[500]}
              />
            </Grid>
            <Grid item xs={12} lg={4} xl={2.3}>
              <QuestionInfoCard
                title="Inativas"
                value={questionnaire?.inactiveQuestionsCount}
                icon={<MoonIcon color="white" size={20} />}
                boxColor={colors.blueGrey[400]}
              />
            </Grid>
          </Grid>
        </Grid>
        {!loadingQuestionnaire && (
          <Grid item xs={12}>
            <GeneralInformationCard
              setIsLoading={setIsLoading}
              questionnaireId={questionnaireId}
              questionnaire={questionnaire}
              refresh={questionnaireRefresh}
              disableQuestionnaire={disableQuestionnaire}
              disableManagement={!isPermittedManage}
              disableDefault={questionnaire?.default}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <LinkedCompaniesTable
            disableManagement={!isPermittedManage}
            questionnaire={questionnaire}
            questionnaireId={questionnaireId}
            setIsLoading={setIsLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <QuestionTable
            disableManagement={!isPermittedManage}
            disableDefault={questionnaire?.default}
            questionnaireId={questionnaireId}
            setDisableQuestionnaire={setDisableQuestionnaire}
            setIsLoading={setIsLoading}
          />
        </Grid>
      </Grid>
    </ListPageStructure>
  )
}

export default QuestionnairesEdit
