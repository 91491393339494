import { QuestionnaireType } from 'types/organizationCompany'

const general = (questionnaireStats: QuestionnaireType[]) => {
  const sumQuestions = (
    attribute:
      | 'answeredQuestions'
      | 'affirmativeQuestions'
      | 'totalQuestions'
      | 'pendingQuestions'
  ) =>
    questionnaireStats
      .map((stats) => stats[attribute])
      .reduce((accumulator, attribute) => accumulator + attribute, 0)

  return {
    totalQuestions: sumQuestions('totalQuestions'),
    answeredQuestions: sumQuestions('answeredQuestions'),
    affirmativeQuestions: sumQuestions('affirmativeQuestions'),
    pendingQuestions: sumQuestions('pendingQuestions'),
  }
}

export default {
  general,
}
