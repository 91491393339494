import lineWithDate from './LineWithDate/lineWithDate'
import numberAndTitle from './NumberAndTitle/numberAndTitle'
import numberPage from './NumberPage/numberPage'
import paragraph from './Paragraph/paragraph'
import rectangle from './Rectangle/rectangle'
import rowTwoColumns from './RowTwoColumns/rowTwoColumns'
import subtitle from './Subtitle/subtitle'
import table from './Table/table'
import textTopic from './TextTopic/textTopic'
import title from './Title/title'

const components = {
  lineWithDate,
  numberAndTitle,
  numberPage,
  paragraph,
  rectangle,
  rowTwoColumns,
  subtitle,
  table,
  textTopic,
  title,
}

export default components
