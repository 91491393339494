import { useContext } from 'react'

import { OrganizationContext } from 'contexts'

const useOrganization = () => {
  const context = useContext(OrganizationContext)
  if (context === undefined) {
    throw new Error(
      'useOrganization must be used within a OrganizationContext.Provider'
    )
  }

  return context
}

export default useOrganization
