import * as yup from 'yup'

const schema = yup.object({
  content: yup.string().required(),
  attachment: yup.mixed(),
  // .test('fileType', 'Tipo de arquivo não suportado.', validateFileType)
  // .test('fileSize', 'Tamanho máximo excedido (10MB).', validateFileSize),
})

export type TicketContentFormType = yup.InferType<typeof schema>

export type TicketContentControlType = 'content' | 'attachment'

export default schema
