import { useState, ChangeEvent } from 'react'
import { Search as SearchIcon } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import {
  TextField,
  Button,
  Box,
  Typography,
  InputAdornment,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { useSnackbar } from 'hooks'

import { BasicDialog, MUI } from 'components'

import { formatters, regex } from 'helpers'

import {
  DialogLinkedCompanyType,
  PayloadData,
} from './dialogLinkedCompany.types'

import companyConstants from 'constants/companies'
import service from 'service'
import schema from './schema'

const DialogLinkedCompany = ({
  open,
  onClose,
  setLoading,
}: DialogLinkedCompanyType) => {
  const { snackbar } = useSnackbar()

  const { SOLICITATION_TEXT, EXIST, NOT_EXIST } = companyConstants

  const [companyExist, setCompanyExist] = useState('')

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      document: '',
    },
  })

  const handleReturn = () => {
    setValue('document', '')
    setCompanyExist('')
    onClose()
  }

  const onSubmit = async (data: PayloadData) => {
    setLoading(true)
    try {
      await service.dponet.company.consultDocument({
        document: regex.onlyNumbers(data?.document)?.toString(),
      })
      setCompanyExist(EXIST)
    } catch {
      setCompanyExist(NOT_EXIST)
    } finally {
      setLoading(false)
    }
  }

  const handleFomart = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (event: any) => void
  ) => {
    event.target.value = formatters.identificationDocument(event?.target.value)
    onChange(event)
  }

  const handleSolicitation = async () => {
    setLoading(true)
    try {
      await service.dponet.tomticket.createTicket({
        tomticket: {
          title: SOLICITATION_TEXT,
          description: SOLICITATION_TEXT,
        },
      })
      handleReturn()
      snackbar.open({
        message: 'Sucesso ao solicitar contato',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BasicDialog
      open={open}
      onClose={handleReturn}
      title="Saiba se a empresa está ativa na DPOnet"
      dialogActions={
        <Button color="primary" onClick={handleReturn}>
          Fechar
        </Button>
      }
    >
      <Typography variant="body1">
        Para adicionar uma nova empresa no DPOadvisor, ela precisa estar ativa
        na plataforma da DPOnet.
      </Typography>
      <Box mt={4} mb={8}>
        <Typography variant="body1">
          Digite o CNPJ da empresa que você deseja adicionar ao seu painel para
          saber os próximos passos.
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" gap={4}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                inputProps={{
                  maxLength: 18,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MUI.Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        $borderRadius="0px 4px 4px 0px"
                        $height={56}
                      >
                        <SearchIcon />
                      </MUI.Button>
                    </InputAdornment>
                  ),
                  sx: {
                    paddingRight: 0,
                  },
                }}
                error={!!errors?.document}
                helperText={<>{errors?.document?.message}</>}
                label="Digite o CPF ou CNPJ"
                type="text"
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => handleFomart(event, field.onChange)}
                fullWidth
              />
            )}
            name="document"
            control={control}
          />
        </Box>
        {companyExist === NOT_EXIST && (
          <Box mt={8}>
            <Typography variant="body1">
              Esta empresa ainda <strong>não está ativa</strong> na plataforma
              da DPOnet.
            </Typography>
            <Box my={2}>
              <Typography variant="body1">
                Para ativá-la na DPOnet, entre em contato com o nosso time
                comercial.
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              type="button"
              fullWidth
              onClick={handleSolicitation}
            >
              Solicitar contato de especialista
            </Button>
          </Box>
        )}
        {companyExist === EXIST && (
          <Box mt={8}>
            <Typography variant="body1">
              Esta empresa está <strong>ativa</strong> na plataforma DPOnet.
            </Typography>
            <Box my={2}>
              <Typography variant="body1">
                É necessário habilitar a conexão com o DPOAdvisor nas
                configurações da empresa na plataforma DPOnet.
              </Typography>
            </Box>
            <Typography variant="body1">
              O representante da empresa deverá acessar na plataforma DPOnet:
            </Typography>
            <Box my={2}>
              <Typography variant="body1">
                Configurações {'>'} Dados da empresa {'>'} Visualizar DPO
                responsável {'>'} Alterar DPO responsável.
              </Typography>
            </Box>
            <Typography variant="body1">
              Após realizar a configuração, o DPO responsável deverá confirmar o
              vínculo na plataforma DPO Advisor.
            </Typography>
          </Box>
        )}
      </form>
    </BasicDialog>
  )
}

export default DialogLinkedCompany
