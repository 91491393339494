import { useRef, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

import { BasicDialog, LoadingFeedback } from 'components'

import { UploadLogoDialogType } from './uploadLogoDialog.types'
import service from 'service'
import { useSnackbar } from 'hooks'
import { formatters } from 'helpers'

const UploadLogoDialog = ({
  organizationId,
  open,
  onClose,
  setLogo,
  src,
}: UploadLogoDialogType) => {
  const { snackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const validateAndSubmitLogo = async (logo: File) => {
    try {
      const reader = new FileReader()

      reader.onload = (e) => {
        if (e.target && e.target.result) {
          const img = new Image()
          img.src = e.target.result.toString()

          img.onload = () => {
            const width = img.width
            const height = img.height
            const ratio = 3 / 1

            if (width / height === ratio) {
              performLogoUpload(logo)
            } else {
              snackbar.open({
                variant: 'error',
                message: 'A proporção da imagem não está correta!.',
              })
            }
          }
        }
      }
      reader.readAsDataURL(logo)
    } catch (error) {
      setLogo(src)
      snackbar.open({
        variant: 'error',
        message: formatters.errorMessage(error, false),
      })
    } finally {
      setLoading(false)
    }
  }

  const performLogoUpload = async (logo: File) => {
    try {
      if (organizationId) {
        setLoading(true)
        await service.dponet.organization.uploadLogo({
          organizationId: organizationId,
          logo: logo,
        })
        setLogo(URL.createObjectURL(logo))
      }
      snackbar.open({
        variant: 'success',
        message: 'Logotipo da empresa alterado com sucesso!',
      })
      onClose()
    } catch (error) {
      setLogo(src)
      snackbar.open({
        variant: 'error',
        message: formatters.errorMessage(error, false),
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title="Adicionar logo"
      dialogActions={
        <Button color="primary" onClick={onClose}>
          CANCELAR
        </Button>
      }
    >
      <LoadingFeedback open={loading} />

      <Typography variant="body1">
        Para funcionamento ideal, sua imagem&nbsp;
        <strong>
          deverá possuir a proporção de 3:1 e fundo transparente. (Exemplo de
          dimensão: 150x50 pixels)
        </strong>
      </Typography>

      <Box mt={2}>
        <Button onClick={handleClick} fullWidth>
          Selecionar Arquivo
        </Button>
      </Box>
      <input
        type="file"
        id="logo-upload"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files) validateAndSubmitLogo(e.target.files[0])
        }}
      />
    </BasicDialog>
  )
}

export default UploadLogoDialog
