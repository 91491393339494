import incidentManegementConstants from 'constants/incidentManegement'
import { IncidentsType } from 'types/incidents.types'

const enumSteps = (incident: IncidentsType) => {
  if (incident.confirmed) {
    return [
      incidentManegementConstants.DETECTION_ENUM,
      incidentManegementConstants.INVESTIGATION_ENUM,
      incidentManegementConstants.AVALIATION_ENUM,
      incidentManegementConstants.DETALING_ENUM,
      incidentManegementConstants.TREATMENT_ENUM,
      incidentManegementConstants.COMMUNICATION_ENUM,
      incidentManegementConstants.LESSONS_LEARNED_ENUM,
    ]
  }

  return [
    incidentManegementConstants.DETECTION_ENUM,
    incidentManegementConstants.INVESTIGATION_ENUM,
    incidentManegementConstants.AVALIATION_ENUM,
    incidentManegementConstants.LESSONS_LEARNED_ENUM,
  ]
}

export default enumSteps
