import { ButtonBase } from '@mui/material'
import { styled } from '@mui/material/styles'

const AccountBox = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
}))

export default AccountBox
