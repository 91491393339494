import { Menu as MenuMUI, MenuItem } from '@mui/material'

import { MenuType } from './menu.types'

const Menu = ({ anchorEl, open, onClose, logout }: MenuType) => {
  return (
    <MenuMUI
      id="account-menu"
      open={open}
      anchorEl={anchorEl}
      MenuListProps={{
        'aria-labelledby': 'account-box',
      }}
      onClose={onClose}
    >
      {/* <MenuItem onClick={onClose} divider>
        CONFIGURAÇÕES
      </MenuItem> */}
      <MenuItem onClick={logout}>SAIR</MenuItem>
    </MenuMUI>
  )
}

export default Menu
