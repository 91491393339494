import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const TicketInnerMessageBox = styled(Box)(({ theme }) => ({
  width: '-webkit-fill-available',
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
  fontSize: theme.typography.body1.fontSize,
  whiteSpace: 'pre-line',
  [theme.breakpoints.down('xs')]: {
    '& p > img': {
      width: '100%',
      height: 'auto',
    },
  },
}))

export default TicketInnerMessageBox
