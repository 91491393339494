import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({
  liaQuestionId = '',
  params = {},
}: {
  liaQuestionId?: number | string
  params: object
}) => {
  return await dponetAPI.get(`/advisors/lia_questions/${liaQuestionId}`, {
    params,
  })
}

const getWithAnswers = async ({ params }: { params?: object | string }) => {
  return await dponetAPI.get(`/advisors/lia_questions/list_with_answers`, {
    params,
  })
}

export default { get, getWithAnswers }
