import { Fragment } from 'react'
import { Box } from '@mui/material'

import { TitleSection, TopicSection, DataProcessInfoChip } from '..'

import { DataTreatmentAgentsType } from './dataTreatmentAgents.types'
import { dataProcesses } from 'helpers'

import constants from 'constants/index'

const DataTreatmentAgents = ({
  dataTreatmentAgents = [],
}: DataTreatmentAgentsType) => {
  const { LABELS } = constants.dataTreatmentAgents

  const { agentsTypeFilter } = dataProcesses

  const { controllers, operators, suboperators } =
    agentsTypeFilter(dataTreatmentAgents)

  return (
    <Box m={4}>
      <TitleSection title="Agentes de tratamento" />
      {controllers.length > 0 && (
        <Fragment>
          <TopicSection text={LABELS.CONTROLLER_LABEL} />
          <Box display="flex" mt={0.5}>
            {controllers.map((controller) => (
              <DataProcessInfoChip
                key={controller.id}
                text={controller?.name}
              />
            ))}
          </Box>
        </Fragment>
      )}
      {operators.length > 0 && (
        <Fragment>
          <TopicSection text={LABELS.OPERATOR_LABEL} />
          <Box display="flex" mt={0.5}>
            {operators.map((operator) => (
              <DataProcessInfoChip key={operator.id} text={operator?.name} />
            ))}
          </Box>
        </Fragment>
      )}
      {suboperators.length > 0 && (
        <Fragment>
          <TopicSection text={LABELS.SUBOPERATOR_LABEL} />
          <Box display="flex" mt={0.5}>
            {suboperators.map((suboperator) => (
              <DataProcessInfoChip
                key={suboperator.id}
                text={suboperator?.name}
              />
            ))}
          </Box>
        </Fragment>
      )}
    </Box>
  )
}

export default DataTreatmentAgents
