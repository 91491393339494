import accordionOverride from './overrides/accordion'
import avatarOverride from './overrides/avatar'
import buttonOverride from './overrides/button'
import cardOverride from './overrides/card'
import menuOverride from './overrides/menu'
import paperOverride from './overrides/paper'
import tableOverride from './overrides/table'
import typographyOverride from './overrides/typography'

export default {
  ...accordionOverride,
  ...avatarOverride,
  ...buttonOverride,
  ...cardOverride,
  ...menuOverride,
  ...paperOverride,
  ...tableOverride,
  ...typographyOverride,
}
