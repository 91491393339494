import { Button } from '@mui/material'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import { useNavigate } from 'react-router-dom'

import routes from 'constants/routes'

const ProfileCreate = () => {
  const navigate = useNavigate()

  const redirectToCreate = () => {
    navigate(routes.app.profiles.new)
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<PlusCircleIcon />}
        disableElevation
        onClick={redirectToCreate}
        aria-label="criar novo perfil"
      >
        ADICIONAR PERFIL DE USUÁRIO
      </Button>
    </>
  )
}

export default ProfileCreate
