import { Grid } from '@mui/material'

import { Card } from 'components'

import { useIncidentManegement } from 'hooks'
import IncidentTextArea from '../IncidentTextArea'

const DetectionStep = () => {
  const { incident } = useIncidentManegement()

  return (
    <Card title="Detecção do Incidente">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <IncidentTextArea
            label="Descreva como ficou sabendo a respeito do incidente:"
            value={incident?.discoveryDescription}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default DetectionStep
