import jsPDF from 'jspdf'
import { ratio } from 'utils/jsPDF/helpers'

const FONT_SIZE = {
  PARAGRAPH: ratio(48),
  SUBTITLE: ratio(80),
  TITLE: ratio(230),
  TOPIC: ratio(65),
  DOCUMENT_TITLE: ratio(260),
  PAGE_NUMBER: ratio(60),
  CHAPTER_NUMBER: ratio(450),
  CHAPTER_TITLE: ratio(120),
  PROGRESS_LABEL: ratio(40),
  SMALL: ratio(42),
}

const PADDING = ratio(200)

const WIDTH = (pdf: jsPDF) => pdf.internal.pageSize.width
const HEIGHT = (pdf: jsPDF) => pdf.internal.pageSize.height

const ROW_GAP = ratio(80)
const GAP = ratio(240)

const RECTANGLE_SIZE = {
  HALF_WIDTH: ratio(650),
}

const LABEL_WIDTH = ratio(160)
const LABEL_HEIGHT = ratio(80)

const MARGIN_TABLE = ratio(68)

const jsPdf = {
  FONT_SIZE,
  PADDING,
  WIDTH,
  HEIGHT,
  ROW_GAP,
  GAP,
  RECTANGLE_SIZE,
  LABEL_WIDTH,
  LABEL_HEIGHT,
  MARGIN_TABLE,
}

export default jsPdf
