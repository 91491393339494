import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

const HtmlLink = styled('a', {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $textDecoration?: Property.TextDecoration<TLength> | undefined
  $color?: Property.Color | undefined
}>(({ $textDecoration, $color }) => ({
  textDecoration: $textDecoration,
  color: $color,
}))

export default HtmlLink
