import { isArray } from 'lodash'
import React, { useEffect, useState } from 'react'

import { AutoCompleteOptions, FiltersTypes } from 'types/filters.types'
import {
  ChildrenInputComponentType,
  DefaultValueStateType,
} from './childrenInput.types'

const ChildrenInput = ({
  child,
  initialValue,
  handleChange,
}: ChildrenInputComponentType) => {
  const value = initialValue as FiltersTypes
  const options: AutoCompleteOptions[] = child.props.options
  const isAutocomplete = !!child.props.fieldConfigs.fieldAutocomplete
  const isRangeSlider = !!child.props.fieldConfigs.rangeSlider
  const getAutocompleteValues = () => {
    if (child.props.multiple) {
      if (!value) {
        return []
      }

      if (isArray(value)) {
        return options.filter(
          (opt) => value?.includes(opt.id) || value.includes(opt.id.toString())
        )
      }

      return options.filter(
        (option) => option.id.toString() === value?.toString()
      )
    }
    if (!value) {
      return null
    }
    return options.find((option) => option.id.toString() === value?.toString())
  }

  const getInitialState = () => {
    if (isAutocomplete) return getAutocompleteValues()
    if (isRangeSlider && isArray(value))
      return value?.map((sliderValues: string) => parseInt(sliderValues))

    return value || ''
  }

  const [defaultValue, setValue] = useState<DefaultValueStateType | number[]>(
    getInitialState()
  )

  useEffect(() => {
    if (isAutocomplete) {
      const newValue = getAutocompleteValues()
      return setValue(newValue)
    }

    if (isRangeSlider && isArray(value)) {
      const newValue = value?.map((sliderValues: string) =>
        parseInt(sliderValues)
      )
      return setValue(newValue)
    }

    setValue(value || '')
  }, [value])

  const mountProps = () => ({
    ...child?.props,
    onChange: (
      e: React.ChangeEvent<HTMLInputElement>,
      value: AutoCompleteOptions[]
    ) => {
      handleChange(child?.props?.name, e.target.value || value)
    },
    value: defaultValue,
  })

  const newChildren = React.cloneElement(child, mountProps())

  return newChildren
}

export default ChildrenInput
