import {
  DataProcessRiskType,
  DataProcessStatsType,
  DataProcessStatusType,
} from 'types/dataProcess.types'

const general = (dataProcessStats: DataProcessStatsType[]) => {
  const sumStatuses = (status: keyof DataProcessStatusType) =>
    dataProcessStats
      .map((stats) => stats['statuses'][status])
      .reduce((accumulator, attribute) => accumulator + attribute, 0)

  const sumRisks = (status: keyof DataProcessRiskType) =>
    dataProcessStats
      .map((stats) => stats['risks'][status])
      .reduce((accumulator, attribute) => accumulator + attribute, 0)

  return {
    risks: {
      low: sumRisks('low'),
      medium: sumRisks('medium'),
      high: sumRisks('high'),
      severe: sumRisks('severe'),
    },
    statuses: {
      pending: sumStatuses('pending'),
      reviewing: sumStatuses('reviewing'),
      reviewDisapproved: sumStatuses('reviewDisapproved'),
      reviewApproved: sumStatuses('reviewApproved'),
      inactive: sumStatuses('inactive'),
      reviewingLia: sumStatuses('reviewing'),
    },
  }
}

export default {
  general,
}
