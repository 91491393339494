import { Box, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import schema from './schema'
import { TagFormType } from './tagForm.types'

const TagForm = ({ onSubmit, errorMessage, tag }: TagFormType) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: tag?.name || '',
    },
  })

  return (
    <Box
      id="tag-creation-form"
      pt={2}
      flex={1}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors?.name || !!errorMessage}
            helperText={
              errorMessage ?? errors?.name?.message ?? 'Máximo de 50 caracteres'
            }
            label="Nome do classificador"
            type="text"
            variant="outlined"
            fullWidth
          />
        )}
        name="name"
        control={control}
      />
    </Box>
  )
}

export default TagForm
