import { Checkbox as CheckboxMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'

const Checkbox = styled(CheckboxMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $color?: Property.Color | undefined
}>(({ $color }) => ({
  color: $color,
}))

export default Checkbox
