import { Box, Paper, Typography } from '@mui/material'

import { QuestionInfoCardType } from './questionInfoCard.types'

const QuestionInfoCard = ({
  title,
  value,
  icon,
  boxColor,
}: QuestionInfoCardType) => {
  return (
    <Paper
      component={Box}
      variant="outlined"
      py={4}
      px={5}
      gap={4}
      width="100%"
      display="flex"
    >
      <Box
        borderRadius={40}
        bgcolor={boxColor}
        height={40}
        width={40}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {icon}
      </Box>
      <Box>
        <Typography
          textTransform="uppercase"
          variant="body2"
          fontWeight={500}
          color="textSecondary"
        >
          {title}
        </Typography>
        <Typography textTransform="uppercase" variant="body2" fontWeight={500}>
          {value}
        </Typography>
      </Box>
    </Paper>
  )
}

export default QuestionInfoCard
