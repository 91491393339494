import { Toolbar as ToolbarMUI } from '@mui/material'
import { styled } from '@mui/material/styles'

const Toolbar = styled(ToolbarMUI)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 72,
}))

export default Toolbar
