import { colors } from '@mui/material'

import { firstCoverType } from './firstCover.types'

import { ratio } from '../../helpers'
import constants from 'constants/index'
import theme from 'theme'
import { logoAdvisorWhite } from '../../assets/logoAdvisorWhite64'
import { logoAdvisorBlue } from '../../assets/logoAdvisorBlue64'

const firstCover = ({
  pdf,
  companyName,
  document,
  date,
  reportName,
}: firstCoverType) => {
  const PADDING = constants.jsPdf.PADDING
  const WIDTHPdf = constants.jsPdf.WIDTH
  const HEIGHTPdf = constants.jsPdf.HEIGHT
  const FONT_SIZE = constants.jsPdf.FONT_SIZE

  const WIDTH = WIDTHPdf(pdf)
  const HEIGHT = HEIGHTPdf(pdf)

  pdf.setFillColor(theme.palette.primary.main)
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')

  pdf.addImage(
    logoAdvisorWhite,
    'png',
    PADDING,
    PADDING - 10,
    ratio(500),
    ratio(203)
  )
  pdf.setFontSize(FONT_SIZE.DOCUMENT_TITLE)
  pdf.setLineHeightFactor(1.2)
  pdf.setFont('Raleway-Bold')
  let splitedTitle = pdf.splitTextToSize(reportName, ratio(1700))
  pdf.setTextColor(colors.grey['A100']) //aq
  pdf.text(splitedTitle, PADDING, ratio(600), undefined, 'left')

  pdf.setFontSize(FONT_SIZE.SUBTITLE)
  pdf.setFont('Raleway-Regular')
  let splitedName = pdf.splitTextToSize(companyName, WIDTH - PADDING * 3)

  pdf.text(splitedName, PADDING, ratio(1940))
  document && pdf.text(document, PADDING, ratio(2000 + 50 * splitedName.length))
  pdf.rect(PADDING, HEIGHT - ratio(374), WIDTH - PADDING * 2, ratio(8), 'F')
  pdf.text(date, WIDTH / 2, HEIGHT - PADDING, undefined, 'center')

  pdf.addPage('a4', 'p')

  pdf.setFillColor(colors.grey['A100'])
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')

  pdf.addImage(
    logoAdvisorBlue,
    'png',
    WIDTH / 2 - PADDING * 1.25,
    PADDING - 10,
    ratio(500),
    ratio(203)
  )
  pdf.setFontSize(FONT_SIZE.DOCUMENT_TITLE)
  pdf.setLineHeightFactor(1.2)
  pdf.setFont('Raleway-Bold')
  splitedTitle = pdf.splitTextToSize(reportName, ratio(1700))
  pdf.setTextColor(theme.palette.primary.main)
  pdf.text(splitedTitle, WIDTH / 2, ratio(600), undefined, 'center')

  pdf.setFontSize(FONT_SIZE.SUBTITLE)
  pdf.setFont('Raleway-Regular')

  splitedName = pdf.splitTextToSize(companyName, ratio(1024))

  pdf.text(splitedName, WIDTH / 2, ratio(1940), undefined, 'center')
  document &&
    pdf.text(
      document,
      WIDTH / 2,
      ratio(2000 + 50 * splitedName.length),
      undefined,
      'center'
    )
  pdf.text(date, WIDTH / 2, HEIGHT - PADDING, undefined, 'center')
}

export default firstCover
