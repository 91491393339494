import autoCompleteNoText from './autoCompleteNoText'
import autoCompleteOptions from './autoCompleteOptions'
import date from './date'
import errorMessage from './errorMessage'
import fullName from './fullName'
import dateFromNow from './dateFromNow'
import phoneDynamicMask from './phoneDynamicMask'
import convertBoolToString from './convertBoolToString'
import cnpj from './cnpj'
import cpf from './cpf'
import identificationDocument from './identificationDocument'
import formatDataTomticket from './formatDataTomtickets'

export default {
  autoCompleteNoText,
  autoCompleteOptions,
  cnpj,
  convertBoolToString,
  cpf,
  date,
  dateFromNow,
  errorMessage,
  formatDataTomticket,
  fullName,
  identificationDocument,
  phoneDynamicMask,
}
