import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

const Img = styled('img', {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $maxHeight?: Property.MaxHeight<TLength> | undefined
  $maxWidth?: Property.MaxWidth<TLength> | undefined
  $objectFit?: Property.ObjectFit
  $objectPosition?: Property.ObjectPosition
  $height?: Property.Height
  $width?: Property.Width
}>(
  ({
    $maxHeight,
    $maxWidth,
    $objectFit,
    $objectPosition,
    $height,
    $width,
  }) => ({
    maxHeight: $maxHeight,
    maxWidth: $maxWidth,
    objectFit: $objectFit,
    objectPosition: $objectPosition,
    height: $height,
    width: $width,
  })
)

export default Img
