const ADMIN_ADVISOR_PROFILE_ID = 500_000
const RESPONSIBLE_DPO_PROFILE_ID = 500_002
const ASSISTANT_DPO_PROFILE_ID = 500_003
const LEGAL_ADVICE_PROFILE_ID = 500_004
const TITULAR_ATTENDANCE_PROFILE_ID = 500_005
const OBSERVER_AUTHOR_ADVISOR_PROFILE_ID = 500_006
const OBSERVER_ADVISOR_PROFILE_ID = 500_007

const DEFAULT_PROFILES_OPTIONS = [
  {
    id: ASSISTANT_DPO_PROFILE_ID,
    name: 'DPO Assistente',
  },
  { id: LEGAL_ADVICE_PROFILE_ID, name: 'Parecer Jurídico' },
  { id: TITULAR_ATTENDANCE_PROFILE_ID, name: 'Atendimento ao Titular' },
  { id: OBSERVER_ADVISOR_PROFILE_ID, name: 'Observador' },
]

const DEFAULT_DPO_PROFILES_OPTIONS = [
  {
    id: ASSISTANT_DPO_PROFILE_ID,
    name: 'DPO Assistente',
  },
  { id: LEGAL_ADVICE_PROFILE_ID, name: 'Parecer Jurídico' },
  { id: TITULAR_ATTENDANCE_PROFILE_ID, name: 'Atendimento ao Titular' },
]

const DEFAULT_OBSERVER_PROFILES_OPTIONS = [
  { id: OBSERVER_ADVISOR_PROFILE_ID, name: 'Observador' },
]

export default {
  ADMIN_ADVISOR_PROFILE_ID,
  ASSISTANT_DPO_PROFILE_ID,
  DEFAULT_DPO_PROFILES_OPTIONS,
  DEFAULT_OBSERVER_PROFILES_OPTIONS,
  DEFAULT_PROFILES_OPTIONS,
  LEGAL_ADVICE_PROFILE_ID,
  OBSERVER_ADVISOR_PROFILE_ID,
  OBSERVER_AUTHOR_ADVISOR_PROFILE_ID,
  RESPONSIBLE_DPO_PROFILE_ID,
  TITULAR_ATTENDANCE_PROFILE_ID,
}
