import { createContext } from 'react'

import { LegitimateInterestContextType } from 'providers/LegitimateInterestProvider/LegitimateInterestProvider.types'

const legitimateInterestContext = createContext<LegitimateInterestContextType>({
  activeStep: 0,
  setActiveStep: () => {},
  stepFinalityCompleted: false,
  setStepFinalityCompleted: () => {},
  stepNecessityCompleted: false,
  setStepNecessityCompleted: () => {},
  stepBalancingSafeguardingCompleted: false,
  setStepBalancingSafeguardingCompleted: () => {},
  stepFinalityIncomplete: false,
  setStepFinalityIncomplete: () => {},
  stepNecessityIncomplete: false,
  setStepNecessityIncomplete: () => {},
  stepBalancingSafeguardingIncomplete: false,
  setStepBalancingSafeguardingIncomplete: () => {},
})

export default legitimateInterestContext
