import { useEffect, useState } from 'react'

import { FilterInput, Filters } from 'components'

import { useFetch } from 'hooks'

import { LegalFrameworksFiltersType } from './legalFrameworksFilters.types'
import { OrganizationCompanyType } from 'types/organizationCompany'

import service from 'service'
import dataProcessConstants from 'constants/dataProcess'
import { formatters } from 'helpers'

const LegalFrameworksFilters = ({ filter }: LegalFrameworksFiltersType) => {
  const [shouldFetch, setShouldFetch] = useState<boolean>(false)
  const [organizationCompanyFilter, setOrganizationCompanyFilter] = useState<
    string | null
  >()
  const validCompanyFilterInput =
    !!organizationCompanyFilter && organizationCompanyFilter?.length >= 3

  useEffect(() => {
    if (filter.drawerOpen && !shouldFetch) setShouldFetch(true)
  }, [filter.drawerOpen])

  const { response: responseCompanies, loading: loadingCompany } = useFetch(
    service.dponet.organizationCompany.get,
    {
      params: {
        perPage: 999,
        minimal: true,
      },
    },
    [organizationCompanyFilter],
    validCompanyFilterInput
  )

  const organizationCompanies: OrganizationCompanyType[] =
    responseCompanies?.data?.organizationCompanies

  const activeOrganizationCompanies = organizationCompanies?.filter(
    (organizationCompanie) => organizationCompanie?.status === 'ACTIVE'
  )

  return (
    <Filters filter={filter}>
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        {...{
          label: 'Status',
          name: 'statusId',
          options: dataProcessConstants?.STATUSES_WITH_LEGAL_FRAMEWORK.map(
            (status) => ({
              id: status.id,
              name: status.name,
            })
          ),
          multiple: true,
        }}
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Identficador', name: 'id' }}
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Nome', name: 'name' }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        loading={loadingCompany && validCompanyFilterInput}
        autocompleteOnChange={(data) => {
          setOrganizationCompanyFilter(data)
        }}
        {...{
          noOptionsText: formatters.autoCompleteNoText(
            organizationCompanyFilter || ''
          ),
          label: 'Empresa',
          name: 'companyId',
          options: activeOrganizationCompanies?.map(
            (organizationCompany: OrganizationCompanyType) => ({
              id: organizationCompany?.company?.id,
              name: organizationCompany?.company?.name,
            })
          ),
        }}
      />
    </Filters>
  )
}

export default LegalFrameworksFilters
