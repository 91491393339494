import { useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'

import {
  OrganizationAvatar,
  OrganizationAvatarHover,
} from 'views/Organization/components'

import { usePermission } from 'hooks'

import permissionTagsConstants from 'constants/permissionTags'
import { UploadLogoDialog } from './components'

const OrganizationUploadImage = ({
  organizationId,
  name,
  src,
}: {
  organizationId: string | number
  name: string | undefined
  src?: string
}) => {
  const [openUploadLogoDialog, setOpenUploadLogoDialog] = useState(false)
  const [logo, setLogo] = useState<string | undefined>()

  const { isPermitted } = usePermission()

  const handleChangeCompanyMatrixDialog = () => {
    setOpenUploadLogoDialog((openUploadLogoDialog) => !openUploadLogoDialog)
  }

  useEffect(() => {
    setLogo(src)
  }, [src])

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
      {isPermitted(permissionTagsConstants.ORGANIZATION.MANAGE) ? (
        <>
          <Button onClick={handleChangeCompanyMatrixDialog} fullWidth>
            <OrganizationAvatarHover
              alt={name}
              src={`${logo || '/broken-image.jpg'}`}
            />
          </Button>
        </>
      ) : (
        <OrganizationAvatar alt={name} src={`${logo || '/broken-image.jpg'}`} />
      )}

      <UploadLogoDialog
        organizationId={organizationId}
        open={openUploadLogoDialog}
        onClose={handleChangeCompanyMatrixDialog}
        setLogo={setLogo}
        src={src}
      />
    </Box>
  )
}

export default OrganizationUploadImage
