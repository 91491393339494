import { isUndefined } from 'lodash'
import { Grid } from '@mui/material'

import { LoadingFeedback } from 'components'
import { DescriptionIncident, PersonalDataImpact } from './components'

import { DataOptionIncidents } from 'types/incidents.types'

import { useFetch, useIncidentManegement } from 'hooks'

import service from 'service'

const InvestigationStep = () => {
  const { incident } = useIncidentManegement()
  const { response, loading } = useFetch(
    service.dponet.incidents.options,
    {
      typeFields: [
        'which_security_measures_adopted',
        'types_sensitive_personal_data_breached',
        'other_type_personal_data_violated',
      ],
    },
    []
  )

  const incidentOptions: DataOptionIncidents = response?.data

  return (
    <Grid container spacing={4}>
      <LoadingFeedback open={loading} />

      {!isUndefined(incidentOptions) && (
        <>
          <Grid item xs={12}>
            <PersonalDataImpact incident={incident} options={incidentOptions} />
          </Grid>
          <Grid item xs={12}>
            <DescriptionIncident
              incident={incident}
              options={incidentOptions}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default InvestigationStep
