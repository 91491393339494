import { Box, Button, Typography } from '@mui/material'

import { AiTicketGenerativeButtonType } from './aiTicketGenerativeButton.types'

import { LogoDai } from 'components'

import theme from 'theme'
import daiLogo from 'images/dai_logo.png'

const AiTicketGenerativeButton = ({
  handleRequestGenerativeSuggestion,
  disabled,
}: AiTicketGenerativeButtonType) => {
  return (
    <Box display="flex" justifyContent="flex-end" mr={3}>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleRequestGenerativeSuggestion}
        disabled={disabled}
      >
        <LogoDai src={daiLogo} alt="logo dai" />
        <Typography
          variant="body2"
          color={theme.palette.secondary.main}
          fontWeight={500}
        >
          Gerar análise do ticket
        </Typography>
      </Button>
    </Box>
  )
}

export default AiTicketGenerativeButton
