import { SubtitleType } from './subtitle.types'

import constants from 'constants/index'
import theme from 'theme'

const subtitle = ({
  pdf,
  sub,
  positionY,
  textColor = theme.palette.primary.main,
}: SubtitleType) => {
  const FONT_SIZE = constants.jsPdf.FONT_SIZE
  const PADDING = constants.jsPdf.PADDING

  pdf.setFont('Raleway-Bold')
  pdf.setFontSize(FONT_SIZE.SUBTITLE)
  pdf.setTextColor(textColor)
  pdf.text(sub, PADDING, positionY, undefined, 'left')
}

export default subtitle
