import {
  Grid,
  TableContainer,
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { MenuButton } from 'components'

import { AttachedDocumendataDocumenttsTableType } from './attachedDocumentsTable.types'

const AttachedDocumentsTable = ({
  dataDocument,
  handleDelete,
}: AttachedDocumendataDocumenttsTableType) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Documentos</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataDocument?.map((document, idx) => (
            <TableRow key={document?.name + idx}>
              <TableCell>{document?.name}</TableCell>
              <TableCell align="right">
                <Grid>
                  <Box display="flex" justifyContent="flex-end">
                    <MenuButton id={`id-menu-button-${document?.name + idx}`}>
                      <Button
                        color="primary"
                        fullWidth
                        onClick={() => handleDelete(document?.name)}
                      >
                        Remover
                      </Button>
                    </MenuButton>
                  </Box>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default AttachedDocumentsTable
