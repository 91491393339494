import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'

import { Form } from './components'
import { ListPageStructure, LoadingFeedback, PageHead } from 'components'

import { IncidentsType, IncidentManegementStep } from 'types/incidents.types'

import { useFetch, useIncidentManegement } from 'hooks'

import service from 'service'
import incidentManegementConstants from 'constants/incidentManegement'

const IncidentManegementsEdit = () => {
  const { incidentId } = useParams()
  const { setActiveStep, incident, setIncident } = useIncidentManegement()

  const { response, loading, refresh } = useFetch(
    service.dponet.incidents.get,
    {
      incidentId,
    }
  )

  const incidentResponse: IncidentsType = response?.data?.incident

  const identifyCurrentStep = (
    incidentManegementSteps: IncidentManegementStep[]
  ) => {
    const stepsByEnum = incidentManegementConstants.STEPS_BY_ENUM

    incidentManegementSteps.forEach((incidentManegementStep) => {
      incidentManegementStep.order = stepsByEnum[incidentManegementStep.step]
    })

    incidentManegementSteps = incidentManegementSteps.sort((a, b) => {
      if (!a?.order || !b?.order) return 0

      return a?.order - b?.order
    })

    const firstUnconcludedStep = incidentManegementSteps.find(
      (incidentManegementStep) => !incidentManegementStep.concluded
    )

    if (isEmpty(firstUnconcludedStep))
      return setActiveStep(stepsByEnum['lessons_learned'])

    if (firstUnconcludedStep.order) setActiveStep(firstUnconcludedStep.order)
  }

  useEffect(() => {
    if (incidentResponse) {
      if (isEmpty(incident)) {
        identifyCurrentStep(incidentResponse.incidentManegementSteps)
      }

      setIncident(incidentResponse)
    }
  }, [incidentResponse])

  return (
    <ListPageStructure>
      <LoadingFeedback open={loading} />

      <PageHead name="Editar Incidente" />

      {incident && <Form incident={incident} refresh={refresh} />}
    </ListPageStructure>
  )
}

export default IncidentManegementsEdit
