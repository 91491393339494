import { Avatar as AvatarMUI } from '@mui/material'
import { styled } from '@mui/material/styles'

const OrganizationAvatar = styled(AvatarMUI)(() => ({
  width: '100%',
  height: 'auto',
  aspectRatio: '1/1',
  maxWidth: 124,
  position: 'relative',
}))

export default OrganizationAvatar
