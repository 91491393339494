import { styled } from '@mui/material/styles'

interface RatingImageType {
  disabled?: boolean
  selected?: boolean
}

const RatingImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'selected',
})<RatingImageType>(({ disabled, selected }) => ({
  maxHeight: 100,
  width: 'auto',
  filter: 'grayscale(1) brightness(10) contrast(0.5)',
  transition: 'filter 0.2s ease',
  cursor: 'pointer',
  '&:hover': {
    filter: 'grayscale(0)',
  },

  ...(selected && {
    cursor: 'default',
    filter: 'grayscale(0)',

    '&:hover': {
      filter: 'grayscale(0)',
    },
  }),

  ...(disabled && {
    cursor: 'default',

    '&:hover': {
      filter: 'grayscale(1) brightness(10) contrast(0.5)',
    },
  }),
}))

export default RatingImage
