import 'utils/jsPDF/fonts/Raleway-Bold-normal'
import 'utils/jsPDF/fonts/Raleway-Thin-normal'
import 'utils/jsPDF/fonts/Raleway-Regular-normal'
import 'utils/jsPDF/fonts/Roboto-Regular-normal'
import 'utils/jsPDF/fonts/Roboto-Bold-normal'

import { Button } from '@mui/material'
import { Download as DownloadIcon } from 'react-feather'
import { Fragment, useState } from 'react'
import { jsPDF } from 'jspdf'
import moment from 'moment'

import LoadingFeedback from 'components/LoadingFeedback'

import { useSnackbar } from 'hooks'

import { SimplifiedIncidentPdfType } from './SimplifiedIncidentPdf.types'

import { formatters } from 'helpers'
import templates from 'utils/jsPDF/templates'
import pages from './pages'

moment.locale('pt-br')
let dateNow = moment().format('MMM YYYY')

const SimplifiedIncidentPdf = ({
  organization,
  incidents,
}: SimplifiedIncidentPdfType) => {
  const [loading, setLoading] = useState(false)

  const { snackbar } = useSnackbar()

  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    userUnit: 300,
  })

  dateNow = dateNow.charAt(0).toUpperCase() + dateNow.slice(1)

  const exportPdf = async () => {
    try {
      setLoading(true)

      templates.firstCover({
        pdf,
        companyName: organization?.name || '',
        date: dateNow,
        reportName: 'Relatório de Incidente Simplificado',
      })

      pages.incident({ pdf, date: dateNow, incidents: incidents })

      pages.treatment({ pdf, date: dateNow, incidents: incidents })

      templates.lastCover({ pdf })

      pdf.save(`Relatorio_de_Incidente_simplificado_${organization?.name}`)

      snackbar.open({
        variant: 'success',
        message: 'Relatório gerado com sucesso, aguarde o download começar',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        variant: 'error',
        message: formatters.errorMessage(error, false),
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <LoadingFeedback open={loading} />
      <Button
        onClick={exportPdf}
        startIcon={<DownloadIcon size={16} />}
        variant="outlined"
      >
        Relatório Simplificado
      </Button>
    </Fragment>
  )
}

export default SimplifiedIncidentPdf
