const phoneDynamicMask = (phone?: string) => {
  const digits = !phone ? '' : phone.replace(/[^\d]/g, '')

  if (!digits || digits.length < 10) return phone

  const splitPoint = digits.length === 10 ? 6 : 7
  const digitsLen = digits.length > 11 ? 11 : digits.length

  return `(${digits.substring(0, 2)}) ${digits.substring(
    2,
    splitPoint
  )}-${digits.substring(splitPoint, digitsLen)}`
}

export default phoneDynamicMask
