import { formatters, incidents as incidentsHelpers } from 'helpers'
import constants from 'constants/index'
import theme from 'theme'

import components from 'utils/jsPDF/components'

import { TimmingCommunicationType } from './TimmingCommunication.types'

const TimmingCommunication = ({
  pdf,
  date,
  incidents,
}: TimmingCommunicationType) => {
  const GAP = constants.jsPdf.GAP
  const MARGIN_TABLE = constants.jsPdf.MARGIN_TABLE

  const incidentReporting = incidents?.incidentReporting
  const communicationIncidentHolder = incidents?.communicationIncidentHolder

  pdf.addPage('a4', 'p')

  components.subtitle({
    pdf,
    sub: 'Tempestividade e Comunicação',
    positionY: GAP,
  })
  components.lineWithDate({ pdf, date: date })

  components.paragraph({
    pdf,
    text: 'Sobre o Incidente',
    positionY: GAP * 1.5,
    font: 'Roboto-Bold',
  })

  const incidentData = [
    [
      'Quando ocorreu',
      formatters.date.ptBrFormatDate(incidentReporting?.occurred) || '-',
    ],
    [
      'Quando tomou ciência',
      formatters.date.ptBrFormatDate(incidentReporting?.incidentAware) || '-',
    ],
    [
      'Quando comunicou à ANPD',
      formatters.date.ptBrFormatDate(incidentReporting?.anpdNotificationDate) ||
        '-',
    ],
    [
      'Quando comunicou aos titulares',
      formatters.date.ptBrFormatDate(incidentReporting?.informedHolder) || '-',
    ],
    [
      'Justifique, se cabível, a não realização da comunicação completa à ANPD e aos titulares de dados afetados no prazo sugerido de 2 (dois) dias úteis após a ciência do incidente',
      incidentReporting?.justificationNotNotifyingAnpd || '-',
    ],
    [
      'Se cabível, informe quando e a quais outras autoridades o incidente foi comunicado:',
      incidentReporting?.informedOtherAuthority || '-',
    ],
  ]

  components.table({
    pdf,
    body: incidentData,
    startY: GAP * 1.6,
    cellWidth: 0.4,
  })

  components.paragraph({
    pdf,
    text: 'Da Comunicação do Incidente aos Titulares dos Dados',
    positionY: pdf.lastAutoTable.finalY + MARGIN_TABLE,
    font: 'Roboto-Bold',
  })

  const controllerDataTable = [
    [
      'Os titulares dos dados afetados foram comunicados sobre o incidente?',
      incidentsHelpers.holderAffectedInformedStatus(
        communicationIncidentHolder?.holderAffectedInformed
      ) || '-',
    ],
    [
      'Se cabível, quando os titulares serão comunicados sobre o incidente?',
      communicationIncidentHolder?.holderWillCommunicateIn || '-',
    ],
    [
      'De que forma a ocorrência do incidente foi comunicada aos titulares?',
      incidents?.dataOptionIncidents?.howWasIncidentReportedOwner
        ? incidents?.dataOptionIncidents?.howWasIncidentReportedOwner
            .map((dataType) => dataType.name + '\n')
            .join('')
        : '-',
    ],
    [
      'Descreva como ocorreu a comunicação:',
      communicationIncidentHolder?.holderCommunicationOccurred || '-',
    ],
    [
      'Quantos titulares foram comunicados individualmente sobre o incidente??',
      communicationIncidentHolder?.holderCommunicatedIndividually || '-',
    ],
    [
      'Justifique, se cabível, o que motivou a não realização da comunicação individual aos titulares:',
      communicationIncidentHolder?.holderNotCommunicatedIndividually || '-',
    ],
    [
      'O comunicado aos titulares atendeu os requisitos acima?',
      formatters.convertBoolToString(
        communicationIncidentHolder?.metAboveRequirement
      ) || '-',
    ],
  ]
  components.table({
    pdf,
    body: controllerDataTable,
    startY: pdf.lastAutoTable.finalY + MARGIN_TABLE + GAP * 0.1,
    cellWidth: 0.4,
  })

  components.numberPage({ pdf, color: theme.palette.primary.main })
}

export default TimmingCommunication
