import axios from 'axios'

import { getBaseURL } from 'service/env'
import service from 'service'
import api from 'service/api'

const dponetAPI = api.create('dponet')
const baseURL = getBaseURL('dponet')

type TicketRequestType = {
  ticketId?: string | number
  params?: object
  ticket?: object
  tickets?: object
  ticketFeedback?: object
  ticketSteps?: object
  allowedObserversIds?: object
}

const list = async ({ params = {} }: TicketRequestType) => {
  return dponetAPI.get('/advisors/tickets/privacy_portal', {
    params,
  })
}

const show = async ({ ticketId = '', params = {} }: TicketRequestType) => {
  return dponetAPI.get(`/advisors/tickets/${ticketId}/privacy_portal`, {
    params,
  })
}

const getMessages = async ({
  ticketId = '',
  params = {},
}: TicketRequestType) => {
  return dponetAPI.get(
    `/advisors/tickets/${ticketId}/privacy_portal/messages`,
    {
      params,
    }
  )
}

const getLogs = async ({ ticketId = '', params = {} }: TicketRequestType) => {
  return dponetAPI.get(`/advisors/tickets/${ticketId}/privacy_portal/logs`, {
    params,
  })
}

const update = async ({ ticketId = '', ticket = {} }: TicketRequestType) => {
  return dponetAPI.put(`/advisors/tickets/${ticketId}/privacy_portal`, {
    ticket,
  })
}

const reviewLegitimacySolicitation = async ({
  ticketId = '',
  ticketSteps = {},
}: TicketRequestType) => {
  return dponetAPI.put(
    `/advisors/tickets/${ticketId}/privacy_portal/legitimacy_solicitation`,
    {
      ticketSteps,
    }
  )
}

const postMessages = async ({
  ticketId,
  ticketMessages,
}: {
  ticketId?: string | number
  ticketMessages: {
    kind: number
    content?: string
    attachments?: File[]
  }
}) => {
  const data = new FormData()

  data.append('ticket_messages[kind]', String(ticketMessages?.kind))
  data.append('ticket_messages[content]', ticketMessages?.content || '')

  if (ticketMessages?.attachments) {
    for (const value of Object.entries(ticketMessages?.attachments)) {
      data.append(`ticket_messages[attachments][]`, value[1])
    }
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/advisors/tickets/${ticketId}/privacy_portal/messages`,
    data: data,
    ...config,
  })
}

const allowObservers = async ({
  ticketId = '',
  allowedObserversIds = {},
}: TicketRequestType) => {
  return dponetAPI.put(
    `/advisors/tickets/${ticketId}/privacy_portal/allow_observers`,
    { allowedObserversIds }
  )
}

const analyzeTicket = async ({ ticketId = '' }: TicketRequestType) => {
  return dponetAPI.get(
    `/advisors/tickets/${ticketId}/privacy_portal/analyze_ticket`
  )
}

const createJuridic = async ({
  tickets = {},
}: {
  tickets: {
    title?: string
    description?: string
    attachments?: File[]
  }
}) => {
  const data = new FormData()

  data.append('tickets[title]', tickets?.title || '')
  data.append('tickets[content]', tickets?.description || '')

  if (tickets?.attachments) {
    for (const value of Object.entries(tickets?.attachments)) {
      data.append(`tickets[attachments][]`, value[1])
    }
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/advisors/tickets/juridic`,
    data: data,
    ...config,
  })
}

const createSecurity = async ({
  tickets = {},
}: {
  tickets: {
    title?: string
    description?: string
    attachments?: File[]
  }
}) => {
  const data = new FormData()

  data.append('tickets[title]', tickets?.title || '')
  data.append('tickets[content]', tickets?.description || '')

  if (tickets?.attachments) {
    for (const value of Object.entries(tickets?.attachments)) {
      data.append(`tickets[attachments][]`, value[1])
    }
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/advisors/tickets/security`,
    data: data,
    ...config,
  })
}

const listMyTickets = async ({ params = {} }: TicketRequestType) => {
  return dponetAPI.get('/advisors/tickets/my', {
    params,
  })
}

const showMyTicket = async ({ ticketId = '' }: TicketRequestType) => {
  return dponetAPI.get(`/advisors/tickets/${ticketId}/my`)
}

const getMyTicketMessages = async ({ ticketId = '' }: TicketRequestType) => {
  return dponetAPI.get(`/advisors/tickets/${ticketId}/my/messages`)
}

const postMyTicketMessages = async ({
  ticketId,
  ticketMessages,
}: {
  ticketId?: string | number
  ticketMessages: {
    content?: string
    attachments?: File[]
  }
}) => {
  const data = new FormData()

  data.append('ticket_messages[content]', ticketMessages?.content || '')

  if (ticketMessages?.attachments) {
    for (const value of Object.entries(ticketMessages?.attachments)) {
      data.append(`ticket_messages[attachments][]`, value[1])
    }
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/advisors/tickets/${ticketId}/my/messages`,
    data: data,
    ...config,
  })
}

const createFeedback = async ({
  ticketId = '',
  ticketFeedback,
}: TicketRequestType) => {
  return dponetAPI.post(`/advisors/tickets/${ticketId}/my/feedbacks`, {
    ticketFeedback,
  })
}

export default {
  allowObservers,
  analyzeTicket,
  createFeedback,
  createJuridic,
  createSecurity,
  getLogs,
  getMessages,
  getMyTicketMessages,
  list,
  listMyTickets,
  postMessages,
  postMyTicketMessages,
  reviewLegitimacySolicitation,
  show,
  showMyTicket,
  update,
}
