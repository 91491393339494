import { styled } from '@mui/material/styles'
import { LinearGradient } from 'components'

const RightDescriptionBox = styled(LinearGradient('60%'))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingRight: '10vw',
  paddingLeft: '10vw',
  [theme.breakpoints.down('md')]: {
    height: '15vh',
  },
}))

export default RightDescriptionBox
