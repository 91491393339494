import { useState } from 'react'
import { Button } from '@mui/material'
import { PlusCircle as PlusCircleIcon } from 'react-feather'

import { BasicDialog } from 'components'
import { OrganizationForm } from 'views/Organization/components'

import { useSnackbar } from 'hooks'

import service from 'service'
import { OrganizationCreateType } from './organizationCreate.types'
import { formatters } from 'helpers'

const OrganizationCreate = ({
  refresh = () => {},
  setLoading,
}: OrganizationCreateType) => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false)

  const { snackbar } = useSnackbar()

  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true)
  }

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false)
  }

  const createOrganization = async (organization: object) => {
    try {
      setLoading(true)

      await service.dponet.organization.create(organization)

      handleCloseCreateDialog()
      refresh()

      snackbar.open({
        message: 'Organização criada com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<PlusCircleIcon />}
        disableElevation
        onClick={handleOpenCreateDialog}
        aria-label="abrir modal para criar grupo advisor"
      >
        ADICIONAR GRUPO ADVISOR
      </Button>

      <BasicDialog
        open={openCreateDialog}
        title="Adicionar Grupo Advisor"
        onClose={handleCloseCreateDialog}
        form="organization-creation-form"
      >
        <OrganizationForm onSubmit={createOrganization} canDisable={false} />
      </BasicDialog>
    </>
  )
}

export default OrganizationCreate
