import { Control, Controller } from 'react-hook-form'
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  Typography,
} from '@mui/material'

import { ButtonColorPicker, MUI } from 'components'

import { ColorSpecificationType } from './colorSpecification.types'

import colorSpecificationConstants from './colorSpecification.constants'
import { PrivacyPortalThemeFormType } from '../../schema'

const ColorSpecification = ({
  titleName,
  subtitleName,
  buttonMainName,
  buttonFontName,
  control,
  errorMainName,
  errorFontName,
  controlMainName,
  controlFontName,
  colorPicker,
  fontColor,
  setColorPicker,
}: ColorSpecificationType) => {
  const colors = colorSpecificationConstants.COLOR_FONT_OPTIONS

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} display="flex" gap={1}>
        <Typography variant="body1" fontWeight={500}>
          {titleName}
        </Typography>
        <Typography variant="body2">{subtitleName}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ButtonColorPicker
          name={buttonMainName}
          controlName={controlMainName}
          control={control as Control<PrivacyPortalThemeFormType>}
          error={errorMainName}
          colorPicker={colorPicker}
          setColorPicker={setColorPicker}
        />
      </Grid>
      <Grid item xs={12} lg={5}>
        <Controller
          render={({ field }) => (
            <FormControl fullWidth margin="dense">
              <InputLabel id="primary-font-select-label">
                {buttonFontName}
              </InputLabel>
              <Select
                {...field}
                error={!!errorFontName}
                labelId="primary-font-select-label"
                id="primary-font-select"
                label={buttonFontName}
                value={fontColor}
              >
                {colors.map((option) => (
                  <MUI.MenuItem
                    key={option.value}
                    value={option.value}
                    $textTransform="capitalize"
                  >
                    {option.name}
                  </MUI.MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!errorFontName}>
                {errorFontName?.message}
              </FormHelperText>
            </FormControl>
          )}
          name={controlFontName}
          control={control}
          defaultValue={fontColor}
        />
      </Grid>
    </Grid>
  )
}

export default ColorSpecification
