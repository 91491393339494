import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const LinearGradient = (percentage?: string) =>
  styled(Box)(({ theme }) => ({
    background: theme.palette.primary.main,
    backgroundImage: `linear-gradient(to bottom, ${
      theme.palette.primary.main
    } ${percentage || '40%'}, ${theme.palette.secondary.main} 100%)`,
  }))

export default LinearGradient
