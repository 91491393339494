import { createContext } from 'react'

import { IncidentManegementContextType } from 'providers/IncidentManegementProvider/incidentManegementProvider.types'

const contextValues: IncidentManegementContextType = {
  activeStep: 0,
  lastActiveStep: 0,
  wantsToAdvanceStep: false,
  wantsToReturnStep: false,
  setIncident: () => {},
  handleAdvanceStep: () => {},
  handleReturnStep: () => {},
  resetAdvanceStep: () => {},
  resetReturnStep: () => {},
  handleNext: () => {},
  handleBack: () => {},
  handleStep: () => {},
  setActiveStep: () => {},
  isCompleted: () => false,
}

const IncidentManegementContext = createContext(contextValues)

export default IncidentManegementContext
