import Compliance from './Compliance'
import ComplianceCard from './ComplianceCard'
import ComplianceByQuestionnaire from './ComplianceByQuestionnaire'
import Progress from './Progress'
import StatusBarChart from './StatusBarChart'
import StatusPieChart from './StatusPieChart'

export default {
  Compliance,
  ComplianceCard,
  ComplianceByQuestionnaire,
  Progress,
  StatusBarChart,
  StatusPieChart,
}
