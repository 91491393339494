import { useState } from 'react'
import { Grid, Typography } from '@mui/material'

import { BaseBox, FormNewPassword } from './components'
import { MUI } from 'components'

import theme from 'theme'

const NewPassword = () => {
  const [isLoading, setLoading] = useState<boolean>(false)

  return (
    <BaseBox title="Recuperar senha">
      <Grid item xs={12}>
        <FormNewPassword isLoading={isLoading} setLoading={setLoading} />
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <MUI.Link
            $textDecoration="none"
            color={theme.palette.neutral.main}
            to="/"
          >
            Voltar
          </MUI.Link>
        </Typography>
      </Grid>
    </BaseBox>
  )
}

export default NewPassword
