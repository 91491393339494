import { BasicDialog } from 'components'
import { Typography } from '@mui/material'

import { DestroyTaskDialogType } from './destroyTaskDialog.types'

import { useSnackbar } from 'hooks'

import { formatters } from 'helpers'
import service from 'service'

const DestroyTaskDialog = ({
  open,
  setOpen,
  taskId,
  refresh,
}: DestroyTaskDialogType) => {
  const { snackbar } = useSnackbar()

  const handleDestroy = async () => {
    try {
      await service.dponet.tasks.destroy(taskId)
      snackbar.open({
        message: 'Tarefa excluída com sucesso',
        variant: 'success',
      })
      refresh()
      setOpen(false)
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    }
  }

  return (
    <BasicDialog
      title="Excluir atividade"
      open={open}
      onClose={() => setOpen(false)}
      onSave={handleDestroy}
    >
      <Typography variant="body1">
        Tem certeza que deseja excluir essa atividade?
      </Typography>
    </BasicDialog>
  )
}

export default DestroyTaskDialog
