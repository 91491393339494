import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowLeft as ArrowLeftIcon } from 'react-feather'
import { Box, IconButton, Typography } from '@mui/material'

import { PageNameType } from './pageName.types'

const PageName = ({ name, onlyPageTitle, returnedUrl }: PageNameType) => {
  const navigate = useNavigate()

  const handleCLickTableRow = () => {
    navigate(returnedUrl)
  }

  useEffect(() => {
    document.title = `${name} - DPOnet Advisor`

    return () => {
      document.title = 'DPOnet Advisor'
    }
  }, [])

  if (onlyPageTitle) return <></>

  return (
    <Box display="flex" gap={4}>
      {returnedUrl && (
        <IconButton color="inherit" onClick={() => handleCLickTableRow()}>
          <ArrowLeftIcon />
        </IconButton>
      )}
      <Typography variant="h6" component="h1">
        {name}
      </Typography>
    </Box>
  )
}

export default PageName
