import { Box, List } from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { HelpBox, ListItem, LogoAdvisor, MenuItemButton } from './components'
import { Permitted } from 'components'

import { usePermission } from 'hooks'

import menuItems from 'constants/menuItems'
import dponetAdvisorLogo from 'images/logo_advisor.png'

const Items = () => {
  const { loaded } = usePermission()

  if (!loaded) return <></>

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      paddingY={4}
      overflow="hidden"
    >
      <PerfectScrollbar>
        <List component={Box} width={256}>
          {menuItems.map((menuItem) => (
            <Permitted
              key={menuItem.title}
              someTags={menuItem?.somePermissions}
            >
              <ListItem>
                <MenuItemButton menuItem={menuItem} />
              </ListItem>
            </Permitted>
          ))}
        </List>
      </PerfectScrollbar>
      <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
        <HelpBox />
        <LogoAdvisor src={dponetAdvisorLogo} alt="dponet advisor logo" />
      </Box>
    </Box>
  )
}

export default Items
