import { useContext } from 'react'

import { LegitimateInterest } from 'contexts'

const useLegitimateInterest = () => {
  const context = useContext(LegitimateInterest)

  if (context === undefined) {
    throw new Error(
      'useLegitimateInterest must be used within a LegitimateInterestContext.Provider'
    )
  }

  return context
}

export default useLegitimateInterest
