import { Box, Typography, colors } from '@mui/material'

import { IncidentStatsCardType } from './incidentStatusCard.types'

import theme from 'theme'

const IncidentStatsCard = ({ incidentRmcs }: IncidentStatsCardType) => {
  const finalizedRmcs = incidentRmcs.finalized + incidentRmcs.riskAccepted
  const openRmcs =
    incidentRmcs.onTime + incidentRmcs.overdue + incidentRmcs.pending

  return (
    <Box
      bgcolor={openRmcs == 0 ? colors.green[900] : colors.red[900]}
      borderRadius={1}
      p={5}
      paddingX={8}
      display="flex"
      color={theme.palette.primary.contrastText}
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Typography variant="h2" fontFamily="Roboto">
          {openRmcs}
        </Typography>
        <Typography variant="body2" fontWeight="bold">
          Incidentes em análise
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Typography variant="caption" fontWeight="bold">
          {finalizedRmcs} resolvidos
        </Typography>
      </Box>
    </Box>
  )
}

export default IncidentStatsCard
