import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const RoundedBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  borderColor: theme.palette.neutral.light,
  borderWidth: 1,
  borderStyle: 'solid',
  margin: theme.spacing(4, 3),
  padding: theme.spacing(2, 2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  color: theme.palette.neutral.light,
  width: 200,
}))

export default RoundedBox
