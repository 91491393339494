import * as yup from 'yup'

const schema = yup.object({
  content: yup.string().required(),
})

export type TicketFormFormType = yup.InferType<typeof schema>

export type TicketFormControlType = 'content' | 'attachment'

export default schema
