import { Box, Paper, Typography, colors } from '@mui/material'
import { Layers as LayersIcon } from 'react-feather'

import { AbsoluteCenteredBox } from 'components'

import polygonLightGreen from 'images/polygon_light_green_filled.svg'
import polygonGrayGreen from 'images/polygon_gray_filled.png'
import polygonRedGreen from 'images/polygon_red_filled.svg'
import theme from 'theme'
import { DetailBoxType } from './detailBox.types'

const DetailBox = ({ color, value, label }: DetailBoxType) => {
  const image = {
    green: polygonLightGreen,
    gray: polygonGrayGreen,
    red: polygonRedGreen,
  }

  const iconColor = {
    green: theme.palette.tertiary.main,
    gray: theme.palette.neutral.main,
    red: colors.red[700],
  }

  return (
    <Paper
      component={Box}
      height="100%"
      paddingX={5}
      paddingY={6}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Box display="inline-block" position="relative">
          <img
            src={image[color]}
            width={theme.spacing(10)}
            height={theme.spacing(10)}
          />
          <AbsoluteCenteredBox>
            <LayersIcon color={iconColor[color]} />
          </AbsoluteCenteredBox>
        </Box>
      </Box>
      <Typography variant="h6">{value}</Typography>
      <Typography variant="subtitle2">{label}</Typography>
    </Paper>
  )
}

export default DetailBox
