const CONTROLLER_TYPE = 'CONTROLLER'
const OPERATOR_TYPE = 'OPERATOR'
const SUBOPERATOR_TYPE = 'SUBOPERATOR'
const CONTROLLER_LABEL = 'Controlador'
const OPERATOR_LABEL = 'Operador'
const SUBOPERATOR_LABEL = 'Suboperador'

const TYPES = {
  CONTROLLER_TYPE,
  OPERATOR_TYPE,
  SUBOPERATOR_TYPE,
}

const LABELS = {
  CONTROLLER_LABEL,
  OPERATOR_LABEL,
  SUBOPERATOR_LABEL,
}

export default {
  TYPES,
  LABELS,
}
