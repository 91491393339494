import components from '../../components'

import { chapterCoverType } from './chapterCover.types'

import { ratio } from '../../helpers'
import constants from 'constants/index'
import theme from 'theme'

const chapterCover = ({ pdf, title, subtitle }: chapterCoverType) => {
  const PADDING = constants.jsPdf.PADDING
  const WIDTHPdf = constants.jsPdf.WIDTH
  const FONT_SIZE = constants.jsPdf.FONT_SIZE
  const HEIGHTPdf = constants.jsPdf.HEIGHT

  const WIDTH = WIDTHPdf(pdf)
  const HEIGHT = HEIGHTPdf(pdf)

  pdf.addPage('a4', 'p')
  pdf.setFont('Raleway-Bold')
  pdf.setFillColor(theme.palette.primary.main)
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')
  pdf.setFontSize(FONT_SIZE.TITLE)
  pdf.setTextColor(theme.palette.background.default)
  const splited_title = pdf.splitTextToSize(title, ratio(1500))
  pdf.text(splited_title, PADDING, ratio(290))
  pdf.setFontSize(FONT_SIZE.SUBTITLE)
  pdf.setFont('Raleway-Regular')
  const splited_subtitle = pdf.splitTextToSize(subtitle, ratio(1000))
  pdf.text(splited_subtitle, PADDING, HEIGHT - ratio(280))

  components.numberPage({ pdf, color: '#FFFFFF' })
}

export default chapterCover
