import { Hidden, TablePagination } from '@mui/material'

import {
  ListPageStructure,
  LoadingFeedback,
  NoData,
  PageName,
  PagePaper,
} from 'components'
import { TicketTable } from './components'

import { useFetch, useFilter, usePagination } from 'hooks'

import service from 'service'
import { TicketType } from 'types/ticket.types'
import { isEmpty } from 'lodash'

const MyTicketMain = () => {
  const filter = useFilter()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const { response, loading } = useFetch(
    service.dponet.ticket.listMyTickets,
    {
      params: {
        ...filter.filters,
        page,
        perPage,
      },
    },
    [filter.filters, page, perPage]
  )

  const tickets: TicketType[] = response?.data?.tickets ?? []

  return (
    <ListPageStructure>
      <PageName name="Minhas solicitações" />
      <LoadingFeedback open={loading} />

      <PagePaper>
        {isEmpty(tickets) ? (
          <NoData title={'Nenhuma solicitação foi encontrada.'} withoutPaper />
        ) : (
          <>
            <TicketTable tickets={tickets} />
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={(_, page) => handleChangePage(page)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </>
        )}
      </PagePaper>
    </ListPageStructure>
  )
}

export default MyTicketMain
