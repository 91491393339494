import { Button, Grid } from '@mui/material'
import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
} from 'react-feather'

import { DefaultTicketMessage } from './components'

import { TicketMessagesType } from './ticketMessages.types'
import { useState } from 'react'
import { isEmpty } from 'lodash'

const TicketMessages = ({ ticketMessages = [] }: TicketMessagesType) => {
  const [showAllMessages, setShowAllMessages] = useState(false)

  const lastMessage = ticketMessages[ticketMessages.length - 1]
  const firstMessage = ticketMessages[0]

  const messages = ticketMessages?.slice(1, -1) || []
  const messagesLength = isEmpty(ticketMessages)
    ? 0
    : ticketMessages?.length - 1

  const handleChangeShowAllMessages = () => {
    setShowAllMessages((showAllMessages) => !showAllMessages)
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {lastMessage && <DefaultTicketMessage message={lastMessage} />}
      </Grid>

      <Grid item xs={12}>
        <Button
          startIcon={showAllMessages ? <ChevronDownIcon /> : <ChevronUpIcon />}
          onClick={handleChangeShowAllMessages}
        >
          Todas as Integrações ({messagesLength})
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3}>
          {ticketMessages && ticketMessages.length > 1 && (
            <Grid item xs={12}>
              <DefaultTicketMessage message={firstMessage} />
            </Grid>
          )}

          {showAllMessages &&
            messagesLength > 0 &&
            messages.map((message, index) => (
              <Grid item key={index} xs={12}>
                <DefaultTicketMessage message={message} />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TicketMessages
