import { Button } from '@mui/material'

import { colors } from '@mui/material'
import { styled } from '@mui/material/styles'

const ButtonOption = styled(Button)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.primary.main,
  border: 'none',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    border: 'none',
    backgroundColor: colors.grey[100],
  },
  width: '20%',
}))

export default ButtonOption
