import { styled } from '@mui/material/styles'
import { Typography as TypographyMui } from '@mui/material'

const TitleFaq = styled(TypographyMui)(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: theme.spacing(97),
  [theme.breakpoints.down('lg')]: {
    maxWidth: theme.spacing(75),
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: theme.spacing(32.5),
  },
  [theme.breakpoints.down('xs')]: {
    maxWidth: theme.spacing(12.5),
    whiteSpace: 'wrap',
  },
}))

export default TitleFaq
