import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Grid, Typography } from '@mui/material'
import {
  BaseBox,
  EmailHighlighted,
  FormTwoFactorAuthentication,
  ResendEmail,
} from './components'

import { MUI } from 'components'

const TwoFactorAuthentication = () => {
  const location = useLocation()
  const userEmail = location?.state?.email
  const [loading, setLoading] = useState(false)

  return (
    <BaseBox
      title={
        <>
          Enviamos um código de verificação no seu e-mail&nbsp;
          <EmailHighlighted>{userEmail}</EmailHighlighted>
        </>
      }
    >
      <Grid item xs={12}>
        <FormTwoFactorAuthentication setLoading={setLoading} />
      </Grid>
      <Grid item xs={12}>
        <ResendEmail setLoading={setLoading} />
      </Grid>
      <Grid item xs={12}>
        <Button
          form="two-factor-authentication-form"
          variant="contained"
          type="submit"
          disabled={loading}
          fullWidth
        >
          Acessar
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <MUI.Link $textDecoration="none" to="/">
            Voltar
          </MUI.Link>
        </Typography>
      </Grid>
    </BaseBox>
  )
}

export default TwoFactorAuthentication
