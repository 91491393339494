import { Typography, Box, TextField, Button } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { BasicDialog } from 'components'

import { useSnackbar } from 'hooks'

import { DialogReproveRevisionType } from './dialogReproveRevision.types'

import { formatters } from 'helpers'
import service from 'service'
import schema, { DialogReproveRevisionSchemaType } from './schema'
import dataProcessConstants from 'constants/dataProcess'

const DialogReproveRevision = ({
  dataProcess,
  open,
  setOpen,
  setLoading,
  onEvent,
}: DialogReproveRevisionType) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      observation: '',
    },
  })

  const { snackbar } = useSnackbar()

  const handleReproveRevision = async (
    data: DialogReproveRevisionSchemaType
  ) => {
    setLoading(true)

    try {
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId: dataProcess.id,
        statusId: dataProcessConstants.DISAPPROVED_STATUS_ID,
        dataProcess: { observation: data.observation },
      })
      snackbar.open({
        message: 'Processamento de dados reprovado com sucesso!',
        variant: 'success',
      })
      onEvent()
      setLoading(false)
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <BasicDialog
      open={open}
      onClose={() => setOpen(false)}
      title="Reprovar processamento de dados"
      dialogActions={
        <>
          <Button color="primary" onClick={() => setOpen(false)}>
            CANCELAR
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            form="id-form-observation"
          >
            REPROVAR
          </Button>
        </>
      }
    >
      <Typography>
        Deseja realmente reprovar este processamento de dados?
      </Typography>
      <Box mt={2}>
        <form
          onSubmit={handleSubmit(handleReproveRevision)}
          id="id-form-observation"
        >
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                rows={4}
                label="Observação"
                color="primary"
                variant="outlined"
                error={!!errors.observation}
                helperText={errors.observation?.message}
                fullWidth
              />
            )}
            name="observation"
            control={control}
          />
        </form>
      </Box>
    </BasicDialog>
  )
}

DialogReproveRevision.defaultProps = {
  dataProcess: {},
  setOpen: () => {},
  setLoading: () => {},
  onEvent: () => {},
}

export default DialogReproveRevision
