import { BoxProps, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { PolygonFilled } from './components'
import theme from 'theme'
import { BoxFilledType } from './boxFilled.types'

const BoxPolygonFilled = ({
  value,
  label,
  ...rest
}: BoxFilledType & BoxProps) => {
  return (
    <PolygonFilled {...rest}>
      <Typography variant="h5" color={theme.palette.primary.contrastText}>
        {value}
      </Typography>
      <Typography
        variant="body1"
        color={theme.palette.primary.contrastText}
        textAlign="center"
      >
        {label}
      </Typography>
    </PolygonFilled>
  )
}

BoxPolygonFilled.propTypes = {
  data: PropTypes.array,
}

export default BoxPolygonFilled
