import axios from 'axios'
import humps from 'humps'
import service from 'service'
import qs from 'qs'

import { getBaseURL } from './env'
import routes from 'constants/routes'

export const create = (projectTag: string, treatError: boolean = true) => {
  const api = axios.create({ baseURL: getBaseURL(projectTag) })

  api.interceptors.request.use((reqConfig) => {
    const authToken = service.dponet.auth.getToken()

    if (authToken) {
      reqConfig.headers.Authorization = `${authToken}`
    }

    reqConfig.headers.crossDomain = true
    reqConfig.data = humps.decamelizeKeys(reqConfig.data)
    reqConfig.params = humps.decamelizeKeys(reqConfig.params)
    reqConfig.paramsSerializer = (params: object) => {
      return qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false,
      })
    }

    return reqConfig
  })
  api.interceptors.response.use(
    //eslint-disable-next-line
    (resp): any => humps.camelizeKeys(resp),
    (error): object => {
      if (treatError) {
        if (error?.response?.status === 401) {
          if (service.dponet.auth.isAuthenticated()) {
            location.replace(routes.app.forbidden)
          } else {
            location.replace(routes.auth.forbidden)
          }

          return Promise.reject(humps.camelizeKeys(error))
        }

        if (error?.response?.status === 404) {
          if (!error?.params?.listAnswersOrigin) {
            if (service.dponet.auth.isAuthenticated()) {
              location.replace(routes.app.notFound)
            } else {
              location.replace(routes.auth.notFound)
            }

            return Promise.reject(humps.camelizeKeys(error))
          }
        }

        if (error?.response?.status === 403) {
          if (service.dponet.auth.isAuthenticated()) {
            location.replace(routes.app.forbidden)
          } else {
            location.replace(routes.auth.forbidden)
          }

          return Promise.reject(humps.camelizeKeys(error))
        }
      }
      return Promise.reject(humps.camelizeKeys(error))
    }
  )

  return api
}

const api = { create }

export default api
