import { colors } from '@mui/material'

import components from '../../components'

import { glossaryType } from './glossary.types'

import { ratio } from '../../helpers'
import constants from 'constants/index'
import templates from '..'
import theme from 'theme'

const glossary = ({ pdf, date }: glossaryType) => {
  const PADDING = constants.jsPdf.PADDING

  templates.chapterCover({
    pdf,
    title: 'GLOSSÁRIO',
    subtitle: 'Síntese das principais informações das funcionalidades',
  })

  pdf.addPage('a4', 'p')

  components.lineWithDate({
    pdf,
    date: date,
    color: theme.palette.primary.main,
  })

  components.numberAndTitle({ pdf, number: '2', title: 'Glossário' })

  pdf.setFont('Roboto-Bold')
  pdf.setFontSize(ratio(48))
  pdf.setTextColor(theme.palette.primary.main)

  pdf.text('LGPD:', PADDING, ratio(931), undefined, 'left')
  pdf.text('RMC:', PADDING, ratio(1005), undefined, 'left')
  pdf.text('RMC pendente:', PADDING, ratio(1079), undefined, 'left')
  pdf.text('RMC no prazo:', PADDING, ratio(1269), undefined, 'left')
  pdf.text('RMC vencido:', PADDING, ratio(1445), undefined, 'left')
  pdf.text('RMC risco assumido:', PADDING, ratio(1645), undefined, 'left')
  pdf.text('RMC finalizado:', PADDING, ratio(1825), undefined, 'left')

  pdf.setFont('Roboto-Regular')

  pdf.text(
    'Lei Geral de Proteção de Dados.',
    PADDING + ratio(110),
    ratio(931),
    undefined,
    'left'
  )

  pdf.text(
    'Registro de melhoria contínua.',
    PADDING + ratio(100),
    ratio(1005),
    undefined,
    'left'
  )

  components.paragraph({
    pdf,
    text: '                             Indica que a melhoria contínua proposta ou planejada ainda não foi iniciada ou está aguardando revisão do usuário. Essa etapa envolve a identificação de oportunidades de aprimoramento e a definição de ações específicas para implementar as melhorias.',
    positionY: ratio(1079),
    textColor: theme.palette.primary.main,
  })

  components.paragraph({
    pdf,
    text: '                            Refere-se às melhorias contínuas que estão sendo executadas dentro do prazo estabelecido. Nessa fase, as ações planejadas estão em andamento, e é importante monitorar o progresso para garantir que sejam concluídas conforme o cronograma',
    positionY: ratio(1269),
    textColor: theme.palette.primary.main,
  })

  components.paragraph({
    pdf,
    text: '                          Indica que o prazo estabelecido para a implementação das melhorias contínuas foi ultrapassado. Nesse caso, é necessário investigar os motivos do atraso e tomar medidas corretivas para retomar o progresso e evitar que as melhorias sejam abandonadas.',
    positionY: ratio(1445),
    textColor: theme.palette.primary.main,
  })

  components.paragraph({
    pdf,
    text: '                                        Quando o status é classificado como "Risco Assumido", significa que a decisão foi tomada conscientemente de não prosseguir com a implementação das melhorias contínuas devido a razões específicas ou à aceitação dos riscos envolvidos.',
    positionY: ratio(1645),
    textColor: theme.palette.primary.main,
  })

  components.paragraph({
    pdf,
    text: '                              Indica que as ações de melhoria contínua foram concluídas com sucesso. Nessa etapa, as ações planejadas foram implementadas, e os resultados e benefícios esperados foram alcançados, fortalecendo as práticas de conformidade com a LGPD.',
    positionY: ratio(1825),
    textColor: theme.palette.primary.main,
  })
  components.numberPage({ pdf, color: colors.grey[900] })
}

export default glossary
