import { Box } from '@mui/material'

import { Body, BodyGridStructure, Header } from './components'

import { DrawerProvider } from 'providers'

const Navigation = ({ children }: { children: React.ReactNode }) => {
  return (
    <DrawerProvider>
      <Box gridArea="header">
        <Header />
      </Box>
      <BodyGridStructure gridArea="body">
        <Body>{children}</Body>
      </BodyGridStructure>
    </DrawerProvider>
  )
}

export default Navigation
