import { Box, styled } from '@mui/material'

const AbsoluteCenteredBox = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))

export default AbsoluteCenteredBox
