import * as yup from 'yup'

const schema = yup.object({
  kind: yup.string().max(100).required(),
  visible: yup.boolean().required(),
})

export type GeneralInformationCardSchemaType = yup.InferType<typeof schema>

export default schema
