const CUSTOM_KIND = 'custom'
const DEMAND_KIND = 'demand'
const RELATIONSHIP_SHELF_KIND = 'relationship_self'
const RELATIONSHIP_TITULAR_KIND = 'relationship_titular'
const REQUEST_KIND = 'request'

const ALL_KINDS_VALUES = [
  CUSTOM_KIND,
  DEMAND_KIND,
  RELATIONSHIP_SHELF_KIND,
  RELATIONSHIP_TITULAR_KIND,
  REQUEST_KIND,
] as const

export type KindQuestionOptionsType = (typeof ALL_KINDS_VALUES)[number]

export default {
  CUSTOM_KIND,
  DEMAND_KIND,
  RELATIONSHIP_SHELF_KIND,
  RELATIONSHIP_TITULAR_KIND,
  REQUEST_KIND,
  ALL_KINDS_VALUES,
}
