import { Database as DatabaseIcon } from 'react-feather'
import { Box, Typography, colors } from '@mui/material'

import { AbsoluteCenteredBox } from 'components'

import { TitleSectionType } from './titleSection.types'

import polygonLightBlueFilled from 'images/polygon_light_blue_filled.svg'

const TitleSection = ({ title, subtitle, icon: Icon }: TitleSectionType) => {
  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Box mr={3} position="relative">
        <img src={polygonLightBlueFilled} alt="check" height={40} />
        <AbsoluteCenteredBox>
          <Icon size={20} color={colors.blue[800]} />
        </AbsoluteCenteredBox>
      </Box>
      <Box>
        <Typography variant="h6">{title}</Typography>
        {!!subtitle && <Typography variant="body2">{subtitle}</Typography>}
      </Box>
    </Box>
  )
}

TitleSection.defaultProps = {
  icon: DatabaseIcon,
}

export default TitleSection
