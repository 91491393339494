import { Box, Paper, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import Question from '../Question'

import { NecessityType } from './necessity.types'
import { LiaQuestionType } from 'types/liaQuestion.types'
import constants from 'constants/index'
import { QuestionsDpoFormType } from '../../schema'

const Necessity = ({ questions }: NecessityType) => {
  const { MINIMIZATION, TREATMENT_INTENDED_PURPOSE } =
    constants.legitimateInterest.LIA_QUESTION_KINDS.ALL_KINDS

  const filterQuestionsMinimization = (questions: LiaQuestionType[]) => {
    return questions?.filter((question) => {
      return question?.kind === MINIMIZATION
    })
  }

  const filterQuestionsTreatmentIntendedPurpose = (
    questions: LiaQuestionType[]
  ) => {
    return questions?.filter((question) => {
      return question?.kind === TREATMENT_INTENDED_PURPOSE
    })
  }

  const questionsMinimization = filterQuestionsMinimization(questions)
  const questionsTreatmentIntendedPurpose =
    filterQuestionsTreatmentIntendedPurpose(questions)

  const {
    control,
    formState: { errors },
  } = useFormContext<QuestionsDpoFormType>()

  return (
    <Paper component={Box} mb={3} p={4}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontWeight={700} color="primary">
          Tratamento e finalidade pretendida
        </Typography>
        {questionsTreatmentIntendedPurpose?.map((question) => (
          <Question
            key={question?.id}
            question={question}
            control={control}
            errors={errors}
          />
        ))}
      </Box>
      <Box display="flex" flexDirection="column" gap={3} mt={6}>
        <Typography fontWeight={700} color="primary">
          Minimização
        </Typography>
        {questionsMinimization?.map((question) => (
          <Question
            key={question?.id}
            question={question}
            control={control}
            errors={errors}
          />
        ))}
      </Box>
    </Paper>
  )
}

export default Necessity
