import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Grid,
  Box,
  Typography,
  colors,
  Container,
  useMediaQuery,
} from '@mui/material'
import { isEmpty } from 'lodash'

import { LoadingFeedback, PageName } from 'components'
import { TicketAssigned } from 'components/Tickets'
import { TicketHead } from '../Ticket/components'
import {
  AiTicketGenerative,
  TicketInfoTable,
  TicketMessage,
  TicketTextBox,
} from './components'

import {
  useAuth,
  useFetch,
  useOrganization,
  useSnackbar,
  usePermission,
} from 'hooks'

import { UpdateParamsType } from './ticket.types'
import { TicketMessageType } from 'types/ticketMessages.types'
import { SolicitationLogsType, TicketType } from 'types/ticket.types'

import { formatters } from 'helpers'
import logoNotFound from 'images/not-found-ticket.svg'
import schema, { TicketContentFormType } from './schema'
import service from 'service'
import theme from 'theme'
import permissionTagsConstants from 'constants/permissionTags'
import ticketsConstants from 'constants/tickets'

const LegalTicketContent = () => {
  const { ticketId } = useParams()

  const {
    response: responseTicket,
    loading: loadingTicket,
    refresh: refreshTicket,
  } = useFetch(service.dponet.legalTickets.get, {
    ticketId,
  })

  const ticket: TicketType = responseTicket?.data?.ticket ?? []

  const [activeButton, setActiveButton] = useState(
    ticketsConstants.INTERNAL_MESSAGE_ID
  )
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [responsibleButtonGroup, setResponsibleButtonGroup] = useState<
    number | undefined
  >()
  const [statusButtonGroup, setStatusButtonGroup] = useState<
    number | undefined
  >()

  const isSwipeable = useMediaQuery(theme?.breakpoints?.down('lg'), {
    defaultMatches: true,
  })
  const { currentOrganization } = useOrganization()
  const { user } = useAuth()
  const { snackbar } = useSnackbar()
  const { isPermitted } = usePermission()
  const formMethods = useForm<TicketContentFormType>({
    resolver: yupResolver(schema),
  })

  const { handleSubmit, reset } = formMethods

  const isPermittedManageTicket = isPermitted(
    permissionTagsConstants.JURIDIC_TICKET.MANAGE
  )

  const {
    response: responseMessages,
    loading: loadingMessages,
    refresh: refreshMessages,
  } = useFetch(service.dponet.legalTickets.getMessages, {
    ticketId,
  })

  const {
    response: responseOrganizationUsers,
    loading: loadingOrganizationUsers,
    refresh: refreshOrganizationUsers,
  } = useFetch(service.dponet.organizationUser.get, {
    organizationId: currentOrganization?.id,
    params: { perPage: 999 },
  })

  const {
    response: responseLogs,
    loading: loadingLogs,
    refresh: refreshLogs,
  } = useFetch(service.dponet.legalTickets.getLogs, {
    ticketId,
  })

  const ticketMessages: TicketMessageType[] =
    responseMessages?.data?.ticketMessages ?? []

  const organizationUsers =
    responseOrganizationUsers?.data?.organizationUsers ?? []

  const solicitationLogs: SolicitationLogsType[] =
    responseLogs?.data?.dponetAudits ?? []

  const onSubmit = async (data: TicketContentFormType) => {
    try {
      const postMessageResponse =
        await service?.dponet?.legalTickets?.postMessages({
          ticketId,
          ticketMessages: {
            kind: activeButton,
            content: data?.content,
            attachments: Object.values(selectedFiles || {}),
          },
        })

      const updateParams: UpdateParamsType = {}

      if (
        responsibleButtonGroup &&
        responsibleButtonGroup != ticket?.responsible?.id
      ) {
        updateParams.responsibleId = responsibleButtonGroup
      }
      if (statusButtonGroup && statusButtonGroup != ticket?.status?.id) {
        updateParams.statusId = statusButtonGroup
      }
      if (!isEmpty(updateParams)) {
        await service?.dponet?.legalTickets.update({
          ticketId,
          ticket: {
            ticketMessageId: postMessageResponse?.data?.ticket_message?.id,
            ...updateParams,
          },
        })
      }

      snackbar.open({
        message: 'Mensagem enviada com sucesso!',
        variant: 'success',
      })
      reset()
      refreshTicket()
      refreshMessages()
      refreshOrganizationUsers()
      refreshLogs()
      setSelectedFiles([])
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  const isMessageAvailable =
    !loadingMessages &&
    !loadingTicket &&
    (!isEmpty(ticketMessages) || ticket?.content)

  useEffect(() => {
    if (
      ticket &&
      isEmpty(responsibleButtonGroup) &&
      isEmpty(statusButtonGroup)
    ) {
      setResponsibleButtonGroup(ticket?.responsible?.id)
      setStatusButtonGroup(ticket?.status?.id)
    }
  }, [ticket])

  return (
    <Container
      component={Box}
      paddingTop={12}
      paddingBottom={isSwipeable ? 12 : 0}
    >
      <PageName name="Ticket" onlyPageTitle />
      <LoadingFeedback open={loadingMessages || loadingLogs} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {!loadingTicket && (
            <TicketHead
              ticket={ticket}
              ticketMessages={ticketMessages}
              logs={solicitationLogs}
              loadingLogs={loadingLogs}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box display="flex" flexDirection="column">
            {!isEmpty(ticket) && <AiTicketGenerative ticket={ticket} />}
            <PerfectScrollbar>
              <Box height={isPermittedManageTicket ? 440 : 675}>
                {isMessageAvailable ? (
                  <TicketMessage
                    ticket={ticket}
                    ticketMessages={ticketMessages}
                  />
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    textAlign="center"
                    gap={5}
                    justifyContent="center"
                    height={340}
                  >
                    <img height={150} src={logoNotFound} />
                    <Typography variant="h5" color={colors.grey[500]}>
                      Nenhuma interação neste ticket
                    </Typography>
                  </Box>
                )}
              </Box>
            </PerfectScrollbar>
          </Box>
          {user && !loadingTicket && isPermittedManageTicket && (
            <FormProvider {...formMethods}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                id="'juridic-ticket-content-form'"
              >
                <TicketTextBox
                  activeButton={activeButton}
                  selectedFiles={selectedFiles}
                  setActiveButton={setActiveButton}
                  setSelectedFiles={setSelectedFiles}
                  setStatusButtonGroup={setStatusButtonGroup}
                  statusButtonGroup={statusButtonGroup}
                  ticket={ticket}
                  user={user}
                />
              </form>
            </FormProvider>
          )}
        </Grid>
        <Grid item lg={4} xs={12}>
          <Box display="flex" flexDirection="column" gap={6}>
            {!loadingOrganizationUsers && !loadingTicket && (
              <TicketAssigned
                ticket={ticket}
                organizationUsers={organizationUsers}
                responsibleButtonGroup={responsibleButtonGroup}
                setResponsibleButtonGroup={setResponsibleButtonGroup}
                statusButtonGroup={statusButtonGroup}
                isJuridic={true}
              />
            )}
            {!loadingTicket && <TicketInfoTable ticket={ticket} />}
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default LegalTicketContent
