import 'configs/yup'
import 'react-perfect-scrollbar/dist/css/styles.css'

import ptBR from 'date-fns/locale/pt-BR'

import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { SnackbarProvider } from 'providers'
import Router from 'Router'
import theme from 'theme'

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
          <Router />
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
