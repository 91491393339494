import { isEmpty } from 'lodash'
import { IncidentOptionType } from 'types/incidents.types'

const mountOption = (
  options: IncidentOptionType[],
  other: string,
  literaName: string
) => {
  const newOptionOther = {
    keyName: 'other',
    name: other,
    typeField: literaName,
  }

  if (other) {
    if (isEmpty(options)) {
      return [newOptionOther]
    }

    const newOptions = options.filter((option) => option.keyName !== 'other')
    return [...newOptions, newOptionOther]
  }

  return options
}

export default mountOption
