import { Grid, Typography } from '@mui/material'

import { Card } from 'components'
import { Report } from 'views/IncidentManegements/components'

import { useIncidentManegement, useOrganization } from 'hooks'

const CommunicationStep = () => {
  const { currentOrganization } = useOrganization()
  const { incident } = useIncidentManegement()

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card title="Relatório Simplificado">
          <Typography variant="body1" gutterBottom>
            O relatório simplificado é gerado com base nos dados da etapa de
            <strong>“INVESTIGAÇÃO”</strong>. Ele não deve ser utilizado para
            notificar a ANPD.
          </Typography>

          {incident && (
            <Report.SimplifiedIncident
              organization={currentOrganization}
              incidents={incident}
            />
          )}
        </Card>
      </Grid>
      {incident && !incident?.simplifiedForm && (
        <Grid item xs={12}>
          <Card title="Relatório Completo">
            <Typography variant="body1" gutterBottom>
              O relatório completo é o documento a ser utilizado caso seja
              necessário notificar a ANPD.
            </Typography>

            <Report.CompleteIncident
              organization={currentOrganization}
              incidents={incident}
            />
          </Card>
        </Grid>
      )}
    </Grid>
  )
}

export default CommunicationStep
