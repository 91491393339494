import { BasicDialog } from 'components'
import { TaskForm } from './components'
import { TaskFormDialogType } from './taskFormDialog.types'

const TaskFormDialog = ({
  open,
  setOpen,
  task,
  refresh,
}: TaskFormDialogType) => {
  const onClose = () => setOpen(false)

  return (
    <BasicDialog
      title={task?.id ? 'Editar' : 'Criar'}
      open={open}
      onClose={onClose}
      form="form-task"
    >
      <TaskForm task={task} refresh={refresh} onClose={onClose} />
    </BasicDialog>
  )
}

export default TaskFormDialog
