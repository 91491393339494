import OrganizationDashboard from './OrganizationDashboard'
import OrganizationMain from './OrganizationMain'
import OrganizationUpdate from './OrganizationUpdate'

const Organization = {
  OrganizationDashboard,
  OrganizationMain,
  OrganizationUpdate,
}

export default Organization
