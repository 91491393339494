import { useEffect, useState } from 'react'
import { AxiosError } from 'axios'

import { Box, Typography } from '@mui/material'
import { MUI } from 'components'

import { useSnackbar } from 'hooks'
import { useNavigate, useParams } from 'react-router-dom'

import { ResendEmailType } from './resendEmail.types'

import { formatters } from 'helpers'

import theme from 'theme'
import service from 'service'
import routes from 'constants/routes'

const ResendEmail = ({ setLoading }: ResendEmailType) => {
  const navigate = useNavigate()
  const params = useParams()
  const { snackbar } = useSnackbar()
  const [enableResendButton, setEnableResendButton] = useState(false)
  const [counter, setCounter] = useState(30)

  const resendEmail = async () => {
    setEnableResendButton(false)
    setLoading(true)
    try {
      await service.dponet.twoFactorAuthentication.resend({
        accessToken: params?.accessToken,
      })
    } catch (e) {
      let message = formatters.errorMessage(e)
      if ((e as AxiosError)?.response?.status === 403) {
        message = 'Tempo expirado, faça login novamente!'
        navigate(routes.root)
      }

      snackbar.open({
        message: message,
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!enableResendButton) setEnableResendButton(counter === 0)

    const timer = enableResendButton
      ? null
      : setInterval(() => setCounter((c) => c - 1), 1000)

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [counter])

  useEffect(() => {
    if (!enableResendButton && counter !== 1) setCounter(30)
  }, [enableResendButton])

  return (
    <Box>
      <Typography variant="subtitle1">
        Não recebeu seu código?&nbsp;
        {enableResendButton ? (
          <MUI.Link
            $color={theme.palette.support.main}
            $cursor="pointer"
            $textDecoration="none"
            to="#"
            onClick={resendEmail}
          >
            Reenvie
          </MUI.Link>
        ) : (
          <strong>Reenvie em {counter} segundos</strong>
        )}
      </Typography>
    </Box>
  )
}

export default ResendEmail
