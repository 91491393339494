import { Calendar as CalendarIcon } from 'react-feather'
import { Box, Typography, colors } from '@mui/material'

import { HeaderType } from './header.types'

import { dataProcesses, formatters } from 'helpers'

const Header = ({ status, updatedAt }: HeaderType) => {
  return (
    <Box width="100%" display="flex" justifyContent="space-between">
      <Box
        display="flex"
        alignItems="center"
        border={1}
        borderColor={colors.grey[300]}
        borderRadius={1}
        p={2}
      >
        <Box
          bgcolor={dataProcesses.statusColor(status)}
          borderRadius={5}
          py={1.5}
          px={2}
          mr={2}
        >
          {dataProcesses.statusIcon(status, 22)}
        </Box>
        <Typography variant="button">
          {dataProcesses.statusLabel(status)?.toUpperCase()}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box display="flex" flexDirection="column" pr={0.5} textAlign="end">
          <Typography variant="body2" fontWeight={500}>
            Última atualização
          </Typography>
          <Typography variant="caption">{`feita ${formatters.dateFromNow(
            updatedAt
          )}`}</Typography>
        </Box>
        <CalendarIcon />
      </Box>
    </Box>
  )
}

export default Header
