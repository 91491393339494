import { Box } from '@mui/material'

import { InformativeBox } from 'components'
import { CompanyStatusRow } from './components'

import { CompanyStatusCardType } from './companyStatusCard.types'
import {
  Briefcase as BriefcaseIcon,
  Star as StarIcon,
  User as UserIcon,
  Box as BoxIcon,
} from 'react-feather'

const CompanyStatusCard = ({ company }: CompanyStatusCardType) => {
  const companyStats = [
    {
      title: 'Quantidade de Departamentos',
      value: company.stats.general.quantityDepartments,
      icon: BriefcaseIcon,
    },
    {
      title: 'Quantidade de Usuários',
      value: company.stats.general.quantityUsers,
      icon: UserIcon,
    },
    {
      title: 'Meus Fornecedores',
      value: company.stats.supplier.mySuppliers.length,
      icon: BoxIcon,
    },
    {
      title: 'Sou Fornecedor',
      value: company.stats.supplier.providingSuppliers.length,
      icon: StarIcon,
    },
  ]
  return (
    <InformativeBox title="Status">
      <Box
        display="flex"
        gap={3}
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        {companyStats.map((companyStat) => (
          <CompanyStatusRow key={companyStat.title} {...companyStat} />
        ))}
      </Box>
    </InformativeBox>
  )
}

export default CompanyStatusCard
