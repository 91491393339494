import React from 'react'
import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Edit2 } from 'react-feather'
import { reverse } from 'named-urls'

import {
  BigAvatar,
  ConditionalLoading,
  DefaultPageStructure,
  PageName,
  Permitted,
} from 'components'
import {
  CompanyByCompliance,
  // CompanyTrailsCard,
  OrganizationDetails,
  OrganizationPdf,
} from './components'
import {
  ComplianceByPercentage,
  DataProcess,
  HealthScorePieChart,
  IncidentStatsCard,
  Questionnaire,
} from 'views/Company/components'

import { useOrganization, useFetch } from 'hooks'

import {
  CompanyStats,
  // CompanyTrailStatsType,
  OrganizationStats,
  QuestionnairesStats,
} from 'types/organization.types'
import { NonComplianceReportStatusType } from 'types/nonComplianceReport.types'
import { DataProcessStatsType } from 'types/dataProcess.types'

import { companies as companiesHelpers } from 'helpers'
import service from 'service'
import theme from 'theme'
import routes from 'constants/routes'
import permissionTagsConstants from 'constants/permissionTags'

const OrganizationDashboard = () => {
  const { currentOrganization } = useOrganization()
  const navigate = useNavigate()

  const params = {
    organizationId: currentOrganization?.id,
  }

  const { response: organizationStatsResponse, loading: organizationLoading } =
    useFetch(
      service.dponet.organizationStats.organization,
      params,
      [],
      !!currentOrganization
    )

  const { response: companyStatsResponse, loading: companyLoading } = useFetch(
    service.dponet.organizationStats.company,
    params,
    [],
    !!currentOrganization
  )

  // const { response: companyTrailStatsResponse, loading: companyTrailLoading } =
  //   useFetch(
  //     service.dponet.organizationStats.companyTrail,
  //     params,
  //     [],
  //     !!currentOrganization
  //   )

  const { response: incidentStatsResponse, loading: incidentLoading } =
    useFetch(
      service.dponet.organizationStats.incident,
      params,
      [],
      !!currentOrganization
    )

  const {
    response: questionnaireStatsResponse,
    loading: questionnaireLoading,
  } = useFetch(
    service.dponet.organizationStats.questionnaire,
    params,
    [],
    !!currentOrganization
  )

  const { response: dataProcessStatsResponse, loading: dataProcessLoading } =
    useFetch(
      service.dponet.organizationStats.dataProcess,
      params,
      [],
      !!currentOrganization
    )

  const organizationStats: OrganizationStats = organizationStatsResponse?.data
  const companyStats: CompanyStats[] = companyStatsResponse?.data
  // const companyTrailStats: CompanyTrailStatsType[] =
  //   companyTrailStatsResponse?.data
  const incidentStats: NonComplianceReportStatusType =
    incidentStatsResponse?.data
  const questionnaireStats: QuestionnairesStats[] =
    questionnaireStatsResponse?.data
  const dataProcessStats: Omit<DataProcessStatsType, 'nonComplianceReports'> =
    dataProcessStatsResponse?.data

  const gapCompliance =
    companyStats?.reduce((prev, current) => prev + current.gapCompliance, 0) /
    (companyStats?.length || 1)
  const companyHealthScore =
    companyStats?.reduce((prev, current) => prev + current.healthScore, 0) /
    (companyStats?.length || 1)
  const companies = companyStats || []

  const handleClickRedirectToEdit = () => {
    navigate(
      reverse(routes.app.organizations.update, {
        organizationId: Number(currentOrganization?.id),
      })
    )
  }

  return (
    <DefaultPageStructure>
      <PageName name="Dashboard" onlyPageTitle />

      <ConditionalLoading
        loading={
          organizationLoading ||
          companyLoading ||
          // companyTrailLoading ||
          incidentLoading ||
          questionnaireLoading ||
          dataProcessLoading
        }
        centered
        height="100%"
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={2}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <BigAvatar
                alt={currentOrganization?.name}
                src="broken-image.png"
                $background={theme.palette.secondary.main}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              height="100%"
            >
              <Typography variant="h5" component="h1" color="primary">
                {currentOrganization?.name}
              </Typography>

              <Permitted
                someTags={[
                  permissionTagsConstants.ORGANIZATION_COMPANY.MANAGE,
                  permissionTagsConstants.ORGANIZATION_USER.MANAGE,
                ]}
              >
                <Hidden smUp>
                  <IconButton onClick={handleClickRedirectToEdit}>
                    <Edit2 size={18} />
                  </IconButton>
                </Hidden>
              </Permitted>

              <Hidden smUp>
                <IconButton onClick={handleClickRedirectToEdit}>
                  <Edit2 size={18} />
                </IconButton>
              </Hidden>

              <Box display="flex" flexDirection="column">
                <Permitted
                  someTags={[
                    permissionTagsConstants.ORGANIZATION_COMPANY.MANAGE,
                    permissionTagsConstants.ORGANIZATION_USER.MANAGE,
                  ]}
                >
                  <Hidden smDown>
                    <Button
                      variant="outlined"
                      startIcon={<Edit2 />}
                      onClick={handleClickRedirectToEdit}
                    >
                      EDITAR GRUPO ADVISOR
                    </Button>
                  </Hidden>
                </Permitted>
                <Box marginTop={2}>
                  <OrganizationPdf
                    organizationStats={organizationStats}
                    incidentStats={incidentStats}
                    dataProcessStats={dataProcessStats}
                    gapCompliance={gapCompliance}
                    companyStats={companyStats}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <OrganizationDetails
              organization={currentOrganization}
              organizationStats={organizationStats}
            />
          </Grid>
          {organizationStats?.quantityCompanies !== 0 && (
            <React.Fragment>
              <Grid
                item
                xs={12}
                md={6}
                // display="grid"
                // gridTemplateRows="auto 1fr"
                // gap={4}
              >
                <HealthScorePieChart
                  healthScore={Number(companyHealthScore.toFixed(0))}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <IncidentStatsCard incidentRmcs={incidentStats} />
                {/*   <CompanyTrailsCard
                  companyTrails={companyTrailStats || []}
                  quantityCompanies={organizationStats?.quantityCompanies}
                /> */}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Processos
                </Typography>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <DataProcess.DetailsStatus
                  generalDataProcessStats={dataProcessStats}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <DataProcess.DetailsRisks
                  generalDataProcessStats={dataProcessStats}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <DataProcess.StatusPieChart
                  generalDataProcessStats={dataProcessStats}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Questionários
                </Typography>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Questionnaire.Compliance gapCompliance={gapCompliance} />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Questionnaire.ComplianceByQuestionnaire
                  questionnairiesStats={questionnaireStats || []}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Empresas
                </Typography>
              </Grid>

              <Grid item xs={12} lg={4}>
                <ComplianceByPercentage
                  title="Conformidade de minhas empresas"
                  subtitle="Acompanhe o progresso de suas empresas"
                  zeroToTwenty={companiesHelpers.companyByHealthScore.count(
                    companies,
                    0,
                    20
                  )}
                  twentyToForty={companiesHelpers.companyByHealthScore.count(
                    companies,
                    20,
                    40
                  )}
                  fortyToSixty={companiesHelpers.companyByHealthScore.count(
                    companies,
                    40,
                    60
                  )}
                  sixtyToEighty={companiesHelpers.companyByHealthScore.count(
                    companies,
                    60,
                    80
                  )}
                  eightyToHundred={companiesHelpers.companyByHealthScore.count(
                    companies,
                    80,
                    101
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <CompanyByCompliance
                  id="goodCompliance"
                  title="Empresas com boa conformidade"
                  companiesStats={companies}
                  order="desc"
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <CompanyByCompliance
                  id="badCompliance"
                  title="Empresas com baixa conformidade"
                  companiesStats={companies}
                  order="asc"
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </ConditionalLoading>
    </DefaultPageStructure>
  )
}

export default OrganizationDashboard
